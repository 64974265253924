import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Teammates from "../Teammates";
import TeammateCommunity from "../TeammateCommunity";
import TeammateLandingQuestion from "../TeammateLandingQuestion";
import TeammateBest from "../TeammateBest";
import LoginModel from "components/AuthModel/LoginModel";
import Forgotpassword from "components/AuthModel/Forgotpassword";
import SignupModel from "components/AuthModel/SignupModel";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiGrid-spacing-xs-2": {
      marginTop: 0,
    },
  },
  propertyImage: {
    width: "100vw",
    filter: "brightness(0.5)",
  },
  Findahome: {
    fontSize: "36px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#FFFFFF",
    fontWeight: 600,
    margin: 0,
    textAlign: "end",
  },

  propertyImageLabel: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
    width: "100%",
  },
  cardHeading: {
    color: "#FE4B6B",
    fontSize: 32,
    fontWeight: 600,
  },
  input_label: {
    fontSize: 16,
    color: "#000000",
    fontWeight: 500,
    marginTop: 10,
  },
  learMoreButton: {
    textTransform: "capitalize",
    width: "100%",
    height: "45px",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: 400,
    marginTop: 14,
    borderRadius: 8,
  },
  learMoreDetailButton: {
    textTransform: "capitalize",
    width: "25%",
    height: "45px",
    fontWeight: 400,
    marginTop: 14,
    color: "#FFF",
    borderColor: "#FFF",
    borderRadius: 8,
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  form__input: {
    marginTop: 14,
    background: "#FFFFFF",
    borderRadius: 9,
    height: 45,
    width: "100%",
  },
  radioGroup: {
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
      width: "100%",
      height: 45,
      background: "#FFFFFF",
      borderRadius: 8,
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#fff;",
    },
  },
  gridRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 25px",
    [theme.breakpoints.down("xs")]: {
      padding: "17px 25px",
    },
  },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

const TeammateLandingDetails = (props) => {
  const classes = useStyles();
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
  };
  const onLoginOpen = () => {
    setLoginOpen(true);
    setForgotpasswordOpen(false);
    setOpen(false);
  };

  const onSignupOpen = () => {
    setLoginOpen(false);
    setOpen(true);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true);
  };

  return (
    <>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} style={{ position: "relative" }}>
            <img
              src="/images/matchUp/TeammatesLanding.jpg"
              alt="TeammatesLanding"
              className={classes.propertyImage}
            />
            <div className={classes.propertyImageLabel}>
              <Grid container justify="center">
                <Grid item xs={12} sm={5} md={5} lg={4} xl={3}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5}
                  className={classes.gridRoot}
                >
                  <h1 className={classes.Findahome}>
                    Find your Dream Team from MatchUpMates
                  </h1>
                  <div style={{ textAlign: "end" }}>
                    <Button
                      fullWidth
                      className={classes.learMoreDetailButton}
                      variant="outlined"
                      onClick={handleClickOpenLogin}
                    >
                      Find Teammates
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <Teammates />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <TeammateCommunity />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}></Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <TeammateLandingQuestion />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <TeammateBest />
          </Grid>
        </Grid>
        <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
        <LoginModel
          loginOpen={loginOpen}
          setLoginOpen={setLoginOpen}
          setOpenSignUp={onSignupOpen}
          setForgotpasswordOpen={onForgotpasswordOpen}
        />
        <Forgotpassword
          loginOpen={forgotpasswordOpen}
          setLoginOpen={onLoginOpen}
          setOpenSignUp={onSignupOpen}
        />
      </div>
    </>
  );
};

export default TeammateLandingDetails;
