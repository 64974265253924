import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Badge,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Popover,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TelegramIcon from "@material-ui/icons/Telegram";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { Link as browserLink, useLocation } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Checkbox from "@material-ui/core/Checkbox";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import ReactFancyBox from "react-fancybox";
import useRouter from "utils/useRouter";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BlockIcon from "@material-ui/icons/Block";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../Helper/ApiHandler";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import firebase from "firebase/app";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import _ from "lodash";
import * as Scroll from "react-scroll";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ClearChatDialog from "components/ClearChatModel/ClearChatModel";
import ExitGroupModel from "components/ExitGroupModel/ExitGroupModel";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  bgProfilePage: {
    background: "#e9edf0",
    // padding: "35px 0",
    // minHeight: "calc(100vh - 125px)",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  inboxRoot: {
    display: "flex",
    padding: "5px",
    cursor: "pointer",
  },
  inboxImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  inboxNameRoot: {
    marginLeft: "10px",
  },
  inboxName: {
    fontSize: "14px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxLastMsg: {
    fontSize: "11px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxTime: {
    fontSize: "11px",
    fontWeight: "400",
  },
  inboxCountRoot: {
    "& .MuiBadge-badge": {
      fontSize: "10px",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: "auto",
    },
    marginLeft: "auto",
    marginRight: "10px",
  },
  groupEditMsgRoot: {
    display: "flex",
    justifyContent: "center",
  },
  groupEditMsg: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#FFF7F8",
    borderRadius: "5px",
    padding: "3px",
    margin: "3px",
    color: "#F24451",
  },

  myMessageRoot: {
    display: "flex",
    justifyContent: "flex-end",
  },
  myMessage: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px 0px",
    padding: "10px",
    margin: "3px",
    color: "#3F444F",
    maxWidth: "50%",
    display: "flex",
    wordBreak: "break-all",
  },
  myMessageTime: {
    fontSize: "10px",
    fontWeight: "400",
    minWidth: "45px",
    marginLeft: "10px",
    alignSelf: "flex-end",
    color: "#A2A2AB",
    position: "relative",
  },
  myDoubleTick: {
    fontSize: "16px",
    bottom: "0px",
    position: "absolute",
    marginLeft: "5px",
  },

  yourMessageRoot: {
    display: "flex",
    justifyContent: "flex-start",
  },
  yourMessage: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px 0px",
    padding: "10px",
    margin: "3px",
    color: "#3F444F",
    maxWidth: "50%",
    wordBreak: "break-all",
  },
  yourMessageTime: {
    fontSize: "10px",
    fontWeight: "400",
    minWidth: "25px",
    marginLeft: "10px",
    alignSelf: "flex-end",
    color: "#A2A2AB",
  },
  yourName: {
    color: "#E84A62",
    fontSize: "12px",
    textTransform: "capitalize",
    marginBottom: "3px",
    fontWeight: "500",
  },

  mainInputMessage: {
    width: "98%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  iconSendBg: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    borderRadius: "100%",
    width: "35px",
    height: "35px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "white",
    cursor: "pointer",
  },
  iconSendBgSmallDevice: {
    background: "#FF695B",
    borderRadius: "100%",
    width: "34px",
    height: "34px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    marginTop: "2px",
  },
  inputBoxWrite: {
    background: "white",
    width: "100%",
    height: "60px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },
    display: "flex",
    alignItems: "center",
    borderRadius: "0px 0px 10px 10px",
    borderTop: "1px solid #d9d9d9",
  },
  menuRoot: {
    marginRight: "10px",
  },
  menu: {
    cursor: "pointer",
    "& .MuiPaper-rounded": {
      borderRadius: "10px",
    },
  },
  typographyMenu: {
    fontSize: "14px",
    padding: "5px",
  },
  exitnote: {
    color: "#E84A62",
    fontSize: "12px",
    width: "350px",
    margin: "auto",
    textAlign: "center",
  },
}));

const GChat = (props) => {
  const classes = useStyles();
  const router = useRouter();
  var scroll = Scroll.animateScroll;
  const listInnerRef = useRef();
  const { clickInbox, lstTmpMessage, setLstTmpMessage, receiverId } = props;

  const [lstInbox, setLstInbox] = useState([]);
  const [lstTmpInbox, setLstTmpInbox] = useState([]);
  // const [lstTmpMessage, setLstTmpMessage] = useState([])
  const [senderId, setSenderId] = useState("");
  const [userName, setUserName] = useState("");
  const [chatType, setChatType] = useState("");
  const [openClearChat, setOpenClearChat] = useState(false);
  const [openExitGroup, setopenExitGroup] = useState(false);

  const [activityRef, setActivityRef] = useState("");
  const [clearAt, setClearAt] = useState(null);
  const [exitAt, setExitAt] = useState(null);
  const [exitAt1, setExitAt1] = useState(null);
  const [lastSeenMsg, setLastSeenMsg] = useState(null);

  const [message, setMessage] = useState("");
  const [objSelectedGroup, setObjSelectedGroup] = useState({
    name: "",
    no_of_members: "",
    profile_picture: "",
    slug: "",
    groupId: "",
  });
  const [objSelectedUser, setObjSelectedUser] = useState({
    name: "",
    profile_picture: "",
    slug: "",
    userId: "",
  });

  const [sessionMessage, setSessionMessage] = useState({
    message: "",
    type: "",
    open: false,
  });
  let query = useLocation();

  const db = firebase.firestore();
  // menu start
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSingleUser = (event) => {
    setAnchorEl1(event.currentTarget);
    // console.log("handleClickSingleUser")
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuCloseSingleUser = () => {
    setAnchorEl1(null);
  };



  const openMenu = Boolean(anchorEl);
  const openMenu2 = Boolean(anchorEl1);
  const idMenu = openMenu ? "simple-popover" : undefined;
  const idMenu2 = openMenu2 ? "simple-popover" : undefined;
  // menu end

  const onOpenClearChat = (receiverId) => {
    let tmp = db
      .collection("message")
      .doc(senderId)
      .collection("activity")
      .doc(receiverId);
    setOpenClearChat(true);
    setActivityRef(tmp);
    handleMenuClose();
  };

  const onCloseClearChat = () => {
    setOpenClearChat(false);
  };

  const onCloseExitGroup = () => {
    setopenExitGroup(false);
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };


  const onOpenClearChatSingleUser = () => {
    console.log("Clear Chat button clicked")
  }

  // const hasMoreMessageEnd = () => {
  //   // fetch message for group
  //   let start = lstTmpMessage[lstTmpMessage.length - 1]?.createdAt ? lstTmpMessage[lstTmpMessage.length - 1]?.createdAt : new Date()

  //   let messageRef = db.collection("message").doc(receiverId).collection("messages").orderBy("createdAt", "asc").limit(5).startAt(start)
  //   messageRef.onSnapshot((querySnapshot) => {
  //     let temp = [];
  //     querySnapshot.forEach((mainDoc) => {
  //       temp.push(mainDoc.data())

  //       // seen message
  //       let lstReadBy = mainDoc.data().readBy;
  //       let lstNewReadBy = lstReadBy?.includes(senderId) ? lstReadBy : [...lstReadBy, senderId];
  //       if (!lstReadBy?.includes(senderId)) {
  //         let msgRefSeen = db.collection("message").doc(receiverId).collection("messages").doc(mainDoc.data().messageId);
  //         msgRefSeen.set({ ...mainDoc.data(), readBy: lstNewReadBy });
  //       }
  //     });
  //     let lstTmpOld = [...lstTmpMessage]
  //     let lstTmp = temp.filter((obj) => obj?.messageId != lstTmpMessage[lstTmpMessage.length - 1].messageId)
  //     temp.length && setLstTmpMessage(_.concat(lstTmpOld, lstTmp))
  //     let scr = document.getElementById('chat')
  //     scr && scr.scrollTo && scr.scrollTo('0', scr.scrollHeight)
  //   });
  // }

  // const hasMoreMessageStart = async () => {
  //   // fetch message for group
  //   let start = lstTmpMessage[0] && lstTmpMessage[0].createdAt
  //   let receiverId = await AsyncLocalStorage.getItem("receiverId")

  //   if (start) {
  //     let messageRef = exitAt ?
  //       db.collection("message").doc(receiverId).collection("messages")
  //         .orderBy("createdAt", "desc").limit(5).startAt(start).where("createdAt", ">", clearAt).where("createdAt", "<", exitAt)
  //       :
  //       db.collection("message").doc(receiverId).collection("messages")
  //         .orderBy("createdAt", "desc").limit(5).startAt(start).where("createdAt", ">", clearAt)

  //     messageRef.onSnapshot((querySnapshot) => {
  //       let temp = [];
  //       querySnapshot.forEach((mainDoc) => {
  //         temp.push(mainDoc.data())

  //         // seen message
  //         let lstReadBy = mainDoc.data().readBy
  //         let lstNewReadBy = lstReadBy?.includes(senderId) ? lstReadBy : [...lstReadBy, senderId]
  //         if (!lstReadBy?.includes(senderId)) {
  //           let msgRefSeen = db.collection("message").doc(receiverId).collection("messages").doc(mainDoc.data().messageId)
  //           msgRefSeen.set({ ...mainDoc.data(), readBy: lstNewReadBy })
  //         }
  //       });
  //       let lstTmpOld = [...lstTmpMessage]
  //       let lstTmp = temp.filter((obj) => obj.messageId != lstTmpMessage[0].messageId)

  //       temp.length && setLstTmpMessage(_.concat(lstTmp, lstTmpOld))
  //       lstTmp.length && scr.scrollTo('0', '50')
  //     });

  //     let scr = document.getElementById('chat')
  //   }
  // }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // hasMoreMessageEnd()
      }
      if (!scrollTop) {
        // hasMoreMessageStart()
      }
    }
  };

  // useEffect(() => {
  //   checkReciverID()
  // }, []);

  let checkReciverID = async () => {
    // let receiverId = await AsyncLocalStorage.getItem("receiverId")
    // if (receiverId) {
    //   router.history.push(`/messages/${receiverId}`)
    // }
  };

  useEffect(() => {
    // console.log("lstTmpMessage",lstTmpMessage)
    // console.log("setLstTmpMessage",setLstTmpMessage)
    // console.log("receiverId",receiverId)
    onload();
  }, [receiverId]);
  // }, [clickInbox, clearAt]);

  // useEffect(() => {
  //   if (lstTmpMessage?.[0]?.receiverId != receiverId) {
  //     onload(receiverId)
  //     // console.log(lstTmpMessage?.[0]?.receiverId, "!=", receiverId, "update manthan")
  //   }
  // }, [lstTmpMessage]);

  // useEffect(() => {
  //   onload(receiverId)
  // }, []);
  // }, [clickInbox]);

  // let onloadIndex = async () => {
  //   // get Inbox
  //   let inboxRef = db.collection("message").doc(senderId).collection("inbox")
  //   inboxRef.orderBy("createdAt", "desc")
  //     .onSnapshot((querySnapshot) => {
  //       let temp = [];
  //       querySnapshot.forEach((mainDoc) => {
  //         temp.push(mainDoc.data())
  //       });
  //       setLstTmpInbox(temp)
  //     });

  // }

  let onload = async () => {
    let senderId = await AsyncLocalStorage.getItem("senderId");
    let user_name = await AsyncLocalStorage.getItem("userName");

    if (senderId === "") return;
    setSenderId(senderId);
    setUserName(user_name);

    let chatType = await AsyncLocalStorage.getItem("chat_type");
    // console.log("chatType",chatType)
    setChatType(chatType);
    if (chatType == "group") {
      console.log("Group Chat");
      let receiverName = await AsyncLocalStorage.getItem("receiverName");
      let no_of_members = await AsyncLocalStorage.getItem("no_of_members");
      let receiverProfilePicuture = await AsyncLocalStorage.getItem(
        "receiverProfilePicuture"
      );
      let to_slug = await AsyncLocalStorage.getItem("to_slug");
      // let receiverId = await AsyncLocalStorage.getItem("receiverId")
      let lstMember = await AsyncLocalStorage.getItem("group_members");

      // console.log(lstMember, "lstMember+++")

      setObjSelectedGroup({
        name: receiverName,
        no_of_members: no_of_members,
        profile_picture: receiverProfilePicuture,
        slug: to_slug,
        groupId: receiverId,
        members:
          lstMember && lstMember != "undefined" ? JSON.parse(lstMember) : [],
      });

      // console.log("lstMember+++", "asd")

      // cleareAt get
      var groupRef = db
        .collection("message")
        .doc(senderId)
        .collection("activity")
        .doc(receiverId);

      groupRef.get().then((doc) => {
        if (doc.data()) {
          if (exitAt) {
            if (exitAt?.seconds != doc.data().exitAt?.seconds) {
              setExitAt(doc.data().exitAt)
            }
          } else {
            if (doc.data().exitAt) {
              setExitAt(doc.data().exitAt)
            }
          }

          if (clearAt) {
            if (clearAt?.seconds != doc.data().clearAt?.seconds) {
              setClearAt(doc.data().clearAt)
            }
          } else {
            setClearAt(doc.data().clearAt)
          }
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      })

      // groupRef.onSnapshot((doc) => {
      //   if (doc.data()) {
      //     if (exitAt) {
      //       if (exitAt?.seconds != doc.data().exitAt?.seconds) {
      //         setExitAt(doc.data().exitAt)
      //       }
      //     } else {
      //       if (doc.data().exitAt) {
      //         setExitAt(doc.data().exitAt)
      //       }
      //     }

      //     if (clearAt) {
      //       if (clearAt?.seconds != doc.data().clearAt?.seconds) {
      //         setClearAt(doc.data().clearAt)
      //       }
      //     } else {
      //       setClearAt(doc.data().clearAt)
      //     }
      //   }
      // });
      // console.log(exitAt, "exitAt")
      // console.log(receiverId, "receiverId++++")
      // fetch message for group
      // let receiverIdLocalStorage = lstTmpMessage.length ? lstTmpMessage[0].receiverId : await AsyncLocalStorage.getItem("receiverId")
      let receiverIdLocalStorage = receiverId;

      // if (receiverIdLocalStorage != query.pathname.split("/")[2]) {
      //   return
      // }



      console.log("receiverIdLocalStorage", receiverId);

      // let messageRef = exitAt ? db.collection("message").doc(receiverIdLocalStorage).collection("messages").orderBy("createdAt", "desc").where("createdAt", "<", exitAt) : db.collection("message").doc(receiverIdLocalStorage).collection("messages").orderBy("createdAt", "desc")
      let messageRef = db
        .collection("message")
        .doc(localStorage.getItem("receiverId"))
        .collection("messages")
        .orderBy("createdAt", "desc");
      // .orderBy("createdAt", "desc").where("createdAt", ">", clearAt).where("createdAt", "<", exitAt)

      // messageRef.orderBy("createdAt", "asc").limit(15)
      messageRef.onSnapshot((querySnapshot) => {
        let temp = [];
        querySnapshot.forEach((mainDoc) => {
          console.log("mainDoc \\\\\\\\\\", mainDoc.data());
          temp.push(mainDoc.data());
          // console.log("receiverIdLocalStorage444",localStorage.getItem("receiverId"))
          console.log("trmp msg", temp);

          // Read message

          let lstReadBy = mainDoc.data().readBy;
          let lstNewReadBy = lstReadBy?.includes(senderId) ? lstReadBy : [...lstReadBy, senderId];
          // console.log("lstReadBy", lstReadBy)
          // console.log("senderId", senderId)
          let queryparams = window.location.href;
          let getUrlid = queryparams.split('/')[4]
          // console.log("window1", getUrlid)
          // console.log("lstNewReadBy", lstNewReadBy)
          let bs = [];
          if (lstReadBy?.includes(senderId)) {
            console.log("che j");
          } else {
            console.log("getUrlid", getUrlid)
            console.log("localStorage.getItem", localStorage.getItem("receiverId"))
            if (getUrlid === mainDoc.data().receiverId) {
              // console.log("localStorage.getItem",localStorage.getItem("receiverId"))
              // // bs.push(localStorage.getItem("receiverId"))
              // // console.log("bs",mainDoc.data().receiverId)
              // console.log("getUrlid",getUrlid)
              // console.log("222fff");
              let msgRefSeen = db.collection("message").doc(receiverIdLocalStorage).collection("messages").doc(mainDoc.data().messageId)
              msgRefSeen.set({ ...mainDoc.data(), readBy: lstNewReadBy })
            }
          }
        });

        // console.log("temp---------------------",temp)

        if (temp.length) {
          if (lstTmpMessage.length) {
            console.log(receiverIdLocalStorage, "call message--");
            if (temp[0].receiverId == lstTmpMessage[0].receiverId) {
              console.log(
                temp[0].receiverId,
                "==+",
                lstTmpMessage[0].receiverId
              );
              console.log("Msg send called inside");
              setLstTmpMessage(_.reverse(temp));

              let scr = document.getElementById("chat");
              scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
            } else {
              console.log(temp[0].receiverId, "=++", receiverIdLocalStorage);
              console.log("Msg send called else inside");
            }
          } else {
            let messageRef2 = db
              .collection("message")
              .doc(localStorage.getItem("receiverId"))
              .collection("messages")
              .orderBy("createdAt", "desc");
            messageRef2.onSnapshot((querySnapshot) => {
              let temp2 = [];
              querySnapshot.forEach((mainDoc) => {
                console.log("mainDocty", mainDoc.data());
                temp2.push(mainDoc.data());
                // console.log("receiverIdLocalStorage444",localStorage.getItem("receiverId"))
                console.log("trmp msg inside else", temp2);

                // Read message
                // let lstReadBy = mainDoc.data().readBy;
                // let lstNewReadBy = lstReadBy?.includes(senderId) ? lstReadBy : [...lstReadBy, senderId];
                // console.log("1111",lstReadBy)
                // console.log("2222",senderId)
                // if (lstReadBy.includes(senderId)) {
                //   console.log("che j inside");
                // } else {
                //   console.log("else che j")
                //   let msgRefSeen = db.collection("message").doc(localStorage.getItem("receiverId")).collection("messages").doc(mainDoc.data().messageId)
                //   msgRefSeen.set({ ...mainDoc.data(), readBy: lstNewReadBy })
                // }
              });
              setLstTmpMessage(_.reverse(temp2));
              let scr = document.getElementById("chat");
              scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
            });

            console.log("Msg send called else lstTmpMessage");

            let scr = document.getElementById("chat");
            scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
          }
        } else {
          setLstTmpMessage([]);
        }
        // temp.length ? setLstTmpMessage(temp) : setLstTmpMessage([])
      });
    } else {
      console.log("Group Chat not");
      let receiverName = await AsyncLocalStorage.getItem("receiverName")
      let receiverProfilePicuture = await AsyncLocalStorage.getItem("receiverProfilePicuture")
      let to_slug = await AsyncLocalStorage.getItem("to_slug")
      let receiverId = await AsyncLocalStorage.getItem("receiverId")
      setObjSelectedUser({
        name: receiverName,
        profile_picture: receiverProfilePicuture,
        slug: to_slug,
        userId: receiverId,
      })
      if (receiverId) {
        // fetch message for personal
        let messageRef = db.collection("message").doc(senderId).collection(receiverId)
        messageRef.orderBy("createdAt", "asc")
          .onSnapshot((querySnapshot) => {
            let temp = [];
            querySnapshot.forEach((mainDoc) => {
              temp.push(mainDoc.data())
              // read personal message
              if (mainDoc.data().senderId == receiverId) {
                if (mainDoc.data().seen != true) {
                  console.log(mainDoc.data().seen, "call seen 1")
                  let msgRefSeen = db.collection("message").doc(senderId).collection(receiverId).doc(mainDoc.data().messageId)
                  msgRefSeen.set({ ...mainDoc.data(), seen: true })
                }
              }
            });
            // temp.length ? setLstTmpMessage(_.reverse(temp)) : setLstTmpMessage([])
            // temp.length ? setLstTmpMessage(temp) : setLstTmpMessage([])

            if (temp.length) {

              if (lstTmpMessage.length) {
                // console.log("temp[0].receiverId",temp[0].receiverId,lstTmpMessage[0].receiverId)
                if (temp[0].receiverId == lstTmpMessage[0].receiverId) {
                  setLstTmpMessage(temp);

                  let scr = document.getElementById("chat");
                  scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
                } else {
                  // console.log(temp[0].receiverId, "=++", receiverIdLocalStorage);
                  console.log("Msg send called else inside");
                }
              } else {
                let messageRef2 = db.collection("message").doc(localStorage.getItem("senderId")).collection(receiverId)
                messageRef2.orderBy("createdAt", "asc")
                  .onSnapshot((querySnapshot) => {
                    let temp2 = [];
                    querySnapshot.forEach((mainDoc) => {
                      console.log("mainDocty", mainDoc.data());
                      temp2.push(mainDoc.data());

                      // read personal message
                      if (mainDoc.data().senderId == receiverId) {
                        if (mainDoc.data().seen != true) {
                          console.log(mainDoc.data().seen, "call seen 2")
                          let msgRefSeen = db.collection("message").doc(senderId).collection(receiverId).doc(mainDoc.data().messageId)
                          msgRefSeen.set({ ...mainDoc.data(), seen: true })
                        }
                      }

                      // console.log("receiverIdLocalStorage444",localStorage.getItem("receiverId"))
                      console.log("trmp msg inside else", temp2);
                    });
                    setLstTmpMessage(temp2);

                    let scr = document.getElementById("chat");
                    scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
                  })
              }
            } else {
              console.log("else settmpnow")
              // let messageRef3 = db.collection("message").doc(localStorage.getItem("senderId")).collection(receiverId)
              // let temp3 = [];
              // messageRef3.orderBy("createdAt", "asc").onSnapshot((querySnapshot) => {
              //   querySnapshot.forEach((mainDoc) => {
              //     // temp3.push(mainDoc.data())
              //     console.log("mainDoc daya",mainDoc.data)
              //   })
              //   // console.log("temp3",temp3)
              //   setLstTmpMessage(temp3)
              // })
              setLstTmpMessage([]);
            }
          });
      }


      // fetch message for personal
      // let messageRef = db.collection("message").doc(senderId).collection(receiverId)
      // messageRef.orderBy("createdAt", "asc")
      //   .onSnapshot((querySnapshot) => {
      //     let temp = [];
      //     querySnapshot.forEach((mainDoc) => {
      //       temp.push(mainDoc.data())
      //     })

      //     if (temp.length) {
      //       if (lstTmpMessage.length) {
      //         if (temp[0].receiverId == lstTmpMessage[0].receiverId) {
      //           setLstTmpMessage(temp);

      //           // let scr = document.getElementById("chat");
      //           // scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
      //         } else {
      //           console.log("first else")
      //         }
      //       } else {
      //         let messageRef2 = db.collection("message").doc(senderId).collection(receiverId)
      //         messageRef.orderBy("createdAt", "asc")
      //         .onSnapshot((querySnapshot) => {
      //         let temp2 = [];
      //         querySnapshot.forEach((mainDoc) => {
      //         temp2.push(mainDoc.data())

      //         console.log("trmp msg inside else", temp2);
      //     })
      //     setLstTmpMessage(temp2)
      //   })
      //       }
      //     }
      //   })


      // if (temp.length) {
      //   if (lstTmpMessage.length) {
      //     console.log(receiverIdLocalStorage, "call message--");
      //     if (mainDoc.data().senderId == receiverId) {
      //       console.log(
      //         temp[0].receiverId,
      //         "==+",
      //         lstTmpMessage[0].receiverId
      //       );
      //       console.log("Msg send called inside");
      //       setLstTmpMessage(_.reverse(temp));

      //       let scr = document.getElementById("chat");
      //       scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
      //     } else {
      //       console.log(temp[0].receiverId, "=++", receiverIdLocalStorage);
      //       console.log("Msg send called else inside");
      //     }
      //   } else {
      //     let messageRef2 = db
      //       .collection("message")
      //       .doc(localStorage.getItem("receiverId"))
      //       .collection("messages")
      //       .orderBy("createdAt", "desc");
      //     messageRef2.onSnapshot((querySnapshot) => {
      //       let temp2 = [];
      //       querySnapshot.forEach((mainDoc) => {
      //         console.log("mainDocty", mainDoc.data());
      //         temp2.push(mainDoc.data());
      //         // console.log("receiverIdLocalStorage444",localStorage.getItem("receiverId"))
      //         console.log("trmp msg inside else", temp2);

      //         // Read message
      //         // let lstReadBy = mainDoc.data().readBy;
      //         // let lstNewReadBy = lstReadBy?.includes(senderId) ? lstReadBy : [...lstReadBy, senderId];
      //         // console.log("1111",lstReadBy)
      //         // console.log("2222",senderId)
      //         // if (lstReadBy.includes(senderId)) {
      //         //   console.log("che j inside");
      //         // } else {
      //         //   console.log("else che j")
      //         //   let msgRefSeen = db.collection("message").doc(localStorage.getItem("receiverId")).collection("messages").doc(mainDoc.data().messageId)
      //         //   msgRefSeen.set({ ...mainDoc.data(), readBy: lstNewReadBy })
      //         // }
      //       });
      //       setLstTmpMessage(_.reverse(temp2));
      //       let scr = document.getElementById("chat");
      //       scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
      //     });

      //     console.log("Msg send called else lstTmpMessage");

      //     let scr = document.getElementById("chat");
      //     scr && scr.scrollTo && scr.scrollTo("0", scr.scrollHeight);
      //   }
      // } else {
      //   setLstTmpMessage([]);
      // }
    }
  };

  function handleEnterKeyOnCards(e) {
    if (e.keyCode == 13) {
      if (chatType == "group") {
        sendMessageGroup(e);
      } else {
        sendMessagePersonal(e);
      }
    }
  }

  const sendMessageGroup = async (event) => {
    event.preventDefault();
    let userName = await AsyncLocalStorage.getItem("userName");
    var timestamp = new Date();
    let messageId = uuid();

    // add last message
    var groupRef = db.collection("group").doc(objSelectedGroup.groupId);
    groupRef
      .get()
      .then((doc) => {
        console.log("doc--==", doc)
        if (doc.exists) {
          db.collection("group")
            .doc(objSelectedGroup.groupId)
            .set({
              ...doc.data(),
              messageText: message,
              recentMessage: {
                messageText: message,
                readBy: [senderId],
                sentAt: timestamp,
                sentBy: senderId,
              },
            }).then(() => {
              console.log("resolved message", message)
            });
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    // entery for add message
    let messageObject = {
      createdAt: timestamp,
      createdBy: senderId,
      groupEdit: false,
      messageId: messageId,
      name: userName,
      textMessage: message,
      type: "group",
      readBy: [senderId],
      receiverId: receiverId,
    };

    db.collection("message")
      .doc(objSelectedGroup.groupId)
      .collection("messages")
      .doc(messageId)
      .set(messageObject);
    let lstUser = objSelectedGroup.members;

    const unresolved = lstUser.map(async (f_uid) => {
      await db
        .collection("message")
        .doc(f_uid)
        .collection("inbox")
        .doc(objSelectedGroup.groupId)
        .set({
          type: "group",
          createdAt: timestamp,
          createdBy: senderId,
          groupId: objSelectedGroup.groupId,
          messageText: message,
        });
    });

    // cleare message
    setMessage("");
    // onload();

    await Promise.all(unresolved);
    // Refrsh inbox
    // onloadIndex()
  };

  const sendMessagePersonal = async (event) => {
    event.preventDefault();


    // console.log("receiverId")

    if (message === "") return;
    let receiverName = await AsyncLocalStorage.getItem("receiverName");
    let receiverProfilePicuture = await AsyncLocalStorage.getItem(
      "receiverProfilePicuture"
    );
    let senderId = await AsyncLocalStorage.getItem("senderId");
    let userProfilePicture = await AsyncLocalStorage.getItem("userProfile");
    let userName = await AsyncLocalStorage.getItem("userName");
    let to_id = await AsyncLocalStorage.getItem("to_id");
    let from_id = await AsyncLocalStorage.getItem("from_id");
    let from_slug = await AsyncLocalStorage.getItem("from_slug");
    let to_slug = await AsyncLocalStorage.getItem("to_slug");
    let uuid1 = uuid();
    console.log("single user", receiverId, senderId, message, uuid1)

    var timestamp = new Date();

    let sendMessageData = {
      createdAt: timestamp,
      messageId: uuid1,
      textMessage: message.trim(),
      type: "personal",
      senderId: senderId,
      receiverId: receiverId,
      // mediaUrl: "",
      // fileName: "",
      seen: false,
    };
    db.collection("message")
      .doc(senderId)
      .collection(receiverId)
      .doc(uuid1)
      .set(sendMessageData);
    db.collection("message")
      .doc(receiverId)
      .collection(senderId)
      .doc(uuid1)
      .set(sendMessageData);

    // Inbox
    let senderInbox = {
      createdAt: timestamp,
      messageText: message.trim(),
      type: "text",
      mediaUrl: "",
      senderId: receiverId,
      receiverId: senderId,
      seen: false,
      archive: false,
      fullName: userName,
      imageUrl: userProfilePicture,
      // status: requestStatus,
      id: from_id,
      slug: from_slug,
      // count: receiverCount
    };

    let receiverInbox = {
      messageText: message.trim(),
      mediaUrl: "",
      senderId: senderId,
      receiverId: receiverId,
      seen: false,
      type: "text",
      createdAt: timestamp,
      archive: false,
      fullName: receiverName,
      imageUrl: receiverProfilePicuture,
      // status: requestStatus,
      id: to_id,
      slug: to_slug,
      count: 0,
    };

    db.collection("message")
      .doc(senderId)
      .collection("inbox")
      .doc(receiverId)
      .set(receiverInbox);

    db.collection("message")
      .doc(receiverId)
      .collection("inbox")
      .doc(senderId)
      .set(senderInbox);
    // AsyncLocalStorage.setItem("count", parseInt(receiverCount) + 1)
    setMessage("");
  };

  const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: "10px 10px 0px 0px",
            height: "calc(100vh - 260px)",
          }}
        >
          {/* User/group heading */}
          {chatType == "group" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className={classes.inboxRoot}
                style={{ padding: "10px", width: "fit-content" }}
                onClick={() => {
                  router.history.push(`/groupDetail/${objSelectedGroup.slug}`);
                }}
              >
                <div>
                  <img
                    src={
                      objSelectedGroup.profile_picture
                        ? objSelectedGroup.profile_picture
                        : "/images/male_defualt.svg"
                    }
                    alt="Img"
                    className={classes.inboxImage}
                  />
                </div>
                <div className={classes.inboxNameRoot}>
                  <div className={classes.inboxName}>
                    {objSelectedGroup.name}
                  </div>
                  <div className={classes.inboxLastMsg}>
                    {objSelectedGroup.no_of_members} members
                  </div>
                </div>
              </div>

              <div className={classes.menuRoot}>
                <MoreVertIcon
                  onClick={(e) => {
                    if (!exitAt) {
                      handleClick(e);
                    }
                  }}
                  id={idMenu}
                  style={{ cursor: "pointer" }}
                />
                <Popover
                  id={idMenu}
                  open={openMenu}
                  anchorEl={anchorEl}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  style={{ padding: "10px", borderRadius: "10px" }}
                  className={classes.menu}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      padding: "10px",
                      width: "200px",
                    }}
                  >
                    <Typography
                      className={classes.typographyMenu}
                      onClick={() =>
                        router.history.push(
                          `/groupDetail/${objSelectedGroup.slug}`
                        )
                      }
                    >
                      Group Info
                    </Typography>
                    <Typography
                      className={classes.typographyMenu}
                      onClick={() => onOpenClearChat(objSelectedGroup.groupId)}
                    >
                      Clear chat
                    </Typography>
                    <Typography
                      className={classes.typographyMenu}
                      onClick={() => {
                        router.history.push({
                          pathname: `/shareGourp/${objSelectedGroup.slug}`,
                          search: "",
                          state: {
                            objGroup: objSelectedGroup,
                          },
                        });
                      }}
                    >
                      Share Group
                    </Typography>
                    <Divider />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#F24451",
                      }}
                    >
                      <Typography
                        className={classes.typographyMenu}
                        onClick={() => {
                          setopenExitGroup(true);
                        }}
                      >
                        Exit Group
                      </Typography>
                      <ExitToAppIcon />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={classes.inboxRoot}
                style={{ padding: "10px", width: "fit-content" }}
                onClick={() => {
                  router.history.push(
                    `/your-matches/view/${objSelectedUser.slug}`
                  );
                }}
              >
                <div>
                  {console.log("objSelectedUser.profile_picture", objSelectedUser)}
                  <img
                    src={
                      objSelectedUser.profile_picture == "undefined" || objSelectedUser.profile_picture == "null"
                        ? "/images/male_defualt.svg"
                        : objSelectedUser.profile_picture
                    }
                    alt="Img"
                    className={classes.inboxImage}
                  />
                </div>
                <div className={classes.inboxNameRoot}>
                  <div className={classes.inboxName}>
                    {objSelectedUser.name}
                  </div>
                  <div className={classes.inboxLastMsg}>Tap for more info</div>
                </div>
              </div>
              <div style={{ marginRight: "10px", marginTop: "18px" }}>
                <MoreVertIcon
                  onClick={(e) => {
                    if (!exitAt1) {
                      handleClickSingleUser(e);
                    }
                  }}
                  id={idMenu2}
                  style={{ cursor: "pointer" }}
                />

                <Popover
                  id={idMenu2}
                  open={openMenu2}
                  anchorEl={anchorEl1}
                  onClose={handleMenuCloseSingleUser}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  style={{ padding: "10px", borderRadius: "10px" }}
                  className={classes.menu}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      padding: "10px",
                      width: "200px",
                    }}
                  >
                    <Typography
                      className={classes.typographyMenu}
                      onClick={() => onOpenClearChatSingleUser()}
                    >
                      Clear chat
                    </Typography>
                    {/* <Divider /> */}
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#F24451",
                      }}
                    >
                      <Typography
                        className={classes.typographyMenu}
                        onClick={() => {
                          setopenExitGroup(true);
                        }}
                      >
                        Exit Group
                      </Typography>
                      <ExitToAppIcon />
                    </div> */}
                  </div>
                </Popover>

              </div>
            </div>
          )}
          <Divider />
          {/* message display */}
          {console.log("lstTmpMessageoo", lstTmpMessage)}
          <div
            id="chat"
            style={{
              padding: "20px",
              height: "calc(100vh - 330px)",
              overflow: "auto",
            }}
            onScroll={onScroll}
            ref={listInnerRef}
          >
            {lstTmpMessage.map((obj, index) => {
              if (obj.type == "group") {
                if (obj.groupEdit == true) {
                  if (obj.createdBy == senderId) {
                    return (
                      <div key={index}>
                        {/* {lastSeenMsg?.seconds == obj.createdAt.seconds &&
                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "15px 0px" }}>
                                      <Divider style={{ width: "40%" }} />
                                      <div style={{ margin: "0px 5px", fontSize: "12px" }}>
                                        New message
                                      </div>
                                      <Divider style={{ width: "40%" }} />
                                    </div>
                                  } */}
                        <div className={classes.groupEditMsgRoot}>
                          <div className={classes.groupEditMsg}>
                            {obj.textMessage.replace(userName, "you")}
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index}>
                        {/* {lastSeenMsg?.seconds == obj.createdAt.seconds &&
                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "15px 0px" }}>
                                      <Divider style={{ width: "40%" }} />
                                      <div style={{ margin: "0px 5px", fontSize: "12px" }}>
                                        New message
                                      </div>
                                      <Divider style={{ width: "40%" }} />
                                    </div>
                                  } */}
                        <div className={classes.groupEditMsgRoot}>
                          <div className={classes.groupEditMsg}>
                            {/* {obj.textMessage} */}
                            {obj.textMessage.replace(userName, "you")}
                          </div>
                        </div>
                      </div>
                    );
                  }
                } else {
                  if (obj.createdBy == senderId) {
                    return (
                      <div key={index}>
                        {/* {lastSeenMsg?.seconds == obj.createdAt.seconds &&
                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "15px 0px" }}>
                                      <Divider style={{ width: "40%" }} />
                                      <div style={{ margin: "0px 5px", fontSize: "12px" }}>
                                        New message
                                      </div>
                                      <Divider style={{ width: "40%" }} />
                                    </div>
                                  } */}
                        <div className={classes.myMessageRoot}>
                          <div className={classes.myMessage}>
                            <div>{obj.textMessage}</div>
                            <div className={classes.myMessageTime}>
                              {moment(new Date(obj.createdAt * 1000)).format(
                                "HH:mm"
                              )}
                              {/* <DoneAllIcon className={classes.myDoubleTick} /> */}
                              {obj.readBy.length >=
                                objSelectedGroup.no_of_members ? (
                                <DoneAllIcon className={classes.myDoubleTick} />
                              ) : (
                                <DoneIcon className={classes.myDoubleTick} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index}>
                        {/* {lastSeenMsg?.seconds == obj.createdAt.seconds &&
                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "15px 0px" }}>
                                      <Divider style={{ width: "40%" }} />
                                      <div style={{ margin: "0px 5px", fontSize: "12px" }}>
                                        New message
                                      </div>
                                      <Divider style={{ width: "40%" }} />
                                    </div>
                                  } */}
                        <div className={classes.yourMessageRoot}>
                          <div className={classes.yourMessage}>
                            <div className={classes.yourName}>{obj.name}</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>{obj.textMessage}</div>
                              <div className={classes.yourMessageTime}>
                                {" "}
                                {moment(new Date(obj.createdAt * 1000)).format(
                                  "HH:mm"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              } else {
                if (obj.senderId == senderId) {
                  return (
                    <div className={classes.myMessageRoot} key={index}>
                      <div className={classes.myMessage}>
                        <div>{obj.textMessage}</div>
                        <div className={classes.myMessageTime}>
                          {moment(new Date(obj.createdAt * 1000)).format(
                            "HH:mm"
                          )}
                          {/* <DoneAllIcon className={classes.myDoubleTick} /> */}
                          {obj.seen ?
                            <DoneAllIcon className={classes.myDoubleTick} />
                            :
                            <DoneIcon className={classes.myDoubleTick} />
                          }
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className={classes.yourMessageRoot} key={index}>
                      <div className={classes.yourMessage}>
                        <div className={classes.yourName}>{obj.name}</div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>{obj.textMessage}</div>
                          <div className={classes.yourMessageTime}>
                            {" "}
                            {moment(new Date(obj.createdAt * 1000)).format(
                              "HH:mm"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>
        <div className={classes.inputBoxWrite}>
          {!exitAt ? (
            <Grid
              container
              style={{ display: "flex"}}
            >
              <Grid item xs={10} sm={10} md={10} lg={11} xl={11}>
                <TextField
                  className={classes.mainInputMessage}
                  fullWidth
                  size="small"
                  placeholder="Message..."
                  variant="outlined"
                  onKeyDown={(e) => handleEnterKeyOnCards(e)}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <div className={classes.iconSendBg}>
                  <SendOutlinedIcon
                    type="submit"
                    onClick={(e) => {
                      if (chatType == "group") {
                        sendMessageGroup(e);
                      } else {
                        sendMessagePersonal(e);
                      }
                    }}

                  // onKeyPress={(event) =>
                  //   handleEnterKeyOnCards(event)
                  //   }
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            <div style={{ textAlign: "center", width: "100%" }}>
              <Typography className={classes.exitnote}>
                You can't send message to this group because you're no longer a
                participant
              </Typography>
            </div>
          )}
        </div>

        <ClearChatDialog
          activityRef={activityRef}
          onClose={onCloseClearChat}
          open={openClearChat}
          onLoad={onload}
        />

        <ExitGroupModel
          setSessionMessage={setSessionMessage}
          slug={objSelectedGroup.slug}
          onClose={onCloseExitGroup}
          open={openExitGroup}
        />
      </div>
    </>
  );
};
export default GChat;
