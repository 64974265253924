import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import { Skeleton } from "@material-ui/lab";
import MuiAlert from '@material-ui/lab/Alert';
import Api from '../../Helper/ApiHandler';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import MapTC from 'components/MapTC/MapTC';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useRouter from "utils/useRouter";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ShareDialog from "components/ShareModel/ShareModel";
import SliderDialog from "components/SliderModel/SliderModel";
import MessageIcon from '@material-ui/icons/Message';
// import ContactPropertyOwnerDialog from "components/ContactPropertyOwnerModel/contactPropertyOwnerModel";
import ContactDialog from "components/ContactModel/ContactModel";

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // backgroundColor: "E9EDF0 !important",
    background: "#E9EDF0",
    padding: "35px 0",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
    },
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  searchInput: {
    width: "290px",
    marginRight: "10px",
    background: "#E9EDF0",
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    textAlign: "center",
    marginTop: "35px",
    textTransform: "capitalize",
  },
  filterIconBtn: {
    marginLeft: "10px",
  },
  listBg: {
    background: "#E9EDF0",
  },
  matchesHead: {
    margin: "0",
    // padding: "20px 15px",
    paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px !important",
    textTransform: "capitalize"
  },
  cardUser: {
    background: "white",
    padding: "25px 10px",
    textAlign: "center",
    paddingBottom: "10px",
    margin: "7px",
    borderRadius: "15px",
    height: "260px"
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400"
  },
  chipUserDetail: {
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0"
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGrid: {
    paddingBottom: "60px",
    padding: "10px"
  },
  imageMatches: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down('xs')]: {
      width: "80px",
      height: "80px",
    },
    borderRadius: "50%",
    objectFit: "cover"
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  responsiveHide: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    [theme.breakpoints.only('md')]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only('lg')]: {
      width: "28%",
    },
    [theme.breakpoints.down('xl')]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.only('md')]: {
      width: "66.67%",
    },
    [theme.breakpoints.only('lg')]: {
      width: "72%",
    },
    [theme.breakpoints.only('xl')]: {
      width: "72%",
    },
  },
  typography: {
    padding: "15px"
  },
  hadding: {
    marginTop: "20px",
    padding: "30px 0px 5px 0px"
  },
  chip: {
    backgroundColor: '#FFDBE1',
    color: "#FE4B6B",
    margin: "3px"
  },
  divider: {
    backgroundColor: "#d9d4d4"
  },
  card: {
    // backgroundColor: theme.palette.background.paper, 
    borderRadius: "10px"
  },
  roomCaradantiol: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px"
  },
  roomCaradantiolCount: {

  },
  roomCaradantiolCountText: {
    fontWeight: "400",
    fontSize: "14px !important",
    margin: "5px 0px !important"
  },
  roomPhotoLocation: {
    display: "flex",
    alignItems: "center"
  },
  roomImage: {
    // marginTop: "20px",
    padding: "5px 25px 15px 25px",
    backgroundColor: "white",
    borderRadius: "10px"
  },
  image: {
    "& .react-fancybox .thumbnail img": {
      height: "290px",
      width: "100%",
      objectFit: "cover"
    }
  },
  imageSub: {
    "& .react-fancybox .thumbnail img": {
      height: "140px",
      width: "100%",
      objectFit: "cover",
    }

  },
  roomLocation: {
    color: "#FE4B6B",
    textTransform: "capitalize"
  },
  rentalUnit: {
    marginTop: "15px",
    padding: "25px 25px 25px 25px",
    backgroundColor: "white",
    borderRadius: "10px"
  },
  rentalUnitSubHeading1: {
    fontWeight: "400",
    fontSize: "14px !important",
    padding: "5px 0px 5px 0px"
  },
  rentalUnitSubHeading2: {
    fontWeight: "400"
  },
  offer: {
    marginTop: "30px",
    padding: "25px 25px 25px 25px",
    backgroundColor: "white",
    borderRadius: "10px"
  },
  offerHeading: {
    fontWeight: "600",
    fontSize: "16px !important",
  },
  offerChipRoot: {
    padding: "10px 0px"
  },
  offerChip: {
    padding: "10px !important",
    borderRadius: "20px !important",
    fontSize: "14px",
    color: "#FE4B6B",
    backgroundColor: "#FFDBE1",
    margin: "5px"
  },
  offerChipIcon: {
    color: "#FE4B6B",
  },
  headindRoomate: {
    fontSize: "20px !important",
    fontWeight: "400",
    margin: 0
    // textAlign: "center"
  },
  headindgender: {
    fontSize: "16px !important",
    fontWeight: "400",
    color: "#7E7E7E",
    margin: 0
    // textAlign: "center"
  },
  joinChipRoot: {
    marginRight: "10px",
    '& .MuiChip-outlined': {
      borderColor: "#FE4B6B"
    },
    '& .MuiChip-label': {
      fontSize: "18px",
      fontWeight: "500",
      margin: "10px 15px"
    }
  },
  joinChip: {
    color: "#FE4B6B",
    backgroundColor: "#FFDBE1",
    cursor: "pointer"
  },
  backRoot: {
    // maxWidth: "fit-content",
    cursor: "pointer",
    marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex"
  },


  discriptionCard: {
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: "20px"
  },
  seeAllVutton: {
    position: "absolute",
    bottom: "15px",
    right: "8px",
    height: "25px",
    textTransform: "capitalize",
    borderRadius: "20px",
    fontSize: "10px",
    backgroundColor: "white"
  },
  line: {
    border: "0.1px solid #CCCCCC",
    margin: "15px 0px"
  },
  descriptionPart1: {
    fontSize: "14px",
    color: "#707070",
    fontWeight: "500",
    margin: "8px 0px"
  },
  descriptionPart2: {
    fontSize: "14px",
    margin: "8px 0px"
  },
  contactButton: {
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    textTransform: "capitalize",
    color: "white",
    width: "100%",
    marginTop: "36px",
    borderRadius: "10px"
  }
}));

const PropertyInfoSingleUnAuth = (props) => {
  const { match } = props;

  const router = useRouter();
  const { id } = match.params
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const [sliderOpen, setSliderOpen] = React.useState(false);
  const [contactOpen, setContactOpen] = React.useState(false);

  const [objProperty, setObjProperty] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState({
    message: '',
    type: '',
    open: false
  });

  useEffect(() => {
    (() => {
      setLoading(true)
      // Api Call For Fetch The User Data
      api.get(`user/viewproperty/${id}`)
        .then((result) => {
          if (result.success === "true") {
            setObjProperty(result.data)
          } else {
            console.log(result, "error")
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log(err, "catch error")
          setLoading(false)
        });
    })()

  }, [id]);


  // const onLoad = () => {
  //   setLoading(true)
  //   // Api Call For Fetch The User Data
  //   api.get(`user/viewproperty/${id}`)
  //     .then((result) => {
  //       if (result.success === "true") {
  //         console.log(result, "suc")
  //         setObjProperty(result.data)
  //       } else {
  //         console.log(result, "error")
  //       }
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       console.log(err, "catch error")
  //       setLoading(false)
  //     });
  // };


  const onJoin = async () => {
    // let from_id = await AsyncLocalStorage.getItem("from_id")

    api.post('user/property/sendrequesttoowner', {
      data: {
        to_id: objProperty.property_owner_id,
        property_id: objProperty.id
      }
    })
      .then((result) => {
        if (result.success === "true") {
          // onLoad()
          setSessionMessage({
            message: result.message,
            type: 'success',
            open: true
          });
          setObjProperty({ ...objProperty, request_status: true })
        } else {
          console.log(result, "error")
          setSessionMessage({
            message: result.message,
            type: 'error',
            open: true
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch")
        setSessionMessage({
          message: err.message,
          type: 'error',
          open: true
        });
      });
  }
  const handleClose = () => {
    // setSessionMessage({
    //   message: '',
    //   type: '',
    //   open: false
    // });
    setShareOpen(false)
  }

  const handleSliderClose = () => {
    setSliderOpen(false)
  }
  const handleContactClose = () => {
    setContactOpen(false)
  }

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 1
  // };
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.mainRoot} style={{ position: "relative" }}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid item xs={12} spaccing={2}>
              <div className={classes.listBg}>
                <Grid
                  justifyContent="center"
                  alignItems="center"
                  className={classes.cardBoxGrid}
                  container
                >
                  {!loading ?
                    <Grid spacing={2} container>
                      <Grid item xs={12}>
                        <h5 className={classes.matchesHead}> {objProperty.property_name} </h5>
                        <div style={{ display: "flex", alignItems: "flex-start" }}>
                          <img src={'/images/location.svg'} alt='img' />&nbsp;
                          {/* <Tooltip title={objProperty.address} placement="top"> */}
                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                marginTop: "2px",
                              }}
                            >
                              {objProperty.address}
                            </div>
                            <div>
                              <Button
                                startIcon={<img src={'/images/matchUp/shareIcon.svg'} alt='img' />}
                                style={{ textTransform: "capitalize" }}
                                onClick={() => {
                                  setShareOpen(true)
                                }}
                              >
                                Share
                              </Button>
                            </div>
                          </div>
                          {/* </Tooltip> */}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container
                        // style={{ height: "400px", overflow: "hidden" }}
                        >
                          <Grid item xs={6} style={{ width: "100%", overflow: "hidden", borderRadius: "10px 0px 0px 10px" }}>
                            <Box>
                              <img
                                src={objProperty && objProperty.property_photos && objProperty.property_photos[0]}
                                alt="Property Image"
                                style={{ width: "100%", borderRadius: "10px 0px 0px 10px", aspectRatio: "1.47" }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid item xs={6} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                <Box pl={"10px"} pb={"8px"} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                  <img
                                    src={objProperty && objProperty.property_photos && objProperty.property_photos[1]}
                                    alt="Property Image"
                                    style={{ width: "100%", height: "100%", aspectRatio: "1.47" }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                <Box pl={"10px"} pb={"8px"} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                  <img
                                    src={objProperty && objProperty.property_photos && objProperty.property_photos[2]}
                                    alt="Property Image"
                                    style={{ width: "100%", height: "100%", borderRadius: "0px 10px 0px 0px", aspectRatio: "1.47" }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                <Box pl={"10px"} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                  <img
                                    src={objProperty && objProperty.property_photos && objProperty.property_photos[3]}
                                    alt="Property Image"
                                    style={{ width: "100%", aspectRatio: "1.47" }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                <Box pl={"10px"} style={{ width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                  <img
                                    src={objProperty && objProperty.property_photos && objProperty.property_photos[4]}
                                    alt="Property Image"
                                    style={{ width: "100%", height: "100%", borderRadius: "0px 0px 10px 0px", aspectRatio: "1.47" }}
                                  />
                                  <Button
                                    variant="contained"
                                    className={classes.seeAllVutton}
                                    startIcon={<CameraAltIcon />}
                                    onClick={() => { setSliderOpen(true) }}
                                  >
                                    See all photos
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item md={8}>
                        <Box className={classes.discriptionCard}>
                          <div className={classes.offerHeading} style={{ margin: 0 }}>
                            Property type
                          </div>
                          <div>
                            {objProperty.property_type}
                          </div>
                          <div className={classes.line}></div>
                          <div className={classes.offerHeading} style={{ margin: 0 }}>
                            Description
                          </div>
                          <div className={classes.descriptionPart1}>
                            {/* A rented place within a multi-unit residential building or complex. */}
                            {objProperty.place_type}
                          </div>
                          <div>
                            {objProperty.description}
                            {/* It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).Read more */}
                          </div>
                        </Box>
                        <Box mt={"20px"}>
                          <Grid spacing={2} container>
                            <Grid item md={3}>
                              <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px" }}>
                                <img src={"/images/matchUp/guest.svg"} alt="Guest" style={{ width: "30px", height: "30px" }} />
                                <div style={{ fontSize: "14px", fontWeight: "600", margin: "5px 0px" }}>
                                  Guest
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  4 Guests
                                </div>
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px" }}>
                                <img src={"/images/matchUp/bedroom.svg"} alt="Guest" style={{ width: "30px", height: "30px" }} />
                                <div style={{ fontSize: "14px", fontWeight: "600", margin: "5px 0px" }}>
                                  Bedroom
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {objProperty.no_of_beds ? objProperty.no_of_beds : 0} Bedrooms
                                </div>
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px" }}>
                                <img src={"/images/matchUp/bathroom.svg"} alt="Guest" style={{ width: "30px", height: "30px" }} />
                                <div style={{ fontSize: "14px", fontWeight: "600", margin: "5px 0px" }}>
                                  Bathroom
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {objProperty.no_of_bathrooms ? objProperty.no_of_bathrooms : 0} Barthrooms
                                </div>
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px" }}>
                                <img src={"/images/matchUp/bed.svg"} alt="Guest" style={{ width: "30px", height: "30px" }} />
                                <div style={{ fontSize: "14px", fontWeight: "600", margin: "5px 0px" }}>
                                  Bed
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  1 Single bed
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mt={"20px"} className={classes.discriptionCard}>
                          <div className={classes.offerHeading}>
                            Aminities
                          </div>
                          <div className={classes.offerChipRoot}>
                            {objProperty.amenities && objProperty.amenities.map((value) => {
                              return (
                                <Chip
                                  // icon={obj.icon}
                                  label={value}
                                  className={classes.offerChip}
                                />
                              )
                            })}
                            {objProperty.property_highlights && objProperty.property_highlights.map((value) => {
                              return (
                                <Chip
                                  // icon={obj.icon}
                                  label={value}
                                  className={classes.offerChip}
                                />
                              )
                            })}
                          </div>
                          {/* <div>
                            <Button variant="contained">
                              Show all 10 amenities
                            </Button>
                          </div> */}
                        </Box>
                        <Box mt={"20px"}>
                          {objProperty && objProperty.latitude &&
                            <LoadScript
                              id="script-loader"
                              // googleMapsApiKey={"AIzaSyBXLz2f0FmKJj-98ncABlISrs0WWxrymGo"}
                              // googleMapsApiKey={"AIzaSyCDoPmvnBPd0XTaGSftzJC25Hva6BYxYz0"}
                              googleMapsApiKey={"AIzaSyBXLz2f0FmKJj-98ncABlISrs0WWxrymGo"}
                              libraries={["places"]}

                            >
                              <MapTC
                                latitude={objProperty.latitude ? objProperty.latitude : 45}
                                longitude={objProperty.longitude ? objProperty.longitude : 45}
                                // latitude={45}
                                // longitude={45}
                                width="100%"
                              >
                              </MapTC>
                            </LoadScript>}
                        </Box>
                      </Grid>

                      <Grid item md={4}>
                        <Box className={classes.discriptionCard}>
                          <div style={{ borderBottom: "1px dashed #CCCCCC" }}>
                            <div style={{ fontSize: "24px", fontWeight: "600", lineHeight: "1" }}>
                              {/* $12,000 */}
                              {objProperty.price ? `$ ${objProperty.price}` : "$0"}
                            </div>
                            <div style={{ fontSize: "10px", color: "#808080", marginBottom: "8px" }}>
                              inclusive all taxes
                            </div>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
                            <div style={{ fontSize: "16px" }}>
                              Your Saving
                            </div>
                            <div style={{ fontSize: "16px", fontWeight: "600" }}>
                              $0
                            </div>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
                            <div>
                              <div style={{ fontSize: "16px", lineHeight: "1" }}>
                                Total Price
                              </div>
                              <div style={{ fontSize: "10px", color: "#808080", marginBottom: "8px" }}>
                                (inclusive all taxes)
                              </div>
                            </div>
                            <div style={{ fontSize: "16px", fontWeight: "600" }}>
                              {/* $9000 */}
                              {objProperty.price ? `$ ${objProperty.price}` : "$0"}
                            </div>
                          </div>

                          <div>
                            <Button
                              variant="contained"
                              className={classes.contactButton}
                              onClick={() => { setContactOpen(true) }}
                            >
                              Contact to Owner
                            </Button>
                          </div>
                        </Box>
                        <Box className={classes.rentalUnit}>
                          <h5 className={classes.offerHeading} style={{ margin: 0 }}> RoomMates</h5>
                          <Box
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="column"
                          >
                            {objProperty.roommates &&
                              objProperty.roommates.length ? objProperty.roommates.map((obj, index) => {
                                return (
                                  <>
                                    <div style={{ display: "flex", marginLeft: "10px", padding: "10px" }}>
                                      <div>
                                        <img
                                          src={obj.profile_picture ? obj.profile_picture : "/images/male_defualt.svg"}
                                          style={{ width: "80px", height: "80px", borderRadius: "50%", objectFit: "cover" }}
                                          alt="profile_picture"
                                        />
                                      </div>
                                      <div style={{ marginLeft: "20px" }}>
                                        <h6 className={classes.headindRoomate}>
                                          {obj.first_name} {obj.last_name}
                                        </h6>
                                        <p className={classes.headindgender}>
                                          {obj.gender}
                                        </p>
                                        <p className={classes.headindgender}>
                                          {obj.email}
                                        </p>
                                      </div>
                                    </div>
                                    {/* {index !== objProperty.roommates.length - 1 && */}
                                    {/* <Divider style={{ color: "red" }} /> */}
                                    {/* } */}
                                  </>
                                )
                              }) :
                              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                <Typography
                                  variant="caption"
                                  component="primary"
                                  color="primary"
                                  style={{ fontSize: "14px", color: "gray", fontWeight: "400" }}
                                >
                                  No Member found
                                </Typography>
                              </div>
                            }
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    :
                    ""
                  }
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* <Container maxWidth="lg"> */}
          <div style={{ position: "fixed", bottom: "10px", right: "10px" }}>
            {contactOpen ?
              <ContactDialog
                pushTo="/auth/login/admin"
                mobileDropdown={true}
                onOpen={contactOpen}
                onCloseHandle={handleContactClose}
                objProperty={objProperty}
                setObjProperty={setObjProperty}
              // open={contactOpen}
              // onClose={handleContactClose}
              />
              :
              <IconButton className={classes.contactButton} style={{ borderRadius: "50%" }}>
                <MessageIcon
                  fontSize="inherit"
                // onClick={() => { setContactOpen(true) }} 
                />
              </IconButton>
            }
          </div>
          {/* </Container> */}
        </Container>

      </div >
      <ShareDialog
        open={shareOpen}
        onClose={handleClose}
      />
      <SliderDialog
        open={sliderOpen}
        onClose={handleSliderClose}
        lstImage={objProperty.property_photos}
      />
      {/* <ContactPropertyOwnerDialog
        open={contactOpen}
        onClose={handleContactClose}
      /> */}

      {/* <ContactDialog
        pushTo="/auth/login/admin"
        mobileDropdown={true}
        onOpen={contactOpen}
        onCloseHandle={handleContactClose}
        objProperty={objProperty}
        setObjProperty={setObjProperty}
      // open={contactOpen}
      // onClose={handleContactClose}
      /> */}
    </>
  );
};
const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rect" width={"100%"} height={"260px"} style={{ borderRadius: "10px" }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" width={"100%"} height={"260px"} style={{ borderRadius: "10px" }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" width={"100%"} height={"260px"} style={{ borderRadius: "10px" }} />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default PropertyInfoSingleUnAuth;
