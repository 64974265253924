import React from "react";
import RoommateLandingDetail from "./component/RoommateLandingDetail";

const PropertyLanding = () => {
  return (
    <>
      <RoommateLandingDetail />
    </>
  );
};

export default PropertyLanding;
