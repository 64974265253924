import React, { useContext, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Button,
  Box,
  TextField,
  Grid,
  Container,
  Snackbar,
  InputAdornment,
  Link,
  useMediaQuery,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import useRouter from "utils/useRouter";
import validate, { async } from "validate.js";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "firebase/app";
// import 'firebase/firestore';
// import dotsLogin from './images/matchUp/dotsLogin.png';
// import { dotsLogin } from '../../../public/images/matchUp/dotsLogin.png';
// import { dotsLogin } from './images/matchUp/dotsLogin';
import "firebase/messaging";
import OtpInput from "react-otp-input";
import Api from "../../Helper/ApiHandler";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import AppDataContext from "Helper/AppDataContext";
// const messaging = firebase.messaging();
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SignupDetailForm from "./SignupDetailForm";
import SelecteUniversityForm from "./SelecteUniversityForm";
import PersonalDetailForm from "./PersonalDetailForm";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import Cookies from 'js-cookie';

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
};

const schemaPhone = {
  phone: {
    presence: { allowEmpty: false, message: "is required" },
    length: { minimum: 10 },
  },
  countryCode: {
    presence: { allowEmpty: false, message: "is required" },
  },
  // phone: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: { maximum: 15, message: "is too long (maximum is 15 digit)" },
  //   format: {
  //     pattern: "[0-9]+",
  //     flags: "i",
  //     message: "Enter only number"
  //   }
  // }
};

const useStyles = makeStyles((theme) => ({
  root: {},
  signUpFormBg: {
    // background:
    //   "linear-gradient(54.75deg, #22346E 0%, #0071CE 100%, rgba(0, 113, 206, 0) 70.7%)",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    width: "100%",
    // height: "610px",
    height: "470px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      // height: "250px",
      display: "none",
    },
  },
  dotImageTop: {
    position: "absolute",
    top: "10px",
    left: "190px",
  },
  dotImageBottom: {
    position: "absolute",
    bottom: "10px",
    left: "-70px",
  },
  headingSignUp: {
    margin: "0px 0px 0px 40px",
    color: "white",
    fontWeight: 700,
  },
  hr: {
    margin: "15px 0px 15px 40px",
    width: "125px",
    height: "5px",
    background: "#FFFFFF",
    opacity: "0.5",
    borderRadius: "4px",
  },
  signINMessage: {
    color: "white",
    margin: "0px 40px",

    fontweight: 500,
    fontSize: "12px",

    //fontFamily: "Gilroy !important",
  },
  matchUpLogin: {
    textAlign: "center",
    margin: "20px 10px 5px 10px !important",
    fontWeight: 600,
  },
  formHr: {
    width: "100%",
    background: "#FFFFFF",
    opacity: "0.1",
    border: "1px solid #000000",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  gruopLinkButton: {
    textAlign: "center",
    margin: "20px 0px",
    "& .MuiButton-label": {
      justifyContent: "flex-start",
    },
  },
  buttonSignIn: {
    width: "80%",
    margin: "10px",
    textTransform: "capitalize",
    fontSize: "15px",
    fontWeight: "500",
  },
  textFiledBox: {
    width: "90%",
    margin: "10px auto",
  },
  forgotText: {
    width: "95%",
    color: "#FE4B6B",
    textAlign: "right",
  },
  startedButtonBox: {
    textAlign: "center",
    "& .MuiBox-root-144": {
      marginTop: "10px !important",
    },
  },
  startedButton: {
    // fontSize: "20px",
    // height: "50px",
    height: "35px",
    fontSize: "18px",
    width: "246px",
    // background: "#0071CE",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "400",
  },
  memberMessage: {
    textAlign: "center",
    // marginTop: "25px !important",
    fontWeight: 400,
  },
  socialIcon: {
    position: "absolute",
    right: "0",
    top: "0",
    cursor: "pointer",
  },
  backIcon: {
    position: "absolute",
    left: "0",
    top: "0",
    cursor: "pointer",
  },
  hrLine: {
    borderBottom: "2px solid #000000",
    opacity: "0.1",
    marginTop: "8px",
  },
  orMessage: {
    color: "#000",
    opacity: "0.4",
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      width: "730px",
      // height: "500px",
      // height: "610px",
      height: "470px",

      overflow: "hidden",
      // overflow: "hidden !important",
    },
  },
  asterisk: {
    color: "red",
    "&$error": {
      color: "red",
    },
  },
  termsRedirect: {
    cursor: "pointer",
  },
  mumLogin: {
    height: "auto",
    width: "50px",
    // marginTop: "20px",
    // display: "none",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "30px",
    },
  },
  paperRoot: {
    width: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  inputePhone: {
    "& .react-tel-input .form-control": {
      width: "100%",
      padding: "8.5px 14px 8.5px 60px",
    },
  },
  // otpInput: {
  //   [theme.breakpoints.down("xs")]: {
  //     width: "38px",
  //   },
  //   width: "45px",
  //   fontSize: "20px",
  //   height: "40px",
  //   borderRadius: "5px",
  //   border: "1px solid rgba(0,0,0,0.15)"
  // }
}));
const LoginModel = ({
  setForgotpasswordOpen,
  setOpenSignUp,
  loginOpen,
  setLoginOpen,
  url,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [loading, setLoading] = useState(false);
  const [loadingSignup, setLoadingSignup] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [signupType, setSignupType] = useState("notADefine");
  const [otpEmail, setOtpEmail] = React.useState(false);
  const [verifyEmail, setVerifyEmail] = React.useState(false);
  const [otpSendEmail, setOtpSendEmail] = React.useState(false);
  const [otpPhone, setOtpPhone] = React.useState(false);
  const [otpSendPhone, setOtpSendPhone] = React.useState(false);
  const [verifyPhone, setVerifyPhone] = React.useState(false);
  const [otpLoader, setOtpLoader] = React.useState(false);
  const [counter, setCounter] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [isSignupDetail, setIsSignupDetail] = React.useState(false);
  const [phone, setPhone] = useState("");

  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  // console.log(router.match.path, "router--")
  const handleClickOpenSignUp = () => {
    setLoginOpen(true);
  };

  const handleCloseSignUp = () => {
    closeHandlerModel();
    // setLoginOpen(false);
  };

  const closeHandlerModel = () => {
    logOutHandler();
    setLoginOpen(false);
    setSignupType("notADefine");
    setCounter(null);
    setOtpSendEmail(false);
    setOtpSendPhone(false);
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    setOtpPhone("");
    setOtpSendEmail("");
    setOtpEmail(false);
  };

  const logOutHandler = async () => {
    let device_token = await AsyncLocalStorage.getItem("device_token");
    localStorage.clear();
    AsyncLocalStorage.setItem("device_token", device_token ? device_token : "");
  };

  const handleBack = () => {
    setSignupType("notADefine");
    setCounter(null);
    setOtpSendEmail(false);
    setOtpSendPhone(false);
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    setOtpPhone("");
    setOtpSendEmail("");
    setOtpEmail(false);
  };
  // console.log(counter, "counter")
  const handleRedirectTerms = () => {
    setLoginOpen(false);
    router.history.push("/terms-of-use");
  };

  const handleRedirectPolicy = () => {
    setLoginOpen(false);
    router.history.push("/privacy-policy");
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    let tmp = signupType === "phone" ? schemaPhone : schema;
    // schemaPhone
    // console.log(tmp, "tmp")
    const errors = validate(formState.values, tmp);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values, signupType]);

  const sendOtp = async (event, type, value) => {
    event.preventDefault();
    // dispatch(login());
    setDisabled(true);
    setOtpLoader(true);
    let tmpData = {};
    if (type === "email") {
      tmpData = {
        type: type,
        value: value,
      };
    } else {
      tmpData = {
        type: type,
        value: value,
        phonecode: formState.values.countryCode,
        // phonecode: 91
      };
    }
    api
      .post("user/sendcode", {
        data: tmpData,
        skipAuth: true,
      })
      .then((result) => {
        if (result.success === "true") {
          setCounter(60);
          if (type === "email") {
            setOtpSendEmail(true);
          } else {
            setOtpSendPhone(true);
          }
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setDisabled(false);
        setOtpLoader(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        // setLoading(false)
        setDisabled(false);
        setOtpLoader(false);
      });
  };

  React.useEffect(() => {
    // console.log(counter, "counter--")
    if (signupType === "notADefine") {
      setCounter(null);
    } else {
      if (counter) {
        // (signupType === "email" || signupType === "phone") &&
        //   setCounter(null)

        counter &&
          counter > 0 &&
          setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        // setCounter(null)
        return;
      }
    }
  }, [counter]);

  const handleFieldChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.isValid === true) {
      setDisabledLogin(true);
      setLoading(true);

      const readData = async () => {
        try {
          let device_token = await AsyncLocalStorage.getItem("device_token");

          api
            .post("user/signin", {
              data: {
                ...formState.values,
                device_token: device_token,
              },
              skipAuth: true,
            })
            .then(async (result) => {
              if (result.success === "true") {
                let userName =
                  result.data.first_name + " " + result.data.last_name;
                setAppContext({
                  ...appContext,
                  userProfile: result.data.profile_picture,
                  userName: userName,
                });
                await AsyncLocalStorage.setItem(
                  "authToken",
                  result.data.accessToken
                );
                await AsyncLocalStorage.setItem(
                  "senderId",
                  result.data.firebase_uid
                );
                await AsyncLocalStorage.setItem("userName", userName);
                await AsyncLocalStorage.setItem(
                  "userProfile",
                  result.data.profile_picture
                );
                await AsyncLocalStorage.setItem("from_id", result.data.user_id);
                await AsyncLocalStorage.setItem("from_slug", result.data.slug);
                await AsyncLocalStorage.setItem(
                  "currentModule",
                  result.data.module_name
                );
                await AsyncLocalStorage.setItem("tabFavValue", 0);
                await AsyncLocalStorage.setItem("tabValue", 0);
                if (result.data.quiz) {
                  setLoginOpen(false);

                  setSessionMessage({
                    message: result.message,
                    type: "success",
                    open: true,
                  });

                  router.history.push({
                    pathname: `/your-matches`,
                    search: "",
                    state: {
                      sessionPropsMessage: {
                        message: result.message,
                        type: "success",
                        open: true,
                      },
                    },
                  });
                  // window.location.replace("/your-matches");

                  // router.push("/your-matches")
                } else {
                  // window.location.replace("/matching-quiz");
                  router.history.push("/matching-quiz");
                }
              } else {
                // setLoginOpen(false);
                setSessionMessage({
                  message: result.message,
                  type: "error",
                  open: true,
                });
              }
              setDisabledLogin(false);
              setLoading(false);
            })
            .catch((err) => {
              setDisabledLogin(false);
              setLoading(false);
              setSessionMessage({
                message: err.message,
                type: "error",
                open: true,
              });
            });
        } catch (e) {
          console.log(e, "catch error");
        }
      };
      readData();
    }
  };
  var provider = new firebase.auth.GoogleAuthProvider();

  const signInwithGoogle = async (event) => {
    event.preventDefault();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        setDisabledLogin(true);
        setLoadingSignup(true);
        setLoading(true);

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // "first_name": "Manthan",
        // "last_name": "Patel",
        // "email": "ax012019@gmail.com",
        // "date_of_birth": "23/07/1993",
        // "location": "Surat, Gujarat, India",
        // "latitude": 23.7654,
        // "longitude": 43.6543,
        // "firebase_uid": "aSWbznt9WGMNuxL6bmObniUSt1g2",
        // "device_token":"dadsadadad",
        // "profile_picture": "fdsfdsfsdfdsfsdf",
        // "type":"Mobile".
        let device_token = await AsyncLocalStorage.getItem("device_token");

        let sendObj = {
          first_name: result.additionalUserInfo.profile.given_name,
          last_name: result.additionalUserInfo.profile.family_name,
          email: result.additionalUserInfo.profile.email,
          date_of_birth: null,
          location: "",
          latitude: null,
          longitude: null,
          device_token: device_token ? device_token : null,
          type: "",
          profile_picture: result.additionalUserInfo.profile.picture,
          firebase_uid: result.user.uid,
        };

        let teammateRedirectTo = Cookies.get('teammateRedirectTo')
        let roommateRedirectTo = Cookies.get('roommateRedirectTo')
        // let mtype = teammateRedirectTo ? "teammodule" : roommateRedirectTo ? "roommmodule" : null
        let mtype = ""

        if (teammateRedirectTo) {
          if (appContext.objAuth?.TEAMMATE) {
            mtype = "teammodule"
          } else {
            // priority module
            if (appContext.objAuth?.DORMMATE) {
              mtype = "dormmodule"
            } else if (appContext.objAuth?.ROOMMATE) {
              mtype = "roommodule"
            } else if (appContext.objAuth?.SOULMATE) {
              mtype = "soulmodule"
            } else if (appContext.objAuth?.TEAMMATE) {
              mtype = "teammodule"
            }
          }
        } else if (roommateRedirectTo) {
          if (appContext.objAuth?.ROOMMATE) {
            mtype = "roommodule"
          } else {
            // priority module
            if (appContext.objAuth?.DORMMATE) {
              mtype = "dormmodule"
            } else if (appContext.objAuth?.ROOMMATE) {
              mtype = "roommodule"
            } else if (appContext.objAuth?.SOULMATE) {
              mtype = "soulmodule"
            } else if (appContext.objAuth?.TEAMMATE) {
              mtype = "teammodule"
            }
          }
        } else if (appContext.objAuth?.LIVE_MODULE_COUNT == 1) {
          // priority module
          if (appContext.objAuth?.DORMMATE) {
            mtype = "dormmodule"
          } else if (appContext.objAuth?.ROOMMATE) {
            mtype = "roommodule"
          } else if (appContext.objAuth?.SOULMATE) {
            mtype = "soulmodule"
          } else if (appContext.objAuth?.TEAMMATE) {
            mtype = "teammodule"
          }
        }


        // if (formState.isValid === true) {

        api
          .post(`user/signinwithgoogle?mtype=${mtype}`, {
            data: sendObj,
            skipAuth: true,
          })
          .then((result) => {
            if (result.success === "true") {
              console.log("call---");
              setLoginOpen(false);
              let userName =
                result.data.first_name + " " + result.data.last_name;
              Cookies.set('authToken', result.data.accessToken, { expires: 365 });
              Cookies.set('REACT_APP_API_URL', process.env.REACT_APP_API_URL, { expires: 365 });
              Cookies.set('REACT_APP_URL', process.env.REACT_APP_URL, { expires: 365 });
              Cookies.set('REACT_APP_DIR_KEY_SERVERJS', process.env.REACT_APP_DIR_KEY_SERVERJS, { expires: 365 });
              if (result.data.is_registered) {
                AsyncLocalStorage.setItem("authToken", result.data.accessToken);
                AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
                AsyncLocalStorage.setItem("userName", userName);
                AsyncLocalStorage.setItem(
                  "userProfile",
                  result.data.profile_picture
                );
                AsyncLocalStorage.setItem("from_id", result.data.user_id);
                AsyncLocalStorage.setItem("university", result.data.university);
                AsyncLocalStorage.setItem("isOrgUser", result.data.isOrgUser);

                AsyncLocalStorage.setItem(
                  "puniversity_lat",
                  result.data.puniversity_lat
                );
                AsyncLocalStorage.setItem(
                  "puniversity_long",
                  result.data.puniversity_long
                );
                AsyncLocalStorage.setItem("from_slug", result.data.slug);
                AsyncLocalStorage.setItem(
                  "currentModule",
                  result.data.module_name
                );
                AsyncLocalStorage.setItem("tabFavValue", 0);
                AsyncLocalStorage.setItem("tabValue", 0);
                AsyncLocalStorage.setItem(
                  "signupDeatils",
                  result.data.is_registered
                );
                AsyncLocalStorage.setItem(
                  "personalDeatils",
                  result.data.has_personalInfo
                );
                AsyncLocalStorage.setItem("quiz", result.data.quiz);
                setAppContext({
                  ...appContext,
                  currentModule: result.data.module_name,
                });
              } else {
                AsyncLocalStorage.setItem("email", result.data.email);
                AsyncLocalStorage.setItem("phone", "");
                AsyncLocalStorage.setItem("authToken", result.data.accessToken);
                AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
                AsyncLocalStorage.setItem("userName", "");
                AsyncLocalStorage.setItem("userProfile", "");
                AsyncLocalStorage.setItem("from_id", "");
                AsyncLocalStorage.setItem("university", "");
                AsyncLocalStorage.setItem("puniversity_lat", 0);
                AsyncLocalStorage.setItem("puniversity_long", 0);
                AsyncLocalStorage.setItem("from_slug", "");
                AsyncLocalStorage.setItem("currentModule", result.data.module_name)
                AsyncLocalStorage.setItem("tabFavValue", 0);
                AsyncLocalStorage.setItem("tabValue", 0);
                AsyncLocalStorage.setItem(
                  "signupDeatils",
                  result.data.is_registered
                );
                AsyncLocalStorage.setItem(
                  "personalDeatils",
                  result.data.has_personalInfo
                );
                AsyncLocalStorage.setItem("quiz", result.data.quiz);
                if (result.data.module_name) {
                  setAppContext({ ...appContext, currentModule: result.data.module_name })
                }
                // setAppContext({ ...appContext, currentModule: "Dormmates" })
              }
              if (
                result.data.module_name == "Soulmates" ||
                result.data.module_name == "Teammates"
              ) {
                let teammateRedirectTo = Cookies.get('teammateRedirectTo')

                if (!result.data.is_registered) {
                  // window.location.replace("/signup-details")
                  // window.location.replace("/selecte-module")
                  if (teammateRedirectTo) {
                    router.history.push({
                      pathname: `/signup-details`,
                      search: "",
                      state: {
                        step2: true,
                        teammateRedirectTo: teammateRedirectTo
                      },
                    });
                  } else {
                    if (appContext.objAuth?.LIVE_MODULE_COUNT == 1) {
                      router.history.push({
                        pathname: `/signup-details`,
                        search: "",
                        state: {
                          step2: true,
                        },
                      });
                    } else {
                      router.history.push({
                        pathname: `/selecte-module`,
                        search: "",
                        state: {
                          step1: true,
                        },
                      });
                    }
                  }
                }
                else if (!result.data.has_personalInfo) {
                  // window.location.replace("/personal-details")
                  router.history.push({
                    pathname: `/personal-details`,
                    search: "",
                    state: {
                      step4: true,
                      teammateRedirectTo: teammateRedirectTo
                    },
                  });
                  // router.history.push("/personal-details");
                  // setSignupType("personalDetail")
                } else if (result.data.quiz) {
                  if (teammateRedirectTo) {
                    console.log("call cokkie")
                    Cookies.remove('teammateRedirectTo')
                    setLoginOpen(false)
                    router.history.push(teammateRedirectTo)
                    // window.location.replace(teammateRedirectTo)
                  } else {
                    window.location.replace("/your-matches");
                  }
                  // router.history.push("/your-matches");
                } else {
                  // router.history.push("/matching-quiz");
                  window.location.replace("/matching-quiz");
                }
              } else {
                let roommateRedirectTo = Cookies.get('roommateRedirectTo')
                if (!result.data.is_registered) {
                  // window.location.replace("/signup-details")
                  // window.location.replace("/selecte-module")
                  if (appContext.objAuth?.LIVE_MODULE_COUNT == 1) {
                    router.history.push({
                      pathname: `/signup-details`,
                      search: "",
                      state: {
                        step2: true,
                      },
                    });
                  } else {
                    router.history.push({
                      pathname: `/selecte-module`,
                      search: "",
                      state: {
                        step1: true,
                      },
                    });
                  }
                  // setIsSignupDetail(true)
                  // setSignupType("signupDetail")
                } else if (!result.data.has_university) {
                  router.history.push({
                    pathname: `/select-university`,
                    search: "",
                    state: {
                      step3: true,
                    },
                  });
                  // window.location.replace("/select-university")
                  // router.history.push("/select-university");
                  // setSignupType("selectUniversity")
                } else if (!result.data.has_personalInfo) {
                  // window.location.replace("/personal-details")
                  router.history.push({
                    pathname: `/personal-details`,
                    search: "",
                    state: {
                      step4: true,
                    },
                  });
                  // router.history.push("/personal-details");
                  // setSignupType("personalDetail")
                } else if (result.data.quiz) {
                  if (roommateRedirectTo) {
                    Cookies.remove('roommateRedirectTo')
                    setLoginOpen(false)
                    router.history.push(roommateRedirectTo)
                    // window.location.replace(roommateRedirectTo)
                  } else {
                    // window.location.replace("/your-matches");
                    router.history.push("/your-matches");
                  }
                } else {
                  // router.history.push("/matching-quiz");
                  window.location.replace("/matching-quiz");
                }
              }

              // if (result.data.quiz)
              //   // router.history.push("/your-matches")
              //   window.location.replace("/your-matches");
              // else
              //   // router.history.push("/matching-quiz")
              //   window.location.replace("/matching-quiz");
            } else {
              setLoginOpen(false);
            }
            setDisabledLogin(false);
            setLoading(false);
            setLoadingSignup(false);
          })
          .catch((err) => {
            setDisabledLogin(false);
            setLoading(false);
            setLoadingSignup(false);
          });
        // }
      })
      .catch((error) => {
        // // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // // ...
      });
  };

  //   accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo2LCJlbWFpbCI6ImphbWVzQHlvcG1haWwuY29tIiwiaWF0IjoxNjUzMzY5NTE4LCJleHAiOjE2NTU5NjE1MTh9.dewEF5V3vgg-veY9q4GJWiLMHgqmftYAmimDgdjRXEE"
  // email: "james@yopmail.com"
  // firebase_uid: "CxmFUYvReaP2OSd9n05DTzoDfbq1"
  // first_name: "James"
  // has_university: true
  // is_registered: true
  // last_name: "Williams"
  // module_name: "DormMates"
  // quiz: true
  // user_id: 6
  //   Today's update: Karan
  // project name: Matchupmates & Tellzme
  // 1. web dev. Matchupmates roommates end to end retesting
  // 2. Tellzme app end-to-end testing and bug report
  // 3. bug assign to developer excel sheets
  console.log(url, "url");

  const verifyOtp = async (event, type, value, code) => {
    event.preventDefault();
    setLoading(true);
    setDisabledLogin(true);

    // let mtype = teammateRedirectTo ? "teammodule" : roommateRedirectTo ? "roommmodule" : null
    let teammateRedirectTo = Cookies.get('teammateRedirectTo')
    let roommateRedirectTo = Cookies.get('roommateRedirectTo')
    // let mtype = teammateRedirectTo ? "teammodule" : roommateRedirectTo ? "roommmodule" : null
    let mtype = ""

    if (teammateRedirectTo) {
      if (appContext.objAuth?.TEAMMATE) {
        mtype = "teammodule"
      } else {
        // priority module
        if (appContext.objAuth?.DORMMATE) {
          mtype = "dormmodule"
        } else if (appContext.objAuth?.ROOMMATE) {
          mtype = "roommodule"
        } else if (appContext.objAuth?.SOULMATE) {
          mtype = "soulmodule"
        } else if (appContext.objAuth?.TEAMMATE) {
          mtype = "teammodule"
        }
      }
    } else if (roommateRedirectTo) {
      if (appContext.objAuth?.ROOMMATE) {
        mtype = "roommodule"
      } else {
        // priority module
        if (appContext.objAuth?.DORMMATE) {
          mtype = "dormmodule"
        } else if (appContext.objAuth?.ROOMMATE) {
          mtype = "roommodule"
        } else if (appContext.objAuth?.SOULMATE) {
          mtype = "soulmodule"
        } else if (appContext.objAuth?.TEAMMATE) {
          mtype = "teammodule"
        }
      }
    } else if (appContext.objAuth?.LIVE_MODULE_COUNT == 1) {
      // priority module
      if (appContext.objAuth?.DORMMATE) {
        mtype = "dormmodule"
      } else if (appContext.objAuth?.ROOMMATE) {
        mtype = "roommodule"
      } else if (appContext.objAuth?.SOULMATE) {
        mtype = "soulmodule"
      } else if (appContext.objAuth?.TEAMMATE) {
        mtype = "teammodule"
      }
    }
    // dispatch(login());
    console.log(mtype, "mtype")
    api
      .post(`user/verifycode?mtype=${mtype}`, {
        data: {
          value: value,
          code: code,
          is_verify: false,
          type: type,
        },
        skipAuth: true,
      })
      .then((result) => {
        if (result.success === "true") {
          // console.log(result.data, "result.data")
          let userName = result.data.first_name + " " + result.data.last_name;
          // if (url) {
          //   window.open(url);
          // }
          // let link = url
          // link ? window.open(link) : ""
          Cookies.set('authToken', result.data.accessToken, { expires: 365 });
          Cookies.set('REACT_APP_API_URL', process.env.REACT_APP_API_URL, { expires: 365 });
          Cookies.set('REACT_APP_URL', process.env.REACT_APP_URL, { expires: 365 });
          Cookies.set('REACT_APP_DIR_KEY_SERVERJS', process.env.REACT_APP_DIR_KEY_SERVERJS, { expires: 365 });
          if (result.data.is_registered) {
            AsyncLocalStorage.setItem("email", result.data.email);
            AsyncLocalStorage.setItem("phone", result.data.phone);
            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
            AsyncLocalStorage.setItem("userName", userName);
            AsyncLocalStorage.setItem(
              "userProfile",
              result.data.profile_picture
            );
            AsyncLocalStorage.setItem("from_id", result.data.user_id);
            AsyncLocalStorage.setItem("university", result.data.university);
            AsyncLocalStorage.setItem("isOrgUser", result.data.isOrgUser);
            AsyncLocalStorage.setItem(
              "puniversity_lat",
              result.data.puniversity_lat
            );
            AsyncLocalStorage.setItem(
              "puniversity_long",
              result.data.puniversity_long
            );
            AsyncLocalStorage.setItem("from_slug", result.data.slug);
            AsyncLocalStorage.setItem("currentModule", result.data.module_name);
            AsyncLocalStorage.setItem("tabFavValue", 0);
            AsyncLocalStorage.setItem("tabValue", 0);
            AsyncLocalStorage.setItem(
              "signupDeatils",
              result.data.is_registered
            );
            AsyncLocalStorage.setItem(
              "personalDeatils",
              result.data.has_personalInfo
            );
            AsyncLocalStorage.setItem("quiz", result.data.quiz);
            setAppContext({
              ...appContext,
              currentModule: result.data.module_name,
            });
            setOtpEmail(false);
            // setOtpEmail('')
          } else {
            AsyncLocalStorage.setItem("email", result.data.email);
            AsyncLocalStorage.setItem("phone", result.data.phone);
            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
            AsyncLocalStorage.setItem("userName", "");
            AsyncLocalStorage.setItem("userProfile", "");
            AsyncLocalStorage.setItem("from_id", "");
            AsyncLocalStorage.setItem("university", "");
            AsyncLocalStorage.setItem("puniversity_lat", 0);
            AsyncLocalStorage.setItem("puniversity_long", 0);
            AsyncLocalStorage.setItem("from_slug", "");
            AsyncLocalStorage.setItem("currentModule", result.data.module_name)
            AsyncLocalStorage.setItem("tabFavValue", 0);
            AsyncLocalStorage.setItem("tabValue", 0);
            AsyncLocalStorage.setItem(
              "signupDeatils",
              result.data.is_registered
            );
            AsyncLocalStorage.setItem(
              "personalDeatils",
              result.data.has_personalInfo
            );
            AsyncLocalStorage.setItem("quiz", result.data.quiz);
            if (result.data.module_name) {
              setAppContext({ ...appContext, currentModule: result.data.module_name })
            }
          }
          if (
            result.data.module_name == "Soulmates" ||
            result.data.module_name == "Teammates"
          ) {
            let teammateRedirectTo = Cookies.get('teammateRedirectTo')

            if (!result.data.is_registered) {
              // window.location.replace("/selecte-module")
              // router.history.push("/selecte-module");
              if (teammateRedirectTo) {
                router.history.push({
                  pathname: `/signup-details`,
                  search: "",
                  state: {
                    step2: true,
                    teammateRedirectTo: teammateRedirectTo
                  },
                });
              } else {
                if (appContext.objAuth?.LIVE_MODULE_COUNT == 1) {
                  router.history.push({
                    pathname: `/signup-details`,
                    search: "",
                    state: {
                      step2: true,
                    },
                  });
                } else {
                  router.history.push({
                    pathname: `/selecte-module`,
                    search: "",
                    state: {
                      step1: true,
                    },
                  });
                }
              }
              // window.location.replace("/signup-details")
              // setIsSignupDetail(true)
              // setSignupType("signupDetail")
            }
            else if (!result.data.has_personalInfo) {
              window.location.replace("/personal-details");
              router.history.push({
                pathname: `/personal-details`,
                search: "",
                state: {
                  step4: true,
                  teammateRedirectTo: teammateRedirectTo
                },
              });
              // router.history.push("/personal-details");
              // setSignupType("personalDetail")
            } else if (result.data.quiz) {
              if (teammateRedirectTo) {
                console.log("call cokkie")
                Cookies.remove('teammateRedirectTo')
                setLoginOpen(false)
                router.history.push(teammateRedirectTo)
                // window.location.replace(teammateRedirectTo)
              } else {
                router.history.push("/your-matches");
              }
            } else {
              window.location.replace("/matching-quiz");
              // router.history.push("/matching-quiz");
            }
          } else {
            let roommateRedirectTo = Cookies.get('roommateRedirectTo')
            if (!result.data.is_registered) {
              // window.location.replace("/selecte-module")
              // router.history.push("/selecte-module");
              if (appContext.objAuth?.LIVE_MODULE_COUNT == 1) {
                router.history.push({
                  pathname: `/signup-details`,
                  search: "",
                  state: {
                    step2: true,
                  },
                });
              } else {
                router.history.push({
                  pathname: `/selecte-module`,
                  search: "",
                  state: {
                    step1: true,
                  },
                });
              }
              // window.location.replace("/signup-details")
              // setIsSignupDetail(true)
              // setSignupType("signupDetail")
            } else if (!result.data.has_university) {
              router.history.push({
                pathname: `/select-university`,
                search: "",
                state: {
                  step3: true,
                },
              });
              // window.location.replace("/select-university")
              // router.history.push("/select-university");

              // setSignupType("selectUniversity")
            } else if (!result.data.has_personalInfo) {
              window.location.replace("/personal-details");
              router.history.push({
                pathname: `/personal-details`,
                search: "",
                state: {
                  step4: true,
                },
              });
              // router.history.push("/personal-details");
              // setSignupType("personalDetail")
            } else if (result.data.quiz) {
              if (roommateRedirectTo) {
                console.log(roommateRedirectTo, "roommateRedirectTo")
                Cookies.remove('roommateRedirectTo')
                setLoginOpen(false)
                router.history.push(roommateRedirectTo)
              } else {
                router.history.push("/your-matches");
              }
            } else {
              window.location.replace("/matching-quiz");
              // router.history.push("/matching-quiz");
            }
          }

          // if (!result.data.has_university) {
          //   router.history.push("/select-university")
          // }
          // else if (!result.data.is_registered) {
          //   router.history.push("/personal-details")
          // }
          // else if (result.data.quiz) {
          //   router.history.push("/your-matches")
          // } else {
          //   router.history.push("/matching-quiz")
          // }

          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setDisabledLogin(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setDisabledLogin(false);
        setLoading(false);
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // function handleEnterKeyOnCards(e) {
  //   if (e.keyCode == 13) {
  //     if (chatType == "group") {
  //       sendMessageGroup(e);
  //     } else {
  //       sendMessagePersonal(e);
  //     }
  //   }
  // }

  const keyPress = (e) => {
    if (e.code == "Enter") {
      alert("hello");
      // put the login here
    }
  };

  // const changeHandlerContryCode = value => {
  //   setValue(value)
  // }

  // console.log(isMobile, "isMobile--")
  return (
    <>
      <Dialog
        classes={{ paper: classes.paperRoot }}
        maxWidth={"md"}
        open={loginOpen}
        onClose={handleCloseSignUp}
        aria-labelledby="max-width-dialog-title"
      >
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Grid container className={classes.container}>
          <Grid item xl={5} md={5} lg={5} xs={12}>
            <div className={classes.signUpFormBg}>
              <h3 className={classes.headingSignUp}>
                Welcome Back to MatchUpMates
              </h3>
              <div className={classes.hr}></div>
              <p className={classes.signINMessage}>
                {" "}
                Sign in to continue to your account{" "}
              </p>
              <img
                className={classes.dotImageTop}
                src="/images/matchUp/dotsLogin.png"
                // src={dotsLogin}
                alt={"Alt Image"}
              />
              <img
                className={classes.dotImageBottom}
                src="/images/matchUp/dotsLogin.png"
                // src={dotsLogin}
                alt={"Alt Image"}
              />
            </div>
          </Grid>
          <Grid
            item
            xl={7}
            md={7}
            lg={7}
            xs={12}
            style={{ padding: "20px 20px 20px 20px", position: "relative" }}
          >
            {loadingSignup ? (
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                {/* loadingSignup */}
                <CircularProgress size={50} />
              </div>
            ) : (
              <>
                <div style={{ display: "flex", position: "relative" }}>
                  {signupType !== "notADefine" && (
                    <ArrowBackIcon
                      className={classes.backIcon}
                      onClick={handleBack}
                    />
                  )}
                  <CloseIcon
                    className={classes.socialIcon}
                    onClick={closeHandlerModel}
                  />
                </div>
                {/* <form onSubmit={(event) => handleSubmit(event)}> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {/* <hr className={classes.formHr} /> */}
                  {signupType === "notADefine" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className={classes.mumLogin}
                          src="/images/matchUp/mumLogin.png"
                        // src={dotsLogin}
                        />
                        <h4 className={classes.matchUpLogin}>
                          Welcome to MatchUpMates
                        </h4>
                        <p
                          style={{ margin: 0, color: "gray", fontSize: "12px" }}
                        >
                          It's time to choose your best matches
                        </p>
                      </div>
                      <Box className={classes.gruopLinkButton}>
                        <Button
                          variant="outlined"
                          className={classes.buttonSignIn}
                          startIcon={
                            <img
                              src="/images/matchUp/google.png"
                              alt={"Alt Image"}
                            />
                          }
                          onClick={(event) => {
                            signInwithGoogle(event);
                          }}
                        >
                          &nbsp;&nbsp;Continue with Google
                        </Button>
                        <Button
                          variant="outlined"
                          className={classes.buttonSignIn}
                          startIcon={
                            <img
                              src="/images/matchUp/email.svg"
                              alt={"Alt Image"}
                            />
                          }
                          onClick={() => {
                            setSignupType("email");
                          }}
                        >
                          &nbsp;&nbsp;Continue with Email
                        </Button>
                        <Button
                          variant="outlined"
                          className={classes.buttonSignIn}
                          startIcon={
                            <img
                              src="/images/matchUp/phone.svg"
                              alt={"Alt Image"}
                            />
                          }
                          onClick={() => {
                            setSignupType("phone");
                          }}
                        >
                          &nbsp;&nbsp;Continue with Phone
                        </Button>
                      </Box>
                      <div style={{ marginBottom: "20px" }}>
                        <h6 style={{ color: "black", textAlign: "center" }}>
                          <span style={{ fontWeight: "400" }}>
                            By signing in you are agreeing to our
                          </span>
                          {"  "}
                          <span
                            className={classes.termsRedirect}
                          // onClick={() => handleRedirectPolicy()}
                          >
                            <u style={{ fontWeight: "900" }}>
                              <a
                                target="black"
                                href="/privacy-policy"
                                style={{ color: "black" }}
                              >
                                Privacy policy
                              </a>
                            </u>
                          </span>{" "}
                          <span style={{ fontWeight: "400" }}>and</span>{" "}
                          <span
                            className={classes.termsRedirect}
                          // onClick={() => handleRedirectTerms()}
                          >
                            <u style={{ fontWeight: "900" }}>
                              <a
                                target="black"
                                href="/terms-of-use"
                                style={{ color: "black" }}
                              >
                                Terms of Service
                              </a>
                            </u>
                          </span>
                        </h6>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <form> */}
                      {signupType !== "personalDetail" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <img
                            className={classes.mumLogin}
                            src="/images/matchUp/mumLogin.png"
                            alt={"Alt Image"}
                          // src={dotsLogin}
                          />
                          <h4 className={classes.matchUpLogin}>
                            Welcome to MatchUpMates
                          </h4>
                          <p
                            style={{
                              margin: 0,
                              color: "gray",
                              fontSize: "12px",
                            }}
                          >
                            It's time to choose your best matches
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {signupType === "email" && (
                        <form
                          onSubmit={(event) => {
                            sendOtp(event, "email", formState.values.email);
                          }}
                        >
                          {/* <form> */}
                          <div style={{ marginTop: "30px" }}>
                            <Box my={1} className={classes.textFiledBox}>
                              <TextField
                                autoFocus={true}
                                id="outlined-basic"
                                label="Emails"
                                fullWidth
                                autoComplete="off"
                                size="small"
                                variant="outlined"
                                value={formState.values.email || ""}
                                onChange={handleFieldChange}
                                name="email"
                                InputLabelProps={{
                                  classes: {
                                    asterisk: classes.asterisk,
                                  },
                                  shrink: true,
                                }}
                                disabled={otpSendEmail}
                                required
                                error={hasError("email")}
                                helperText={
                                  hasError("email")
                                    ? formState.errors.email[0]
                                    : null
                                }
                              />
                            </Box>
                            <form
                              onSubmit={(event) => {
                                verifyOtp(
                                  event,
                                  "mobile",
                                  formState.values.phone,
                                  otpPhone
                                );
                              }}
                            >
                              {otpSendEmail && !verifyEmail ? (
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent="center"
                                >
                                  <OtpInput
                                    shouldAutoFocus={true}
                                    value={otpEmail || ""}
                                    name="otp"
                                    onChange={(otp) =>
                                      !verifyEmail && setOtpEmail(otp)
                                    }
                                    numInputs={6}
                                    className={classes.otp}
                                    separator={<span>&nbsp;&nbsp; </span>}
                                    inputStyle={{
                                      width: isMobile ? "40px" : "52px",
                                      fontSize: "20px",
                                      height: "40px",
                                      borderRadius: "5px",
                                      border: "1px solid rgba(0,0,0,0.15)",
                                    }}
                                    focusStyle={{
                                      border: "2px solid #2196f3",
                                      outline: "none",
                                    }}
                                  />
                                  {/* {console.log(otpEmail, "otpEmail")} */}
                                </Box>
                              ) : (
                                ""
                              )}
                              {counter > 0 ? (
                                <Box
                                  style={{
                                    textAlign: "left",
                                    marginLeft: "20px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <span>Resend Otp in 00:{counter}</span>
                                </Box>
                              ) : (
                                ""
                              )}

                              {counter === 0 ? (
                                <Box
                                  style={{
                                    textAlign: "right",
                                    marginRight: "15px",
                                    marginTop: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {!otpLoader && (
                                    <Link
                                      onClick={(event) => {
                                        sendOtp(
                                          event,
                                          "email",
                                          formState.values.email
                                        );
                                      }}
                                    >
                                      Resend Otp
                                    </Link>
                                  )}
                                  {otpLoader && (
                                    <>
                                      &nbsp;
                                      <CircularProgress size={15} />
                                    </>
                                  )}
                                </Box>
                              ) : (
                                ""
                              )}
                              {counter !== 0 && !counter ? (
                                <Box
                                  my={3}
                                  className={classes.startedButtonBox}
                                >
                                  <Button
                                    className={classes.startedButton}
                                    variant="contained"
                                    disabled={
                                      formState.isValid ? disabled : true
                                    }
                                    size="large"
                                    color="primary"
                                    onClick={(event) => {
                                      sendOtp(
                                        event,
                                        "email",
                                        formState.values.email
                                      );
                                    }}
                                  >
                                    Send otp
                                    {otpLoader && (
                                      <>
                                        &nbsp;
                                        <CircularProgress
                                          size={20}
                                          style={{ color: "white" }}
                                        />
                                      </>
                                    )}
                                  </Button>
                                </Box>
                              ) : (
                                <Box
                                  my={3}
                                  className={classes.startedButtonBox}
                                >
                                  <Button
                                    className={classes.startedButton}
                                    variant="contained"
                                    type="submit"
                                    disabled={
                                      disabledLogin
                                        ? disabledLogin
                                        : otpEmail.length === 6
                                          ? false
                                          : true
                                    }
                                    size="large"
                                    color="primary"
                                    onClick={(event) => {
                                      verifyOtp(
                                        event,
                                        "email",
                                        formState.values.email,
                                        otpEmail
                                      );
                                    }}
                                  >
                                    Log in
                                    {loading && (
                                      <>
                                        &nbsp;
                                        <CircularProgress
                                          size={20}
                                          style={{ color: "white" }}
                                        />
                                      </>
                                    )}
                                  </Button>
                                </Box>
                              )}
                            </form>
                            <hr className={classes.formHr} />
                            {/* </form> */}
                            {/* <h6 className={classes.memberMessage}>
                          Don’t have an account?{" "}
                          <span style={{ cursor: "pointer", color: "#FE4B6B" }} onClick={() => { setOpenSignUp() }}> <u>Sign up</u> </span>
                        </h6> */}
                          </div>
                        </form>
                      )}

                      {/* mobile */}
                      {signupType === "phone" && (
                        <>
                          <form>
                            <Box my={1} className={classes.inputePhone}>
                              <PhoneInput
                                inputProps={{ autoFocus: true }}
                                onEnterKeyPress={(event) => {
                                  sendOtp(
                                    event,
                                    "phone",
                                    formState.values.phone
                                  );
                                }}
                                country={"us"}
                                onlyCountries={["us"]}
                                countryCodeEditable={false}
                                value={phone}
                                onChange={(
                                  value,
                                  data,
                                  event,
                                  formattedValue
                                ) => {
                                  setFormState((formState) => ({
                                    ...formState,
                                    values: {
                                      ...formState.values,
                                      phone: value.substr(
                                        data.dialCode.length,
                                        value.length
                                      ),
                                      countryCode: data.dialCode.length,
                                    },
                                  }));
                                }}
                              />
                              {/* <TextField
                                id="outlined-basic"
                                label="Phone"
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={!verifyPhone && handleFieldChange}
                                name="phone"
                                InputLabelProps={{
                                  classes: {
                                    asterisk: classes.asterisk
                                  },
                                  shrink: true,
                                }}
                                required
                                error={hasError('phone')}
                                helperText={hasError('phone') ? formState.errors.phone[0] : null}
                              // InputProps={{
                              //   endAdornment:
                              //     <InputAdornment position="end">
                              //       {verifyPhone ?
                              //         <Box>
                              //           <img src='/images/verifyOtp.svg' alt='' />
                              //         </Box>
                              //         :
                              //         <Button
                              //           variant='contained'
                              //           color={"primary"}
                              //           size="small"
                              //           style={{ textTransform: "capitalize" }}
                              //           disabled={formState.values.phone && !hasError('phone') ? false : true}
                              //           onClick={(event) => {
                              //             sendOtp(event, "phone", formState.values.phone)
                              //           }}
                              //         >
                              //           Send Otp
                              //         </Button>
                              //       }
                              //     </InputAdornment>
                              // }}
                              /> */}
                            </Box>
                            <form
                              onSubmit={(event) => {
                                verifyOtp(
                                  event,
                                  "mobile",
                                  formState.values.phone,
                                  otpPhone
                                );
                              }}
                            >
                              {otpSendPhone && !verifyPhone ? (
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent="center"
                                >
                                  <OtpInput
                                    shouldAutoFocus={true}
                                    value={otpPhone || ""}
                                    name="otp"
                                    onChange={(otp) =>
                                      !verifyPhone && setOtpPhone(otp)
                                    }
                                    numInputs={6}
                                    className={classes.otp}
                                    separator={<span>&nbsp;&nbsp; </span>}
                                    // inputStyle={{ width: "40px", fontSize: "20px", height: "40px", borderRadius: "5px", border: "1px solid rgba(0,0,0,0.15)" }}
                                    inputStyle={{
                                      width: isMobile ? "40px" : "52px",
                                      fontSize: "20px",
                                      height: "40px",
                                      borderRadius: "5px",
                                      border: "1px solid rgba(0,0,0,0.15)",
                                    }}
                                    focusStyle={{
                                      border: "2px solid #2196f3",
                                      outline: "none",
                                    }}
                                  />
                                </Box>
                              ) : (
                                ""
                              )}
                              {counter > 0 ? (
                                <Box
                                  style={{
                                    textAlign: "left",
                                    marginLeft: "20px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <span>Resend Otp in 00:{counter}</span>
                                </Box>
                              ) : (
                                ""
                              )}
                              {counter === 0 ? (
                                <Box
                                  style={{
                                    textAlign: "right",
                                    marginRight: "20px",
                                    marginTop: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {!otpLoader && (
                                    <Link
                                      onClick={(event) => {
                                        sendOtp(
                                          event,
                                          "phone",
                                          formState.values.phone
                                        );
                                      }}
                                    >
                                      Resend Otp
                                    </Link>
                                  )}
                                  {otpLoader && (
                                    <>
                                      &nbsp;
                                      <CircularProgress size={15} />
                                    </>
                                  )}
                                </Box>
                              ) : (
                                ""
                              )}
                              {counter !== 0 && !counter ? (
                                // <Box className={classes.forgotText}>
                                //   {!otpLoader && <span
                                //     style={{ cursor: "pointer", color: "#FE4B6B", objectFit: "cover" }}
                                //     onClick={(event) => { sendOtp(event, "phone", formState.values.phone) }}
                                //   >
                                //     <u>Send Otp?</u>
                                //   </span>}
                                //   {otpLoader && <>&nbsp;<CircularProgress size={15} /></>}
                                // </Box>
                                <Box
                                  my={3}
                                  className={classes.startedButtonBox}
                                >
                                  <Button
                                    className={classes.startedButton}
                                    variant="contained"
                                    disabled={
                                      formState.isValid ? disabled : true
                                    }
                                    size="large"
                                    color="primary"
                                    onClick={(event) => {
                                      sendOtp(
                                        event,
                                        "phone",
                                        formState.values.phone
                                      );
                                    }}
                                  >
                                    Send otp
                                    {otpLoader && (
                                      <>
                                        &nbsp;
                                        <CircularProgress
                                          size={20}
                                          style={{ color: "white" }}
                                        />
                                      </>
                                    )}
                                  </Button>
                                </Box>
                              ) : (
                                <Box
                                  my={3}
                                  className={classes.startedButtonBox}
                                >
                                  <Button
                                    className={classes.startedButton}
                                    type="submit"
                                    variant="contained"
                                    disabled={
                                      disabledLogin
                                        ? disabledLogin
                                        : otpPhone.length === 6
                                          ? false
                                          : true
                                    }
                                    size="large"
                                    color="primary"
                                    onClick={(event) => {
                                      verifyOtp(
                                        event,
                                        "mobile",
                                        formState.values.phone,
                                        otpPhone
                                      );
                                    }}
                                  >
                                    Log in
                                    {loading && (
                                      <>
                                        &nbsp;
                                        <CircularProgress
                                          size={20}
                                          style={{ color: "white" }}
                                        />
                                      </>
                                    )}
                                  </Button>
                                </Box>
                              )}
                            </form>
                            {/* </form> */}
                            {/* <h6 className={classes.memberMessage}>
                          Don’t have an account?{" "}
                          <span style={{ cursor: "pointer", color: "#FE4B6B" }} onClick={() => { setOpenSignUp() }}> <u>Sign up</u> </span>
                        </h6> */}
                          </form>
                        </>
                      )}
                      {signupType === "signupDetail" && (
                        <>
                          {formState.values.email && (
                            <SignupDetailForm
                              email={formState.values.email}
                              setSignupType={setSignupType}
                            />
                          )}
                          {formState.values.phone && (
                            <SignupDetailForm
                              phone={formState.values.phone}
                              setSignupType={setSignupType}
                            />
                          )}
                        </>
                      )}

                      {/* selectUniversity */}
                      {signupType === "selectUniversity" && (
                        <SelecteUniversityForm setSignupType={setSignupType} />
                      )}

                      {/* personalDetail */}
                      {signupType === "personalDetail" && (
                        <PersonalDetailForm />
                      )}
                    </>
                  )}
                  {/* <h6 className={classes.memberMessage}>
                    Don’t have an account?{" "}
                    <span style={{ cursor: "pointer", color: "#FE4B6B" }} onClick={() => { setOpenSignUp() }}> <u>Sign up</u> </span>
                  </h6> */}
                </div>
              </>
            )}

            {/* </form> */}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default LoginModel;
