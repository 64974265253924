import React from 'react'
import FormResetPassword from './component/FormResetPassword'

const ResetPassword = (props) => {
    const { match } = props;
    const { token } = match.params
    return (
        <>
            <FormResetPassword token={token} />
        </>
    )
}

export default ResetPassword
