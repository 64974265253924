import React from "react";
import FaqDetail from "./component/PrivacyPolicyDetail";

const FaqSoulmate = () => {
  return (
    <>
      <FaqDetail />
    </>
  );
};

export default FaqSoulmate;
