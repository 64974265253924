import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import firebase from "firebase/app";

export default function NamePersonal(props) {
  const { groupId } = props
  const [groupName, setGroupName] = useState("");
  // Base path of firebase
  const database = firebase.firestore();
  let db = database.collection("teammate").doc("chatRooms")

  useEffect(() => {
    getNameFromFireBase(groupId)
  }, [groupId])

  let getNameFromFireBase = (groupId) => {
    var groupRef = db.collection("user").doc(groupId);
    groupRef.get().then((doc) => {
      if (doc.exists) {
        console.log("Document data:", doc.data().displayName);
        setGroupName(doc.data().displayName)
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
    // console.log(name, "name--")

    // groupRef.onSnapshot((doc) => {
    //   if (doc.exists) {
    //     setGroupName(doc.data().displayName)
    //   } else {
    //     console.log("No such document!");
    //   }
    // });
  }

  return groupName
}
