import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import QRCode from "qrcode.react";

import Api from '../../Helper/ApiHandler';
import { useHistory } from 'react-router-dom';
var api = new Api();

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 10px 10px 10px",
    '& .MuiButton-root': {
      textTransform: "capitalize"
    }
  },
  dialogRoot: {
    padding: "10px",
    '& .MuiDialog-paper': {
      // overflow: "visible"
      borderRadius: "10px"
    },
    '& .MuiBackdrop-root': {
      backgroundColor: "rgb(0 0 0 / 51%)",
    },
    '& .MuiPaper-elevation24': {
      boxShadow: "none"
    }
  },
  btnSave: {
    borderRadius: "5px",
    backgroundImage: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white"
  },
  btnCancel: {
    borderRadius: "5px",
  },
  flexWithPading: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 500
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500
  },
  groupName: {
    fontSize: "18px",
    fontWeight: "500"
  },
}))
function ShareQrGroupDialog(props) {
  const classes = useStyles();
  const history = useHistory()
  const { groupName, url, onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog className={classes.dialogRoot} onClose={handleClose} maxWidth="sm" open={open} >
      <Box p={"10px"}>
        <Grid spacing={2} container justify="center" style={{ padding: "20px" }}>
          <Grid item xs={12}>
            <div style={{ position: "absolute", top: "15px", right: "25px", cursor: "pointer" }}>
              <CloseIcon
                onClick={() => {
                  handleClose()
                }}
              />
            </div>
          </Grid>

          {/* <Grid item xs={12}>
            <Typography className={classes.groupName}>{groupName}</Typography>
          </Grid> */}
          <Grid item xs={12}>
            <QRCode
              id="qr-gen"
              value={url}
              size={290}
              level={"H"}
              includeMargin={true}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog >
  );
}

export default ShareQrGroupDialog;