import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Slider from '../Slider/Slider'

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 10px 10px 10px",
    '& .MuiButton-root': {
      textTransform: "capitalize"
    }
  },
  dialogRoot: {
    padding: "10px",
    '& .MuiDialog-paper': {
      // overflow: "visible"
      borderRadius: "10px"
    },
    '& .MuiBackdrop-root': {
      backgroundColor: "rgb(0 0 0 / 0.05)",
    },
    '& .MuiPaper-elevation24': {
      boxShadow: "none"
    }
  },
  btnSave: {
    borderRadius: "5px",
    //backgroundImage: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white"
  },
  btnCancel: {
    borderRadius: "5px",
  },
  flexWithPading: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 500
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500
  }
}))
function DeleteDialog(props) {
  const classes = useStyles();
  const { lifestyle_photos, onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog className={classes.dialogRoot} onClose={handleClose} maxWidth="md" open={open} >
      <Slider lifestyle_photos={lifestyle_photos} style={{ width: "100%", height: "100%" }} />
    </Dialog>
  );
}

export default DeleteDialog;