import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Container, Grid, Snackbar, Typography } from "@material-ui/core";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import MuiAlert from "@material-ui/lab/Alert";
import { Skeleton } from "@material-ui/lab";
import useRouter from "utils/useRouter";

import Api from "../../../../Helper/ApiHandler";
import GroupCard from "components/GroupCard/GroupCard";
import { Link } from "react-router-dom";
import GroupSearchBar from "components/GroupSearchBar/GroupSearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import UserSkeletonTeamCard from "components/Skeleton/UserSkeletonTeamCard";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    minHeight: "90vh",
    background: "#E9EDF0",
    padding: "35px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0",
    },
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("xs")]: {
      height: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "210px",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  listBg: {
    background: "#E9EDF0",
    padding: '2px 10px 10px 16px',
  },
}));

const MatchesTeamList = (props) => {
  const { sessionPropsMessage, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();

  const [totalRecored, setTotalRecored] = React.useState(0);
  const [lstGroup, setLstGroup] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setrowsPerPage] = React.useState(3);
  const [loading, setLoading] = React.useState(false);
  const [loadingScroll, setLoadingScroll] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const onLoad = () => {
    setLoading(true);
    let url = search
      ? `user/teammate/getgrouplist?page=${0}&size=${rowsPerPage}&s=${search}`
      : `user/teammate/getgrouplist?page=${0}&size=${rowsPerPage}`;
    // Api Call For Fetch The Our group
    api
      .get(url)
      .then((result) => {
        if (result.success === "true") {
          setLstGroup(result.data.user);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "catch error");
      });
  };

  const onClickLoad = () => {
    let url = search
      ? `user/teammate/getgrouplist?page=${0}&size=${rowsPerPage}&s=${search}`
      : `user/teammate/getgrouplist?page=${0}&size=${rowsPerPage}`;
    // Api Call For Fetch The Our group
    api
      .get(url)
      .then((result) => {
        if (result.success === "true") {
          setLstGroup(result.data.user);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const onMore = async () => {
    setLoadingScroll(true);

    // Api Call For Fetch The Our Matches
    let url = search
      ? `user/teammate/getgrouplist?page=${page}&size=${rowsPerPage}&s=${search}`
      : `user/teammate/getgrouplist?page=${page}&size=${rowsPerPage}`;

    await api
      .get(url)
      .then((result) => {
        if (result.success === "true") {
          const lstTmp = lstGroup.concat(result.data.user);
          setLstGroup(lstTmp);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
        setLoadingScroll(false);
      })
      .catch((err) => {
        setLoadingScroll(false);
        console.log(err, "catch error");
      });
  };

  const fetchMoreMatches = () => {
    if (lstGroup.length >= totalRecored) {
      setHasMore(false);
      return;
    }

    setPage(page + 1);
  };

  useEffect(() => {
    if (page !== 0) {
      onMore();
    }
  }, [page]);

  useEffect(() => {
    onLoad();
  }, [search]);

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.mainRoot}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid item className={classes.container}>
              <div className={classes.listBg}>
                <div style={{ width: "100%" }}>
                  {/* <Container maxWidth="md"> */}
                  <Box className={classes.newFilterTop}>
                    <Box flexGrow={1}>
                      <GroupSearchBar
                        heading={"Suggested for you"}
                        setSearch={setSearch}
                      />
                    </Box>
                    <InfiniteScroll
                      style={{ overflow: "hidden" }}
                      dataLength={lstGroup.length}
                      // next={fetchMoreData}
                      next={fetchMoreMatches}
                      hasMore={true}
                      loader={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "95%",
                            margin: "10px",
                          }}
                        ></div>
                      }
                      endMessage={
                        <p style={{ paddingBottom: "60px" }}>{/* <p> */}</p>
                      }
                    >
                      <Grid spacing={2} container>
                        {!loading ? (
                          <>
                            {lstGroup.length ? (
                              lstGroup.map((objGroup, index) => {
                                return (
                                  <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <GroupCard
                                      objGroup={{
                                        ...objGroup,
                                        groupName: objGroup.group_subject,
                                        noOfMembers: objGroup.no_of_members,
                                        groupDescription:
                                          objGroup.group_description,
                                        isFavourite: objGroup.isFavourite,
                                        group_picture:
                                          objGroup.group_profile_pic,
                                        groupType: objGroup.group_type,
                                        slug: objGroup.slug,
                                        teamRequest: objGroup.teamRequest,
                                      }}
                                      setSessionMessage={setSessionMessage}
                                      lstGroup={lstGroup}
                                      index={index}
                                      setLstGroup={setLstGroup}
                                      reDirectUrl={`/group/${objGroup.slug}`}
                                      onClickUpload={onClickLoad}
                                    />
                                  </Grid>
                                );
                              })
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  marginTop: "50px"
                                }}
                              >
                                <img
                                  src="/images/matchUp/notFoundMatches.svg"
                                  alt="favAlt"
                                />
                                <Typography
                                  variant="caption"
                                  component="primary"
                                  color="primary"
                                  style={{
                                    fontSize: "20px",
                                    color: "black",
                                    fontWeight: "600",
                                  }}
                                >
                                  No Matches Yet!
                                </Typography>
                                <Typography
                                  variant="caption"
                                  component="primary"
                                  color="primary"
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    fontWeight: "400",
                                  }}
                                >
                                  Mark your favorite profile and always have
                                  them here.
                                </Typography>
                              </div>
                            )}
                          </>
                        ) : (
                          <SkeletonLoading />
                        )}
                      </Grid>
                    </InfiniteScroll>
                  </Box>
                  {/* </Container> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* <FilterModel filterOpen={filterOpen} setFilterOpen={setFilterOpen} setMaltiFilter={setMaltiFilter} setPage={setPage} /> */}
    </>
  );
};
const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      className={classes.cardBoxGrid}
      container
    >
      {[0, 1, 2, 3, 4, 5, 6, 7].map((obj) => {
        return (
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <UserSkeletonTeamCard />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default MatchesTeamList;
