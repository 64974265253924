import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Container, Grid, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  mainStepPart: {
    backgroundImage: `url(${"../images/matchUp/banner.png"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "571px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      height: "600px",
    },
  },
  headingStep: {
    textAlign: "center",
    padding: "40px 0px 5px 0px",
    margin: "0",
    color: "white",
    fontSize: "33px",
    fontWeight: '500',
    [theme.breakpoints.down('xs')]: {
      padding: "10px",
      fontSize: "24px !important",
      marginTop: "50px",
    },
  },
  bgIconImg: {
    width: "145px",
    height: "145px",
    background: "#D6D9E333",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    position: "relative",
    margin: "0 auto",
    [theme.breakpoints.down('xs')]: {
      width: 80,
      height: 80,

      // '& .MuiGrid-spacing-xs-10 > .MuiGrid-item':{
      // }
    },
  },
  imgIconInside: {
    width: "65px",
    height: "65px",
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
  stepNumber: {
    position: "absolute",
    top: "20px",
    right: "10px",
    fontSize: "60px",
    fontWeight: "bold",
    color: "white",
    [theme.breakpoints.down('xs')]: {
      top: "20px",
      fontSize: "30px",
    },
  },

  itemStepGrid: {
    justifyContent: "center",
  },
  stepSubGrid: {
    textAlign: "center"
  },
  nameStep: {
    color: "white",
    fontSize: "20px",
    fontWeight: 500
  }
}));

const StepHome = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.mainStepPart}>
        <h1 className={classes.headingStep}>
          Match with like minded people in three easy steps
        </h1>
        <Container maxWidth="md">
          <Box py={5}>
            <Grid
              direction="row"
              justifyContent="center"
              container
            >
              <Grid className={classes.itemStepGrid} item container sm={4} xs={12} md={4} lg={4} xl={4}>
                <Grid className={classes.stepSubGrid}>
                  <div className={classes.bgIconImg}>
                    <img
                      className={classes.imgIconInside}
                      src="/images/matchUp/people.png"
                      alt="step Dormates icon"
                    />
                    <p className={classes.stepNumber}> 1 </p>
                  </div>
                  <p className={classes.nameStep}> Create your account</p>
                </Grid>
              </Grid>

              <Grid className={classes.itemStepGrid} item container sm={4} xs={12} md={4} lg={4} xl={4}>
                <Grid className={classes.stepSubGrid}>
                  <div className={classes.bgIconImg}>
                    <img
                      className={classes.imgIconInside}
                      src="/images/matchUp/quiz.png"
                      alt="step Dormates icon"
                    />
                    <p className={classes.stepNumber}> 2 </p>
                  </div>
                  <p className={classes.nameStep}> Take the matching criteria quiz </p>
                </Grid>
              </Grid>

              <Grid className={classes.itemStepGrid} item container sm={4} xs={12} md={4} lg={4} xl={4}>
                <Grid className={classes.stepSubGrid}>
                  <div className={classes.bgIconImg}>
                    <img
                      className={classes.imgIconInside}
                      src="/images/matchUp/match.png"
                      alt="step Dormates icon"
                    />
                    <p className={classes.stepNumber}> 3 </p>
                  </div>
                  <p className={classes.nameStep}> Find your perfect match  </p>
                </Grid>
              </Grid>

            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default StepHome;

StepHome.propTypes = {
  className: PropTypes.string,
};
