import React from "react";
import ProfileEdit from "./component/ProfileEdit";

const EditProfile = () => {

  return (
    <>
      <ProfileEdit />
    </>
  );
};

export default EditProfile;
