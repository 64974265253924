import { Box, Dialog, Grid, makeStyles } from "@material-ui/core";
import React, { Component } from "react";
import Slider from "react-slick";

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 10px 10px 10px",
    '& .MuiButton-root': {
      textTransform: "capitalize"
    }
  },
  dialogRoot: {
    padding: "10px",
    '& .MuiDialog-paper': {
      // overflow: "visible"
      borderRadius: "10px"
    },
    '& .MuiBackdrop-root': {
      backgroundColor: "rgb(0 0 0 / 54%)",
    },
    '& .MuiPaper-elevation24': {
      boxShadow: "none"
    },
    "& .slick-slider": {
      padding: "0"
    }
  },
}))

const GalleryNextArrow = ({ currentSlide, slideCount, ...props }) => {
  const { className, onClick } = props;

  return (
    <div {...props} className="custom-nextArrow" onClick={onClick} style={{ marginLeft: "30px", position: "absolute", top: "50%", right: "5%" }} >
      <img src="./images/matchUp/nextArrow.svg" alt="next" />
    </div>
  );
};

const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }) => {
  const { lifestyle_photos, className, onClick } = props;

  return (
    <div {...props} className="custom-prevArrow" onClick={onClick} style={{ marginRight: "30px", position: "absolute", top: "50%", left: "5%" }}>
      <img src="./images/matchUp/preArrow.svg" alt="next" />
    </div>
  );
};

function SliderDialog(props) {
  const classes = useStyles();
  const { lstImage, onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };
  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    className: "center",
    centerPadding: "0px !important",
    className: "center",
    speed: 200,
    focusOnSelect: true,
    // centerPadding: "100px",
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 4,
    nextArrow: <GalleryNextArrow />,
    prevArrow: <GalleryPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Dialog className={classes.dialogRoot} onClose={handleClose} open={open} maxWidth={"md"}>
      <Slider {...settings}>
        {lstImage && lstImage.length && lstImage.map((value, index) => {
          return (
            <Box style={{ width: "100%", padding: "1px 3px" }}>
              <img src={value} alt="img" style={{ width: "100%" }} />
            </Box>
          )
        })}
      </Slider>
      {/* <Grid container style={{ padding: "20px" }}>
        <Grid item xs={6} style={{ width: "100%" }}>
          {lstImage && lstImage.length && lstImage.map((value, index) => {
            if (index % 2 == 0) {
              return (
                <Box style={{ width: "100%", padding: "1px 3px" }}>
                  <img src={value} alt="img" style={{ width: "100%" }} />
                </Box>
              )
            }
          })}
        </Grid>
        <Grid item xs={6} style={{ width: "100%" }}>
          {lstImage && lstImage.length && lstImage.map((value, index) => {
            if (index % 2 != 0) {
              return (
                <Box style={{ width: "100%", padding: "1px 3px" }}>
                  <img src={value} alt="img" style={{ width: "100%" }} />
                </Box>
              )
            }
          })}
        </Grid>
      </Grid> */}
    </Dialog >
  );
}

export default SliderDialog;