/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  listItemIcon: {
    minWidth: "auto",
  },
  listItemLink: {
    textDecoration: "none",
    //fontFamily: "Gilroy !important",
    fontSize: "18px",
    color: "black",
  },
  closeIcon: {
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  divider: {
    width: "100%",
  },
  buttonSideLogin: {
    margin: "10px 0",
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
  },
}));

const SidebarNav = (props) => {
  const { pages, onClose, className, ...rest } = props;
  const classes = useStyles();

  const [loginOpen, setLoginOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onLoginOpen = () => {
    setOpen(false);
    setLoginOpen(true)
  };

  const onSignupOpen = () => {
    setLoginOpen(false)
    setOpen(true);
  };

  const handleClickOpenSignUp = () => {
    setOpen(true);
  };

  const handleClickOpenLogin = async () => {
    setLoginOpen(true);
  };

  return (
    <>
      <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.closeIcon} onClick={onClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <CloseIcon fontSize="small" />
          </ListItemIcon>
        </ListItem>
        <ListItem className={classes.listItem}>
          <a
            variant="h6"
            color="primary"
            href="/"
            className={classes.listItemLink}
            onClick={onClose}
          >
            Home
          </a>
        </ListItem>
        <ListItem className={classes.listItem}>
          <a
            variant="h6"
            color="primary"
            href="/how-it-work"
            className={classes.listItemLink}
            onClick={onClose}
          >
            How it Works
          </a>
        </ListItem>
        <ListItem className={classes.listItem}>
          <a
            variant="h6"
            color="primary"
            href="/contact-us"
            className={classes.listItemLink}
            onClick={onClose}
          >
            Contact Us
          </a>
        </ListItem>
        <ListItem className={classes.listItem}>
          <a
            variant="h6"
            color="primary"
            href="/about-us"
            className={classes.listItemLink}
            onClick={onClose}
          >
            About Us
          </a>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            className={classes.buttonSideLogin}
            size="large"
            variant="contained"
            onClick={handleClickOpenLogin}
            color="primary"
            fullWidth
          >
            Login
          </Button>
          <Button
            className={classes.buttonSideLogin}
            size="large"
            variant="contained"
            onClick={handleClickOpenSignUp}
            color="primary"
            fullWidth
          >
            Sign Up
          </Button>
        </ListItem>
      </List>
      {/* signUp Form Dialoug  */}
      <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
      <LoginModel loginOpen={loginOpen} setLoginOpen={setLoginOpen} setOpenSignUp={onSignupOpen} />
    </>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default SidebarNav;
