import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import firebase from "firebase/app";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { Badge } from '@material-ui/core';

export default function PersonalCounter(props) {
  const { receiverId } = props
  const [unReadCounter, setUnReadCounter] = useState(0);
  // Base path of firebase
  const database = firebase.firestore();
  let db = database.collection("teammate").doc("chatRooms")

  useEffect(() => {
    getCounterFromFireBase(receiverId)
  }, [receiverId])

  //   .collection("CxmFUYvReaP2OSd9n05DTzoDfbq1")
  // .where("seen", "==", false)

  let getCounterFromFireBase = async (receiverId) => {
    var senderId = await AsyncLocalStorage.getItem("senderId")

    var messageRef = db.collection("messageBox").doc(receiverId).collection(senderId).where("seen", "==", false)
    messageRef.onSnapshot((querySnapshot) => {
      let temp = [];
      querySnapshot.forEach((mainDoc) => {
        temp.push(mainDoc.data())
      });

      setUnReadCounter(temp.length)
    });
  }
  // console.log(name, "name--")
  return <div>
    <Badge badgeContent={unReadCounter} color="primary"></Badge>
  </div>

}
