import React from "react";
import TermsOfUseDetail from "./component/TermsOfUseDetail";

const TermsOfUse = () => {
  return (
    <>
      <TermsOfUseDetail />
    </>
  );
};

export default TermsOfUse;
