import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Button,
  Box,
  TextField,
  Grid,
  Container,
  Snackbar,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import useRouter from 'utils/useRouter';
import validate from "validate.js";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// import dotsLogin from './images/matchUp/dotsLogin.png';
// import { dotsLogin } from '../../../public/images/matchUp/dotsLogin.png';
// import { dotsLogin } from './images/matchUp/dotsLogin';

import Api from '../../Helper/ApiHandler';

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {},
  signUpFormBg: {
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)',
    width: "100%",
    height: "550px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      // height: "250px",
      display: "none",
    },
  },
  dotImageTop: {
    position: "absolute",
    top: "10px",
    left: "190px",
  },
  dotImageBottom: {
    position: "absolute",
    bottom: "10px",
    left: "-70px",
  },
  headingSignUp: {
    margin: "0px 0px 0px 40px",
    color: "white",
    fontWeight: 600
  },
  hr: {
    margin: "15px 0px 15px 40px",
    width: "125px",
    height: "5px",
    background: "#FFFFFF",
    opacity: "0.5",
    borderRadius: "4px",
  },
  signINMessage: {
    color: "white",
    margin: "0px 40px",
    //fontFamily: "Gilroy !important",
  },
  matchUpLogin: {
    textAlign: "center",
    marginTop: "40px !important",
    fontWeight: 900
  },
  formHr: {
    width: "100%",
    background: "#FFFFFF",
    opacity: "0.1",
    border: "1px solid #000000",
  },
  gruopLinkButton: {
    textAlign: "center",
  },
  buttonSignIn: {
    width: "90%",
    margin: "10px auto",
    textTransform: "lowercase",
    fontSize: "15px",
    fontWeight: "bold",
  },
  textFiledBox: {
    width: "90%",
    margin: "10px auto",
  },
  forgotText: {
    width: "95%",
    color: "#FE4B6B",
    textAlign: "right",
  },
  startedButtonBox: {
    textAlign: "center",
    "& .MuiBox-root-144": {
      marginTop: "10px !important",
    }
  },
  startedButton: {
    fontSize: "20px",
    width: "246px",
    height: "50px",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    borderRadius: "10px",
    textTransform: "capitalize",
  },
  memberMessage: {
    textAlign: "center",
    marginTop: "25px !important",
    color: "#FE4B6B",
    fontWeight: 400
  },
  socialIcon: {
    position: "absolute",
    right: "0",
    top: "0",

  },
  hrLine: {
    // borderBottom: "2px solid #000000",
    opacity: "0.1",
    marginTop: "8px",
  },
  orMessage: {
    color: "#000",
    opacity: "0.4",
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      width: "730px",
      height: "500px",
      overflow: "hidden",
      // overflow: "hidden !important",
    },
  },
  asterisk: {
    color: 'red',
    '&$error': {
      color: 'red'
    }
  },
}));

const Forgotpassword = ({ setOpenSignUp, loginOpen, setLoginOpen }) => {
  const classes = useStyles();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false
  });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleClickOpenSignUp = () => {
    setLoginOpen(true);
  };

  const handleCloseSignUp = () => {
    setLoginOpen(false);
  };

  const closeHandlerModel = () => {
    setLoginOpen(false);
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleFieldChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formState.isValid === true) {
      setDisabledLogin(true)
      setLoading(true)

      api.post('user/forgotpwd', {
        data: formState.values,
        skipAuth: true
      })
        .then((result) => {
          if (result.success === "true") {
            setLoginOpen();
          } else {
            // setLoginOpen(false);
            setSessionMessage({
              message: result.message,
              type: 'error',
              open: true
            });
          }
          setDisabledLogin(false)
          setLoading(false)
        })
        .catch((err) => {
          setDisabledLogin(false)
          setLoading(false)
          setSessionMessage({
            message: err.message,
            type: 'error',
            open: true
          });
        });
    }
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  return (
    <>
      <Dialog
        maxWidth={"md"}
        open={loginOpen}
        onClose={handleCloseSignUp}
        aria-labelledby="max-width-dialog-title"
      >
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert
              onClose={handleErorrClose}
              severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Grid container className={classes.container}>
          <Grid item xl={5} md={5} lg={5} xs={12}>
            <div className={classes.signUpFormBg}>
              <h3 className={classes.headingSignUp}>
                Forgot Password
              </h3>
              <div className={classes.hr}></div>
              <img
                className={classes.dotImageTop}
                src="/images/matchUp/dotsLogin.png"
                // src={dotsLogin}
                alt={"Alt Image"}
              />
              <img
                className={classes.dotImageBottom}
                src="/images/matchUp/dotsLogin.png"
                // src={dotsLogin}
                alt={"Alt Image"}
              />
            </div>
          </Grid>
          <Grid item xl={7} md={7} lg={7} xs={12} style={{ padding: "0px 20px 0px 20px" }}>
            <form onSubmit={(event) => handleSubmit(event)}>
              <IconButton
                onClick={closeHandlerModel}
                className={classes.socialIcon}
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
              <div>
                <h4 className={classes.matchUpLogin}>
                  Forgot Password
                </h4>
                <hr className={classes.formHr} />
                <Container maxWidth="lg">
                  <Grid container>
                    <Grid xs={5}>
                      <div className={classes.hrLine} />
                    </Grid>
                  </Grid>
                </Container>
                {/* <form> */}
                <Box justifyContent={"center"} alignItems={"center"}>
                  <Box my={1} className={classes.textFiledBox}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      fullWidth
                      size="small"
                      variant="outlined"
                      onChange={handleFieldChange}
                      name="email"
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.asterisk
                        },
                        shrink: true,
                      }}
                      required
                      error={hasError('email')}
                      helperText={hasError('email') ? formState.errors.email[0] : null}
                    />
                  </Box>
                  <Box my={3} className={classes.startedButtonBox}>
                    <Button
                      className={classes.startedButton}
                      variant="contained"
                      disabled={formState.isValid ? disabledLogin : true}
                      size="large"
                      color="primary"
                      type="submit"
                    >
                      Reset password
                      {loading && <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></>}
                    </Button>
                  </Box>
                </Box>
                {/* </form> */}
                <h6 className={classes.memberMessage}>
                  <span style={{ cursor: "pointer", color: "#FE4B6B", objectFit: "cover" }} onClick={() => { setLoginOpen() }}> <u>Back to login?</u> </span>
                </h6>
              </div>
            </form>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Forgotpassword;
