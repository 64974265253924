/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Button,
  Box,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ModuleMates from "layouts/Main/components/LoginTopBar/ModuleMates";
import { Link, useHistory } from "react-router-dom";
import AsyncLocalStorage from '@createnextapp/async-local-storage'


import Api from '../../../../../../Helper/ApiHandler';
import useRouter from "utils/useRouter";
import AppDataContext from "Helper/AppDataContext";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  root: {
    '& .WithStyles\(ForwardRef\(MenuItem\)\)-root-102': {
      borderBottom: "0px !important",
    },
  },
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",

    textDecoration: "none !important",
  },
  listItemIcon: {
    minWidth: "auto",
  },
  listItemLink: {
    textDecoration: "none",
    //fontFamily: "Gilroy !important",
  },
  closeIcon: {
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  divider: {
    width: "100%",
  },
  buttonSideLogin: {
    margin: "10px 0",
    textTransform: "capitalize",
  },
  moduleBox: {
    display: "flex",
    alignItems: "center",
    color: "#1976d2",
    marginRight: "10px",
  },
  selectRoot: {
    marginLeft: "18px",
    fontWeight: "700",
    '& .MuiSelect-select:focus': {
      backgroundColor: "rgb(0 0 0 / 0%)",
    },
    '& .MuiSelect-select.MuiSelect-select': {
      color: "#FE4B6B",
      fontSize: "16px"
    },
    '& .MuiSelect-icon': {
      color: "#FE4B6B"
    },
    // [theme.breakpoints.down("xs")]: {
    //   display: "none"
    // }
  },
  selectItem: {
    fontSize: "14px"
  },
}));

const LoginSideNavbar = (props) => {
  const { pages, onClose, className, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();
  const router = useRouter();

  const [loginOpen, setLoginOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [module, setModule] = React.useState("");


  useEffect(() => {
    onLoad()
  }, []);

  const onLoad = async () => {
    let tmp = await AsyncLocalStorage.getItem("currentModule")
    setModule(tmp)
  }
  const siwtchModule = (e) => {
    handleCloseModule(e)
    // user/switchmodule
    api.put('user/switchmodule', {
      data: {
        module_name: e.target.value
      }
    })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("currentModule", e.target.value)
          AsyncLocalStorage.setItem("tabValue", 0)
          AsyncLocalStorage.setItem("tabFavValue", 0)
          setModule(result?.data?.module_name)
          setAppContext({ ...appContext, currentModule: result?.data?.module_name })
          console.log(e.target.value, "DormMates")
          if (e.target.value === "Dormmates") {
            // if (process.env.REACT_APP_DORMMATES == "true") {
            if (appContext.objAuth.DORMMATE) {
              if (!result.data.hasUniversity) {
                // router.history.push("/select-university");
                router.history.push({
                  pathname: `/select-university`,
                  search: '',
                  state: {
                    step3: true
                  }
                })
              }
              else if (!result.data.quiz) {
                router.history.push("/matching-quiz");
              } else {
                router.history.push("/your-profile");
              }
            }
            else {
              router.history.push("/comminsoon");
            }
          } else if (e.target.value === "Roommates") {
            if (appContext.objAuth.ROOMMATE) {
              if (!result.data.hasUniversity) {
                // router.history.push("/select-university");
                router.history.push({
                  pathname: `/select-university`,
                  search: '',
                  state: {
                    step3: true
                  }
                })
              }
              else if (!result.data.quiz) {
                router.history.push("/matching-quiz");
              } else {
                router.history.push("/your-profile");
              }
            }
            else {
              router.history.push("/comminsoon");
            }
          } else if (e.target.value === "Teammates") {
            if (appContext.objAuth.TEAMMATE) {
              router.history.push("/your-profile");
              // if (!result.data.quiz) {
              //   router.history.push("/matching-quiz");
              // } else {
              //   router.history.push("/your-profile");
              // }
            }
            else {
              router.history.push("/comminsoon");
            }
          } else if (e.target.value === "Soulmates") {
            if (appContext.objAuth.SOULMATE) {
              if (!result.data.quiz) {
                router.history.push("/matching-quiz");
              } else {
                router.history.push("/your-profile");
              }
            }
            else {
              router.history.push("/comminsoon");
            }
          }
          else {
            router.history.push("/comminsoon");
          }
        } else {

        }
      })
      .catch((err) => {
      });
  }
  const handleClickOpenSignUp = () => {
    setOpen(true);
  };

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
  };

  const handleClickModule = (event) => {
    setModule(event.currentTarget);
  };

  const handleCloseModule = () => {
    setModule(null);
  };

  const logOutHandler = () => {
    localStorage.clear();
    history.push("/");
    onClose();
  };

  return (
    <>
      <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.closeIcon} onClick={onClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <CloseIcon fontSize="small" />
          </ListItemIcon>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography
            variant="h6"
            color="primary"
            component={Link}
            className={classes.listItemLink}
            onClick={logOutHandler}
          >
            Log Out
          </Typography>
        </ListItem>
        {/* <ListItem className={classes.listItem}>
          <Typography
            variant="h6"
            color="primary"
            component={Link}
            to="/reset-password"
            className={classes.listItemLink}
            onClick={onClose}
          >
            Change Password
          </Typography>
        </ListItem> */}
        <div className={classes.selectRoot}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={module}
            disableUnderline
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
              transformOrigin: { vertical: "top", horizontal: "center" },
              PopoverClasses: classes.popoverClasses
            }}
            onChange={(e) => siwtchModule(e)}
          >
            {appContext.objAuth?.DORMMATE && <MenuItem value={"Dormmates"} className={classes.selectItem}>DormMates</MenuItem>}
            {appContext.objAuth?.TEAMMATE && <MenuItem value={"Teammates"} className={classes.selectItem}>TeamMates</MenuItem>}
            {appContext.objAuth?.ROOMMATE && <MenuItem value={"Roommates"} className={classes.selectItem}>RoomMates</MenuItem>}
            {appContext.objAuth?.SOULMATE && <MenuItem value={"Soulmates"} className={classes.selectItem}>SoulMates</MenuItem>}
          </Select>
        </div>
        <ListMenuSideBar onClose={onClose} sidebar="sidebar" />
      </List>

      {/* <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.closeIcon} onClick={onClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <CloseIcon fontSize="small" />
          </ListItemIcon>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography
            variant="h6"
            color="primary"
            component="a"
            href="/"
            className={classes.listItemLink}
          >
            Home
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography
            variant="h6"
            color="primary"
            component="a"
            href="/signup-simple"
            className={classes.listItemLink}
          >
            How it Works
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography
            variant="h6"
            color="primary"
            component="a"
            href="/not-found"
            className={classes.listItemLink}
          >
            Contact Us
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography
            variant="h6"
            color="primary"
            component="a"
            href="/not-found"
            className={classes.listItemLink}
          >
            About Us
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button className={classes.buttonSideLogin} size="large" variant="contained" onClick={handleClickOpenLogin} color="primary" fullWidth>
            Login
          </Button>
          <Button className={classes.buttonSideLogin} size="large" variant="contained" onClick={handleClickOpenSignUp} color="primary" fullWidth>
            Sign Up
          </Button>
        </ListItem>
      </List> */}
      {/* signUp Form Dialoug  */}
      <SignupModel open={open} setOpen={setOpen} />
      <LoginModel loginOpen={loginOpen} setLoginOpen={setLoginOpen} />
    </>
  );
};

LoginSideNavbar.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default LoginSideNavbar;
