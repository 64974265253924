import React from "react";
import SoulmateLandingDetails from "./component/SoulmateLandingDetails";

const SoulmateLanding = () => {
  return (
    <>
      <SoulmateLandingDetails />
    </>
  );
};

export default SoulmateLanding;
