import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Hidden,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import copy from "copy-to-clipboard";

import {
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TumblrShareButton,
  ViberShareButton,
  VKShareButton,
  WorkplaceShareButton,
} from "react-share";

import Api from "../../Helper/ApiHandler";

import { useHistory } from "react-router-dom";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 10px 10px 10px",
    "& .MuiButton-root": {
      textTransform: "capitalize",
    },
  },
  dialogRoot: {
    padding: "10px",
    "& .MuiDialog-paper": {
      // overflow: "visible"
      borderRadius: "10px",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgb(0 0 0 / 51%)",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
  },
  btnSave: {
    borderRadius: "5px",
    backgroundImage:
      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white",
  },
  btnCancel: {
    borderRadius: "5px",
  },
  flexWithPading: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 500,
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500,
  },
  btnicon: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    display: "flex",
    alignItem: "center",
    width: "100%",
    border: "1px solid #00000045",
    padding: "6px",
    borderRadius: "10px",
  },
}));
function ShareDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  const { objProperty, onClose, open, shareing, setSessionMessage } = props;
  const [copyId, setCopyId] = React.useState("");
  const [url, setUrl] = React.useState(
    window.location.host + history.location.pathname
  );

  const handleClose = () => {
    onClose(false);
  };

  // console.log(link, "url");

  const handleCopy = () => {
    copy(copyId);
    setSessionMessage({
      // message: `You have copied "${copyId}"`,
      message: "copied",
      // type: 'success',
      // open: true
    });
    setSessionMessage({
      message: "copied",
      type: "success",
      open: true,
      vertical: "top",
      horizontal: "right",
    });
  };

  useEffect(() => {
    let share = url;
    let link = (share += `/${shareing}`);
    setCopyId(link);
  }, [shareing]);

  return (
    <Dialog
      className={classes.dialogRoot}
      onClose={handleClose}
      maxWidth="sm"
      open={open}
    >
      <Box p={"10px"}>
        <Grid
          spacing={2}
          container
          justify="center"
          style={{ padding: "20px" }}
        >
          <Grid item xs={12}>
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "15px",
                cursor: "pointer",
              }}
            >
              <CloseIcon
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Share this place with friends and family
            </div>
          </Grid>
          <Grid item xs={12}>
            {objProperty && (
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  width: "100%",
                  padding: "6px",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <img
                    src={
                      objProperty &&
                      objProperty.property_photos &&
                      objProperty.property_photos[0]
                    }
                    alt={"Alt Image"}
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    alignSelf: "center",
                    marginLeft: "10px",
                    color: "#776e6e",
                  }}
                >
                  {objProperty.property_name}
                </div>
              </div>
            )}
            {shareing && (
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={copyId}
                      disabled
                      InputProps={{
                        style: {
                          height: 50,
                          borderRadius: 10,
                          color: "#000000",
                        },
                        endAdornment: (
                          <InputAdornment>
                            <FileCopyIcon
                              style={{ cursor: "pointer" }}
                              onClick={handleCopy}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <TwitterShareButton
              url={url}
              style={{ alignSelf: "center", width: "100%" }}
            >
              <Box className={classes.btnicon}>
                <TwitterIcon size={"40px"} round={true} />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    alignSelf: "center",
                    marginLeft: "10px",
                  }}
                >
                  <Hidden xsDown>Twitter</Hidden>
                </div>
              </Box>
            </TwitterShareButton>
          </Grid>
          <Grid item xs={6}>
            <WhatsappShareButton
              url={url}
              style={{ alignSelf: "center", width: "100%" }}
            >
              <Box className={classes.btnicon}>
                <WhatsappIcon size={"40px"} round={true} />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    alignSelf: "center",
                    marginLeft: "10px",
                  }}
                >
                  <Hidden xsDown>Whatsapp</Hidden>
                </div>
              </Box>
            </WhatsappShareButton>
          </Grid>
          <Grid item xs={6}>
            <FacebookShareButton
              url={url}
              style={{ alignSelf: "center", width: "100%" }}
            >
              <Box className={classes.btnicon}>
                <FacebookIcon size={"40px"} round={true} />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    alignSelf: "center",
                    marginLeft: "10px",
                  }}
                >
                  <Hidden xsDown>Facebook</Hidden>
                </div>
              </Box>
            </FacebookShareButton>
          </Grid>
          <Grid item xs={6}>
            <EmailShareButton
              url={url}
              style={{ alignSelf: "center", width: "100%" }}
            >
              <Box className={classes.btnicon}>
                <EmailIcon size={"40px"} round={true} />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    alignSelf: "center",
                    marginLeft: "10px",
                  }}
                >
                  <Hidden xsDown>Email</Hidden>
                </div>
              </Box>
            </EmailShareButton>
          </Grid>
          <Grid item xs={6}>
            <TelegramShareButton
              url={url}
              style={{ alignSelf: "center", width: "100%" }}
            >
              <Box className={classes.btnicon}>
                <TelegramIcon size={"40px"} round={true} />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    alignSelf: "center",
                    marginLeft: "10px",
                  }}
                >
                  <Hidden xsDown>Telegram</Hidden>
                </div>
              </Box>
            </TelegramShareButton>
          </Grid>
          <Grid item xs={6}>
            <LinkedinShareButton
              url={url}
              style={{ alignSelf: "center", width: "100%" }}
            >
              <Box className={classes.btnicon}>
                <LinkedinIcon size={"40px"} round={true} />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    alignSelf: "center",
                    marginLeft: "10px",
                  }}
                >
                  <Hidden xsDown>Linkedin</Hidden>
                </div>
              </Box>
            </LinkedinShareButton>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default ShareDialog;
