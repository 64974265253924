import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  howItsWorkRoot: {
    position: "relative",
    background: "#FFF7F8",
  },
  propertyImage: {
    width: "100%",
    filter: "brightness(0.5)",
    [theme.breakpoints.down("xs")]: {
      height: "105vh",
    },
  },
  propertyImageLabel: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
    width: "100%",
    height: "100%",
  },
  Findahome_Detail: {
    fontSize: "18px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#656464",
    fontWeight: 300,
    margin: "15px 0",
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  Findahome_Detaillist: {
    fontSize: "18px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#3F444F",
    fontWeight: 400,
    margin: "5px 0",
  },
  Findahome_text: {
    fontSize: "18px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#FE4B6B",
    fontWeight: 400,
    margin: "15px 0",
  },
  Findahome: {
    fontSize: "36px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#FE4B6B",
    fontWeight: 700,
    margin: "15px 0",
    // width: "60%",
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    // },
  },
  list: {
    marginRight: 5,
  },
  imageList: {
    display: "flex",
    alignItems: "center",
    // [theme.breakpoints.down("xs")]: {
    //   flexDirection: "column",
    // },
  },
  images: {
    padding: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // [theme.breakpoints.only("sm")]: {
    //   width: "150px",
    // },
  },
  imagesone: {
    padding: 10,
    width: "250px",
    height: "300px",
  },
  gridRoot: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 50px",
    },
  },
}));

const Teammates = (props) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} className={classes.howItsWorkRoot}>
            <img
              src="/images/matchUp/TeammateLandingBack.png"
              alt="TeammateLandingBack"
              className={classes.propertyImage}
            />
            <div className={classes.propertyImageLabel}>
              <Grid
                container
                justify="center"
                style={{ height: "100%" }}
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5}
                  className={classes.gridRoot}
                >
                  <h5 className={classes.Findahome_text}>Whats Teammates?</h5>
                  <h1 className={classes.Findahome}>
                    Why Join MatchUpMates to find Teammates?
                  </h1>
                  <h4 className={classes.Findahome_Detail}>
                    Teammates can help boost morale and make the work
                    environment better for everyone. MatchUpMates will help you
                    create a stronger bond with your teammates.
                  </h4>
                  <ul style={{ listStyle: "none" }}>
                    <li className={classes.Findahome_Detaillist}>
                      <img
                        className={classes.list}
                        src="/images/matchUp/ListStyle.svg"
                        alt="ListStyle"
                      />
                      Privacy
                    </li>
                    <li className={classes.Findahome_Detaillist}>
                      <img
                        className={classes.list}
                        src="/images/matchUp/ListStyle.svg"
                        alt="ListStyle"
                      />
                      Security
                    </li>
                    <li className={classes.Findahome_Detaillist}>
                      <img
                        className={classes.list}
                        src="/images/matchUp/ListStyle.svg"
                        alt="ListStyle"
                      />
                      Perfect Match
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  lg={4}
                  xl={4}
                  className={classes.imageList}
                >
                  <div>
                    <img
                      className={classes.images}
                      src="/images/matchUp/TeammatesGridImage1.jpg"
                      alt="TeammatesGridImage"
                    />
                  </div>
                  <div>
                    <img
                      className={classes.images}
                      src="/images/matchUp/TeammatesGridImage2.jpg"
                      alt="TeammatesGridImage"
                    />
                    <img
                      className={classes.images}
                      src="/images/matchUp/TeammatesGridImage3.jpg"
                      alt="TeammatesGridImage"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Teammates;
