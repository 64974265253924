import React from "react";
import NotificationSub from "./component/Notification/index";

const Notification = () => {
  return (
    <>
      <NotificationSub />
    </>
  );
};

export default Notification;
