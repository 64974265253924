import { Container, Grid } from "@material-ui/core";
import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider extends Component {
  render() {
    const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }) => {
      const { className, onClick } = props;

      return (
        <div {...props} className="custom-prevArrow" onClick={onClick} style={{ marginRight: "30px" }}>
          <img src="./images/matchUp/preArrow.svg" alt="next" />
        </div>
      );
    };
    const GalleryNextArrow = ({ currentSlide, slideCount, ...props }) => {
      const { className, onClick } = props;

      return (
        <div {...props} className="custom-nextArrow" onClick={onClick} style={{ marginLeft: "30px" }} >
          <img src="./images/matchUp/nextArrow.svg" alt="next" />
        </div>
      );
    };
    var settings = {
      dots: false,
      infinite: true,
      centerMode: true,
      className: "center",
      centerPadding: "0px",
      className: "center",
      speed: 200,
      focusOnSelect: true,
      centerPadding: "100px",
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      nextArrow: <GalleryNextArrow />,
      prevArrow: <GalleryPrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Container maxWidth="lg">
        <Grid
          direction="row"
          justifyContent="center"
          alignItems="center"
          container
        >
          <Grid item container xl={12} lg={12} xs={12}>
            <Slider style={{ width: "80%" }} {...settings}>
              <div>
                <div className="cardBox">
                  <p className="cardText">
                    It really saves me time and effort. Chamer is exactly what
                    our business has been lacking. Chamer was worth a fortune to
                    my company.
                  </p>
                  <div className="quoteImgBox">
                    <img
                      className="quoteImage"
                      src="/images/matchUp/quote.png"
                      alt={"Alt Image"}
                    />
                  </div>
                  <div className="bottomBox">
                    <div className="userImageBox">
                      <img src="/images/matchUp/user.png" alt={"Alt Image"} />
                    </div>
                    <h6 className="nameOnCard"> Alex Xender </h6>
                    <p className="cityNameCard"> New york </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="cardBox">
                  <p className="cardText">
                    {" "}
                    It really saves me time and effort. Chamer is exactly what
                    our business has been lacking. Chamer was worth a fortune to
                    my company.{" "}
                  </p>
                  <div className="quoteImgBox">
                    <img
                      className="quoteImage"
                      src="/images/matchUp/quote.png"
                      alt={"Alt Image"}
                    />
                  </div>
                  <div className="bottomBox">
                    <div className="userImageBox">
                      <img src="/images/matchUp/user.png" alt={"Alt Image"} />
                    </div>
                    <h6 className="nameOnCard"> Alex Xender </h6>
                    <p className="cityNameCard"> New york </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="cardBox">
                  <p className="cardText">
                    {" "}
                    It really saves me time and effort. Chamer is exactly what
                    our business has been lacking. Chamer was worth a fortune to
                    my company.{" "}
                  </p>
                  <div className="quoteImgBox">
                    <img
                      className="quoteImage"
                      src="/images/matchUp/quote.png"
                      alt={"Alt Image"}
                    />
                  </div>
                  <div className="bottomBox">
                    <div className="userImageBox">

                      <img src="/images/matchUp/user.png" alt={"Alt Image"} />
                    </div>
                    <h6 className="nameOnCard"> Alex Xender </h6>
                    <p className="cityNameCard"> New york </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="cardBox">
                  <p className="cardText">
                    {" "}
                    It really saves me time and effort. Chamer is exactly what
                    our business has been lacking. Chamer was worth a fortune to
                    my company.{" "}
                  </p>
                  <div className="quoteImgBox">
                    <img
                      className="quoteImage"
                      src="/images/matchUp/quote.png"
                      alt={"Alt Image"}
                    />
                  </div>
                  <div className="bottomBox">
                    <div className="userImageBox">

                      <img src="/images/matchUp/user.png" alt={"Alt Image"} />
                    </div>
                    <h6 className="nameOnCard"> Alex Xender </h6>
                    <p className="cityNameCard"> New york </p>
                  </div>
                </div>
              </div>
            </Slider>
          </Grid>
        </Grid>
      </Container>
    );
  }
}