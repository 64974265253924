import React from 'react'
import QuizQuestions from './component/QuizQuestions'

const QuizMatching = (props) => {
    const { sessionPropsMessage } = (props.location && props.location.state) || {};
    return (
        <>
            <QuizQuestions sessionPropsMessage={sessionPropsMessage} />
        </>
    )
}

export default QuizMatching
