import React from 'react'
import ViewTeamProfile from './component/ViewProfile'

const MatchesTeamView = () => {
    return (
        <>
        <ViewTeamProfile />   
        </>
    )
}

export default MatchesTeamView
