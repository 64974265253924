import React from "react";
import UnsubscribeDetail from "./component/AboutUsDetail";

const Unsubscribe = () => {
  return (
    <>
      <UnsubscribeDetail />
    </>
  );
};

export default Unsubscribe;
