import React from "react";
import GroupTeamList from "./components/GroupTeamList";

const MatchesTeam = (props) => {
  const { sessionPropsMessage } = (props.location && props.location.state) || {};
  return (
    <>
      <GroupTeamList sessionPropsMessage={sessionPropsMessage} />
    </>
  );
};

export default MatchesTeam;
