import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./Payment.css";
import MuiAlert from '@material-ui/lab/Alert';
import {
  Button,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Api from '../../../../Helper/ApiHandler';
var api = new Api()

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Payment(props) {
  const promise = loadStripe("pk_test_51KwJYHSIkcBh1eHUbFw5Saae8mgHC9DhTl8QOfNWuljtyhu9gUB4QQHMlLQif9LvfQLxEpKQJNLdL5nTN1z4nWyC00nnywgGg7");
  // REACT_APP_STRIP_PK=pk_test_51KwJYHSIkcBh1eHUbFw5Saae8mgHC9DhTl8QOfNWuljtyhu9gUB4QQHMlLQif9LvfQLxEpKQJNLdL5nTN1z4nWyC00nnywgGg7


  return (
    <div className="strape">
      <Elements stripe={promise}>
        <CheckoutForm paymentType={props.paymentType} paymentAmount={props.paymentAmount} handleClosePayment={props.handleClosePayment} />
      </Elements>

    </div>
  )
}
function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [succ, setSucc] = useState("none");
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: '',
    open: false
  });

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // window
    //   .fetch("/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({items: [{ id: "xl-tshirt" }]})
    //   })
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(data => {
    //   });
    api.post('user/subscrpayment', {
      data: {
        amount: props.paymentAmount,
        currency: "USD"
      }
    })
      .then((result) => {
        if (result.success === "true") {
          console.log(result, "result")
          console.log(result.data.paymentIntent.client_secret, "result.data.clientSecret")
          setClientSecret(result.data.paymentIntent.client_secret);
        } else {
          console.log(result, "result")
        }
      })
      .catch((err) => {
        console.log(err, "error catch")
      });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);


      // const onBuy = async (subscriptionType) => {
      api.post('user/createusersubscr', {
        data: {
          paymentIntentId: payload && payload.paymentIntent && payload.paymentIntent.id,
          subscription_type: props.paymentType,
          promocode: ""
        }
      })
        .then((result) => {
          if (result.success === "true") {
            console.log(result, "result")
            setSessionMessage({
              message: result.message,
              type: 'success',
              open: true
            });
            setSucc("succ")
            // props.handleClosePayment()
          } else {
            console.log(result, "result")
            setSessionMessage({
              message: result.message,
              type: 'error',
              open: true
            });
            setSucc("err")
          }
        })
        .catch((err) => {
          console.log(err, "error catch")
          setSessionMessage({
            message: err.message,
            type: 'error',
            open: true
          });
          setSucc("err")
        });
      // }
    }
  };
  const handleErorrClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }
  return (
    <form id="payment-form" className="form" onSubmit={handleSubmit}>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}
        >
          <Alert
            onClose={handleErorrClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}

      {succ === "none" && <div>
        <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            disabled={processing || disabled || succeeded}
            id="submit"
            className="MuiButton-containedPrimary"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#0071CE",
              width: "118px",
              height: "40px",
              fontSize: "16px",
              fontWeight: "400",
              borderRadius: "50px",
              marginTop: "20px",
              border: "none",
              cursor: "pointer"
            }}
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
        </div>
      </div>}

      {/* {/*  */}
      {succ === "err" &&
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <img src="/images/errorPayment.svg" alt="" />
          <div style={{ marginTop: "30px" }}>
            <Typography
              component="h3"
              variant="h3"
              // className={classes.step7Heading}
              style={{ marginLeft: "5px" }}
            >
              Payment Failed
            </Typography>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <Typography
              component="h6"
              variant="h6"
              // className={classes.step7Heading}
              style={{ marginLeft: "5px" }}
            >
              It seems we have not received money
            </Typography>

          </div>
          <Button
            variant="outlined"
            style={{ color: "red" }}
            onClick={() => props.handleClosePayment()}
          >
            Try Again
          </Button>
        </div>
      }

      {succ === "succ" &&
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <img src="/images/succsessPayment.svg" alt="" />
          <div style={{ marginTop: "30px" }}>
            <Typography
              component="h3"
              variant="h3"
              // className={classes.step7Heading}
              style={{ marginLeft: "5px" }}
            >
              Payment success
            </Typography>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <Typography
              component="h6"
              variant="h6"
              // className={classes.step7Heading}
              style={{ marginLeft: "5px" }}
            >
              It our pleasure to offer you our products
            </Typography>

          </div>
          <Button
            variant="outlined"
            style={{ color: "green" }}
            onClick={() => props.handleClosePayment()}
          >
            Success
          </Button>
        </div>
      }
      {/*       
      {
        error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )
      }
      
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a
          href={`https://dashboard.stripe.com/test/payments`}
        >
          {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p> */}
    </form >
  );
}