import React, { useState } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import useRouter from "utils/useRouter";
import AsyncLocalStorage from "@createnextapp/async-local-storage";

import Api from "../../Helper/ApiHandler";
var api = new Api();

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 10px 10px 10px",
    "& .MuiButton-root": {
      textTransform: "capitalize",
    },
  },
  dialogRoot: {
    padding: "10px",
    "& .MuiDialog-paper": {
      // overflow: "visible"
      borderRadius: "10px",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgb(0 0 0 / 52%)",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
  },
  btnSave: {
    borderRadius: "5px",
    backgroundImage:
      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white",
  },
  btnCancel: {
    borderRadius: "5px",
  },
  flexWithPading: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 500,
    textAlign:"center"
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500,
  },
}));
function RequestDialog(props) {
  const classes = useStyles();
  const router = useRouter();
  const {
    senderId,
    receiverId,
    setRequestStatus,
    setRequestId,
    setSessionMessage,
    onClose,
    selectedValue,
    open,
  } = props;
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleSendRequest = async () => {
    let to_id = await AsyncLocalStorage.getItem("to_id");

    let data = {
      to_id: to_id,
      senderId: senderId,
      receiverId: receiverId,
    };
    setLoading(true);
    setDisabled(true);
    api
      .post("user/sendrequest", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("request_id", senderId);
          AsyncLocalStorage.setItem("request_status", "send");
          setRequestStatus("send");
          setRequestId(senderId);
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          handleClose();
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setLoading(false);
        setDisabled(false);
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setLoading(false);
        setDisabled(false);
      });
  };

  return (
    <Dialog
      className={classes.dialogRoot}
      onClose={handleClose}
      maxWidth="xs"
      open={open}
    >
      <Grid container justify="center" style={{ padding: "20px" }}>
        <Grid item xs={12} className={classes.flex}>
          <img alt="Logo" src="/images/delete_model.svg" />
        </Grid>
        <Grid item xs={12} className={classes.flexWithPading}>
          <Typography component="h5" variant="h5" className={classes.title}>
            {/* Are you sure you want to send request? */}
            Are you sure you want to Send Invitation for DormMate?
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.flex}>
          <Typography
            component="h6"
            variant="subtitle2"
            className={classes.subTitle}
          >
            {/* You will not able to recover this Dormmate`! */}
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ padding: "0px 50px" }}
        >
          {/* <div className={classes.dialogButtonHeader}> */}
          <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
              className={classes.btnCancel}
            >
              No, Cancel
            </Button>
          </Grid>
          <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => {
                handleSendRequest();
              }}
              className={classes.btnSave}
              disabled={disabled}
            >
              Yes
              {loading && (
                <>
                  &nbsp;
                  <CircularProgress size={20} style={{ color: "white" }} />
                </>
              )}
            </Button>
          </Grid>
          {/* </div> */}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default RequestDialog;
