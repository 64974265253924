import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Grid, Box, Button, Zoom } from "@material-ui/core";
import PropTypes from "prop-types";
import useRouter from 'utils/useRouter';

const useStyles = makeStyles((theme) => ({
  root: {},
  headingContainer: {
    position: "absolute",
    fontSize: "30px",
    color: "white",
    fontWeight: 500,
    top: "0%",
    left: "0%",
    right: "0%",
    bottom: "0%",
    textAlign: "center",
    width: "100%",
    height: "99%",
    padding: "30px",
    background: "#00000091",
  },
  headingContainerHover: {
    position: "absolute",
    fontSize: "30px",
    color: "white",
    fontWeight: 500,
    top: "0%",
    left: "0%",
    right: "0%",
    bottom: "0%",
    textAlign: "center",
    width: "100%",
    height: "99%",
    padding: "30px",
    [theme.breakpoints.down('sm')]: {
      padding: "20px",
    },
    background: "#000000a1",
  },
  headingStep: {
    // border: "1px solid #ffffff",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    alignContent: "center",
    flexWrap: "wrap"
  },
  headingStepHover: {
    // border: "1px solid #ffffff",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    alignContent: "center",
    flexWrap: "wrap"
  },
  cardHeading: {
    fontSize: "28px"
  },
  cardBox: {
    background: "white",
    width: "370px",
    height: 500,
    margin: "0 25px",
    marginBottom: "55px",
    // boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)",
    textAlign: "center",
    position: "relative"
  },
  detailBox: {
    // background: "#22346E",
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)',
    width: '290px',
    height: '270px',
    color: "white",
    padding: "10px",
    position: "absolute",
    zIndex: "121212",
    left: "50%",
    top: "20px",
    transform: "translateX(-50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "30px"
  },
  imgBoxDetail: {
    background: "#E1F2FF",
    width: "370px",
    height: "366px",
    top: "200px",
    position: "absolute"
  },
  imgService: {
    width: "100%"
    // marginTop: "60px",
    // position: "relative"
  },
  whyUseDetail: {
    color: "#D3D3D3",
    fontWeight: 300,
    color: '#FFFFFF'
  },
  divRoot: {
    position: "relative",
  },
  regButton: {
    cursor: "pointer",
    color: "white",
    fontSize: "18px",
    borderRadius: "10px",
    border: "1px solid white",
    padding: "10px 54px",
    [theme.breakpoints.down('sm')]: {
      padding: "5px 40px",
    }
  },
  regHeading: {
    fontSize: "30px !important",
    fontWeight: "500",
    [theme.breakpoints.down('sm')]: {
      fontSize: "20px !important",
    }
  }
}));

const PropertyPart = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  let [over, setOver] = React.useState(false);

  const theme = useTheme();

  return (
    <>
      {/* <Grid container style={{ justifyContent: "center", width: "100%", }}> */}
      <Box pt={5}>
        <Grid
          item
          className={classes.divRoot}
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
        >
          <img
            className={classes.imgService}
            src="/images/matchUp/signupProperty.svg"
            alt="step Dormates icon"
            onClick={() => {
              // router.history.push(process.env.PROPERTY_RAGISTRATION)
            }}
          />
          {/* process.env.PROPERTY_RAGISTRATION */}
          <Box className={classes.headingContainer} display="flex" justifyContent="center" alignItems="center">
            <Zoom in={true} timeout={500}>
              <Box height={"100%"} width={"100%"} className={classes.headingStep}>
                <h1 className={classes.regHeading} style={{}}>Register Your Properties</h1>
                {/* {over && */}
                <div
                  className={classes.regButton}
                  style={{ display: "block" }}
                  onClick={() => {
                    // console.log(process.env.REACT_APP_PROPERTY_RAGISTRATION, "process.env.PROPERTY_RAGISTRATION")
                    window.location.replace(process.env.REACT_APP_PROPERTY_HOME);
                  }}
                >
                  Register
                </div>
                {/* } */}
              </Box>
            </Zoom>
          </Box>
        </Grid>
      </Box>
      {/* </Grid> */}
    </>
  );
};

export default PropertyPart;

PropertyPart.propTypes = {
  className: PropTypes.string,
};

