import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import FilterModel from "../FilterModel";
import { Link } from "react-router-dom";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../../../Helper/ApiHandler";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import Tooltip from "@material-ui/core/Tooltip";
import useRouter from "utils/useRouter";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import UserSkeletonCard from "components/Skeleton/UserSkeletonCard";
import { TooltipCustom } from "components/TooltipCustom/TooltipCustom";

var api = new Api();
const lstGender = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
  { id: "intersex", value: "Intersex" },
  { id: "trans", value: "Trans" },
  { id: "noPreference", value: "No Preference" },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // backgroundColor: "E9EDF0 !important",
    minHeight: "90vh",
    background: "#E9EDF0",
    padding: "35px 0",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("xs")]: {
      height: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "210px",
    },
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "500",
  },
  searchInput: {
    width: "290px",
    marginRight: "10px",
    background: "#E9EDF0",
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "14px",
    },
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    height: 32,
    textAlign: "center",
    marginTop: "35px",
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "#E9EDF0",
    boxShadow: "none",
    fontWeight: "500",
    minWidth: "100%",
  },
  filterIconBtn: {
    // marginLeft: "10px",
  },
  listBg: {
    background: "#E9EDF0",
  },
  matchesHead: {
    margin: "0",
    padding: "30px 0px",
    paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px",
  },
  cardUser: {
    background: "white",
    padding: "25px 10px",
    paddingBottom: "10px",
    textAlign: "center",
    margin: "7px",
    borderRadius: "15px",
    height: "auto",
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400",
  },
  textCityName: {
    marginTop: "5px",
    color: "#6C6C6C",
    fontSize: "14px",
    maxHeight: "40px",
    minHeight: "22px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chipUserDetail: {
    whiteSpace: "nowrap",
    width: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0",
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGridRoot: {
    // paddingBottom: "60px",
    // padding: "5px 0",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      padding: "5px",
    }
  },
  cardBoxGrid: {
    // paddingBottom: "60px",
    // padding: "5px 0",
    // padding: "10px",

  },
  imageMatches: {
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  filterSearchBtn: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: "300",
  },
  menuItemSelect: {
    //fontFamily: "Gilroy !important",
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    // '& .MuiOutlinedInput-notchedOutline': {
    //   borderWidth: 0
    // },
    "& div": {
      fontSize: "12px",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      fontSize: "12px",
    },
    backgroundColor: "#e9edf0",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  newFilterTop: {
    // display: "flex",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      alignItems: "normal",
      // flexDirection: "column",
    },
    alignItems: "center",
    padding: "10px 15px"
  },
  filterRoot: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  gender_filter_root: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    alignItems: "center",
    justifyContent: "space-between",
  },
  gender_root: {
    [theme.breakpoints.up("sm")]: {
      margin: "0px 16px",
    },
  },
  gridcontaint: {
    display: "contents",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  carosalImageBox: {
    height: "120px",
    width: "120px",
    margin: "auto",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      width: 80,
      height: 80,
    },
  },
}));

const MatchesList = (props) => {
  const { sessionPropsMessage, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [loadingScroll, setLoadingScroll] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const [matches, setMatches] = React.useState([]);
  const [search, setSearch] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(
    props.sessionPropsMessage
  );
  const [lstUniversity, setLstUniversity] = React.useState([]);
  const [items, setItems] = React.useState(Array.from({ length: 20 }));
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setrowsPerPage] = React.useState(12);
  const [totalRecored, setTotalRecored] = React.useState(0);

  const fetchMoreMatches = () => {
    if (matches.length >= totalRecored) {
      setHasMore(false);
      return;
    }

    // setTimeout(() => {
    // setItems(items.concat(Array.from({ length: 20 })))
    setPage(page + 1);
    // onMore()
    // }, 500);
  };

  useEffect(() => {
    if (page !== 0) onMore();
  }, [page]);

  const fetchMoreData = () => {
    if (items.length >= 500) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setItems(items.concat(Array.from({ length: 20 })));
    }, 500);
  };

  const handleClickOpenSignUp = () => {
    setFilterOpen(true);
  };

  // for filter change
  const onFilterChange = (value, key) => {
    if (key === "s") {
      setPage(0);
    }
    let ftr = { ...filter };
    ftr[key] = value;
    setFilter(ftr);
  };

  const setMaltiFilter = (obj) => {
    if (obj) {
      let ftr = { ...filter };
      Object.keys(obj).forEach(function (key) {
        ftr[key] = obj[key];
      });
      setFilter(ftr);
    } else {
      setFilter({});
    }
  };

  useEffect(() => {
    onLoad();
  }, [filter]);

  useEffect(() => {
    setPage(0);
    getUniversity();
    onCheckIsQuiz();
  }, []);

  const onCheckIsQuiz = async () => {
    const getcurrentModule = await AsyncLocalStorage.getItem("currentModule");
    api
      .get(`user/getquizstatus`)
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data.quiz, "success");
          if (getcurrentModule === "Dormmates") {
            if (!result.data.quiz) {
              router.history.push("/matching-quiz");
            }
          }
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "catch error");
      });
  };

  const onLoad = () => {
    setLoading(true);
    // Api Call For Fetch The Our Matches
    api
      .get(
        `user/yourmatch?page=${page}&size=${rowsPerPage}`,
        filter !== {} && { params: filter }
      )
      .then((result) => {
        if (result.success === "true") {
          setMatches(result.data.user);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
          setMatches([]);
        }
        setLoading(false);
        setInitialLoading(false)
      })
      .catch((err) => {
        setLoading(false);
        setInitialLoading(false)
        console.log(err, "catch error");
      });
  };

  const onMore = async () => {
    setLoadingScroll(true);
    // Api Call For Fetch The Our Matches

    await api
      .get(`user/yourmatch?page=${page}&size=${rowsPerPage}`, {
        params: filter,
      })
      .then((result) => {
        if (result.success === "true") {
          const lstTmp = matches.concat(result.data.user);
          setMatches(lstTmp);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
          setMatches([]);
          setTotalRecored(0);
        }
        setLoadingScroll(false);
      })
      .catch((err) => {
        setLoadingScroll(false);
        console.log(err, "catch error");
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleAutocompletChange = (event, value, name) => {
    setPage(0);
    if (name === "u") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        let ftr = { ...filter };
        ftr["u"] = value.value;
        setFilter(ftr);
      }
    } else if (name === "g") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        let ftr = { ...filter };
        ftr["g"] = value.value;
        setFilter(ftr);
      }
    }
  };
  // Get University
  const getUniversity = async (search) => {
    // Api Call For Fetch University List
    api
      .get(`user/refineuniversity?s=` + search)
      .then((result) => {
        if (result.success === "true") {
          setLstUniversity(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const changeOptionBaseOnValue = (e) => {
    getUniversity(e.target.value);
  };

  return (
    <>
      <div className={classes.mainRoot}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid item className={classes.container}>
              <Box className={classes.newFilterTop}>
                <Grid spacing={3} container>
                  <Grid
                    item
                    lg={7}
                    md={7}
                    sm={7}
                    xs={12}
                    style={{ padding: "0 8px" }}
                  >
                    <h5 className={classes.matchesHead}> Your Matches </h5>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={4}
                    xs={10}
                    style={{ padding: "0 8px" }}
                  >
                    <p className={classes.labelSearch}> University </p>
                    <Autocomplete
                      options={lstUniversity}
                      // value={getSelectedUniversityItem()}
                      onInputChange={(e) => {
                        changeOptionBaseOnValue(e);
                      }}
                      autoHighlight
                      getOptionLabel={(lstUniversity) => lstUniversity.value}
                      fullWidth
                      name="university"
                      noOptionsText={"Type for search..."}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {props.value}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select University"
                          // label="Select University"
                          autoFocus={false}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                          variant="outlined"
                          // name="university"
                          fullWidth
                          className={classes.selectOptionIn}
                        />
                      )}
                      // onChange={(e) => { onFilterChange(e.target.value, 'u') }}
                      onChange={(e, value) => {
                        handleAutocompletChange(e, value, "u");
                      }}
                    />
                  </Grid>
                  {/* <div className={classes.gridcontaint}> */}
                  <Grid
                    item
                    lg={1}
                    md={1}
                    sm={1}
                    xs={2}
                    style={{ padding: "0 8px" }}
                  >
                    <Button
                      variant="outlined"
                      color="default"
                      fullWidth
                      className={classes.buttonFilter}
                      onClick={handleClickOpenSignUp}
                    >
                      <img src="/images/matchUp/filter.png" alt={"Alt Image"} />
                    </Button>
                  </Grid>
                </Grid>
                {/* </div> */}
              </Box>
              <InfiniteScroll
                dataLength={matches.length}
                // next={fetchMoreData}
                style={{ overflow: "hidden" }}
                next={fetchMoreMatches}
                hasMore={true}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "95%",
                      margin: "10px",
                    }}
                  ></div>
                }
                endMessage={
                  <p style={{ paddingBottom: "60px" }}>{/* <p> */}</p>
                }
              >
                <Grid
                  justifyContent="center"
                  alignItems="center"
                  className={classes.cardBoxGridRoot}
                  container
                >
                  {/* > */}
                  {/* {items.map((i, index) => (
                        <div style={style} key={index}>
                          div - #{index}
                        </div>
                      ))} */}
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    className={classes.cardBoxGrid}
                    container
                  >
                    {!initialLoading && !loading ? (
                      <>
                        {matches.length ? (
                          matches.map((item, index) => (
                            <Grid
                              key={index}
                              item
                              xs={6}
                              sm={6}
                              xl={3}
                              lg={3}
                              className={classes.cardMatched}
                            >
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/your-matches/view/${item.slug}`}
                              >
                                <Tooltip
                                  title={`${capitalizeFirstLetter(
                                    item.first_name
                                  )} and you  match ${item.matchPercent !== "NaN"
                                    ? item.matchPercent
                                    : 0
                                    }%`}
                                  placement="top"
                                >
                                  <Box position="absolute" top={15} right={15}>
                                    <CircularProgress
                                      variant="static"
                                      value={100}
                                      size={35}
                                      thickness={4}
                                      className={classes.bottom}
                                    />
                                    <CircularProgress
                                      variant="static"
                                      initialAnimation={true}
                                      value={
                                        item.matchPercent !== "NaN"
                                          ? item.matchPercent
                                          : 0
                                      }
                                      size={35}
                                      thickness={4}
                                      className={classes.top}
                                      style={{ transform: "rotate(270deg)" }}
                                    />
                                    <Box
                                      top={0}
                                      left={0}
                                      bottom={8}
                                      right={0}
                                      position="absolute"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Typography
                                        variant="caption"
                                        component="div"
                                        color="textSecondary"
                                        style={{
                                          fontSize: "10px",
                                          paddingTop: "5px",
                                        }}
                                      >
                                        {item.matchPercent !== "NaN"
                                          ? item.matchPercent
                                          : 0}
                                        %
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Tooltip>

                                <div className={classes.cardUser}>
                                  {/* <TooltipCustom
                                    code={""}
                                    title={""}
                                    placement={"top"}
                                  /> */}

                                  {/* </TooltipCustom> */}
                                  <div className={classes.carosalImageBox}>
                                    <img
                                      src={
                                        item.profile_picture
                                          ? item.profile_picture
                                          : "/images/male_defualt.svg"
                                      }
                                      alt="profile_picture"
                                      className={classes.imageMatches}
                                    />
                                  </div>
                                  {/* <img
                                    src={
                                      item.profile_picture
                                        ? item.profile_picture
                                        : "/images/male_defualt.svg"
                                    }
                                    alt="User Image"
                                    className={classes.imageMatches}
                                  /> */}
                                  <Tooltip
                                    title={
                                      capitalizeFirstLetter(item.first_name) +
                                      "  " +
                                      capitalizeFirstLetter(item.last_name)
                                    }
                                    placement="top"
                                  >
                                    <h4 className={classes.nameTextCard}>
                                      {capitalizeFirstLetter(item.first_name)}
                                    </h4>
                                  </Tooltip>
                                  <Tooltip title={"Location"} placement="top">
                                    <p className={classes.textCityName}>
                                      {item.location}
                                    </p>
                                  </Tooltip>
                                  {item.gender && item.zodiac && (
                                    <div className={classes.hrLine} />
                                  )}
                                  {item.gender && (
                                    <>
                                      {!item.zodiac && (
                                        <div className={classes.hrLine} />
                                      )}
                                    </>
                                  )}
                                  {item.zodiac && (
                                    <>
                                      {!item.gender && (
                                        <div className={classes.hrLine} />
                                      )}
                                    </>
                                  )}
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {item.gender && (
                                      <Tooltip
                                        title={item.gender}
                                        placement="top"
                                      >
                                        <Chip
                                          className={classes.chipUserDetail}
                                          label={capitalizeFirstLetter(
                                            item.gender
                                          )}
                                        />
                                      </Tooltip>
                                    )}
                                    {item.zodiac && (
                                      <Tooltip
                                        title={item.zodiac}
                                        placement="top"
                                      >
                                        <Chip
                                          className={classes.chipUserDetail}
                                          label={capitalizeFirstLetter(
                                            item.zodiac
                                          )}
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                              </Link>
                            </Grid>
                          ))
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              marginTop: "50px"
                            }}
                          >
                            <img
                              src="/images/matchUp/notFoundMatches.svg"
                              alt="favAlt"
                            />
                            <Typography
                              variant="caption"
                              component="primary"
                              color="primary"
                              style={{
                                fontSize: "20px",
                                color: "black",
                                fontWeight: "600",
                              }}
                            >
                              No Matches Yet!
                            </Typography>
                            <Typography
                              variant="caption"
                              component="primary"
                              color="primary"
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                fontWeight: "400",
                              }}
                            >
                              Mark your favorite profile and always have them
                              here.
                            </Typography>
                          </div>
                        )}
                      </>
                    ) : (
                      <SkeletonLoading />
                    )}
                    {loadingScroll && <SkeletonLoading />}
                  </Grid>
                </Grid>
              </InfiniteScroll>
              {/* </div> */}
            </Grid>
          </Grid>
        </Container>
      </div>
      <FilterModel
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        setMaltiFilter={setMaltiFilter}
        setPage={setPage}
      />
    </>
  );
};
const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      className={classes.cardBoxGrid}
      container
    >
      {[0, 1, 2, 3, 4, 5, 6, 7].map((obj) => {
        return (
          <Grid item xs={6} sm={6} xl={3} lg={3}>
            <UserSkeletonCard />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default MatchesList;
