import React from 'react'
import ViewProfile from './component/ViewProfile'

const MatchesView = () => {
    return (
        <>
        <ViewProfile />   
        </>
    )
}

export default MatchesView
