import React from "react";
import AboutUsDetail from "./component/AboutUsDetail";

const AboutUs = () => {
  return (
    <>
      <AboutUsDetail />
    </>
  );
};

export default AboutUs;
