import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Container } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {},
  headingStep: {
    textAlign: "center",
    padding: "35px 0",
    margin: "0",
    fontSize: "28px !important",
    color: "#FE4B6B",
    fontWeight: 500,
  },
  cardHeading: {
    fontSize: "28px",
  },
  cardBox: {
    background: "white",
    display: "flex",
    flexDirection: "column",
    // width: "370px",
    height: 500,
    margin: "0 25px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
      marginRight: "0",
    },
    marginBottom: "55px",
    // boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)",
    textAlign: "center",
    position: "relative",
    transition: "transform .2s",
    // "&:hover": {
    //   [theme.breakpoints.up("xs")]: {
    //     transform: "scale(1.05)",
    //     margin: "0",
    //   },

    // }
  },
  detailBox: {
    // background: "#22346E",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    // width: '290px',
    width: "80%",
    height: "270px",
    justifySelf: "center",
    alignSelf: "center",
    // height: '100px',
    color: "white",
    padding: "10px",
    zIndex: "121212",
    // position: "absolute",
    // left: "50%",
    // top: "20px",
    // transform: "translateX(-50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  imgBoxDetail: {
    background: "#FFF2F4",
    width: "100%",
    height: "366px",
    bottom: "-20px",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
  },
  imgService: {
    alignSelf: "end",

    // position: "absolute",
    // bottom: "0",
    // right: "30%",
    // [theme.breakpoints.down("xs")]: {
    //   transform: "scale(0.6)",
    //   right: "30%",
    // },
    // transform: "translXateX(50%)"
  },
  whyUseDetail: {
    color: "#D3D3D3",
    fontWeight: 300,
    // color:'#FFFFFF'
  },
}));

const WhyUseParts = (props) => {
  const classes = useStyles();

  return (
    <Container>
      <h2 className={classes.headingStep}>Why use Match Up Mates ?</h2>
      {/* <Box px={3}> */}
      <Grid container style={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.cardBox}>
            <Box className={classes.detailBox}>
              <div className={classes.cardHeading}> A perfect match </div>
              <p className={classes.whyUseDetail}>
                Make genuine matches and meaningful connections to expand your
                social network.{" "}
              </p>
            </Box>
            <Box className={classes.imgBoxDetail}>
              <img
                className={classes.imgService}
                src="/images/matchUp/matchUp1.png"
                alt="step Dormates icon"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.cardBox}>
            <Box className={classes.detailBox}>
              <div className={classes.cardHeading}> Chat with your ideal connection </div>
              <p className={classes.whyUseDetail}>
                Start a free conversation to get to know your matches.{" "}
              </p>
            </Box>
            <Box className={classes.imgBoxDetail}>
              <img
                className={classes.imgService}
                src="/images/matchUp/matchUp22.png"
                alt="step Dormates icon"
                style={{ width: "80%" }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.cardBox}>
            <Box className={classes.detailBox}>
              <div className={classes.cardHeading}>A real connection </div>
              <p className={classes.whyUseDetail}>
                Match your personality, fit your lifestyle, and share your values.
                {/* Chat with your mates who truly match your personality, fit your
                lifestyle, and share your values.{" "} */}
              </p>
            </Box>
            <Box className={classes.imgBoxDetail}>
              <img
                className={classes.imgService}
                src="/images/matchUp/matchUp3.png"
                alt="step Dormates icon"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}
    </Container>
  );
};

export default WhyUseParts;

WhyUseParts.propTypes = {
  className: PropTypes.string,
};
