import React, { useEffect } from 'react'
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import { useHistory } from 'react-router-dom';
import useRouter from "utils/useRouter";


function AuthanticateRouter(isRedirect) {
  const router = useRouter()
  const history = useHistory();
  const [authToken, setAuthToken] = React.useState(localStorage.getItem('authToken'));
  const [isAuth, setIsAuth] = React.useState(localStorage.getItem(false));
  // ,
  useEffect(() => {

    console.log(history, "isRedirect")
    // if (isRedirect) {
    //   // if (!history.location.pathname.includes("property")) {
    //     if (!authToken) {
    //       router.history.push("/")
    //     } else {
    //       setIsAuth(true)
    //     }
    //   // }
    // } else {
    if (!authToken) {
      setIsAuth(false)
    } else {
      setIsAuth(true)
    }
    // }
  }, []);
  return isAuth
}

export default AuthanticateRouter;