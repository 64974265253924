import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  checkedon: {
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    padding: "15px",
    borderRadius: "10px",
    border: "1px solid green",
    background: "#FFF7F8",
    cursor: "pointer",
    width: "105%",
  },
  notchacked: {
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    padding: "15px",
    borderRadius: "10px",
    background: "#FFF7F8",
    cursor: "pointer",
    width: "105%",
  },
}));
const objImage = {
  ["I don't Smoke"]: "/images/matchUp/smokeNo.svg",
  ["I like Smoking"]: "/images/matchUp/smoke.svg",
  ["Occasionally"]: "/images/matchUp/smokeParsial.svg",
};
const RadioImgType = (props) => {
  const { ans, setAns, options } = props;
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    if (ans) setSelected(ans);
  }, [ans]);

  const handleChange = (value) => {
    let selected_value = [value];
    setSelected(selected_value);
    setAns(selected_value);
  };

  return (
    <div className={classes.root}>
      {/* <div style={{ display: "flex", margin: "30px 0px" }}> */}
      <Grid container spacing={2}>
        {options.map((value) => {
          return (
            <Grid item xs={12} sm={12} md={6} xl={4} lg={4}>
              <div
                className={
                  selected.includes(value)
                    ? classes.checkedon
                    : classes.notchacked
                }
                onClick={() => {
                  handleChange(value);
                }}
              >
                <img src={objImage[value]} alt="smoke" />
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    marginLeft: "10px",
                    wordBreak: "break-word",
                  }}
                >
                  {value}
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default RadioImgType;
