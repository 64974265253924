import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
  // Link,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import { Image } from "components/atoms";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import { Link } from "react-router-dom";
import Forgotpassword from "components/AuthModel/Forgotpassword";
// import { RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  toolbar: {
    // maxWidth: theme.layout.contentWidth,
    width: "80%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('xs')]: {
      height: "70px",
    },
  },
  listItem: {
    cursor: "pointer",
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center",
  },
  listItemText: {
    flex: "0 0 auto",
    whiteSpace: "nowrap",
    textDecoration: "none",
    //fontFamily: "Gilroy !important",
    color: "black",
    fontSize: "14px",
  },
  listItemButton: {
    whiteSpace: "nowrap",
  },
  iconButton: {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down('xs')]: {
      position: "absolute",
      right: "0",
      color: "black",
    },
  },
  logoContainer: {
    width: 100,
    height: "50px !important",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      height: "35 !important",
      textAlign: "center",
      // marginTop:"30px"
    },
  },
  logoImage: {
    width: "259.2px",
    height: "45px",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      // marginTop: "8px"
    },
    padding: "5px 0px"
  },
  buttnGroup: {
    width: "100%",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  signUpFormBg: {
    // background:
    //   "linear-gradient(54.75deg, #22346E 0%, #0071CE 100%, rgba(0, 113, 206, 0) 70.7%)",
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)',
    width: "100%",
    height: "550px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headingSignUp: {
    margin: "0!important",
    color: "white",
  },
  freeMessageUp: {
    textAlign: "center",
  },
  singUpMessage: {
    color: "#0071CE",
    textAlign: "center",
  },
  textFiledBox: {
    width: "90%",
    margin: "20px auto",
  },
  checkboxArea: {
    width: "50%",
    margin: "20px auto",
  },
  startedButtonBox: {
    textAlign: "center",
  },
  startedButton: {
    width: 300,
  },
  memberMessage: {
    textAlign: "center",
    marginTop: "30px",
  },
  socialIcon: {
    position: "absolute",
    right: "0",
  },
  topBarBtm: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    width: "120px",
  },
}));

const Topbar = (props) => {
  const { onSidebarOpen, pages, ...rest } = props;

  const classes = useStyles();
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onLoginOpen = () => {
    setLoginOpen(true)
    setForgotpasswordOpen(false)
    setOpen(false);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true)
  };

  const onSignupOpen = () => {
    setLoginOpen(false)
    setOpen(true);
  };

  const handleClickOpenSignUp = () => {
    setOpen(true);
  };

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
  };

  return (
    <>
      <Toolbar disableGutters className={classes.toolbar} {...rest}>

        <Grid justifyContent="center" alignItems="center" container spacing={3}>
          <Grid justifyContent="center" alignItems="center" item xl={4} lg={4} xs={6}>
            <div className={classes.logoContainer}>
              <a href="/" >
                <Image
                  className={classes.logoImage}
                  src="/images/matchUp/topBarlogo.svg"
                  alt="thefront"
                  lazy={false}
                />
              </a>

            </div>
          </Grid>
          <Grid item xl={4} lg={4} xs={6}>
            <div className={classes.flexGrow} />
            <Hidden smDown>
              <List className={classes.navigationContainer}>
                <li>
                  <a
                    color="textSecondary"
                    className={classes.listItemText}
                    // component="a"
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    color="textSecondary"
                    className={classes.listItemText}
                    href="/how-it-work"
                  >
                    How it Works
                  </a>
                </li>
                <li>
                  <a
                    color="textSecondary"
                    className={classes.listItemText}
                    href="/contact-us"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    color="textSecondary"
                    className={classes.listItemText}
                    href="/about-us"
                  >
                    About us
                  </a>
                </li>
              </List>
            </Hidden>
          </Grid>
          <Grid justifyContent="center" alignItems="center" item xl={4} lg={4} xs={6}>
            <Hidden smDown>
              <div className={classes.buttnGroup}>
                <Button
                  size="large"
                  // variant="contained"
                  color="primary"
                  component="a"
                  target="blank"
                  style={{ marginRight: "10px" }}
                  className={classes.topBarBtm}
                  onClick={handleClickOpenLogin}
                >
                  Log in
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  component="a"
                  target="blank"
                  className={classes.topBarBtm}
                  onClick={handleClickOpenSignUp}
                  style={{ background: 'linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)' }}
                >
                  Sign Up
                </Button>
              </div>
            </Hidden>
          </Grid>
          <Hidden mdUp>
            <IconButton
              className={classes.iconButton}
              onClick={onSidebarOpen}
              aria-label="Menu"
            >
              <MenuIcon style={{ fontSize: "30px" }} />
            </IconButton>
          </Hidden>
        </Grid>
      </Toolbar>

      {/* signUp Form Dialoug  */}
      <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
      <LoginModel loginOpen={loginOpen} setLoginOpen={setLoginOpen} setOpenSignUp={onSignupOpen} setForgotpasswordOpen={onForgotpasswordOpen} />
      <Forgotpassword loginOpen={forgotpasswordOpen} setLoginOpen={onLoginOpen} setOpenSignUp={onSignupOpen} />
    </>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
};

export default Topbar;
