import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Container, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
  },
  banefits1Root: {
    marginBottom: "70px",
  },
  matchupBest1Root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  matchupBest1: {
    // height:""
  },
  matchupBestHeading1: {
    fontSize: "18px",
    fontWeight: "600",
    marginTop: "30px",
    marginBottom: "14px",
  },
  matchupBestHeading2: {
    fontSize: "14px",
    fontWeight: "400",
    width: "267px",
    textAlign: "center",
  },
  banefits1RootTitle: {
    fontSize: "26px",
    fontWeight: "600",
    textAlign: "center",
    color: "#FE4B6B",
    margin: "30px 0px",
  },
  howItsWorkSubTitle: {
    background: "#FE4B6B",
    height: 2,
    margin: "40px auto",
    width: "15%",
  },
}));

const TeammateBest = (props) => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} lg={12} xl={12}>
            <div className={classes.howItsWorkSubTitle}></div>
            <div className={classes.banefits1RootTitle}>
              Why MatchUpMates is Best
            </div>
          </Grid>
          <Grid item xs={12} lg={12} xl={12} className={classes.banefits1Root}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                lg={4}
                xl={4}
                className={classes.matchupBest1Root}
              >
                <img
                  src="/images/matchUp/matchupBest1.svg"
                  alt="matchupBest1"
                  className={classes.matchupBest1}
                />
                <div className={classes.matchupBestHeading1}>Best Match</div>
                <div className={classes.matchupBestHeading2}>
                  Based on your location, we find best matches for you
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={4}
                xl={4}
                className={classes.matchupBest1Root}
              >
                <img
                  src="/images/matchUp/matchupBest2.svg"
                  alt="matchupBest1"
                  className={classes.matchupBest1}
                />
                <div className={classes.matchupBestHeading1}>Fully Secure</div>
                <div className={classes.matchupBestHeading2}>
                  Your account is safe on MatchUpMates We never share data with
                  third party
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={4}
                xl={4}
                className={classes.matchupBest1Root}
              >
                <img
                  src="/images/matchUp/matchupBest3.svg"
                  alt="matchupBest1"
                  className={classes.matchupBest1}
                />
                <div className={classes.matchupBestHeading1}>100% Privacy</div>
                <div className={classes.matchupBestHeading2}>
                  You have full control over your personal information that you
                  share.
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default TeammateBest;
