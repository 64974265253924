import React from "react";
import FaqDetail from "./component/PrivacyPolicyDetail";

const FaqRoommate = () => {
  return (
    <>
      <FaqDetail />
    </>
  );
};

export default FaqRoommate;
