import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TelegramIcon from "@material-ui/icons/Telegram";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { Link as browserLink, useLocation } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Checkbox from "@material-ui/core/Checkbox";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import ReactFancyBox from "react-fancybox";
import useRouter from "utils/useRouter";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BlockIcon from "@material-ui/icons/Block";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../Helper/ApiHandler";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Cookies from 'js-cookie';
import LoginModel from "components/AuthModel/LoginModel";
import SignupModel from "components/AuthModel/SignupModel";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    minHeight: "90vh",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  containerWA: {
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    // },
    // [theme.breakpoints.only("md")]: {
    //   width: "65%",
    // },
    // [theme.breakpoints.only("lg")]: {
    //   width: "72%",
    // },
    // [theme.breakpoints.only("xl")]: {
    //   width: "72%",
    // },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex",
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "10px 10px 10px 10px",
  },
  nextButton: {
    borderRadius: "5px",
    textTransform: "capitalize",
  },
  groupName: {
    fontSize: "24px",
    fontWeight: "600",
    textTransform: "capitalize",
  },
  groupDescription: {
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "capitalize"
  },
  formHr: {
    width: "100%",
    background: "#FFFFFF",
    opacity: "0.1",
    border: "1px solid #000000",
    margin: "10px 0px",
  },
  memberRoot: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  adminChip: {
    position: "absolute",
    fontSize: "10px",
    background:
      "linear-gradient(168.55deg, #E84A62 0%, #ED6E61 44.79%, #F4A360 100%)",
    color: "white",
    padding: "3px 10px",
    borderRadius: "5px",
    bottom: "-4px",
    margin: "4px",
  },
}));

const GroupDisplay = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const getAuth = localStorage.getItem("authToken");
  const signupDeatils = localStorage.getItem("signupDeatils");
  const isAuth = getAuth && signupDeatils == "true" ? true : false
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [groupData, setGroupData] = React.useState({});
  const [userName, setUserName] = React.useState("");
  const [firebase_uid, setFirebase_uid] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [lstInterests, setLstInterests] = React.useState([]);
  const [isFavourite, setIsFavourite] = React.useState(false);
  const [isBlock, setIsBlock] = React.useState(false);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const [userList, setUserList] = React.useState([]);
  const [userSlug, setUserSlug] = React.useState("");
  const [joinLoading, setJoinLoading] = useState(false);
  const [joinDisable, setJoinDisable] = useState(false);

  let query = useLocation();
  const slug = query.pathname.split("/")[2];
  // const matchPercent = query.pathname.split("/")[4]

  // Get Interests
  const getInterests = async () => {
    // Api Call For Fetch Interests List
    api
      .get(`interestlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstInterests(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  useEffect(() => {
    getInterests();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const userSlug = await AsyncLocalStorage.getItem("from_slug");
      setUserSlug(userSlug);
      console.log("call get by id");
      // Api Call For Fetch The Our Matches
      api
        .get(`user/teammate/viewgroup/${slug}`)
        .then((result) => {
          if (result.success === "true") {
            setGroupData(result.data);
            setUserName(result.data.first_name + " " + result.data.last_name);
            setFirebase_uid(result.data.firebase_uid);
            setIsLoading(false);
            setIsFavourite(
              result.data.userfavour ? result.data.userfavour : false
            );
            setIsBlock(result.data.is_blocked);

            let lstTmp = result.data.groupMembers;
            let lstAdmin = lstTmp.filter((obj) => obj.is_group_admin);
            let lstUser = lstTmp.filter((obj) => obj.slug == userSlug);
            let lstOtherUser = lstTmp.filter(
              (obj) => obj.slug != userSlug && obj.is_group_admin !== true
            );

            let lstFirst = [];

            console.log(lstAdmin, "==", lstUser, "lstFinal==");
            if (lstUser.length) {
              if (lstAdmin[0].slug == lstUser[0].slug) {
                lstFirst = lstAdmin;
              } else {
                console.log("lstFinal==");
                lstFirst = [...lstAdmin, ...lstUser];
              }
            } else {
              lstFirst = [...lstAdmin, ...lstUser];
            }
            let lstFinal = [...lstFirst, ...lstOtherUser];
            setUserList(lstFinal);
          } else {
            console.log(result, "error");
          }
        })
        .catch((err) => {
          console.log(err, "catch error");
        });
    })();
  }, [slug]);

  const onLoad = async () => {
    const userSlug = await AsyncLocalStorage.getItem("from_slug");
    setUserSlug(userSlug);
    console.log("call get by id");
    // Api Call For Fetch The Our Matches
    api
      .get(`user/teammate/viewgroup/${slug}`)
      .then((result) => {
        if (result.success === "true") {
          setGroupData(result.data);
          setUserName(result.data.first_name + " " + result.data.last_name);
          setFirebase_uid(result.data.firebase_uid);
          setIsFavourite(
            result.data.userfavour ? result.data.userfavour : false
          );
          setIsBlock(result.data.is_blocked);

          let lstTmp = result.data.groupMembers;
          let lstAdmin = lstTmp.filter((obj) => obj.is_group_admin);
          let lstUser = lstTmp.filter((obj) => obj.slug == userSlug);
          let lstOtherUser = lstTmp.filter(
            (obj) => obj.slug != userSlug && obj.is_group_admin !== true
          );

          let lstFirst = [];

          if (lstUser.length) {
            if (lstAdmin[0].slug == lstUser[0].slug) {
              lstFirst = lstAdmin;
            } else {
              lstFirst = [...lstAdmin, ...lstUser];
            }
          } else {
            lstFirst = [...lstAdmin, ...lstUser];
          }
          let lstFinal = [...lstFirst, ...lstOtherUser];
          setUserList(lstFinal);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const joinWL = () => {
    console.log(query, "query")
    Cookies.remove('roommateRedirectTo')
    Cookies.set('teammateRedirectTo', query.pathname, { expires: 365 });
    setLoginOpen(true)
  }

  const join = () => {
    setJoinLoading(true)
    setJoinDisable(true)
    api
      .get(`user/teammate/sendrequest/${groupData.slug}`)
      .then((result) => {
        if (result.success === "true") {
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          onLoad();
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setJoinLoading(false)
        setJoinDisable(false)
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setJoinLoading(false)
        setJoinDisable(false)
      });
  };


  const onSignupOpen = () => {
    setLoginOpen(false)
    setOpen(true);
  };

  const onLoginOpen = () => {
    setLoginOpen(true)
    setOpen(false);
  };

  const onMessageClick = (firebase_uid) => {
    // Api Call For Fetch The Our Matches
    api
      .get(`user/teammate/addmessage/${groupData.slug}`)
      .then((result) => {
        if (result.success === "true") {
          router.history.push(`/messages/${firebase_uid}`);

          AsyncLocalStorage.setItem("receiverName", groupData.group_subject);
          AsyncLocalStorage.setItem(
            "receiverProfilePicuture",
            groupData.group_profile_pic
          );
          AsyncLocalStorage.setItem("receiverId", firebase_uid);
          AsyncLocalStorage.setItem("to_slug", groupData.slug);
          AsyncLocalStorage.setItem("to_id", groupData.id);
          AsyncLocalStorage.setItem(
            "no_of_members",
            groupData.teammateGroupdata.length + 1
          );
          AsyncLocalStorage.setItem("chat_type", "group");
          AsyncLocalStorage.setItem(
            "group_members",
            JSON.stringify(groupData.teammateGroupdata)
          );
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  // console.log(groupData, "userData")
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            {isAuth && <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>}
            <Grid className={isAuth ? classes.container : classes.containerWA} item>
              {isLoading ? (
                <SkeletonLoading />
              ) : (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    xl={12}
                    className={classes.detailBox}
                  >
                    {isAuth &&
                      <Box
                        onClick={() => router.history.goBack()}
                        className={classes.backRoot}
                      >
                        <IconButton
                          aria-label="delete"
                          style={{ color: "black", marginRight: "5px" }}
                          size="small"
                        >
                          <ArrowBackIcon fontSize="inherit" />
                        </IconButton>
                        <h5 className={classes.headingPage}> Your Matches </h5>
                      </Box>}
                    <div style={{ padding: "20px 40px" }}>
                      <div>
                        <img
                          src={
                            groupData.group_profile_pic
                              ? groupData.group_profile_pic
                              : "/images/matchUp/roommateLandingPaart11.webp"
                          }
                          alt="imageback"
                          style={{
                            width: "100%",
                            height: "400px",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div className={classes.groupName}>
                            {groupData.group_subject}
                          </div>
                          <div className={classes.groupDescription}>
                            {groupData.group_type} Created by{" "}
                            {groupData.createdBy}, {groupData.createdAt}
                          </div>
                        </div>
                        <div>

                          {!isAuth && (
                            <Button
                              className={classes.nextButton}
                              variant="outlined"
                              color="primary"
                              disabled={joinDisable}
                              onClick={() => {
                                joinWL();
                              }}
                            >
                              Join
                              {joinLoading && <>&nbsp;<CircularProgress size={20} /></>}
                            </Button>
                          )}

                          {/* {console.log(groupData, "groupData")} */}
                          {groupData.teamRequest == "join" && (
                            <Button
                              className={classes.nextButton}
                              variant="outlined"
                              color="primary"
                              disabled={joinDisable}
                              onClick={() => {
                                join();
                              }}
                            >
                              Join
                              {joinLoading && <>&nbsp;<CircularProgress size={20} /></>}
                            </Button>
                          )}
                          {groupData.teamRequest == "sent" && (
                            <Button
                              className={classes.nextButton}
                              variant="outlined"
                              color="primary"
                              disabled
                            // onClick={() => { join() }}
                            >
                              Requested
                            </Button>
                          )}
                          {groupData.teamRequest == "confirm" && (
                            <Button
                              className={classes.nextButton}
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                onMessageClick(groupData.group_firebaseUid);
                              }}
                            >
                              Message
                            </Button>
                          )}
                          {groupData.teamRequest == "deleted" && (
                            <Button
                              className={classes.nextButton}
                              variant="outlined"
                              color="primary"
                              disabled
                            // onClick={() => { join() }}
                            >
                              Rejected by admin
                            </Button>
                          )}
                        </div>
                      </div>
                      <hr className={classes.formHr} />
                      <div className={classes.groupDescription}>
                        {groupData.group_description}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xl={12}
                    xs={12}
                    className={classes.memberRoot}
                  >
                    <h5
                      className={classes.headingPage}
                      style={{ padding: "0px 20px" }}
                    >
                      {" "}
                      Members : {userList.length} Members
                    </h5>

                    <hr className={classes.formHr} />

                    <Grid container spacing={2} style={{ padding: "10px 20px" }}>
                      {userList &&
                        userList.map((obj) => {
                          return (
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              md={2}
                              lg={1}
                              onClick={() => {
                                if (isAuth) {
                                  if (obj.slug != groupData.slug) {
                                    router.history.push(
                                      `/your-matches/view/${obj.slug}`
                                    );
                                  }
                                }
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={
                                    obj.profile_picture
                                      ? obj.profile_picture
                                      : "/images/male_defualt.svg"
                                  }
                                  alt="obj.photo"
                                  style={{
                                    borderRadius: "50%",
                                    width: "60px",
                                    height: "60px",
                                    objectFit: "cover",
                                  }}
                                />
                                {obj.is_group_admin && (
                                  <div className={classes.adminChip}>Admin</div>
                                )}
                              </div>
                              {obj.slug === groupData.slug ? (
                                <div
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  You
                                </div>
                              ) : (
                                <div
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                    overflow: "hidden",
                                    width: "100%",
                                    height: "25px"
                                  }}
                                >
                                  {obj.first_name} {obj.last_name}
                                </div>
                              )}


                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <LoginModel loginOpen={loginOpen} setLoginOpen={setLoginOpen} setOpenSignUp={onSignupOpen} />
      <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" height={500} style={{ margin: "5px" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" height={250} style={{ margin: "5px" }} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default GroupDisplay;
