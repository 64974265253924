import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff"
  },
  howItsWorkRoot: {
    backgroundImage: "linear-gradient(to bottom,#FFF7F8 50%,white 50%)",
    minHeight: "800px",
    position: "relative"
  },
  howItsWorkPrimaryTitle: {
    fontSize: "36px",
    color: "#FE4B6B",
    fontWeight: "600",
    textAlign: "center",
    paddingTop: "80px"
  },
  howItsWorkSubTitle: {
    fontSize: "20px",
    color: "#FE4B6B",
    fontWeight: "500",
    textAlign: "center",
    paddingTop: "20px"
  },

  // card
  howItsWorkCardRoot: {
    position: "absolute",
    top: "250px",
    display: "flex",
    justifyContent: "center"
  },
  cardPossition: {
    position: "relative",
    display: "flex",
    justifyContent: "center"
  },
  howItsWorkCard: {
    height: "400px",
    width: "300px",
    boxShadow: "4px 8px 22px rgba(189, 188, 188, 0.4)",
    borderRadius: "20px",
    background: "white",
    [theme.breakpoints.down('md')]: {
      marginTop: "10px"
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  howItsWorkCard2: {
    height: "400px",
    width: "300px",
    boxShadow: "4px 8px 22px rgba(189, 188, 188, 0.4)",
    borderRadius: "20px",
    background: "white",
    [theme.breakpoints.up('md')]: {
      marginTop: "80px"
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "10px"
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  cardCenter: {
    display: "flex",
    justifyContent: "center",
  },
  dormmateLandingCardImage: {

  },
  dormmateLandingCardText: {
    fontSize: "20px",
    fontWeight: "500",
    marginTop: "20px",
    textAlign: "center"
  }
}));

const HowItsWork = (props) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} className={classes.howItsWorkRoot}>
            <div className={classes.howItsWorkPrimaryTitle}>
              How MatchUpMates Works
            </div>
            <div className={classes.howItsWorkSubTitle}>
              Safe and easy! Find Dormmates near you
            </div>
            <Container maxWidth={"lg"}>
              <Grid item xl={12} lg={12} xs={12}>
                <Box display={"flex"} justifyContent={"space-around"} mt={5}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.cardCenter}>
                      <Box className={classes.howItsWorkCard}>
                        <img src="/images/matchUp/dormmateLandingCard3.svg" alt="dormmateLandingCard1" className={classes.dormmateLandingCardImage} />
                        <div className={classes.dormmateLandingCardText}>
                          Create Your Account
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.cardCenter}>
                      <Box className={classes.howItsWorkCard2}>
                        <img src="/images/matchUp/dormmateLandingCard2.svg" alt="dormmateLandingCard1" className={classes.dormmateLandingCardImage} />
                        <div className={classes.dormmateLandingCardText}>
                          Take the Dormmates matching quiz
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.cardCenter}>
                      <Box className={classes.howItsWorkCard}>
                        <img src="/images/matchUp/dormmateLandingCard1.svg" alt="dormmateLandingCard1" className={classes.dormmateLandingCardImage} />
                        <div className={classes.dormmateLandingCardText}>
                          Meet your matches
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default HowItsWork;
