import { Button, Container, Grid, Hidden, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import Inbox from './Inbox';
import Message from './Message';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import firebase from "firebase/app";
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    // height: "calc(100vh - 125px)",
    // marginBottom: "60px",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    [theme.breakpoints.only('md')]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only('lg')]: {
      width: "28%",
    },
    [theme.breakpoints.down('xl')]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.only('md')]: {
      width: "66.67%",
    },
    [theme.breakpoints.only('lg')]: {
      width: "72%",
    },
    [theme.breakpoints.only('xl')]: {
      width: "72%",
    },
  },
  startedButton: {
    // fontSize: "20px",
    // height: "50px",
    height: "23px",
    fontSize: "14px",
    width: "138px",
    // background: "#0071CE",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "400",
    marginTop: "10px"
  },
  notFoundRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    background: "white",
    height: "calc(100vh - 205px)",
    [theme.breakpoints.down('sm')]: {
      height: "calc(100vh - 140px)",
    },
  }
}));

const ChatroomTest = (props) => {
  // isFromOtherPage
  const router = useHistory();
  const { isFromOtherPage } = (props.location && props.location.state) || {};
  const classes = useStyles();
  let query = useLocation();
  const [senderId, setSenderId] = useState("")
  const [lstInbox, setLstInbox] = useState([]);
  // const [lstMessage, setLstMessage] = useState([]);
  const [lstTmpInbox, setLstTmpInbox] = useState([])
  const [userName, setUserName] = useState([])
  const [group_members, setgGroup_members] = useState([])
  const [nameMobileChat, setNameMobileChat] = useState(false);
  const [clearAt, setClearAt] = useState(null);
  const [exitAt, setExitAt] = useState(null);
  const [chatType, setChatType] = useState("");
  const [lstMessage, setLstMessage] = useState([]);
  const receiverId = query.pathname.split("/")[2]

  // Base path of firebase
  const database = firebase.firestore();
  let db = database.collection("teammate").doc("chatRooms")

  useEffect(() => {
    onload();
  }, [receiverId]);

  useEffect(() => {
    // getMessage()
    get_group_members()
  }, [receiverId]);

  let get_group_members = async () => {
    // Get group members
    var groupRef = db.collection("group").doc(receiverId);
    groupRef.get().then((doc) => {
      console.log("doc--==", doc)
      if (doc.exists) {
        setgGroup_members(doc.data().group_members)
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  // let inboxGet = async () => {
  //   if (lstTmpInbox.length) {
  //     const lstTmp = [];
  //     const unresolved = lstTmpInbox.map(async (mainobj) => {
  //       if (mainobj.chatType === "group") {
  //         var groupRef = db.collection("group").doc(mainobj.receiverId);
  //         await groupRef.get().then((groupDoc) => {
  //           if (groupDoc.exists) {
  //             lstTmp.push({ ...groupDoc.data(), messageCreatedAt: mainobj.createdAt, ...mainobj, createdAt: groupDoc.data().createdAt })
  //           }
  //         }).catch((error) => {
  //           console.log("Error getting document:", error);
  //         })
  //       }
  //       else {
  //         console.log("Personal chat")
  //       }
  //     })
  //     await Promise.all(unresolved)
  //     setLstInbox(lstTmp)
  //   }
  // }

  let onload = async () => {
    let chat_type = await AsyncLocalStorage.getItem("chat_type");
    setChatType(chat_type)

    let senderId = await AsyncLocalStorage.getItem("senderId");
    let userName = await AsyncLocalStorage.getItem("userName");

    setSenderId(senderId)
    setUserName(userName)

    // Get inbox data
    const inboxRef = db.collection("messageBox").doc(senderId).collection("inbox")
    inboxRef.orderBy("createdAt", "desc").onSnapshot((querySnapshot) => {
      let temp = [];
      querySnapshot.forEach((doc) => {
        temp.push(doc.data());
      });
      console.log(temp, "temp")
      // setLstTmpInbox(temp);
      setLstInbox(temp)
    });
  }

  // let getMessage = async () => {
  //   // Get message data
  //   const messageRef = db.collection("messageBox").doc(receiverId).collection("messages")
  //   messageRef.orderBy("createdAt", "asc").onSnapshot((querySnapshot) => {
  //     var prev_receiverId = Cookies.get('prev_receiverId');
  //     var curr_receiverId = Cookies.get('curr_receiverId');
  //     let temp = [];
  //     querySnapshot.forEach((doc) => {
  //       temp.push(doc.data());
  //     });
  //     if (prev_receiverId) {
  //       if (prev_receiverId && curr_receiverId == receiverId) {
  //         setLstMessage(temp)
  //       }
  //     } else {
  //       setLstMessage(temp)
  //     }


  //   });
  // }
  return (
    <div className={classes.bgProfilePage}>
      <Container maxWidth="lg">
        <Grid spacing={3} container>
          <Grid className={classes.responsiveHide} item>
            <ListMenuSideBar />
          </Grid>
          <Grid className={classes.container} item style={{ marginTop: "10px", paddingLeft: "29px" }}>
            {lstInbox.length ?
              <Grid container spacing={2}>
                <Hidden mdDown={nameMobileChat}>
                  <Grid item xs={12} md={4}>
                    <Inbox lstMessage={lstMessage} setLstMessage={setLstMessage} setExitAt={setExitAt} exitAt={exitAt} setClearAt={setClearAt} senderId={senderId} receiverId={receiverId} lstInbox={lstInbox} nameMobileChat={nameMobileChat} setNameMobileChat={setNameMobileChat} />
                  </Grid>
                </Hidden>
                <Hidden mdDown={!nameMobileChat}>
                  <Grid item xs={12} md={8}>
                    {receiverId &&
                      <Message
                        isFromOtherPage={isFromOtherPage}
                        lstMessage={lstMessage}
                        setLstMessage={setLstMessage}
                        chatType={chatType}
                        setExitAt={setExitAt}
                        exitAt={exitAt}
                        setClearAt={setClearAt}
                        clearAt={clearAt}
                        group_members={group_members}
                        receiverId={receiverId}
                        senderId={senderId}
                        userName={userName}
                        nameMobileChat={nameMobileChat}
                        setNameMobileChat={setNameMobileChat}
                      />
                    }
                  </Grid>
                </Hidden>
              </Grid>
              :
              <div
                className={classes.notFoundRoot}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img
                    src="/images/matchUp/notFoundMatches.svg"
                    alt="favAlt"
                  />
                  <Typography
                    variant="caption"
                    component="primary"
                    color="primary"
                    style={{
                      fontSize: "20px",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    No Conversation
                  </Typography>
                  <Typography
                    variant="caption"
                    component="primary"
                    color="primary"
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontWeight: "400",
                    }}
                  >
                    You didn't made any Conversation yet
                  </Typography>

                  <Button
                    className={classes.startedButton}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={(event) => {
                      router.push("/your-matches")
                    }}
                  >
                    Find matches
                  </Button>
                </div>
              </div>
            }
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ChatroomTest