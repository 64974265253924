import { Container, Grid, Hidden } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import GChat from './GpChat'
import GpInbox from './GpInbox'
import { makeStyles } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { useHistory, useLocation } from 'react-router-dom';
import useRouter from 'utils/useRouter';

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    height: "calc(100vh - 125px)",
    // marginBottom: "60px",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    [theme.breakpoints.only('md')]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only('lg')]: {
      width: "28%",
    },
    [theme.breakpoints.down('xl')]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.only('md')]: {
      width: "66.67%",
    },
    [theme.breakpoints.only('lg')]: {
      width: "72%",
    },
    [theme.breakpoints.only('xl')]: {
      width: "72%",
    },
  }
}));
export default function Index() {
  const classes = useStyles();
  const [lstTmpMessage, setLstTmpMessage] = useState([])
  const [clickInbox, setClickInbox] = useState(false)
  let query = useLocation();
  let history = useHistory();
  let router = useRouter();
  const receiverId = query.pathname.split("/")[2]
  console.log(query, "query")
  console.log(history, "history")
  console.log(router, "router")
  // useEffect(() => {
  //   console.log("lstTmpMessage3333",lstTmpMessage)

  // }, [lstTmpMessage])

  return (
    <div className={classes.bgProfilePage}>
      <Container maxWidth="lg">
        <Grid spacing={3} container>
          <Grid className={classes.responsiveHide} item>
            <ListMenuSideBar />
          </Grid>
          <Grid className={classes.container} item style={{ marginTop: "10px", paddingLeft: "20px" }}>
            <Grid container spacing={2}>
              <Hidden mdDown={router.match.path != "/messages"}> <Grid item xs={12} md={4}>

                <GpInbox setLstTmpMessage={setLstTmpMessage} clickInbox={clickInbox} setClickInbox={setClickInbox} receiverId={receiverId} />

              </Grid>
              </Hidden>
              <Hidden mdDown={router.match.path == "/messages"}>
                <Grid item xs={12} md={8}>

                  {receiverId && <GChat lstTmpMessage={lstTmpMessage} setLstTmpMessage={setLstTmpMessage} clickInbox={clickInbox} receiverId={receiverId} />}
                </Grid>
              </Hidden>
            </Grid>
          </Grid >
        </Grid >
      </Container>
    </div>
  )
}
