import React from 'react'
import ViewRoommateProfile from './component/ViewRoommateProfile'

const MatchesView = () => {
    return (
        <>
        <ViewRoommateProfile />   
        </>
    )
}

export default MatchesView
