import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import FilterModel from "../FilterModel";
import { Link } from "react-router-dom";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../../../Helper/ApiHandler";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles } from "@material-ui/styles";
import Matches from "views/MatchesRoommate/MatchesRoommate";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import useRouter from "utils/useRouter";
import ImageWithLoading from "components/ImageWithLoading/ImageWithLoading";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import UserSkeletonRoomCard from "components/Skeleton/UserSkeletonRoomCard";

var api = new Api();
const lstGender = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
  { id: "intersex", value: "Intersex" },
  { id: "trans", value: "Trans" },
  { id: "noPreference", value: "No Preference" },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #c4c4c4",
  },
  indicator: {
    backgroundColor: "#FE4B6B",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: "500px",
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#FE4B6B",
      opacity: 1,
    },
    "&$selected": {
      color: "#FE4B6B",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#FE4B6B",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // backgroundColor: "E9EDF0 !important",
    background: "#E9EDF0",
    // padding: "20px"
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("xs")]: {
      height: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "210px",
    },
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "500",
  },
  searchInput: {
    width: "290px",
    marginRight: "10px",
    background: "#E9EDF0",
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "14px",
    },
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    textAlign: "center",
    marginTop: "35px",
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "#E9EDF0",
    boxShadow: "none",
    fontWeight: "500",
  },
  filterIconBtn: {
    marginLeft: "10px",
  },
  listBg: {
    background: "#E9EDF0",
  },
  matchesHead: {
    margin: "0",
    padding: "20px 30px",
    paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px",
  },
  cardUser: {
    background: "white",
    padding: "25px 10px",
    paddingBottom: "10px",
    textAlign: "center",
    margin: "7px",
    borderRadius: "15px",
    height: "260px",
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400",
  },
  // textCityName: {
  //   marginTop: "5px",
  //   color: "#6C6C6C",
  //   fontSize: "14px",
  //   maxHeight: "40px",
  //   minHeight: "22px",
  //   width: "100%",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  //   whiteSpace: "nowrap"
  // },
  chipUserDetail: {
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0",
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGrid: {
    // paddingBottom: "60px",
    padding: "14px",
  },
  imageMatches: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  filterSearchBtn: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: "300",
  },
  menuItemSelect: {
    //fontFamily: "Gilroy !important",
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      fontSize: "12px",
    },
    backgroundColor: "#e9edf0",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "35% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    width: "100%",
  },
  heading: {
    fontSize: "18px !important",
    fontWeight: "600",
    textAlign: "center",
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: "0px auto !important",
  },
  headingAddress: {
    fontSize: "14px !important",
    fontWeight: "400",
    textAlign: "center",
    margin: "6px 10px",
    display: "-webkit-box",
    "-webkitLineClamp": 2,
    "-webkitBoxOrient": "vertical",
    overflow: "hidden",
  },
  chip: {
    border: "1px solid #E84A62",
    borderRadius: "25px",
    color: "#E84A62",
    width: "fit-content",
    fontSize: "12px",
    padding: "0px 5px",
  },
  likeRoot: {
    "& .MuiCheckbox-root": {
      backgroundColor: "#ffffff59",
      color: "#FE4B6B",
      width: "36px",
      height: "36px",
    },
  },
  carosalRoot: {
    "& .react-multiple-carousel__arrow--right": {
      minWidth: "25px",
      minHeight: "25px",
      top: "35%",
    },
    "& .react-multiple-carousel__arrow::before": {
      fontSize: "12px",
    },
    "& .react-multiple-carousel__arrow--left": {
      minWidth: "25px",
      minHeight: "25px",
      top: "35%",
    },
  },
  carosalImageBox: {
    height: "150px",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px 10px 0px 0px",
  },
}));

const Header = (props) => {
  const { sessionPropsMessage, className, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingScroll, setLoadingScroll] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const [matches, setMatches] = React.useState([]);
  const [search, setSearch] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(
    props.sessionPropsMessage
  );
  const [lstUniversity, setLstUniversity] = React.useState([]);
  const [items, setItems] = React.useState(Array.from({ length: 20 }));
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setrowsPerPage] = React.useState(12);
  const [totalRecored, setTotalRecored] = React.useState(0);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchMoreMatches = () => {
    if (matches.length >= totalRecored) {
      setHasMore(false);
      return;
    }

    // setTimeout(() => {
    // setItems(items.concat(Array.from({ length: 20 })))
    setPage(page + 1);
    // onMore()
    // }, 500);
  };

  useEffect(() => {
    if (page !== 0) onMore();
  }, [page]);

  const onFavourite = (slug, value) => {
    api
      .post(`user/likeproperty/${slug}`, {
        data: { isFavourite: !value },
      })
      .then((result) => {
        if (result.success === "true") {
          let lstTmp = [...matches];
          let lstNew = lstTmp.map((obj) => {
            if (obj.slug === slug) {
              return { ...obj, isFavourite: !obj.isFavourite };
            } else {
              return obj;
            }
          });
          setMatches(lstNew);
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          onClickLoad();
          // onLoad()
          // setIsFavourite(!value)
        } else {
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const fetchMoreData = () => {
    if (items.length >= 500) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setItems(items.concat(Array.from({ length: 20 })));
    }, 500);
  };

  const handleClickOpenSignUp = () => {
    setFilterOpen(true);
  };

  // for filter change
  const onFilterChange = (value, key) => {
    if (key === "s") {
      setPage(0);
    }
    let ftr = { ...filter };
    ftr[key] = value;
    setFilter(ftr);
  };

  const setMaltiFilter = (obj) => {
    let ftr = { ...filter };
    Object.keys(obj).forEach(function (key) {
      ftr[key] = obj[key];
    });
    setFilter(ftr);
  };

  useEffect(() => {
    onLoad();
  }, [filter]);

  useEffect(() => {
    setPage(0);
  }, []);

  const onLoad = () => {
    setLoading(true);
    // Api Call For Fetch The Our Matches
    api
      .get(`user/likepropertylist?page=${page}&size=${rowsPerPage}`, {
        params: filter,
      })
      .then((result) => {
        if (result.success === "true") {
          setMatches(result.data.user);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "catch error");
      });
  };

  const onClickLoad = () => {
    // Api Call For Fetch The Our Matches
    api
      .get(`user/likepropertylist?page=${page}&size=${rowsPerPage}`, {
        params: filter,
      })
      .then((result) => {
        if (result.success === "true") {
          setMatches(result.data.user);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const onMore = () => {
    setLoadingScroll(true);
    // Api Call For Fetch The Our Matches

    api
      .get(`user/likepropertylist?page=${page}&size=${rowsPerPage}`, {
        params: filter,
      })
      .then((result) => {
        if (result.success === "true") {
          const lstTmp = matches.concat(result.data.user);
          setMatches(lstTmp);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
        setLoadingScroll(false);
      })
      .catch((err) => {
        setLoadingScroll(false);
        console.log(err, "catch error");
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}
        >
          <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.mainRoot}>
        <InfiniteScroll
          dataLength={matches.length}
          // next={fetchMoreData}
          next={fetchMoreMatches}
          hasMore={true}
          loader={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "95%",
                margin: "10px",
              }}
            ></div>
          }
          endMessage={<p style={{ paddingBottom: "60px" }}></p>}
        >
          <Grid
            justifyContent="center"
            alignItems="center"
            // className={classes.cardBoxGrid}
            container
          >
            <Grid
              justifyContent="center"
              alignItems="center"
              className={classes.cardBoxGrid}
              container
            >
              {!loading ? (
                <>
                  <Grid container spacing={2} style={{ paddingLeft: "6px" }}>
                    {matches.length ? (
                      matches.map((objProperty) => {
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  overflow: "hidden",
                                }}
                              >
                                {/* <a target="_blank" href={`property/${objProperty.slug}`} style={{ textDecoration: "none" }}> */}
                                <div
                                  style={{
                                    width: "100%",
                                    height: "150px",
                                    overflow: "hidden",
                                  }}
                                  className={classes.carosalRoot}
                                >
                                  {/* <a target="_blank" href={`property/${objProperty.slug}`} style={{ textDecoration: "none" }}> */}
                                  {/* <ImageWithLoading
                                    url={objProperty.property_photos ? objProperty.property_photos[0] : "/images/matchUp/property1.png"}
                                    style={{ width: "100%", height: "200px", borderRadius: "10px", cursor: "pointer" }}
                                  /> */}
                                  <Carousel responsive={responsive}>
                                    {objProperty.property_photos &&
                                      objProperty.property_photos.map(
                                        (value) => {
                                          return (
                                            <a
                                              target="_blank"
                                              href={`property/${objProperty.slug}`}
                                              style={{ textDecoration: "none" }}
                                            >
                                              <div
                                                className={
                                                  classes.carosalImageBox
                                                }
                                                style={{
                                                  backgroundImage: `url(${value
                                                    ? value
                                                    : "/images/matchUp/property1.png"
                                                    })`,
                                                }}
                                              ></div>
                                              {/* <img
                                                src={
                                                  value
                                                    ? value
                                                    : "/images/matchUp/property1.png"
                                                }
                                                alt="property1"
                                                style={{
                                                  width: "100%",
                                                  height: "200px",
                                                  borderRadius: "10px",
                                                  cursor: "pointer",
                                                }}
                                                // onClick={() => { router.history.push(`property/${objProperty.slug}`) }}
                                              /> */}
                                            </a>
                                          );
                                        }
                                      )}
                                  </Carousel>
                                  {/* </a> */}
                                  {/* <img
                                    src={objProperty.property_photos ? objProperty.property_photos[0] : "/images/matchUp/property1.png"}
                                    alt="property1"
                                    style={{ width: "100%", height: "200px", borderRadius: "10px", cursor: "pointer" }}
                                    onClick={() => { router.history.push(`property/${objProperty.slug}`) }}
                                  /> */}
                                </div>
                                {/* </a> */}
                              </div>
                              <a
                                target="_blank"
                                href={`property/${objProperty.slug}`}
                                style={{ textDecoration: "none" }}
                              >
                                <div
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    backgroundColor: "white",
                                    width: "100%",
                                    height: "120px",
                                    alignSelf: "center",
                                    borderRadius: "0px 0px 10px 10px",
                                  }}
                                >
                                  <h6 className={classes.heading}>
                                    {objProperty.property_name}
                                  </h6>
                                  <p className={classes.headingAddress}>
                                    <img
                                      src="/images/matchUp/location.svg"
                                      alt="location"
                                      style={{
                                        height: "15px",
                                        width: "auto",
                                        marginRight: "5px",
                                      }}
                                    />
                                    {objProperty.address}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div className={classes.chip}>
                                      {objProperty.property_type}
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <div className={classes.likeRoot}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      icon={<FavoriteBorder />}
                                      checkedIcon={<Favorite />}
                                      name="checkedH"
                                      checked={objProperty.isFavourite}
                                    />
                                  }
                                  style={{
                                    position: "absolute",
                                    right: "0px",
                                    top: "10px",
                                  }}
                                  onClick={() => {
                                    onFavourite(
                                      objProperty.slug,
                                      objProperty.isFavourite
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          marginTop: "50px"
                        }}
                      >
                        <img
                          src="/images/matchUp/notFoundMatches.svg"
                          alt="favAlt"
                        />
                        <Typography
                          variant="caption"
                          component="primary"
                          color="primary"
                          style={{
                            fontSize: "20px",
                            color: "black",
                            fontWeight: "600",
                          }}
                        >
                          No Property Yet!
                        </Typography>
                        <Typography
                          variant="caption"
                          component="primary"
                          color="primary"
                          style={{
                            fontSize: "14px",
                            color: "gray",
                            fontWeight: "400",
                          }}
                        >
                          Search your Property and always have them here.
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                <SkeletonLoading />
              )}
              {loadingScroll && <SkeletonLoading />}
            </Grid>
          </Grid>
        </InfiniteScroll>
      </div>
      <FilterModel
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        setMaltiFilter={setMaltiFilter}
      />
    </>
  );
};
const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      // className={classes.cardBoxGrid}
      container
    >
      {[0, 1, 2, 3, 4, 5].map((obj) => {
        return (
          <Grid item xs={12} sm={6} md={4}>
            <UserSkeletonRoomCard />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default Header;
