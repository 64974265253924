import React from 'react'
import MyProfileDetail from './component/MyProfileDetail'

const ViewYourProfile = (props) => {
    const { sessionPropsMessage } = (props.location && props.location.state) || {};    
    return (
        <div>
            <MyProfileDetail sessionPropsMessage={sessionPropsMessage}/>
        </div>
    )
}

export default ViewYourProfile
