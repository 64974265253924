import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Api from "../../../../Helper/ApiHandler";
import { Skeleton } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import useRouter from "utils/useRouter";
import { useGeolocated } from "react-geolocated";
import ImageWithLoading from "components/ImageWithLoading/ImageWithLoading";
import MuiAlert from "@material-ui/lab/Alert";
import PropertyCard from "./PropertyCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import UserSkeletonRoomCard from "components/Skeleton/UserSkeletonRoomCard";

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#E9EDF0",
    [theme.breakpoints.down("xs")]: {
      padding: "0 8px",
    },
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("xs")]: {
      height: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "210px",
    },
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "500",
  },
  searchInput: {
    width: "290px",
    marginRight: "10px",
    background: "#E9EDF0",
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "14px",
    },
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    textAlign: "center",
    marginTop: "35px",
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "#E9EDF0",
    boxShadow: "none",
    fontWeight: "500",
  },
  filterIconBtn: {
    marginLeft: "10px",
  },
  listBg: {
    background: "#E9EDF0",
  },
  matchesHead: {
    margin: "0",
    padding: "20px 30px",
    paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px",
  },
  cardUser: {
    background: "white",
    padding: "25px 10px",
    paddingBottom: "10px",
    textAlign: "center",
    margin: "7px",
    borderRadius: "15px",
    height: "260px",
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400",
  },
  // textCityName: {
  //   marginTop: "5px",
  //   color: "#6C6C6C",
  //   fontSize: "14px",
  //   maxHeight: "40px",
  //   minHeight: "22px",
  //   width: "100%",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  //   whiteSpace: "nowrap"
  // },
  chipUserDetail: {
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0",
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGrid: {
    // paddingBottom: "60px",
    // padding: "10px",
    // marginLeft: 6,
    // paddingRight: 10,
    // paddingLeft: 10,
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      marginLeft: 0,
    },
  },
  imageMatches: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  filterSearchBtn: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: "300",
  },
  menuItemSelect: {
    //fontFamily: "Gilroy !important",
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      fontSize: "12px",
    },
    backgroundColor: "#e9edf0",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "35% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    width: "100%",
  },
  heading: {
    fontSize: "18px !important",
    fontWeight: "600",
    textAlign: "center",
    textTransform: "capitalize",
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: "0px auto !important",
  },
  headingAddress: {
    fontSize: "14px !important",
    fontWeight: "400",
    textAlign: "center",
    margin: "6px 10px",
    textTransform: "capitalize",
    display: "-webkit-box",
    "-webkitLineClamp": 2,
    "-webkitBoxOrient": "vertical",
    overflow: "hidden",
    // textOverflow: "ellipsis",
    // width: "100px"
  },
  chip: {
    border: "1px solid #E84A62",
    borderRadius: "25px",
    color: "#E84A62",
    width: "fit-content",
    fontSize: "12px",
    padding: "0px 5px",
  },
  likeRoot: {
    "& .MuiCheckbox-root": {
      backgroundColor: "#ffffff59",
      color: "#FE4B6B",
      width: "36px",
      height: "36px",
    },
  },
  propertyImage: {
    width: "100%",
    height: "200px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  carosalRoot: {
    "& .react-multiple-carousel__arrow--right": {
      minWidth: "25px",
      minHeight: "25px",
      top: "45%",
    },
    "& .react-multiple-carousel__arrow::before": {
      fontSize: "12px",
    },
    "& .react-multiple-carousel__arrow--left": {
      minWidth: "25px",
      minHeight: "25px",
      top: "45%",
    },
  },
  carosalImageBox: {
    height: "150px",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px 10px 0px 0px",
  },
  suggetionText: {
    fontSize: "12px",
    color: "black",
    fontWeight: "400",
    // marginLeft: "15px",
    paddingTop: "10px",
    color: "#E84A62",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
}));

const Header = (props) => {
  const { locationValue, objPropertyLtLg, filterProperty } = props;
  const classes = useStyles();
  const router = useRouter();

  const [loading, setLoading] = React.useState(false);
  const [loadingScroll, setLoadingScroll] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [matches, setMatches] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [rowsPerPage] = React.useState(9);
  const [totalRecored, setTotalRecored] = React.useState(0);
  const [lat, setLat] = React.useState(undefined);
  const [lng, setLng] = React.useState(undefined);
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  //   useGeolocated({
  //     positionOptions: {
  //       enableHighAccuracy: false,
  //     },
  //     // userDecisionTimeout: 5000,
  //   });

  useEffect(() => {
    // console.log(propertyLat, "propertyLat")
    // if (!propertyLat && !propertyLng) {
    // if (!objPropertyLtLg.lat) {
    //   if (coords) {
    //     setLat(coords?.latitude)
    //     setLng(coords?.longitude)
    //   }
    // } else {
    setLat(objPropertyLtLg.lat);
    setLng(objPropertyLtLg.lng);
    // }
  }, [objPropertyLtLg]);

  const fetchMoreMatches = () => {
    if (matches.length >= totalRecored) {
      setHasMore(false);
      return;
    }
    setPage(page + 1);
  };
  console.log(objPropertyLtLg, "objPropertyLtLg");
  useEffect(() => {
    // onMore()
    if (lat) {
      if (page + 1 < totalPage)
        (() => {
          setLoadingScroll(true);
          // Api Call For Fetch The Our Matches
          // latitude=coords.latitude&longitude=coords.longitude&
          let filterPropertyFinal = filterProperty.radius
            ? filterProperty
            : { ...filterProperty, radius: 25 };
          api
            .get(
              `user/propertylist?latitude=${lat}&longitude=${lng}&page=${page}&size=${rowsPerPage}`,
              { params: filterPropertyFinal }
            )
            .then((result) => {
              if (result.success === "true") {
                const lstTmp = matches.concat(result.data.user);
                setMatches(lstTmp);
                setTotalRecored(result.data.totalItems);
                setTotalPage(result.data.totalPages);
              } else {
                console.log(result, "error");
                setMatches([]);
                setTotalRecored(0);
                setTotalPage(0);
              }
              setLoadingScroll(false);
            })
            .catch((err) => {
              setLoadingScroll(false);
              console.log(err, "catch error");
            });
        })();
    }
  }, [page, filterProperty, rowsPerPage]);

  useEffect(() => {
    // onLoad()
    if (page === 0 && lat)
      // if (page === 0)
      (() => {
        // console.log(coords, "coords")
        setLoading(true);
        let filterPropertyFinal = filterProperty.radius
          ? filterProperty
          : { ...filterProperty, radius: 25 };
        // Api Call For Fetch The Our Matches
        api
          .get(
            `user/propertylist?latitude=${lat}&longitude=${lng}&page=${page}&size=${rowsPerPage}`,
            { params: filterPropertyFinal }
          )
          .then((result) => {
            if (result.success === "true") {
              setMatches(result.data.user);
              setTotalRecored(result.data.totalItems);
              setTotalPage(result.data.totalPages);
            } else {
              console.log(result, "error");
              setMatches([]);
              setTotalRecored(0);
              setTotalPage(0);
            }
            setLoading(false);
            setInitialLoading(false)
          })
          .catch((err) => {
            setLoading(false);
            setInitialLoading(false)
            console.log(err, "catch error");
          });
      })();
  }, [filterProperty, rowsPerPage, page, lat]);
  // coords, filterProperty, page, rowsPerPage
  // useEffect(() => {
  //   // onLoad()
  //   console.log(coords, filterProperty, page, rowsPerPage, "coords, filterProperty, page, rowsPerPage")
  //   if (coords) {
  //     if (page === 0)
  //       (() => {
  //         setLoading(true)
  //         let filterPropertyFinal = filterProperty.radius ? filterProperty : { ...filterProperty, radius: 25 }
  //         // Api Call For Fetch The Our Matches
  //         api.get(`user/propertylist?latitude=${coords?.latitude}&longitude=${coords?.longitude}&page=${page}&size=${rowsPerPage}`, { params: filterPropertyFinal })
  //           .then((result) => {
  //             if (result.success === "true") {
  //               setMatches(result.data.user)
  //               setTotalRecored(result.data.totalItems)
  //               setTotalPage(result.data.totalPages)
  //             } else {
  //               console.log(result, "error")
  //             }
  //             setLoading(false)
  //           })
  //           .catch((err) => {
  //             setLoading(false)
  //             console.log(err, "catch error")
  //           });
  //       })()
  //   }
  // }, [coords, filterProperty, page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, []);

  // const onLoad = () => {
  //   setLoading(true)
  //   // Api Call For Fetch The Our Matches
  //   api.get(`user/propertylist?page=${page}&size=${rowsPerPage}`, { params: filterProperty })
  //     .then((result) => {
  //       if (result.success === "true") {
  //         setMatches(result.data.user)
  //         console.log(result.data.user, "result.data.user")
  //         setTotalRecored(result.data.totalItems)
  //       } else {
  //         console.log(result, "error")
  //       }
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       setLoading(false)
  //       console.log(err, "catch error")
  //     });
  // };

  // const onMore = () => {
  //   setLoadingScroll(true)
  //   // Api Call For Fetch The Our Matches

  //   api.get(`user/propertylist?page=${page}&size=${rowsPerPage}`, { params: filterProperty })
  //     .then((result) => {
  //       if (result.success === "true") {
  //         // console.log(result, 'result.data')
  //         const lstTmp = matches.concat(result.data.user);
  //         setMatches(lstTmp)
  //         setTotalRecored(result.data.totalItems)
  //       } else {
  //         console.log(result, "error")
  //       }
  //       setLoadingScroll(false)
  //     })
  //     .catch((err) => {
  //       setLoadingScroll(false)
  //       console.log(err, "catch error")
  //     });
  // };

  const onFavourite = (slug, value) => {
    api
      .post(`user/likeproperty/${slug}`, {
        data: { isFavourite: !value },
      })
      .then((result) => {
        if (result.success === "true") {
          let lstTmp = [...matches];
          let lstNew = lstTmp.map((obj) => {
            setSessionMessage({
              message: result.message,
              type: "success",
              open: true,
            });
            if (obj.slug === slug) {
              return { ...obj, isFavourite: !obj.isFavourite };
            } else {
              return obj;
            }
          });
          setMatches(lstNew);
          // onLoad()
          // setIsFavourite(!value)
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
          console.log(result, "error");
        }
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        console.log(err, "error catch");
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className={classes.mainRoot}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        {console.log(locationValue, "locationValue")}
        {!locationValue ? (
          <Typography
            variant="caption"
            component="primary"
            color="primary"
            className={classes.suggetionText}
          >
            Suggested places are based on the your primary university selection.
          </Typography>
        ) : (
          ""
        )}
        <div></div>
        <InfiniteScroll
          dataLength={matches.length}
          // next={fetchMoreData}
          next={fetchMoreMatches}
          hasMore={true}
          style={{ overflow: "hidden" }}
          loader={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "95%",
                margin: "10px",
              }}
            ></div>
          }
          endMessage={<p style={{ paddingBottom: "60px" }}></p>}
        >
          <Grid
            justifyContent="center"
            alignItems="center"
            className={classes.cardBoxGrid}
            container
            style={{ marginBottom: "60px" }}
          >
            <Grid
              justifyContent="center"
              alignItems="center"
              className={classes.cardBoxGrid}
              container
            >
              {!initialLoading && !loading ? (
                <>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <PropertyCard />
                    </Grid> */}
                    {matches.length ? (
                      matches.map((objProperty) => {
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            {/* <a target="_blank" href={`property/${objProperty.slug}`} style={{ textDecoration: "none" }}> */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  overflow: "hidden",
                                }}
                                className={classes.carosalRoot}
                              >
                                {/* <ImageWithLoading
                                  url={objProperty.property_photos ? objProperty.property_photos[0] : "/images/matchUp/property1.png"}
                                  // style={{ width: "100%", height: "200px", borderRadius: "10px", cursor: "pointer" }}
                                  className={classes.propertyImage}
                                /> */}
                                <Carousel responsive={responsive}>
                                  {objProperty.property_photos &&
                                    objProperty.property_photos.map((value) => {
                                      return (
                                        <a
                                          target="_blank"
                                          href={`property/${objProperty.slug}`}
                                          style={{ textDecoration: "none" }}
                                        >
                                          <div
                                            className={classes.carosalImageBox}
                                            style={{
                                              backgroundImage: `url(${value
                                                ? value
                                                : "/images/matchUp/property1.png"
                                                })`,
                                            }}
                                          ></div>
                                        </a>
                                      );
                                    })}
                                </Carousel>
                                ;
                              </div>
                              <a
                                target="_blank"
                                href={`property/${objProperty.slug}`}
                                style={{ textDecoration: "none" }}
                              >
                                <div
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    backgroundColor: "white",
                                    width: "100%",
                                    height: "120px",
                                    alignSelf: "center",
                                    borderRadius: "0px 0px 10px 10px",
                                  }}
                                >
                                  <h6 className={classes.heading}>
                                    {objProperty.property_name}
                                  </h6>
                                  <p className={classes.headingAddress}>
                                    <img
                                      src="/images/matchUp/location.svg"
                                      alt="location"
                                      style={{
                                        height: "15px",
                                        width: "auto",
                                        marginRight: "5px",
                                      }}
                                    />
                                    {objProperty.address}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div className={classes.chip}>
                                      {objProperty.property_type}
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <div className={classes.likeRoot}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      icon={<FavoriteBorder />}
                                      checkedIcon={<Favorite />}
                                      name="checkedH"
                                      checked={objProperty.isFavourite}
                                    />
                                  }
                                  style={{
                                    position: "absolute",
                                    right: "0px",
                                    top: "10px",
                                  }}
                                  onClick={() => {
                                    onFavourite(
                                      objProperty.slug,
                                      objProperty.isFavourite
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            {/* </a> */}
                          </Grid>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          marginTop: "50px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          marginTop: "50px"
                        }}
                      >
                        <img
                          src="/images/matchUp/notFoundMatches.svg"
                          alt="favAlt"
                        />
                        <Typography
                          variant="caption"
                          component="primary"
                          color="primary"
                          style={{
                            fontSize: "20px",
                            color: "black",
                            fontWeight: "600",
                          }}
                        >
                          No Property Yet!
                        </Typography>
                        <Typography
                          variant="caption"
                          component="primary"
                          color="primary"
                          style={{
                            fontSize: "14px",
                            color: "gray",
                            fontWeight: "400",
                          }}
                        >
                          Search your Property and always have them here.
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                <SkeletonLoading />
              )}
              {loadingScroll && <SkeletonLoading />}
            </Grid>
          </Grid>
        </InfiniteScroll>
      </div>
    </>
  );
};
const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      className={classes.cardBoxGrid}
      container
    >
      {[0, 1, 2, 3, 4, 5, 6, 7].map((obj) => {
        return (
          <Grid item xs={12} sm={6} md={4}>
            <UserSkeletonRoomCard />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default Header;
