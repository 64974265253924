import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Step,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HowItsWork from "../HowItsWork";
import RoommateBanedits from "../RoommateBanedits";
import DormateBest from "../DormateBest";
import RoommateProperty from "../RoommateProperty";
import RoommateLandingQuestion from "../RoommateLandingQuestion";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
    // minHeight: "90vh"
  },
  propertyImageRoot: {
    height: "520px",
    backgroundColor: "rgb(232 238 241)",
  },
  propertyImage: {
    width: "100vw",
  },
  Findahome: {
    fontSize: "40px !important",
    color: "#3F444F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !important",
      width: "auto",
    },
    fontWeight: 600,
    margin: 0,
    width: "450px",
  },
  Findahome2: {
    fontSize: "20px !important",
    color: "##3F444F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      width: "auto",
    },
    fontWeight: 400,
    margin: 0,
    width: "450px",
  },
  propertyImageLabelRoot: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  propertyImageLabel: {
    paddingLeft: "20px",
    // position: "absolute",
    // top: "200px",
    // paddingLeft: "50px",
    // width: "100%",
    // left: "0px",
    // [theme.breakpoints.down("xs")]: {
    //   top: "20px",
    //   left: "20px"
    // },
    // left: "50%"
  },
  howItsWork: {
    fontSize: "40px !important",
    color: "#FE4B6B",
    fontWeight: 500,
    textAlign: "center",
    margin: 0,
  },
  howItsWorkStaperSub: {
    margin: "auto",
    textAlign: "center",
    fontSize: "14px",
    width: "275px",
  },
  roommateBaner: {
    position: "absolute",
    fontSize: "24px",
    color: "#FE4B6B",
    top: "42%",
    textAlign: "center",
    width: "100%",
  },
  aboutTitle: {
    fontSize: "25px !important",
    color: "#FE4B6B",
    fontWeight: 500,
    // margin: 0
  },
  aboutTitleSub: {
    fontSize: "35px !important",
    color: "#FE4B6B",
    fontWeight: 600,
    margin: 0,
    marginTop: "10px",
  },
  // aboutImage1: {
  //   width: "24px",
  // },
  // propertyImage1: {
  //   width: "400px",
  //   height: "500px"
  // },
  // propertyTitle: {
  //   fontSize: "35px !important",
  //   color: "#FE4B6B",
  //   fontWeight: 500,
  //   margin: 0,
  //   width: "500px",
  //   marginTop: "10px"
  // },
  // propertyTitle2: {
  //   fontSize: "24px !important",
  //   color: "#FE4B6B",
  //   fontWeight: 500,
  //   width: "500px",
  //   margin: 0,
  //   marginTop: "10px"
  // },
  // propertyTitle3: {
  //   fontSize: "14px !important",
  //   color: "#FE4B6B",
  //   fontWeight: 400,
  //   margin: 0,
  //   width: "500px",
  //   marginTop: "10px"
  // },
  // customerImage: {
  //   width: "60px",
  //   height: "60px",
  //   borderRadius: "50%",
  //   objectFit: "cover"
  // },
  // customerName: {
  //   fontSize: "16px",
  //   color: "#FE4B6B",
  //   fontWeight: 400,
  //   margin: 0,
  //   marginTop: "10px"
  // },
  // customerCity: {
  //   fontSize: "12px",
  //   color: "#FE4B6B",
  //   fontWeight: 400,
  //   margin: 0,
  // },
  // preBtn: {
  //   width: "80px",
  //   height: "30px",
  // },
  // nextBtn: {
  //   width: "80px",
  //   height: "30px",
  // }
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function getSteps() {
  return [
    {
      label: "Research Subursbs",
      subLabel:
        "Explore median unit and house prices, real estate market data, demography, and lifestyle information for suburbs.",
    },
    {
      label: "Instant Valuation",
      subLabel:
        "Our online valuation service gives you a free and instant estimate of your home's current value.",
    },
    {
      label: "Track Property",
      subLabel: "Find a suitable rental property or shared accommodation",
    },
  ];
}
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
  trustImage: {
    width: "100%",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SearchIcon />,
    2: <GroupAddIcon />,
    3: <HomeIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const PrivacyPolicyDetail = (props) => {
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(3);

  useEffect(() => {
    // onLoad()
  }, []);

  // const onLoad = () => {
  //   // setIsLoading(true)
  //   // Api Call For Fetch The Privacy Policy
  //   api.get(`user/cms/faq3sp`)
  //     .then((result) => {
  //       if (result.success === "true") {
  //         setFaq(result.data.details)
  //       } else {
  //         console.log(result, "error")
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "catch error")
  //     });
  // };

  return (
    <>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} style={{ position: "relative" }}>
            <div
              className={classes.propertyImageRoot}
              style={{
                backgroundImage:
                  "url(/images/matchUp/roommateLandingPaart11.webp)",
              }}
            >
              {/* <img src="/images/matchUp/roommateLandingPaart11.webp" alt="roommateLandingPaart1" className={classes.propertyImage} /> */}
              <Container
                maxWidth="lg"
                className={classes.propertyImageLabelRoot}
              >
                <div className={classes.propertyImageLabel}>
                  {/* <Grid item xl={12} lg={12} xs={12}> */}
                  <h1 className={classes.Findahome}>
                    {" "}
                    Find Like-Minded Roommates
                  </h1>
                  <div className={classes.Findahome2}>
                    Share your room with right roommates
                  </div>
                  {/* </Grid> */}
                </div>
              </Container>
            </div>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
              style={{ background: "#FFF7F8", padding: "50px" }}
            >
              <Container>
                <h1 className={classes.howItsWork}> How it works? </h1>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<ColorlibConnector />}
                  style={{ background: "#FFF7F8" }}
                >
                  {steps.map((obj) => (
                    <Step key={obj.label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {obj.label}
                      </StepLabel>
                      <div className={classes.howItsWorkStaperSub}>
                        {obj.subLabel}
                      </div>
                    </Step>
                  ))}
                </Stepper>
              </Container>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
              style={{
                background: "#FFF7F8",
                // padding: "50px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Container>
                <h1 className={classes.howItsWork}> How it works? </h1>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  style={{ background: "#FFF7F8", alignItems: "center" }}
                  orientation="vertical"
                >
                  {steps.map((obj) => (
                    <Step key={obj.label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {obj.label}
                      </StepLabel>
                      <div className={classes.howItsWorkStaperSub}>
                        {obj.subLabel}
                      </div>
                    </Step>
                  ))}
                </Stepper>
              </Container>
            </Grid>
          </Hidden>

          <Grid item xl={12} lg={12} xs={12}>
            <Container>
              <Grid
                item
                xl={12}
                lg={12}
                xs={12}
                style={{ background: "white" }}
              >
                <h1 className={classes.aboutTitle}> About Us </h1>
                <h1 className={classes.aboutTitleSub}>
                  The Benefit that will{" "}
                  <Hidden smDown>
                    <br />
                  </Hidden>
                  make you comfort
                </h1>
                <Grid container style={{ marginTop: 30 }}>
                  <Grid item xs={12} md={4} lg={4} style={{ display: "flex" }}>
                    <div style={{ marginRight: "20px" }}>
                      <img
                        src="/images/matchUp/aboutImage1.svg"
                        alt="propertyLanding"
                        className={classes.aboutImage1}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          marginBottom: "15px",
                        }}
                      >
                        Private Security
                      </div>
                      <div style={{ fontSize: "14px", width: "100%" }}>
                        Explore median unit and house prices, real estate market
                        data, demography, and lifestyle information for suburbs.
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} style={{ display: "flex" }}>
                    <div style={{ marginRight: "20px" }}>
                      <img
                        src="/images/matchUp/aboutImage2.svg"
                        alt="propertyLanding"
                        className={classes.aboutImage1}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          marginBottom: "15px",
                        }}
                      >
                        Parking Space
                      </div>
                      <div style={{ fontSize: "14px", width: "100%" }}>
                        Explore median unit and house prices, real estate market
                        data, demography, and lifestyle information for suburbs.
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} style={{ display: "flex" }}>
                    <div style={{ marginRight: "20px" }}>
                      <img
                        src="/images/matchUp/aboutImage3.svg"
                        alt="propertyLanding"
                        className={classes.aboutImage1}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          marginBottom: "15px",
                        }}
                      >
                        Clean Environment
                      </div>
                      <div style={{ fontSize: "14px", width: "100%" }}>
                        Explore median unit and house prices, real estate market
                        data, demography, and lifestyle information for suburbs.
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          <Grid
            item
            xl={12}
            lg={12}
            xs={12}
            style={{ position: "relative", width: "100%" }}
          >
            <img
              src="/images/matchUp/RoommateLandingBanner.jpg"
              alt="propertyLanding"
              style={{ width: "100%" }}
              className={classes.trustImage}
            />
            <div className={classes.roommateBaner}>
              The Roommate finder you can trust
            </div>
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <RoommateBanedits />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <RoommateProperty />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <RoommateLandingQuestion />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <DormateBest />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PrivacyPolicyDetail;
