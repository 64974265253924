// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import firebase from 'firebase/app';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import "firebase/messaging";
import AsyncLocalStorage from '@createnextapp/async-local-storage'

var firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
}

firebase.initializeApp(firebaseConfig);

let messaging = null;
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging()
}

export const requestForToken = () => {
    messaging?.getToken({ vapidKey: 'BONPpD8kzjNV8TbMUslluIz5E9i5ojcCEg81bsCqp2GaazfywQ1hErk2v0K-YsF88v3yDNdnIg3FAeNlpnB_gWY' }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log(currentToken, "currentToken")


            const storeData = async () => {
                try {
                    await AsyncLocalStorage.setItem('device_token', currentToken);
                    console.log("call await function")
                } catch (e) {
                    // error
                }
            }

            storeData();

        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {

        messaging?.onMessage((payload) => {
            console.log('Message received. ', payload);
            resolve(payload);
            // ...
        });


    });