import React from "react";
import Subscription from "./component/Subscription/index";

const SubscriptionPlan = () => {
  return (
    <>
      <Subscription />
    </>
  );
};

export default SubscriptionPlan;
