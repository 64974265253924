import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
  Container,
  Avatar,
  Box,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  moduleItem: {
    margin: "0",
    //fontFamily: "Gilroy !important",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    marginTop: "8px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    // borderBottom: `1px solid #dedede`,
  },
}))(MenuItem);


const ModuleMates = (props) => {

  const classes = useStyles();
  const { handleCloseModule, module, ...rest } = props;

  return (
    <>
      <StyledMenu
        id="customized-menu"
        anchorEl={module}
        keepMounted
        open={Boolean(module)}
        onClose={handleCloseModule}
      >
        <StyledMenuItem>
          <p className={classes.moduleItem}> DormMates </p>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <p className={classes.moduleItem}> TeamMates </p>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <p className={classes.moduleItem}> RoomMates </p>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <p className={classes.moduleItem}> SoulMates </p>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default ModuleMates;
