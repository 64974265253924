import React from 'react'
import BlockList from './component/BlockList';

const MyBlock = () => {
    return (
        <>
            <BlockList />
        </>
    )
}

export default MyBlock;
