import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import useRouter from "utils/useRouter";
import Api from "../../Helper/ApiHandler";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 10px 10px 10px",
    "& .MuiButton-root": {
      textTransform: "capitalize",
    },
  },
  dialogRoot: {
    padding: "10px",
    "& .MuiDialog-paper": {
      // overflow: "visible"
      borderRadius: "10px",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgb(0 0 0 / 52%)",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
  },
  btnSave: {
    borderRadius: "5px",
    backgroundImage:
      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white",
  },
  btnCancel: {
    padding: "5px 0",
    borderRadius: "5px",
  },
  flexWithPading: {
    display: "flex",
    justifyContent: "center",
    // padding: "10px",
  },
  flex: {
    height: 100,
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500,
  },
}));
function WrongNavigationDialog(props) {
  const classes = useStyles();
  const router = useRouter();
  const { userType, componentName, slug, onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleRetack = async () => {
    let device_token = await AsyncLocalStorage.getItem("device_token");
    let objData = { device_token: device_token ? device_token : "" };
    localStorage.clear();
    router.history.push("/");
    // {live-url}}api/user/logout
    api
      .post(`user/logout`, {
        data: objData,
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem(
            "device_token",
            device_token ? device_token : ""
          );
          handleClose();
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  return (
    <Dialog
      className={classes.dialogRoot}
      // onClose={handleClose}
      maxWidth="xs"
      open={open}
    >
      <Grid container justify="center" style={{ padding: "20px" }}>
        <Grid item xs={12} className={classes.flex}>
          <img alt="Logo" src="/images/delete_model.svg" />
        </Grid>
        <Grid item xs={12} className={classes.flexWithPading}>
          <Typography component="h5" variant="h5" className={classes.title}>
            Wrong navigation
          </Typography>
        </Grid>
        {/* <Grid item xs={12} className={classes.flex}>
          <Typography
            component="h6"
            variant="subtitle2"
            className={classes.subTitle}
          >
          </Typography>
        </Grid> */}

        <Grid
          container
          spacing={2}
          item
          xs={12}
          className={classes.dialogButtonHeader}
        >
          {/* <div className={classes.dialogButtonHeader}> */}
          {/* <Grid item sm={5} xs={12}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
              className={classes.btnCancel}
            >
              No, Cancel
            </Button>
            &nbsp;&nbsp;
          </Grid> */}
          <Grid item sm={5} xs={12}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => {
                handleRetack();
              }}
              className={classes.btnSave}
            >
              Logout
            </Button>
          </Grid>
          {/* </div> */}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default WrongNavigationDialog;
