import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Container, Grid, Link, Typography } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useRouter from 'utils/useRouter';
import { Helmet } from "react-helmet";
import Api from '../../../../Helper/ApiHandler';
import { useContext } from "react";
import AppDataContext from "Helper/AppDataContext";

var api = new Api();

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
    // minHeight: "90vh"
  },
  aboutStep: {
    textAlign: "center",
    margin: "0",
    paddingTop: "25px",
    color: "white"
    //fontFamily: "Gilroy !important",
  },
  contantAbout: {
    fontSize: "16px",
    color: "gray",
    textAlign: "justify"
  },
  contantBoxAbout: {
    marginTop: "50px",
    // height:"380px",
    [theme.breakpoints.down('xs')]: {
      height: "auto",
    },
  },
  topPart: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    height: "400px",
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  topPartImage1: {
    position: "absolute",
    height: "330px",
    width: '511px',
    bottom: "-45px",
    left: "310px"
  },
  topPartImage2: {
    position: "absolute",
    height: "300px",
    width: '173px',
    bottom: "10px",
    right: "150px"
  },
  cardBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    cursor: "pointer",
    // width: "319px",
    // height: "380px",
    // marginBottom: "55px",
    boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.1)',
    textAlign: "center",
    paddingBottom: "20px",
    // transition: "transform .2s",
    // "&:hover": {
    //   transform: "scale(1.05)"
    // }
  },
  imgService: {
    // width: "100px",
    minHeight: "125px",
  },
  textCard: {
    padding: "10px 20px",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: 300
  },
  learMoreButton: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    width: "141px",
    height: "45px",
    background: 'linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)',
    fontWeight: 400
  },
  cardRoot: {
    display: "flex",
    justifyContent: "center",
    // backgroundColor: '#f6f6f6'
  },
  cardHeader: {
    fontSize: "24px",
    fontWeight: 500
  }
}));

const PrivacyPolicyDetail = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const [faq, setFaq] = React.useState('');
  const [meta, setMeta] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [lstModule, setLstModule] = React.useState([])

  const { appContext, setAppContext } = useContext(AppDataContext);

  useEffect(() => {
    moduleCheck()
    onLoad()
    onLoadMeta()
  }, []);


  const moduleCheck = () => {
    if (appContext.objAuth?.LIVE_MODULE_COUNT > 1) {
      setLoading(true)
      api.get(`faqmoduledata`)
        .then((result) => {
          if (result.success === "true") {
            setLstModule(result.data)
          } else {
            console.log(result, "error")
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log(err, "catch error")
          setLoading(false)
        });
    } else {
      if (appContext.objAuth?.DORMMATE) {
        router.history.push("/faq#dormmate")
      } else if (appContext.objAuth?.ROOMMATE) {
        router.history.push("/faq#roommate")
      } else if (appContext.objAuth?.SOULMATE) {
        router.history.push("/faq#soulmate")
      } else if (appContext.objAuth?.TEAMMATE) {
        router.history.push("/faq#teammate")
      }
    }
  }

  const onLoad = () => {
    // setIsLoading(true)
    // Api Call For Fetch The Privacy Policy
    api.get(`user/cms/faq3sp`)
      .then((result) => {
        if (result.success === "true") {
          setFaq(result.data.details)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  };

  const onLoadMeta = () => {
    // For meta
    api.post('staticmetadata', {
      data: { type: "faq" }
    })
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data, "result")
          setMeta(result.data, 'result.data')
        } else {
          console.log(result, "success === false")
        }
      })
      .catch((err) => {
        console.log(err, "error catch")
      });
  }
  return (
    <>
      <Helmet>
        <title>Faq</title>
        {/* <meta name="description" content={meta.meta_description} />
        <meta name="keywords" content={meta.meta_keyword} />

        <meta itemprop="name" content={meta.page_name} />
        <meta itemprop="description" content={meta.meta_description} />
        <meta itemprop="image" content={meta.meta_image} />

        <meta property="og:url" content="https://matchupmates.com" />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={meta.meta_title} />
        <meta property="og:description" content={meta.meta_description} />
        <meta property="og:image" content={meta.meta_image} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={meta.meta_title} />
        <meta name="twitter:description" content={meta.meta_description} />
        <meta name="twitter:image" content={meta.meta_image} /> */}

      </Helmet>
      <div className={classes.mainRoot}>
        <div className={classes.topPart}>
          {/* <img src="/images/matchUp/faq.png" alt="" className={classes.topPartImage1} />
          <img src="/images/matchUp/faq2.png" alt="" className={classes.topPartImage2} /> */}
          <h1 className={classes.aboutStep}> Faq </h1>
        </div>
        <Container>
          <div style={{ padding: "60px 0px" }}>
            <Box className={classes.cardRoot} px={2}>
              <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>

                {lstModule.map((obj) => {
                  return (
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <div onClick={() => { router.history.push(obj.link) }}>
                        <Box pt={3} className={classes.cardBox}>
                          <img
                            className={classes.imgService}
                            src={obj.picture ? obj.picture : "/images/matchUp/service2.png"}
                            alt="step Dormates icon"
                          />
                          <div className={classes.cardHeader}> {obj.name} </div>
                        </Box>
                      </div>
                    </Grid>
                  )
                })}
                {loading &&
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={30} />
                  </div>
                }

              </Grid>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicyDetail;
