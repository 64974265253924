import React from 'react'
import FormResetPassword from './component/FormResetPassword'

const ResetPassword = () => {
    return (
        <>
            <FormResetPassword />
        </>
    )
}

export default ResetPassword
