import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import validate from "validate.js";
import MuiAlert from '@material-ui/lab/Alert';
import Api from '../../../../Helper/ApiHandler';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useRouter from "utils/useRouter";

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const schema = {
  newPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: { minimum: 6 }
  },
  confirmNewPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: { minimum: 6 }
  },
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    background: "#E5E5E5",
    padding: "50px 0",
    height: "800px",
    display: "flex",
    justifyContent: "center",
  },
  whitePaperReset: {
    background: "white",
    borderRadius: "10px",
    width: "875px",
    height: "330px",
    [theme.breakpoints.down('sm')]: {
      height: "auto",
      margin: "0px 30px"
    },
  },
  saveButton: {
    width: "220px",
    height: "40px",
    fontSize: "15px",
    borderRadius: "10px",
    background: 'linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)',
  },
  passwordFiled: {
    width: "90%",
    margin: "5px 15px",
    //fontFamily: "Gilroy !important",
  },
  headingReset: {
    padding: "15px !important",
    margin: "0",
    fontSize: "22px !important",
    fontWeight: "700 !important",
  },
  imgaeGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      borderBottom: "1px solid #b7b7b7"
    }
  },
  bottonRoot: {
    padding: "30px 0px",
    display: "flex",
    justifyContent: "center"
  },
  formRoot: {
    padding: "20px 0px 0px 0px",
    width: "100%"
  }
}));

const FormResetPassword = (props) => {
  const { token, className, ...rest } = props;
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [sessionMessage, setSessionMessage] = React.useState({
    message: '',
    type: '',
    open: false
  });


  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleFieldChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formState.isValid === true) {
      api.post(`user/forgotpwd/${token}`, {
        data: formState.values
      })
        .then((result) => {
          if (result.success === "true") {
            console.log(result, "success")
            // setSessionMessage({
            //   message: result.message,
            //   type: 'success',
            //   open: true
            // });
            router.history.push({
              pathname: '/',
              search: '',
              state: {
                sessionPropsMessage: {
                  message: result.message,
                  type: 'success',
                  open: true
                }
              }
            })
            // router.push("/")
          } else {
            setSessionMessage({
              message: result.message,
              type: 'error',
              open: true
            });
            console.log(result, "error")
          }
        })
        .catch((err) => {
          console.log(err, "error catch")
          setSessionMessage({
            message: err.message,
            type: 'error',
            open: true
          });
        });
    }
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };
  const handleClickShowPassword2 = () => {
    setShowPass2(!showPass2);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.cardRoot}>
        <Grid container className={classes.whitePaperReset}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.imgaeGrid}>
            <img src="/images/matchUp/resetPassword.png" height="300px" width="300px" alt={"Alt Image"} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex" }}>
            <Divider orientation="vertical" />
            <div className={classes.formRoot}>
              <form onSubmit={(event) => handleSubmit(event)}>
                <h2 className={classes.headingReset}> Reset Password</h2>
                {/* <TextField
                  className={classes.passwordFiled}
                  fullWidth={true}
                  size="small"
                  id="outlined-basic"
                  label="Old Password"
                  variant="outlined"
                  name="oldPassword"
                  onChange={handleFieldChange}
                  error={hasError("oldPassword")}
                  helperText={
                    hasError("oldPassword")
                      ? formState.errors.oldPassword[0]
                      : null
                  }
                /> */}
                <TextField
                  className={classes.passwordFiled}
                  fullWidth
                  size="small"
                  type={showPass ? "text" : "password"}
                  id="outlined-basic"
                  label="New Password"
                  variant="outlined"
                  name="newPassword"
                  onChange={handleFieldChange}
                  error={hasError("newPassword")}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {!showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                  helperText={
                    hasError("newPassword")
                      ? formState.errors.newPassword[0]
                      : null
                  }
                />
                <TextField
                  className={classes.passwordFiled}
                  size="small"
                  type={showPass2 ? "text" : "password"}
                  id="outlined-basic"
                  label="Confirm New Password"
                  variant="outlined"
                  name="confirmNewPassword"
                  onChange={handleFieldChange}
                  error={hasError("confirmNewPassword")}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {!showPass2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                  helperText={
                    hasError("confirmNewPassword")
                      ? formState.errors.confirmNewPassword[0]
                      : null
                  }
                />
                <div className={classes.bottonRoot}>
                  <Button
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                    disabled={!formState.isValid}
                    type="submit"
                  >
                    Change Password
                  </Button>
                </div>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default FormResetPassword;
