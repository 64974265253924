import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import SliderModel from "components/Slider/SliderModel";
import "react-fancybox/lib/fancybox.css";
import { Link as browserLink, useHistory, useLocation } from "react-router-dom";

import { Alert, Skeleton } from "@material-ui/lab";
import Api from "../../../../Helper/ApiHandler";
import SolQuizeDialog from "components/SolQuizeModel/SolQuizeModel";
import { useGeolocated } from "react-geolocated";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import { validate } from "validate.js";
import useRouter from "utils/useRouter";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Fancybox from "components/fancybox";

var api = new Api();

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    minHeight: "90vh",
    // marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "white",
    margin: 0,
    padding: "10px 0px 0px 20px",
  },
  profileImage: {
    height: "200px",
    width: "200px",
    borderRadius: "10px",
    objectFit: "cover",
    objectPosition: "top",
  },
  profileImageRoot: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    width: "fit-content",
    position: "relative",
  },
  personalInfoRoot: {
    backgroundColor: "white",
    borderRadius: "0px 0px 10px 10px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    padding: "15px",
  },
  nameRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  name: {
    fontSize: "20px",
    fontWeight: "500",
    // whiteSpace: "nowrap",
    width: "100%",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
  },
  qulification: {
    fontSize: "12px",
    fontWeight: "400",
  },
  messageIcon: {
    height: "40px",
    width: "40px",
    cursor: "pointer",
  },
  aboutmeTitle: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#FE4B6B",
    margin: "10px 0px",
  },
  aboutme: {
    fontSize: "12px",
    fontWeight: "400",
    wordBreak: "break-all",
  },
  solMatchRoot: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    // paddingLeft:"10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    // padding: "35px"
  },
  solMatchImage: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  solMatchSeeMore: {
    backgroundColor: "white",
    borderRadius: "20px",
    padding: "10px 0px",
    display: "flex",
    justifyContent: "center",
    color: "#FD3576",
    fontWeight: "500",
    fontSize: "13px",
  },
  solImage1: {
    height: "85px",
    width: "85px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "2px solid white",
    objectPosition: "top",
  },
  solRingRoot: {
    position: "absolute",
    left: "30%",
  },
  solRing: {
    height: "85px",
    width: "85px",
    position: "absolute",
    left: "-6px",
  },
  logo: {
    height: "22px",
    width: "22px",
    left: "25px",
    position: "absolute",
    top: "25px",
  },
  textMatching: {
    left: "23px",
    position: "absolute",
    top: "48px",
    fontSize: "12px",
    fontWeight: "600",
  },
  solImage2: {
    height: "85px",
    width: "85px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "2px solid white",
    marginLeft: "10px",
    objectPosition: "top",
  },
  photosRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "8px",
  },
  solphotos: {
    // height: "90px",
    // width: "90px",
    height: "110px",
    width: "110px",
    borderRadius: "10px",
    objectFit: "cover",
    padding: "5px",
  },
  photosTitle: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#FE4B6B",
    margin: "0px 8px",
  },
  lifestyleRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "8px",
    marginTop: "20px",
  },
  lifestyleRootnot: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "8px",
    marginTop: "0px",
  },
  lifestyleTitle: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#FE4B6B",
    margin: "0px 8px",
  },
  lifestyleCard: {
    background: "#F2F2F2",
    borderRadius: "10px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "124px",
    // width: "124px",
  },
  lifestylePhotos: {
    height: "50px",
    width: "50px",
  },
  lifestyleText: {
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
  },
  AddIcon: {
    textAlign: "center",
    fontSize: "24px",
    color: "#FE4B6B",
    fontWeight: "500",
  },
  factfileRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "8px",
    height: "100%",
  },
  factfileTitle: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#FE4B6B",
    margin: "8px",
  },
  factfilephotos: {
    height: "35px",
    width: "35px",
    marginRight: "10px",
  },
  factfileCardRoot: {
    display: "flex",
    margin: "18px 10px",
    // cursor: "pointer"
  },
  question: {
    color: "#808080",
    fontSize: "12px",
  },
  answer: {
    fontWeight: "600",
    fontSize: "14px",
  },
  detailRoot: {
    marginLeft: "20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
    position: "relative",
  },
  viewMore: {
    textAlign: "end",
    color: "#FE4B6B",
    margin: "5px 0",
    fontSize: 12,
    wordBreak: "break-all",
    overflow: "hidden",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  showtext: {
    color: "gray",
    margin: "5px 0",
    wordBreak: "break-all",
    overflow: "hidden",
  },

  textUserDetail: {
    // margin: "0",
    color: "gray",
    margin: "5px 0",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkitLineClamp": 3,
    "-webkitBoxOrient": "vertical",
    [theme.breakpoints.down("sm")]: {
      "-webkitLineClamp": 2,
    },
    [theme.breakpoints.down("xs")]: {
      "-webkitLineClamp": 4,
    },
    overflow: "hidden",
  },
  solBottomBorder: {
    position: "absolute",
    // background: "red",
    height: "40px",
    width: "100%",
    bottom: "4px",
    borderRadius: "0px 0px 10px 10px",
    backdropFilter: "blur(5px)",
    backgroundColor: "#00000030",
    display: "flex",

    // padding: "10px"
  },
  gap: {
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
}));

const lstMasterLifeStyle = {
  "Listening Music": "/images/matchUp/lifeStyle1.svg",
  "Study Reaserch": "/images/matchUp/StudyReaserch.svg",
  Wellness: "/images/matchUp/Wellness.svg",
  Cinema: "/images/matchUp/Cinema.svg",
  Beauty: "/images/matchUp/Beauty.svg",
  Holidays: "/images/matchUp/Holidays.svg",
  Swimming: "/images/matchUp/Swimming.svg",
  "Cruise Trips": "/images/matchUp/CruiseTrips.svg",
  Cycling: "/images/matchUp/Cycling.svg",
  Italian: "/images/matchUp/Italian.svg",
  Playing: "/images/matchUp/Playing.svg",
};
const lstLifeStyle = [
  "Listening Music",
  "Study Reaserch",
  "Wellness",
  "Cinema",
  "Beauty",
  "Holidays",
  "Swimming",
  "Cruise Trips",
  "Cycling",
  "Italian",
  "Playing",
];

const quizMaster = {
  "20": "/images/matchUp/19.svg",
  "21": "/images/matchUp/20.svg",
  "22": "/images/matchUp/21.svg",
  "23": "/images/matchUp/22.svg",
  "24": "/images/matchUp/25.svg",
  "25": "/images/matchUp/23.svg",
  "26": "/images/matchUp/24.svg",
  "27": "/images/matchUp/27.svg",
  "28": "/images/matchUp/26.svg",
};

const quiz = [
  {
    question: [
      "When were you born?",
      "I’m looking for someone in age between...",
    ],
    answer: "Computer Engineer",
    id: 19,
  },
  {
    question: ["Where do you live?", "How far will you go to meet your match?"],
    answer: "Computer Engineer",
    id: 20,
  },
  {
    question: ["What is your body type?", "My partner body type should be..."],
    answer: "Computer Engineer",
    id: 21,
  },
  {
    question: ["I’am", "My partner race type should be..."],
    answer: "Computer Engineer",
    id: 22,
  },
  {
    question: [
      "What is your religious, beliefs or spirituality?",
      "Match me with someone whose religion or spirituality is",
    ],
    answer: "Hindu",
    id: 23,
  },
  {
    question: ["How often do you smoke?", "How often should you match smoke?"],
    answer: "Non-Smoker",
    id: 24,
  },
  {
    question: [
      "What is your marital status?",
      "I’m looking for someone in age between...",
    ],
    answer: "Single",
    id: 25,
  },
  // {
  //   question: ["What kinds of hobbies do you have?", "I’m looking for someone in age between..."],
  //   answer: "Computer Engineer",
  //   id: 26
  // },
  // {
  //   question: ["What is your occupation?", "I’m looking for someone in age between..."],
  //   answer: "Computer Engineer",
  //   id: 27
  // },
];

const ViewMatchesSol = (props) => {
  const { sessionPropsMessage } = props;

  const classes = useStyles();
  const router = useRouter();
  const [userData, setUserData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [sessionMessage, setSessionMessage] = React.useState(
    sessionPropsMessage
  );
  const [lstLifeStyleImage, setLstLifeStyleImage] = React.useState([]);
  const [lstQuiz, setLstQuiz] = React.useState([]);
  const [objQuiz, setObjQuiz] = React.useState({});
  const [ocupation, setOcupation] = React.useState("");
  const [objHighestMatch, setObjHighestMatch] = React.useState({});
  const [profile_picture, setProfile_picture] = React.useState("");
  const [objIntrestImageMaster, setObjIntrestImageMaster] = React.useState({});
  const [lstIntrestImage, setLstIntrestImage] = React.useState([]);
  const [firebase_uid, setFirebase_uid] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [isBlock, setIsBlock] = React.useState(false);
  const [hideText, setHideText] = React.useState(false);
  const handleShowText = () => {
    setHideText(true);
  };
  const handleHideText = () => {
    setHideText(false);
  };

  let query = useLocation();
  const slug = query.pathname.split("/")[3];
  console.log(isBlock, "isBlock");
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    // userDecisionTimeout: 5000,
  });

  const [objDeleteProps, setObjDeleteProps] = React.useState({
    slug: "",
    open: false,
    componentName: "",
    userType: "",
  });
  useEffect(() => {
    onLoad();
    onLifestyleimage();
  }, []);

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };
  const onBlock = (value) => {
    api
      .post(`user/blockprofile/${slug}`, {
        data: { is_blocked: !value },
      })
      .then((result) => {
        if (result.success === "true") {
          // onLoad()
          setIsBlock(!value);
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };
  const onMessageClick = (firebase_uid) => {
    api
      .get(`user/soulmate/getmessagecount`)
      .then((result) => {
        if (result.success === "true") {
          // Api Call For Fetch The Our Matches
          api
            .get(`user/addmessagetosoulmate/${slug}`)
            .then((result) => {
              if (result.success === "true") {
                router.history.push(`/messages/${firebase_uid}`);
                AsyncLocalStorage.setItem("receiverName", userName);
                AsyncLocalStorage.setItem(
                  "receiverProfilePicuture",
                  userData.profile_picture
                );
                AsyncLocalStorage.setItem("request_status", "pending");
                AsyncLocalStorage.setItem("to_id", userData.user_id);
                AsyncLocalStorage.setItem("to_slug", slug);
              } else {
                console.log(result, "error");
              }
            })
            .catch((err) => {
              console.log(err, "catch error");
            });
        } else {
          router.history.push(`/subscription-plan`);
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
    // userconver: {status: "pending", request_id: null}
    // user/addmessage
  };
  const handleClickOpen = (obj) => {
    setObjDeleteProps({
      // slug: slug,
      open: true,
      componentName: "deletefromgroup",
      userType: "user",
    });
    setObjQuiz(obj);
  };

  console.log(objHighestMatch, "objHighestMatch");
  const handleCloseSolModel = (value, objMessage) => {
    setObjDeleteProps({
      slug: "",
      open: false,
      componentName: "",
    });
    setSessionMessage(objMessage);
  };

  // user/soulmate/lifestyleimage
  const onLifestyleimage = async () => {
    // Api Call For Fetch The User Data
    api
      .get(`user/soulmate/lifestyleimage`)
      .then((result) => {
        if (result.success === "true") {
          // console.log(result.data, "result")
          setObjIntrestImageMaster(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const onLoad = async () => {
    setIsLoading(true);
    let userProfile = await AsyncLocalStorage.getItem("userProfile");
    setProfile_picture(userProfile);
    // Api Call For Fetch The User Data
    api
      .get(`user/yoursoulmate/${slug}`)
      .then((result) => {
        if (result.success === "true") {
          setFirebase_uid(result.data.firebase_uid);
          setIsBlock(result.data.is_blocked);
          setUserName(result.data.first_name + " " + result.data.last_name);
          setUserData(result.data);
          if (result.data.lifestyle_photos) {
            setLstLifeStyleImage(result.data.lifestyle_photos);
          }
          const lstInTmp = result.data.interests.concat(
            result.data.planned_activities
          );
          setLstIntrestImage(lstInTmp);
          // if (result.data.userCriteria) {
          console.log(result.data.matchUserCriteria, "lstOcupation.answer[0]");
          let lstOcupation = result.data.matchUserCriteria.filter(
            (obj) => obj.id == 28
          );
          setOcupation(lstOcupation[0].answer[0]);
          let lstTmp = result.data.matchUserCriteria.map((obj) => {
            return {
              ...obj,
              answer: obj.answer[0],
              question: JSON.parse(obj.question)[0],
            };
          });
          setLstQuiz(lstTmp);
          // console.log(lstTmp, "lstTmp")
          // }
          setIsLoading(false);
        } else {
          console.log(result, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
        setIsLoading(false);
      });
  };
  const handleSliderClose = () => {
    setSliderOpen(false);
  };
  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getAge = (date) => {
    if (!date) {
      return;
    }
    let fullYear = new Date().getFullYear();
    const myArray = date.split("-");
    return fullYear - parseInt(myArray[0]);
  };

  const history = useHistory();

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              {isLoading ? (
                <SkeletonLoading />
              ) : (
                <>
                  <Grid
                    spacing={2}
                    container
                    className={classes.gap}
                    style={{ marginTop: "0px" }}
                  >
                    <Grid item xs={12} sm={12} md={8}>
                      <div>
                        <h5 className={classes.headingPage}>
                          <ArrowBackIcon
                            fontSize="default"
                            onClick={() => {
                              router.history.goBack();
                            }}
                            style={{
                              position: "relative",
                              fontSize: 20,
                              top: 4,
                              marginRight: 10,
                            }}
                          />
                          Your matches
                        </h5>
                      </div>
                      <div className={classes.personalInfoRoot}>
                        <div className={classes.profileImageRoot}>
                          <Fancybox>
                            <a
                              data-fancybox="images"
                              href={
                                userData.profile_picture
                                  ? userData.profile_picture
                                  : "/images/male_defualt.svg"
                              }
                            >
                              <img
                                src={
                                  userData.profile_picture
                                    ? userData.profile_picture
                                    : "/images/male_defualt.svg"
                                }
                                alt="Property Image"
                                className={classes.profileImage}
                              // style={{
                              //   width: "100px",
                              //   height: "100px",
                              //   borderRadius: "50%",
                              //   top: 2,
                              //   position: "relative",
                              //   objectFit: "cover",
                              // }}
                              />
                            </a>
                          </Fancybox>
                          <div className={classes.solBottomBorder}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <div
                                className={classes.solBottomBorderName}
                                style={{ display: "flex", height: 40 }}
                              >
                                <p
                                  style={{
                                    width: "50%",
                                    margin: 10,
                                    fontSize: "16px !important",
                                    color: "white",
                                    fontWeight: 400,
                                    padding: "0 8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => onMessageClick(firebase_uid)}
                                >
                                  Message
                                </p>
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  style={{ background: "white" }}
                                />
                                {isBlock ? (
                                  <p
                                    style={{
                                      width: "50%",
                                      margin: 10,
                                      fontSize: "16px !important",
                                      padding: "0 12px",
                                      color: "white",
                                      fontWeight: 400,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      onBlock(true);
                                    }}
                                  >
                                    Unblock
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      width: "50%",
                                      margin: 10,
                                      fontSize: "16px !important",
                                      padding: "0 18px",
                                      color: "white",
                                      fontWeight: 400,
                                      cursor: "pointer",
                                    }}

                                    onClick={() => {
                                      onBlock(false);
                                    }}
                                  >
                                    Block
                                  </p>
                                )}
                              </div>
                            </Box>
                          </div>
                          {/* <img
                            src={
                              userData.profile_picture
                                ? userData.profile_picture
                                : "/images/male_defualt.svg"
                            }
                            alt="Img"
                            className={classes.profileImage}
                          /> */}
                        </div>

                        <div className={classes.detailRoot}>
                          <div className={classes.nameRoot}>
                            <div>
                              <div className={classes.name}>
                                {userData.first_name} {userData.last_name},{" "}
                                {getAge(userData.date_of_birth)}
                              </div>
                              <div className={classes.qulification}>
                                {ocupation}
                              </div>
                            </div>
                            {/* <div
                              style={{
                                position: "absolute",
                                right: "10px",
                                bottom: 0,
                              }}
                            >
                              <img
                                src="/images/matchUp/msgSol.png"
                                alt="Img"
                                className={classes.messageIcon}
                                onClick={() => onMessageClick(firebase_uid)}
                              />
                              &nbsp;&nbsp;
                              {isBlock ? (
                                <img
                                  src="/images/matchUp/blockSol.svg"
                                  alt="Img"
                                  className={classes.messageIcon}
                                  onClick={() => {
                                    onBlock(false);
                                  }}
                                />
                              ) : (
                                <img
                                  src="/images/matchUp/blockSol.svg"
                                  alt="Img"
                                  className={classes.messageIcon}
                                  onClick={() => {
                                    onBlock(true);
                                  }}
                                />
                              )}
                            </div> */}
                          </div>
                          {userData.bio && (
                            <div className={classes.aboutmeTitle}>About me</div>
                          )}
                          {userData.bio && (
                            <p
                              className={
                                hideText === true
                                  ? classes.showtext
                                  : classes.textUserDetail
                              }
                            >
                              <Hidden smUp>
                                {hideText === false
                                  ? userData.bio.slice(0, 40)
                                  : userData.bio}
                              </Hidden>
                              <Hidden xsDown>
                                {hideText === false
                                  ? userData.bio.slice(0, 100)
                                  : userData.bio}
                              </Hidden>
                              {hideText ? (
                                <p
                                  className={classes.viewMore}
                                  onClick={handleHideText}
                                >
                                  Show Less
                                </p>
                              ) : (
                                <span
                                  className={classes.viewMore}
                                  onClick={handleShowText}
                                >
                                  {userData.bio.length >= 40
                                    ? "....View More"
                                    : ""}
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <div className={classes.solMatchRoot}>
                        <Box maxWidth={"276px"} p="35px">
                          <div className={classes.solMatchImage}>
                            <img
                              src={
                                profile_picture != "undefined" &&
                                  profile_picture != "null"
                                  ? profile_picture
                                  : "/images/male_defualt.svg"
                              }
                              alt="Img"
                              className={classes.solImage1}
                            />
                            <div className={classes.solRingRoot}>
                              <div>
                                <img
                                  src="/images/matchUp/solRing.svg"
                                  alt="Img"
                                  className={classes.solRing}
                                />
                              </div>
                              <img
                                src="/images/matchUp/smallLogo.svg"
                                alt="Img"
                                className={classes.logo}
                              />
                              <div className={classes.textMatching}>
                                {userData.matchPercent
                                  ? userData.matchPercent
                                  : 0}
                                %
                              </div>
                            </div>
                            <img
                              src={
                                userData.profile_picture
                                  ? userData.profile_picture
                                  : "/images/male_defualt.svg"
                              }
                              alt="Img"
                              className={classes.solImage2}
                            />
                          </div>
                          {/* <div className={classes.solMatchSeeMore}>
                            See More
                          </div> */}
                        </Box>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8}>
                      <Grid spacing={3} container>
                        <Grid item xs={12}>
                          {lstLifeStyleImage.length === 0 ? (
                            ""
                          ) : (
                            <div className={classes.photosRoot}>
                              <div className={classes.photosTitle}>Photos</div>
                              <div className={classes.photos}>
                                {lstLifeStyleImage.length > 0 ? (
                                  lstLifeStyleImage.map((value) => {
                                    return (
                                      <img
                                        src={
                                          value
                                            ? value
                                            : "/images/matchUp/sol1.jpg"
                                        }
                                        alt="Img"
                                        className={classes.solphotos}
                                      />
                                    );
                                  })
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      width: "100%",
                                      height: "100px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      component="primary"
                                      color="primary"
                                      style={{
                                        fontSize: "12px",
                                        color: "gray",
                                        fontWeight: "400",
                                      }}
                                    >
                                      You have no photos
                                    </Typography>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <div
                            className={
                              lstLifeStyleImage.length === 0
                                ? classes.lifestyleRootnot
                                : classes.lifestyleRoot
                            }
                          // style={
                          //   lstLifeStyleImage.length === 0
                          //     ? { marginTop: "0 !important" }
                          //     : ""
                          // }
                          >
                            <div className={classes.lifestyleTitle}>
                              Lifestyle
                            </div>

                            <Grid spacing={1} container>
                              {lstIntrestImage.map((value) => {
                                let src =
                                  objIntrestImageMaster &&
                                  objIntrestImageMaster.length &&
                                  objIntrestImageMaster.filter((obj) => {
                                    return (
                                      obj.title.toLowerCase() ==
                                      value.toLowerCase()
                                    );
                                  });
                                return (
                                  <Grid item xs={4} sm={4} md={3}>
                                    <div className={classes.lifestyleCard}>
                                      <img
                                        src={src && src.length && src[0]?.image}
                                        alt="Img"
                                        className={classes.lifestylePhotos}
                                      />
                                      <div className={classes.lifestyleText}>
                                        {value}
                                      </div>
                                    </div>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </div>
                        </Grid>

                        {/* <Grid item xs={12}>

                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <div className={classes.factfileRoot}>
                        <div className={classes.factfileTitle}>Factfile</div>
                        <div>
                          {lstQuiz.map((obj) => {
                            return (
                              <div
                                className={classes.factfileCardRoot}
                                onClick={(e) => {
                                  handleClickOpen(obj);
                                }}
                              >
                                <div>
                                  <img
                                    src={quizMaster[obj.id]}
                                    alt="Img"
                                    className={classes.factfilephotos}
                                  />
                                </div>
                                <div>
                                  <div className={classes.question}>
                                    {obj.question}
                                  </div>
                                  <div className={classes.answer}>
                                    {obj.answer}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <SliderModel
        open={sliderOpen}
        onClose={handleSliderClose}
        lifestyle_photos={userData.lifestyle_photos}
      />
      {/* <SolQuizeDialog
        open={objDeleteProps.open}
        onClose={handleCloseSolModel}
        objQuiz={objQuiz}
        onLoad={onLoad}
      /> */}
    </>
  );
};
const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <Skeleton variant="rect" height={200} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Skeleton variant="rect" height={200} />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Grid spacing={3} container>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={100} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={350} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Skeleton variant="rect" height={473} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ViewMatchesSol;
