import { Container, Grid } from "@material-ui/core";
import React, { Component } from "react";
import Slider from "react-slick";
export default class SimpleSlider extends Component {

  render() {
    const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }) => {
      const { lifestyle_photos, className, onClick } = props;

      return (
        <div {...props} className="custom-prevArrow" onClick={onClick} style={{ marginRight: "30px" }}>
          <img src="./images/matchUp/preArrow.svg" alt="next" />
        </div>
      );
    };
    const GalleryNextArrow = ({ currentSlide, slideCount, ...props }) => {
      const { className, onClick } = props;

      return (
        <div {...props} className="custom-nextArrow" onClick={onClick} style={{ marginLeft: "30px" }} >
          <img src="./images/matchUp/nextArrow.svg" alt="next" />
        </div>
      );
    };
    var settings = {
      dots: false,
      infinite: true,
      centerMode: true,
      className: "center",
      centerPadding: "0px !important",
      className: "center",
      speed: 200,
      focusOnSelect: true,
      // centerPadding: "100px",
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <GalleryNextArrow />,
      prevArrow: <GalleryPrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Container maxWidth="sm" >
        <Grid
          direction="row"
          justifyContent="center"
          alignItems="center"
          container
        >
          <Grid item container xl={12} lg={12} xs={12}>
            <Slider style={{ width: "100%", padding: "0px !important" }} {...settings}>
              {this.props.lifestyle_photos && this.props.lifestyle_photos.map((value) => {
                return (
                  <div>
                    <img
                      src={value}
                      width={"100%"}
                      height={"100%"}
                      style={{ objectFit: "scale-down" }}
                      alt={"Alt Image"}
                    />
                  </div>
                )
              })}
            </Slider>
          </Grid>
        </Grid >
      </Container >
    );
  }
}