import React, { useEffect, useState } from "react";
import {
  Badge,
  Divider,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link as browserLink, useLocation } from "react-router-dom";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import useRouter from 'utils/useRouter';
import MuiAlert from '@material-ui/lab/Alert';
import Api from '../../Helper/ApiHandler';
import moment from 'moment';
import firebase from 'firebase/app';
import _ from 'lodash'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  inboxRoot: {
    display: "flex",
    padding: "5px",
    cursor: "pointer"
  },
  inboxRootSelected: {
    display: "flex",
    padding: "5px",
    cursor: "pointer",
    backgroundColor: "#f9dade"
  },
  inboxImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover"
  },
  inboxNameRoot: {
    marginLeft: "10px"
  },
  inboxName: {
    fontSize: "14px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxLastMsg: {
    fontSize: "11px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxTime: {
    fontSize: "11px",
    fontWeight: "400",
  },
  inboxCountRoot: {
    "& .MuiBadge-badge": {
      fontSize: "10px",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: "auto"
    },
    marginLeft: "auto",
    marginRight: "10px"
  }
}));

// [
//   {
//     createdAt: { seconds: 1662033471, nanoseconds: 781000000 },
//     createdBy: "CxmFUYvReaP2OSd9n05DTzoDfbq1",
//     group_admin_id: 6,
//     group_description: "description",
//     group_profile_pic: "https://dev-matchupmates.s3.amazonaws.com/images/teammategroup/1662033471039_etienne-beauregard-riverin-B0aCvAVSX8E-unsplash%20%281%29.jpg",
//     group_type: "private",
//     id: "65e0693a61530f1f8d557841f735",
//     members: ['habn17niNJP3yxjYH4FIg46xLfp2', 'CxmFUYvReaP2OSd9n05DTzoDfbq1'],
//     messageText: "22212",
//     modifyAt: { seconds: 1662033471, nanoseconds: 781000000 },
//     name: "Patiyu one",
//     recentMessage: {
//       messageText: "22212",
//       readBy: [
//         "habn17niNJP3yxjYH4FIg46xLfp2"
//       ],
//       sentAt: {
//         nanoseconds: 953000000,
//         seconds: 1662385908
//       },
//       sentBy: "habn17niNJP3yxjYH4FIg46xLfp2"
//     },
//     slug: "pati-9c0753",
//     type: "group",
//     users: null,
//   },
//   {
//     createdAt: { seconds: 1662033501, nanoseconds: 260000000 },
//     createdBy: "CxmFUYvReaP2OSd9n05DTzoDfbq1",
//     group_admin_id: 6,
//     group_description: "description",
//     group_profile_pic: "https://dev-matchupmates.s3.amazonaws.com/images/teammategroup/1662033487331_Dormmates-banner.webp",
//     group_type: "private",
//     id: "70fb3cc68d6f11b0aa0a9c306e91",
//     members: ['habn17niNJP3yxjYH4FIg46xLfp2', 'CxmFUYvReaP2OSd9n05DTzoDfbq1'],
//     messageText: "aegrgrgar",
//     modifyAt: { seconds: 1662033501, nanoseconds: 260000000 },
//     name: "patiyu two",
//     recentMessage: {
//       messageText: "aegrgrgar",
//       readBy: ["CxmFUYvReaP2OSd9n05DTzoDfbq1"],
//       sentAt: {
//         nanoseconds: 665000000,
//         seconds: 1662385904
//       },
//       sentBy: "CxmFUYvReaP2OSd9n05DTzoDfbq1",
//     },
//     slug: "pati-d3142f",
//     type: "group",
//     users: null,
//   }
// ]

const GIndex = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const { clickInbox, setClickInbox, setLstTmpMessage, receiverId } = props
  const [lstInbox, setLstInbox] = useState([])
  const [lstTmpInbox, setLstTmpInbox] = useState([])
  const [senderId, setSenderId] = useState("")
  const [userName, setUserName] = useState("")

  const [objSelectedGroup, setObjSelectedGroup] = useState({
    name: "",
    no_of_members: "",
    profile_picture: "",
    slug: "",
    groupId: ""
  });
  const [objSelectedUser, setObjSelectedUser] = useState({
    name: "",
    profile_picture: "",
    slug: "",
    userId: ""
  });
  const [sessionMessage, setSessionMessage] = useState({
    message: '',
    type: '',
    open: false
  });

  let query = useLocation();

  const db = firebase.firestore();

  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }


  useEffect(() => {

    onload()
  }, [receiverId]);

  useEffect(() => {
    onSetInbox()
  }, [lstTmpInbox]);

  let onSetInbox = async () => {
    if (lstTmpInbox.length) {
      const lstTmp = [];

      const unresolved = lstTmpInbox.map(async (mainobj) => {
        console.log("mainobjlstTmpInbox", mainobj)
        if (mainobj.type == "group") {
          var groupRef = db.collection("group").doc(mainobj.groupId);
          await groupRef.get().then((groupDoc) => {
            if (groupDoc.exists) {
              console.log("groupDoc-----", groupDoc.data())
              lstTmp.push({ ...groupDoc.data(), type: "group", textShow: mainobj.messageText })
            }
          }).catch((error) => {
            console.log("Error getting document:", error);
          })
        }
        else {
          console.log("Personal Msg", mainobj)
          var userRef = db.collection("user").doc(mainobj.receiverId)
          await userRef.get().then((userDoc) => {
            console.log("userDoc-----", userDoc.data())
            if (userDoc.exists) {
              lstTmp.push({ ...userDoc.data(), ...mainobj, type: "personal", textShow: mainobj.messageText })
              // console.log("lstTmppersonal",lstTmp)
            }
          }).catch((error) => {
            console.log("Error getting document:", error);
          })

        }
      })
      await Promise.all(unresolved)
      console.log("lstTmppersonal", lstTmp)
      setLstInbox(lstTmp)
    }
  }

  let onload = async () => {

    console.log("setLstTmpMessage==", setLstTmpMessage)
    console.log("setClickInbox==", setClickInbox)
    let senderId = await AsyncLocalStorage.getItem("senderId")
    let user_name = await AsyncLocalStorage.getItem("userName")

    if (senderId === "")
      return
    setSenderId(senderId)
    setUserName(user_name)


    // get Inbox
    let inboxRef = db.collection("message").doc(senderId).collection("inbox")
    inboxRef.orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        let temp = [];
        querySnapshot.forEach((mainDoc) => {
          temp.push(mainDoc.data())
        });
        console.log(temp, "tmp---")
        setLstTmpInbox(temp)
      });
  }

  const onInboxClick = async (name, profile_picture, id, receiverId, slug, members) => {
    AsyncLocalStorage.setItem("receiverName", name)
    AsyncLocalStorage.setItem("receiverProfilePicuture", profile_picture)
    AsyncLocalStorage.setItem("receiverId", id)
    AsyncLocalStorage.setItem("to_slug", slug)
    AsyncLocalStorage.setItem("no_of_members", members.length)
    AsyncLocalStorage.setItem("chat_type", "group")
    AsyncLocalStorage.setItem("group_members", JSON.stringify(members))

    // setNameChat(name);
    router.history.push(`/messages/${receiverId}`)
    // await onload()
  };

  const onInboxPresonalClick = async (name, profile_picture, id, receiverId, slug) => {
    // objUser.fullName, objUser.imageUrl, objUser.id, objUser.receiverId, objUser.slug
    AsyncLocalStorage.setItem("receiverName", name)
    AsyncLocalStorage.setItem("receiverProfilePicuture", profile_picture)
    AsyncLocalStorage.setItem("receiverId", receiverId)
    AsyncLocalStorage.setItem("to_slug", slug)
    AsyncLocalStorage.setItem("to_id", id)
    AsyncLocalStorage.setItem("chat_type", "personal")

    // setNameChat(name);
    router.history.push(`/messages/${receiverId}`)
    // await onload()
  };

  let onClickGroupInbox = async (objGroup) => {
    setLstTmpMessage([])
    setClickInbox(!clickInbox)
    onInboxClick(objGroup.name, objGroup.group_profile_pic, objGroup.id, objGroup.id, objGroup.slug, objGroup.members)
  }

  let onClickPersonalInbox = async (objUser) => {
    if (receiverId !== objUser.receiverId) {
      setLstTmpMessage([])
      setClickInbox(!clickInbox)
    }
    onInboxPresonalClick(objUser.fullName, objUser.imageUrl, objUser.id, objUser.receiverId, objUser.slug)
  }

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div style={{ backgroundColor: "white", borderRadius: "10px", height: "calc(100vh - 200px)" }}>
        <div style={{ width: "100%", backgroundColor: "white", fontSize: "20px", fontWeight: "600", padding: "18px", borderRadius: "10px 10px 0px 0px" }}>
          Message
        </div>

        <div style={{ backgroundColor: "white", borderRadius: "0px 0px 10px 10px" }}>

          {/* Inbox */}
          {console.log("lstInbox1111", lstInbox)}
          {/* {console.log("receiverId000", receiverId)} */}
          {lstInbox.map((obj) => {
            if (obj.type == "group") {
              return (
                <>
                  <Divider />
                  <div
                    className={obj.id != receiverId ? classes.inboxRoot : classes.inboxRootSelected}
                    onClick={(e) => {
                      if (receiverId) {
                        if (obj.id != receiverId)
                          onClickGroupInbox(obj)
                      } else {
                        onClickGroupInbox(obj)
                      }
                    }}
                  >
                    <div>
                      <img src={obj.group_profile_pic ? obj.group_profile_pic : '/images/male_defualt.svg'} alt="Img" className={classes.inboxImage} />
                    </div>
                    <div className={classes.inboxNameRoot}>
                      <div className={classes.inboxName}>{obj.name}</div>
                      <div className={classes.inboxLastMsg}>{obj?.textShow}</div>
                    </div>

                    <div className={classes.inboxCountRoot}>
                      <div className={classes.inboxTime}>
                        {moment(new Date(obj?.recentMessage?.sentAt * 1000)).format("HH:mm")}
                      </div>
                      <Badge badgeContent={10} color="primary"></Badge>
                    </div>
                  </div>
                </>
              )
            } else {
              return (
                <>
                  <Divider />
                  <div className={obj.receiverId == receiverId ? classes.inboxRootSelected : classes.inboxRoot}
                    onClick={(e) => { onClickPersonalInbox(obj) }}>
                    <div>
                      <img src={obj.photoURL ? obj.photoURL : '/images/male_defualt.svg'} alt="Img" className={classes.inboxImage} />
                    </div>
                    <div className={classes.inboxNameRoot}>
                      <div className={classes.inboxName}>{obj.displayName}</div>
                      <div className={classes.inboxLastMsg}>{obj.messageText}</div>
                    </div>

                    <div className={classes.inboxCountRoot}>
                      <div className={classes.inboxTime}>
                        {moment(new Date(obj.createdAt * 1000)).format("HH:mm")}
                      </div>
                      <Badge badgeContent={10} color="primary"></Badge>
                    </div>
                  </div>
                </>
              )
            }
          })}
        </div>
      </div>
    </>
  );
};
export default GIndex;
