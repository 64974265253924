import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import { Link } from "react-router-dom";
import PublicIcon from "@material-ui/icons/Public";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CloseIcon from "@material-ui/icons/Close";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import useRouter from "utils/useRouter";
import Cookies from 'js-cookie';

import Api from "../../Helper/ApiHandler";
var api = new Api();

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  group_dp_image: {
    height: "180px",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px 10px 0px 0px",
    width: "100%",
    position: "relative",
  },
  group_detail_root: {
    background: "#FFFFFF",
    padding: "5px 15px",
    borderRadius: "0px 0px 10px 10px",
    height: 170,
  },
  group_personal_info: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  group_name: {
    fontSize: "18px",
    color: "#3F444F",
    fontWeight: "600",
  },
  group_member: {
    fontSize: "12px",
    color: "#3F444F",
    margin: "5px 0",
  },
  like_image: {
    position: "absolute",
    background: "#FFFFFF",
    opacity: "0.6",
    padding: "5px",
    fontSize: "24px",
    borderRadius: "50%",
    left: "5px",
    top: "5px",
    color: "black",
    cursor: "pointer",
  },
  group_description: {
    fontSize: "12px",
    color: "#3F444F",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkitLineClamp": 2,
    "-webkitBoxOrient": "vertical",
    overflow: "hidden",
    margin: "10px 0px 10px 0px",
    minHeight: "36px",
  },
  join_button: {
    width: "100%",
    textTransform: "capitalize",
    color: "#F24451",
    background: "#FFF7F8",
    borderRadius: "10px",
    border: "1px solid #F24451",
    margin: "10px 0px",
    fontSize: "16px",
  },
  add_icon: {
    fontSize: "16px",
  },
  likeRoot: {
    "& .MuiFormControlLabel-root": {
      marginRight: "0px",
    },
    "& .MuiCheckbox-root": {
      backgroundColor: "#ffffff59",
      color: "#FE4B6B",
      width: "36px",
      height: "36px",
    },
  },
  group_lock_image: {
    position: "absolute",
    background: "#FFFFFF",
    opacity: "0.6",
    padding: "5px",
    fontSize: "36px",
    borderRadius: "50%",
    right: "5px",
    top: "5px",
    color: "black",
  },
}));

function GroupCard(props) {
  const {
    reDirectUrl,
    setLstGroup,
    isFavourite,
    index,
    lstGroup,
    setSessionMessage,
    objGroup,
    onClickUpload,
  } = props;
  const classes = useStyles();
  const router = useRouter();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isShown, setIsShown] = useState(false);
  const [joinLoading, setJoinLoading] = useState(false);
  const [joinDisable, setJoinDisable] = useState(false);

  const [isOtpSend, setIsOtpSend] = useState(false);

  const onMessageClick = (firebase_uid) => {
    // Api Call For Fetch The Our Matches
    api
      .get(`user/teammate/addmessage/${objGroup.slug}`)
      .then((result) => {
        if (result.success === "true") {

          const prev_receiverId = Cookies.get('curr_receiverId');
          Cookies.set('prev_receiverId', prev_receiverId, { expires: 365 });
          Cookies.set('curr_receiverId', firebase_uid, { expires: 365 });

          // router.history.push(`/messages/${firebase_uid}`);
          // router.history.push(`/chatroomTest/${firebase_uid}`);
          router.history.push({
            pathname: `/messages/${firebase_uid}`,
            search: "",
            state: {
              isFromOtherPage: true
            },
          });

          AsyncLocalStorage.setItem("receiverName", objGroup.groupName);
          AsyncLocalStorage.setItem(
            "receiverProfilePicuture",
            objGroup.group_picture
          );
          AsyncLocalStorage.setItem("receiverId", firebase_uid);
          AsyncLocalStorage.setItem("to_slug", objGroup.slug);
          AsyncLocalStorage.setItem("to_id", objGroup.id);
          AsyncLocalStorage.setItem(
            "no_of_members",
            objGroup.teammateGroupdata.length + 1
          );
          AsyncLocalStorage.setItem("chat_type", "group");
          AsyncLocalStorage.setItem(
            "group_members",
            JSON.stringify(objGroup.teammateGroupdata)
          );
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const join = () => {
    setJoinLoading(true);
    setJoinDisable(true);
    api
      .get(`user/teammate/sendrequest/${objGroup.slug}`)
      .then((result) => {
        if (result.success === "true") {
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          let lstTmp = [...lstGroup];
          lstTmp[index] = { ...lstTmp[index], teamRequest: result.data.status };
          setLstGroup(lstTmp);
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setJoinLoading(false);
        setJoinDisable(false);
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setJoinLoading(false);
        setJoinDisable(false);
      });
  };

  const onFavourite = () => {
    api
      .get(`user/teammate/addfavourite/${objGroup.slug}`)
      .then((result) => {
        if (result.success === "true") {
          // onClickUpload();
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          let lstTmp = [...lstGroup];
          lstTmp[index] = {
            ...lstTmp[index],
            isFavourite: result.is_favourite,
          };
          setLstGroup(lstTmp);
          // lstTmp[index] = { ...lstTmp[index], }
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <div className={classes.root}>
      {isShown ? (
        <div
          className={classes.group_dp_image}
          style={{ backgroundImage: `url(${objGroup.group_picture})` }}
        >
          {/* groupType */}
          {objGroup.groupType === "private" ? (
            <LockOutlinedIcon className={classes.group_lock_image} />
          ) : (
            <PublicIcon className={classes.group_lock_image} />
          )}

          {objGroup.isFavourite && (
            <CloseIcon
              className={classes.like_image}
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
              onClick={() => onFavourite()}
            />
          )}
        </div>
      ) : (
        <Link style={{ textDecoration: "none" }} to={reDirectUrl}>
          <div
            className={classes.group_dp_image}
            style={{ backgroundImage: `url(${objGroup.group_picture})` }}
          >
            {/* groupType */}
            {objGroup.groupType === "private" ? (
              <LockOutlinedIcon className={classes.group_lock_image} />
            ) : (
              <PublicIcon className={classes.group_lock_image} />
            )}

            {objGroup.isFavourite && (
              <CloseIcon
                className={classes.like_image}
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                onClick={() => onFavourite()}
              />
            )}
          </div>
        </Link>
      )}
      <div className={classes.group_detail_root}>
        <div className={classes.group_personal_info}>
          <Link style={{ textDecoration: "none" }} to={reDirectUrl}>
            <div style={{ lineHeight: "1.2" }}>
              <div className={classes.group_name}>{objGroup.groupName}</div>
              <div className={classes.group_member}>
                {objGroup.noOfMembers} Members
              </div>
            </div>
          </Link>
          <div className={classes.likeRoot}>
            {!objGroup.isFavourite && (
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<FavoriteBorder />}
                    checkedIcon={<Favorite />}
                    name="checkedH"
                    onChange={() => {
                      onFavourite();
                    }}
                  />
                }
              />
            )}
          </div>
        </div>
        <Link style={{ textDecoration: "none" }} to={reDirectUrl}>
          <div className={classes.group_description}>
            {objGroup.groupDescription}
          </div>
        </Link>
        <div>
          {objGroup.teamRequest == "join" && (
            <Button
              variant="outlined"
              className={classes.join_button}
              onClick={() => {
                join();
              }}
              disabled={joinDisable}
            >
              <AddIcon className={classes.add_icon} />
              join
              {joinLoading && (
                <>
                  &nbsp;
                  <CircularProgress size={20} />
                </>
              )}
            </Button>
          )}
          {objGroup.teamRequest == "sent" && (
            <Button variant="outlined" className={classes.join_button} disabled>
              Requested
            </Button>
          )}
          {objGroup.teamRequest == "confirm" && (
            <Button
              variant="outlined"
              className={classes.join_button}
              onClick={() => {
                onMessageClick(objGroup.group_firebaseUid);
              }}
            >
              Message
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default GroupCard;
