import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';

import LoginSideNavbar from './component/LoginSideNavbar';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '82%',
    maxWidth: 400,
    backgroundColor: "#e9edf0"
  },
  root: {
    height: '100%',
    padding: theme.spacing(1),
  },
  nav: {
    marginBottom: theme.spacing(1),
  },
}));

const LoginSidebar = props => {
  const { pages, open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <LoginSideNavbar className={classes.nav} pages={pages} onClose={onClose} />
      </div>
    </Drawer>
  );
};

LoginSidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default LoginSidebar;
