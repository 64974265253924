import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import CustomerSays from "./CustomerSays";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
  },
  howItsWorkRoot: {
    // backgroundImage: "linear-gradient(to bottom,#FFF7F8 50%,white 50%)",
    // background: "#FFF7F8",
    // minHeight: "900px",
    position: "relative",
    height: "600px",
  },
  howItsWorkPrimaryTitle: {
    fontSize: "32px",
    color: "#FFFFFF",
    fontWeight: "600",
    textAlign: "center",
    paddingTop: "20px",
  },
  howItsWorkSubTitle: {
    background: "#FFFFFF",
    height: 2,
    margin: "20px auto",
    // marginTop: 40,
    width: "15%",
  },

  // card
  howItsWorkCardRoot: {
    position: "absolute",
    top: "250px",
    display: "flex",
    justifyContent: "center",
  },
  cardPossition: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  howItsWorkCard: {
    height: "400px",
    width: "300px",
    // boxShadow: "4px 8px 22px rgba(189, 188, 188, 0.4)",
    borderRadius: "20px",
    // background: "white",

    zIndex: 1000,
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  howItsWorkCard2: {
    height: "400px",
    width: "300px",
    boxShadow: "4px 8px 22px rgba(189, 188, 188, 0.4)",
    borderRadius: "20px",
    background: "white",
    [theme.breakpoints.up("md")]: {
      marginTop: "80px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardCenter: {
    display: "flex",
    justifyContent: "center",
  },
  dormmateLandingCardImage: {},
  // dormmateLandingCardText: {
  soulmateLandingCardTitle: {
    fontSize: "20px",
    fontWeight: 600,
    marginTop: "20px",
    textAlign: "center",
  },
  soulmateLandingCardText: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: "20px",
    textAlign: "center",
  },
  propertyImage: {
    width: "100%",
    filter: "brightness(0.5)",
    height: '600px',
    // [theme.breakpoints.down("xs")]: {
    //   height: "105vh",
    // },
  },
  propertyImageLabel: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
    width: "100%",
    height: "100%",
  },
  userListHedding: {
    fontSize: "30px",
    color: "#FE4B6B",
    fontWeight: "500",
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "30px",
  },
  userList: {
    textAlign: "center",
  },
  userPicture: {
    borderRadius: "50%",
    height: "150px",
    width: "150px",
  },
  userName: {
    fontSize: "16px",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px",
  },
  App: {
    textAlign: "center",
    height: "100vh",
  },

  container: {
    padding: "2.5rem 0",
  },
}));

const TopMember = (props) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} className={classes.howItsWorkRoot}>
            <img
              src="/images/matchUp/soulmateLanding1.jpg"
              alt="soulmateLanding"
              className={classes.propertyImage}
            />
            <div className={classes.propertyImageLabel}>
              <div className={classes.howItsWorkSubTitle}></div>
              <div className={classes.howItsWorkPrimaryTitle}>Top Members</div>
              {/* <Grid
                container
                style={{
                  height: "80%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {list.map((obj) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      className={classes.userList}
                    >
                      <img
                        src={obj.profile_picture}
                        alt="propertyLanding1"
                        className={classes.userPicture}
                      />
                      <div className={classes.userName}>{obj.name}</div>
                    </Grid>
                  );
                })}
              </Grid> */}
              <CustomerSays />
              <Grid container>
                {/* <div>
                  <div>
                    <ImageSlider images={IMAGES} slidesToShow={5} />
                  </div>
                </div> */}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TopMember;
