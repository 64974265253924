import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Badge,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Popover,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TelegramIcon from "@material-ui/icons/Telegram";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { Link as browserLink, useLocation } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Checkbox from '@material-ui/core/Checkbox';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import ReactFancyBox from "react-fancybox";
import useRouter from 'utils/useRouter';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BlockIcon from '@material-ui/icons/Block';
import MuiAlert from '@material-ui/lab/Alert';
import Api from '../../Helper/ApiHandler';
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import firebase from 'firebase/app';
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import _ from 'lodash'
import * as Scroll from 'react-scroll';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ClearChatDialog from "components/ClearChatModel/ClearChatModel";
import ExitGroupModel from "components/ExitGroupModel/ExitGroupModel";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character"
    }
  },
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    minHeight: "calc(100vh - 125px)",
    // marginBottom: "60px",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    [theme.breakpoints.only('md')]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only('lg')]: {
      width: "28%",
    },
    [theme.breakpoints.down('xl')]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.only('md')]: {
      width: "66.67%",
    },
    [theme.breakpoints.only('lg')]: {
      width: "72%",
    },
    [theme.breakpoints.only('xl')]: {
      width: "72%",
    },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up('md')]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px"
  },
  inboxRoot: {
    display: "flex",
    padding: "5px",
    cursor: "pointer"
  },
  inboxImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover"
  },
  inboxNameRoot: {
    marginLeft: "10px"
  },
  inboxName: {
    fontSize: "14px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxLastMsg: {
    fontSize: "11px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxTime: {
    fontSize: "11px",
    fontWeight: "400",
  },
  inboxCountRoot: {
    "& .MuiBadge-badge": {
      fontSize: "10px",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: "auto"
    },
    marginLeft: "auto",
    marginRight: "10px"
  },
  groupEditMsgRoot: {
    display: "flex",
    justifyContent: "center"
  },
  groupEditMsg: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#FFF7F8",
    borderRadius: "5px",
    padding: "3px",
    margin: "3px",
    color: "#F24451"
  },

  myMessageRoot: {
    display: "flex",
    justifyContent: "flex-end"
  },
  myMessage: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px 0px",
    padding: "10px",
    margin: "3px",
    color: "#3F444F",
    maxWidth: "50%",
    display: "flex"
  },
  myMessageTime: {
    fontSize: "10px",
    fontWeight: "400",
    minWidth: "45px",
    marginLeft: "10px",
    alignSelf: "flex-end",
    color: "#A2A2AB",
    position: "relative"
  },
  myDoubleTick: {
    fontSize: "16px",
    bottom: "0px",
    position: "absolute",
    marginLeft: "5px"
  },

  yourMessageRoot: {
    display: "flex",
    justifyContent: "flex-start"
  },
  yourMessage: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px 0px",
    padding: "10px",
    margin: "3px",
    color: "#3F444F",
    maxWidth: "50%",
  },
  yourMessageTime: {
    fontSize: "10px",
    fontWeight: "400",
    minWidth: "25px",
    marginLeft: "10px",
    alignSelf: "flex-end",
    color: "#A2A2AB",
  },
  yourName: {
    color: "#E84A62",
    fontSize: "12px",
    textTransform: "capitalize",
    marginBottom: "3px",
    fontWeight: "500"
  },

  mainInputMessage: {
    width: "98%",
    marginLeft: "10px",
    marginRight: "10px"
  },
  iconSendBg: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    borderRadius: "100%",
    width: "35px",
    height: "35px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "white",
    cursor: "pointer"
  },
  iconSendBgSmallDevice: {
    background: "#FF695B",
    borderRadius: "100%",
    width: "34px",
    height: "34px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    marginTop: "2px",
  },
  inputBoxWrite: {
    background: "white",
    width: "100%",
    height: "60px",
    '& .MuiOutlinedInput-root': {
      borderRadius: "50px"
    },
    display: "flex",
    alignItems: "center",
    borderRadius: "0px 0px 10px 10px",
    borderTop: "1px solid #d9d9d9"
  },
  menuRoot: {
    marginRight: "10px",
  },
  menu: {
    cursor: "pointer",
    "& .MuiPaper-rounded": {
      borderRadius: "10px"
    }
  },
  typographyMenu: {
    fontSize: "14px",
    padding: "5px"
  },
  exitnote: {
    color: "#E84A62",
    fontSize: "12px",
    width: "350px",
    margin: "auto",
    textAlign: "center",
  }
}));

const GroupChat = (props) => {
  const classes = useStyles();
  const router = useRouter();
  var scroll = Scroll.animateScroll;
  const listInnerRef = useRef();


  const [lstInbox, setLstInbox] = useState([])
  const [lstTmpInbox, setLstTmpInbox] = useState([])
  const [lstTmpMessage, setLstTmpMessage] = useState([])
  const [senderId, setSenderId] = useState("")
  const [userName, setUserName] = useState("")
  const [chatType, setChatType] = useState("")
  const [openClearChat, setOpenClearChat] = useState(false)
  const [openExitGroup, setopenExitGroup] = useState(false)

  const [activityRef, setActivityRef] = useState("")
  const [clearAt, setClearAt] = useState(null)
  const [exitAt, setExitAt] = useState(null)
  const [lastSeenMsg, setLastSeenMsg] = useState(null)

  const [message, setMessage] = useState("");
  const [objSelectedGroup, setObjSelectedGroup] = useState({
    name: "",
    no_of_members: "",
    profile_picture: "",
    slug: "",
    groupId: ""
  });
  const [objSelectedUser, setObjSelectedUser] = useState({
    name: "",
    profile_picture: "",
    slug: "",
    userId: ""
  });


  const [sessionMessage, setSessionMessage] = useState({
    message: '',
    type: '',
    open: false
  });
  let query = useLocation();
  const receiverId = query.pathname.split("/")[2]

  const db = firebase.firestore();
  // menu start
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openMenu = Boolean(anchorEl);
  const idMenu = openMenu ? 'simple-popover' : undefined;
  // menu end

  const onOpenClearChat = (receiverId) => {
    let tmp = db.collection("message").doc(senderId).collection("activity").doc(receiverId)
    setOpenClearChat(true)
    setActivityRef(tmp)
    handleMenuClose()
  }

  const onCloseClearChat = () => {
    setOpenClearChat(false)
  }

  const onCloseExitGroup = () => {
    setopenExitGroup(false)
  }

  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  function timeAgo(time) {
    moment.updateLocale('en', {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: number => number + "s ago",
        ss: '%ds ago',
        m: "1m ago",
        mm: "%dm ago",
        h: "1h ago",
        hh: "%dh ago",
        d: "1d ago",
        dd: "%dd ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago"
      }
    });

    let secondsElapsed = moment().diff(time, 'seconds');
    let dayStart = moment("2018-01-01").startOf('day').seconds(secondsElapsed);

    if (secondsElapsed > 300) {
      return moment(time).fromNow(true);
    } else if (secondsElapsed < 60) {
      return dayStart.format('s') + ' sec ago';
    } else {
      return dayStart.format('m:ss') + 'min ago';
    }
  }

  const hasMoreMessageEnd = () => {
    // fetch message for group
    let start = lstTmpMessage[lstTmpMessage.length - 1]?.createdAt ? lstTmpMessage[lstTmpMessage.length - 1]?.createdAt : new Date()

    let messageRef = db.collection("message").doc(receiverId).collection("messages").orderBy("createdAt", "asc").limit(5).startAt(start)
    messageRef.onSnapshot((querySnapshot) => {
      let temp = [];
      querySnapshot.forEach((mainDoc) => {
        temp.push(mainDoc.data())

        // seen message
        let lstReadBy = mainDoc.data().readBy;
        let lstNewReadBy = lstReadBy?.includes(senderId) ? lstReadBy : [...lstReadBy, senderId];
        // console.log("!lstReadBy?.includes(senderId)1")
        if (!lstReadBy?.includes(senderId)) {
          // console.log("!lstReadBy?.includes(senderId)2")
          let msgRefSeen = db.collection("message").doc(receiverId).collection("messages").doc(mainDoc.data().messageId);
          // console.log("!lstReadBy?.includes(senderId)3")
          msgRefSeen.set({ ...mainDoc.data(), readBy: lstNewReadBy })
        }
      });
      console.log(temp, "msgtemp---")
      // temp.length && setLstTmpMessage(temp)
      let lstTmpOld = [...lstTmpMessage]
      let lstTmp = temp.filter((obj) => obj?.messageId != lstTmpMessage[lstTmpMessage.length - 1]?.messageId)
      temp.length && setLstTmpMessage(_.concat(lstTmpOld, lstTmp))
    });
    let scr = document.getElementById('chat')
    scr && scr.scrollTo && scr.scrollTo('0', scr.scrollHeight)
    console.log("Callmsgtemp---")
  }

  const hasMoreMessageStart = async () => {
    // fetch message for group
    let start = lstTmpMessage[0] && lstTmpMessage[0].createdAt
    console.log(lstTmpMessage[0], "lstTmpMessage[0]")
    let receiverId = await AsyncLocalStorage.getItem("receiverId")

    // if (start) {
    //   let messageRef = exitAt ?
    //     db.collection("message").doc(receiverId).collection("messages")
    //       .orderBy("createdAt", "desc").limit(5).startAt(start).where("createdAt", ">", clearAt).where("createdAt", "<", exitAt)
    //     :
    //     db.collection("message").doc(receiverId).collection("messages")
    //       .orderBy("createdAt", "desc").limit(5).startAt(start).where("createdAt", ">", clearAt)

    //   messageRef.onSnapshot((querySnapshot) => {
    //     let temp = [];
    //     querySnapshot.forEach((mainDoc) => {
    //       temp.push(mainDoc.data())

    //       // seen message
    //       let lstReadBy = mainDoc.data().readBy
    //       let lstNewReadBy = lstReadBy?.includes(senderId) ? lstReadBy : [...lstReadBy, senderId]
    //       console.log("!lstReadBy?.includes(senderId)1")
    //       if (!lstReadBy?.includes(senderId)) {
    //         console.log("!lstReadBy?.includes(senderId)2")
    //         let msgRefSeen = db.collection("message").doc(receiverId).collection("messages").doc(mainDoc.data().messageId)
    //         console.log("!lstReadBy?.includes(senderId)3")
    //         msgRefSeen.set({ ...mainDoc.data(), readBy: lstNewReadBy })
    //       }
    //     });
    //     let lstTmpOld = [...lstTmpMessage]
    //     let lstTmp = temp.filter((obj) => obj.messageId != lstTmpMessage[0].messageId)

    //     console.log(_.reverse(lstTmp), "msgtemp---")

    //     temp.length && setLstTmpMessage(_.concat(lstTmp, lstTmpOld))
    //     lstTmp.length && scr.scrollTo('0', '50')
    //     console.log("Callmsgtemp---")
    //   });

    //   let scr = document.getElementById('chat')
    // }
  }
  console.log(listInnerRef, "listInnerRef")
  const onScroll = () => {
    // console.log(listInnerRef.current, "call")
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      console.log(listInnerRef.current, "call++")
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("reached bottom");
        hasMoreMessageEnd()
      }
      if (!scrollTop) {
        console.log("reached top");
        hasMoreMessageStart()
      }
    }
  };

  useEffect(() => {
    checkReciverID()
  }, []);

  let checkReciverID = async () => {
    let receiverId = await AsyncLocalStorage.getItem("receiverId")
    if (receiverId) {
      router.history.push(`/messages/${receiverId}`)
    }
  }

  useEffect(() => {
    // if (receiverId) {
    onload()
    // }
  }, [receiverId, clearAt]);

  useEffect(() => {
    onSetInbox()
  }, [lstTmpInbox]);

  let onSetInbox = async () => {

    console.log(lstTmpInbox, "mainobj")
    if (lstTmpInbox.length) {
      const lstTmp = [];

      const unresolved = lstTmpInbox.map(async (mainobj) => {
        console.log(mainobj, "mainobj")
        if (mainobj.type == "group") {
          var groupRef = db.collection("group").doc(mainobj.groupId);
          await groupRef.get().then((groupDoc) => {
            if (groupDoc.exists) {
              lstTmp.push({ ...groupDoc.data(), type: "group" })
            }
          }).catch((error) => {
            console.log("Error getting document:", error);
          })
        }
        else {
          var userRef = db.collection("user").doc(mainobj.receiverId)
          await userRef.get().then((userDoc) => {
            if (userDoc.exists) {
              lstTmp.push({ ...userDoc.data(), ...mainobj, type: "personal" })
              console.log("")
            }
          }).catch((error) => {
            console.log("Error getting document:", error);
          })

        }
      })
      await Promise.all(unresolved)

      console.log(lstTmp, "lstTmp++")
      setLstInbox(lstTmp)
    }
  }

  let onloadIndex = async () => {
    // get Inbox
    let inboxRef = db.collection("message").doc(senderId).collection("inbox")
    inboxRef.orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        let temp = [];
        querySnapshot.forEach((mainDoc) => {
          temp.push(mainDoc.data())
        });
        setLstTmpInbox(temp)
      });

  }

  let onload = async () => {
    let senderId = await AsyncLocalStorage.getItem("senderId")
    let user_name = await AsyncLocalStorage.getItem("userName")
    console.log(senderId, "senderId")

    if (senderId === "")
      return
    setSenderId(senderId)
    setUserName(user_name)


    // get Inbox
    let inboxRef = db.collection("message").doc(senderId).collection("inbox")
    inboxRef.orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        let temp = [];
        querySnapshot.forEach((mainDoc) => {
          temp.push(mainDoc.data())
          console.log(mainDoc.data(), "mainDoc.data()")
        });
        console.log(temp, "tmp---")
        setLstTmpInbox(temp)
      });


    let chatType = await AsyncLocalStorage.getItem("chat_type")
    setChatType(chatType)
    if (chatType == "group") {

      let receiverName = await AsyncLocalStorage.getItem("receiverName")
      let no_of_members = await AsyncLocalStorage.getItem("no_of_members")
      let receiverProfilePicuture = await AsyncLocalStorage.getItem("receiverProfilePicuture")
      let to_slug = await AsyncLocalStorage.getItem("to_slug")
      let receiverId = await AsyncLocalStorage.getItem("receiverId")
      let lstMember = await AsyncLocalStorage.getItem("group_members")

      setObjSelectedGroup({
        name: receiverName,
        no_of_members: no_of_members,
        profile_picture: receiverProfilePicuture,
        slug: to_slug,
        groupId: receiverId,
        members: JSON.parse(lstMember)
      })

      let startMessageRef = exitAt ?
        db.collection("message").doc(receiverId).collection("messages")
          .orderBy("createdAt", "desc")
          .where("createdAt", ">", clearAt)
          .where("createdAt", "<", exitAt)
        :
        db.collection("message").doc(receiverId).collection("messages")
          .orderBy("createdAt", "desc")
          .where("createdAt", ">", clearAt)

      startMessageRef.onSnapshot((querySnapshot) => {
        let temp = [];
        querySnapshot.forEach((mainDoc) => {
          temp.push(mainDoc.data())
        });

        let unseenedMsg = _.filter(temp, function (obj) {
          if (obj.readBy) {
            return !obj.readBy.includes(senderId)
          }
        });

        let lstSeenMsg = _.sortBy(unseenedMsg, (e) => e.createdAt)[0]?.createdAt

        console.log(temp, "lstSeenMsg")
        setLastSeenMsg(lstSeenMsg)
      });

      // cleareAt get 
      var groupRef = db.collection("message").doc(senderId).collection("activity").doc(receiverId);

      groupRef.onSnapshot((doc) => {
        if (doc.data()) {
          if (exitAt) {
            if (exitAt?.seconds != doc.data().exitAt.seconds) {
              setExitAt(doc.data().exitAt)
            }
          } else {
            if (doc.data().exitAt) {
              setExitAt(doc.data().exitAt)
            }
          }

          if (clearAt) {
            if (clearAt?.seconds != doc.data().clearAt.seconds) {
              setClearAt(doc.data().clearAt)
            }
          } else {
            setClearAt(doc.data().clearAt)
          }
        }
      });
      console.log(lastSeenMsg, "lastSeenMsg")
      if (!lstTmpMessage.length) {
        if (lastSeenMsg) {
          // fetch message for group
          let messageRef = exitAt ?
            db.collection("message").doc(receiverId).collection("messages")
              .orderBy("createdAt", "desc").limit(15).where("createdAt", ">", clearAt).where("createdAt", "<", exitAt)
            :
            db.collection("message").doc(receiverId).collection("messages")
              .orderBy("createdAt", "desc").limit(15).where("createdAt", ">", clearAt)

          // messageRef.orderBy("createdAt", "desc").limit(15)
          messageRef.onSnapshot((querySnapshot) => {
            let temp = [];
            querySnapshot.forEach((mainDoc) => {
              temp.push(mainDoc.data())

              // seen message
              let lstReadBy = mainDoc.data().readBy
              let lstNewReadBy = lstReadBy?.includes(senderId) ? lstReadBy : [...lstReadBy, senderId]
              console.log("!lstReadBy?.includes(senderId)1")
              if (!lstReadBy?.includes(senderId)) {
                console.log("!lstReadBy?.includes(senderId)2")
                let msgRefSeen = db.collection("message").doc(receiverId).collection("messages").doc(mainDoc.data().messageId)
                console.log("!lstReadBy?.includes(senderId)3")
                msgRefSeen.set({ ...mainDoc.data(), readBy: lstNewReadBy })
              }
            });
            console.log(temp, "msgtemp---")
            temp.length ? setLstTmpMessage(_.reverse(temp)) : setLstTmpMessage([])
            console.log("Callmsgtemp---")
            let scr = document.getElementById('chat')
            scr && scr.scrollTo && scr.scrollTo('0', scr.scrollHeight)
            // temp.length ? setLstTmpMessage(temp) : setLstTmpMessage([])
          });


        } else {
          // fetch message for group
          let messageRef = exitAt ?
            db.collection("message").doc(receiverId).collection("messages")
              .orderBy("createdAt", "desc").limit(15).where("createdAt", ">", clearAt).where("createdAt", "<", exitAt)
            :
            db.collection("message").doc(receiverId).collection("messages")
              .orderBy("createdAt", "desc").limit(15).where("createdAt", ">", clearAt)

          // messageRef.orderBy("createdAt", "asc").limit(15)
          messageRef.onSnapshot((querySnapshot) => {
            let temp = [];
            querySnapshot.forEach((mainDoc) => {
              temp.push(mainDoc.data())

              // let lstReadBy = mainDoc.data().readBy
              // let lstNewReadBy = lstReadBy?.includes(senderId) ? lstReadBy : [...lstReadBy, senderId]
              // let msgRefSeen = db.collection("message").doc(receiverId).collection("messages").doc(mainDoc.data().messageId)
              // msgRefSeen.set({ ...mainDoc.data(), readBy: lstNewReadBy })
            });
            console.log(temp, "msgtemp---")
            temp.length ? setLstTmpMessage(_.reverse(temp)) : setLstTmpMessage([])
            let scr = document.getElementById('chat')
            console.log(scr, "scr+++")
            scr && scr.scrollTo && scr.scrollTo('0', scr.scrollHeight)
            console.log("Callmsgtemp---")
            // temp.length ? setLstTmpMessage(temp) : setLstTmpMessage([])
          });
          // hasMoreMessageStart()
        }
      }
      console.log(listInnerRef, "listInnerRef")
    } else {
      console.log("temp")
      let receiverName = await AsyncLocalStorage.getItem("receiverName")
      let receiverProfilePicuture = await AsyncLocalStorage.getItem("receiverProfilePicuture")
      let to_slug = await AsyncLocalStorage.getItem("to_slug")
      let receiverId = await AsyncLocalStorage.getItem("receiverId")
      console.log(receiverId, "receiverId")
      setObjSelectedUser({
        name: receiverName,
        profile_picture: receiverProfilePicuture,
        slug: to_slug,
        userId: receiverId,
      })
      if (receiverId) {
        console.log("call--")
        // fetch message for personal
        let messageRef = db.collection("message").doc(senderId).collection(receiverId)
        messageRef.orderBy("createdAt", "asc").limit(10)
          .onSnapshot((querySnapshot) => {
            let temp = [];
            querySnapshot.forEach((mainDoc) => {
              temp.push(mainDoc.data())
            });
            console.log(temp, "msgtemp---")
            // temp.length ? setLstTmpMessage(_.reverse(temp)) : setLstTmpMessage([])
            temp.length ? setLstTmpMessage(temp) : setLstTmpMessage([])
          });
      }

      // // get Inbox
      // let inboxRef = db.collection("message").doc(senderId).collection("inbox")
      // inboxRef.orderBy("createdAt", "desc")
      //   .onSnapshot((querySnapshot) => {
      //     let temp = [];
      //     querySnapshot.forEach((mainDoc) => {
      //       temp.push(mainDoc.data())
      //     });
      //     console.log(temp, "temp")
      //     setLstInbox(temp)
      //   });
    }
  }

  const onInboxClick = async (name, profile_picture, id, receiverId, slug, members) => {
    AsyncLocalStorage.setItem("receiverName", name)
    AsyncLocalStorage.setItem("receiverProfilePicuture", profile_picture)
    AsyncLocalStorage.setItem("receiverId", id)
    AsyncLocalStorage.setItem("to_slug", slug)
    AsyncLocalStorage.setItem("no_of_members", members.length)
    AsyncLocalStorage.setItem("chat_type", "group")
    AsyncLocalStorage.setItem("group_members", JSON.stringify(members))

    // setNameChat(name);
    router.history.push(`/messages/${receiverId}`)
    await onload()
  };

  const onInboxPresonalClick = async (name, profile_picture, id, receiverId, slug) => {
    console.log("call---")
    // objUser.fullName, objUser.imageUrl, objUser.id, objUser.receiverId, objUser.slug
    AsyncLocalStorage.setItem("receiverName", name)
    AsyncLocalStorage.setItem("receiverProfilePicuture", profile_picture)
    AsyncLocalStorage.setItem("receiverId", receiverId)
    AsyncLocalStorage.setItem("to_slug", slug)
    AsyncLocalStorage.setItem("to_id", id)
    AsyncLocalStorage.setItem("chat_type", "personal")
    setLstTmpMessage([])

    // setNameChat(name);
    router.history.push(`/messages/${receiverId}`)
    await onload()
  };

  let onClickGroupInbox = async (objGroup) => {
    setLstTmpMessage([])
    onInboxClick(objGroup.name, objGroup.group_profile_pic, objGroup.id, objGroup.id, objGroup.slug, objGroup.members)
  }

  let onClickPersonalInbox = async (objUser) => {
    {/* archive: false
                              count: 0
                              createdAt: t {seconds: 1661236028, nanoseconds: 522000000}
                              displayName: "trump jems"
                              email: "trump@yopmail.com"
                              fullName: "trump jems"
                              groups: []
                              id: "70"
                              imageUrl: "https://dev-matchupmates.s3.amazonaws.com/images/profile/1648725013234_avatar_4.png"
                              mediaUrl: ""
                              messageText: "Hello"
                              photoURL: "https://dev-matchupmates.s3.amazonaws.com/images/profile/1648725013234_avatar_4.png"
                              receiverId: "habn17niNJP3yxjYH4FIg46xLfp2"
                              seen: false
                              senderId: "CxmFUYvReaP2OSd9n05DTzoDfbq1"
                              slug: "trum-428e"
                              type: "personal"
                              uid: "habn17niNJP3yxjYH4FIg46xLfp2" */}
    onInboxPresonalClick(objUser.fullName, objUser.imageUrl, objUser.id, objUser.receiverId, objUser.slug)
  }

  const sendMessageGroup = async (event) => {
    event.preventDefault();
    let userName = await AsyncLocalStorage.getItem("userName")
    var timestamp = new Date();
    let messageId = uuid()

    // add last message
    var groupRef = db.collection("group").doc(objSelectedGroup.groupId)
    groupRef.get().then((doc) => {
      if (doc.exists) {
        db.collection("group").doc(objSelectedGroup.groupId).set({
          ...doc.data(),
          messageText: message,
          recentMessage: {
            messageText: message,
            readBy: [senderId],
            sentAt: timestamp,
            sentBy: senderId
          }
        });
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    })

    // entery for add message 
    let messageObject = {
      createdAt: timestamp,
      createdBy: senderId,
      groupEdit: false,
      messageId: messageId,
      name: userName,
      textMessage: message,
      type: "group",
      readBy: [senderId]
    }

    db.collection("message").doc(objSelectedGroup.groupId).collection("messages").doc(messageId).set(messageObject);
    let lstUser = objSelectedGroup.members
    console.log(lstUser, "lstUser")

    const unresolved = lstUser.map(async (f_uid) => {
      await db.collection("message").doc(f_uid).collection("inbox").doc(objSelectedGroup.groupId).set({
        type: "group",
        createdAt: timestamp,
        createdBy: senderId,
        groupId: objSelectedGroup.groupId,
        messageText: message,
      });
    })

    // cleare message
    setMessage("")

    await Promise.all(unresolved)
    // Refrsh inbox 
    onloadIndex()
  }

  const sendMessagePersonal = async (event) => {
    event.preventDefault();

    if (message === '')
      return
    let receiverName = await AsyncLocalStorage.getItem("receiverName")
    let receiverProfilePicuture = await AsyncLocalStorage.getItem("receiverProfilePicuture")
    let senderId = await AsyncLocalStorage.getItem("senderId")
    let userProfilePicture = await AsyncLocalStorage.getItem("userProfile")
    let userName = await AsyncLocalStorage.getItem("userName")
    let to_id = await AsyncLocalStorage.getItem("to_id")
    let from_id = await AsyncLocalStorage.getItem("from_id")
    let from_slug = await AsyncLocalStorage.getItem("from_slug")
    let to_slug = await AsyncLocalStorage.getItem("to_slug")
    let uuid1 = uuid()

    var timestamp = new Date();

    let sendMessageData = {
      createdAt: timestamp,
      messageId: uuid1,
      textMessage: message.trim(),
      type: "personal",
      senderId: senderId,
      receiverId: receiverId,
      // mediaUrl: "",
      // fileName: "",
      seen: false,
    }
    db.collection("message").doc(senderId).collection(receiverId).doc(uuid1).set(sendMessageData);
    db.collection("message").doc(receiverId).collection(senderId).doc(uuid1).set(sendMessageData);

    // Inbox
    let senderInbox = {
      createdAt: timestamp,
      messageText: message.trim(),
      type: "text",
      mediaUrl: "",
      senderId: receiverId,
      receiverId: senderId,
      seen: false,
      archive: false,
      fullName: userName,
      imageUrl: userProfilePicture,
      // status: requestStatus,
      id: from_id,
      slug: from_slug,
      // count: receiverCount
    }


    let receiverInbox = {
      messageText: message.trim(),
      mediaUrl: "",
      senderId: senderId,
      receiverId: receiverId,
      seen: false,
      type: "text",
      createdAt: timestamp,
      archive: false,
      fullName: receiverName,
      imageUrl: receiverProfilePicuture,
      // status: requestStatus,
      id: to_id,
      slug: to_slug,
      count: 0
    }

    db.collection("message").doc(senderId).collection("inbox").doc(receiverId).set(receiverInbox);
    db.collection("message").doc(receiverId).collection("inbox").doc(senderId).set(senderInbox);
    // AsyncLocalStorage.setItem("count", parseInt(receiverCount) + 1)
    setMessage("")

  }

  const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const onExitGroup = (slug) => {

  }


  console.log(exitAt, "exitAt")
  console.log(lstInbox, "lstInbox")
  console.log(lstTmpMessage, "lstTmpMessage")
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item style={{ marginTop: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={4} lg={4} xl={4}>
                  <div style={{ backgroundColor: "white", borderRadius: "10px", height: "calc(100vh - 260px)" }}>
                    <div style={{ width: "100%", backgroundColor: "white", fontSize: "20px", fontWeight: "600", padding: "18px", borderRadius: "10px 10px 0px 0px" }}>
                      Message
                    </div>

                    <div style={{ backgroundColor: "white", borderRadius: "0px 0px 10px 10px" }}>
                      {/* Inbox */}
                      {lstInbox.map((obj) => {
                        console.log(obj, "obj")
                        if (obj.type == "group") {
                          return (
                            <>
                              <Divider />
                              <div className={classes.inboxRoot} onClick={(e) => { onClickGroupInbox(obj) }}>
                                <div>
                                  <img src={obj.group_profile_pic ? obj.group_profile_pic : '/images/male_defualt.svg'} alt="Img" className={classes.inboxImage} />
                                </div>
                                <div className={classes.inboxNameRoot}>
                                  <div className={classes.inboxName}>{obj.name}</div>
                                  <div className={classes.inboxLastMsg}>{obj?.messageText}</div>
                                </div>

                                <div className={classes.inboxCountRoot}>
                                  <div className={classes.inboxTime}>
                                    {moment(new Date(obj?.recentMessage?.sentAt * 1000)).format("HH:mm")}
                                    {/* {timeAgo(new Date(obj?.recentMessage?.sentAt?.seconds * 1000))} */}
                                  </div>
                                  <Badge badgeContent={10} color="primary"></Badge>
                                </div>
                              </div>
                            </>
                          )
                        } else {
                          return (
                            <>
                              <Divider />
                              <div className={classes.inboxRoot} onClick={(e) => { onClickPersonalInbox(obj) }}>
                                <div>
                                  <img src={obj.photoURL ? obj.photoURL : '/images/male_defualt.svg'} alt="Img" className={classes.inboxImage} />
                                </div>
                                <div className={classes.inboxNameRoot}>
                                  <div className={classes.inboxName}>{obj.displayName}</div>
                                  <div className={classes.inboxLastMsg}>{obj.messageText}</div>
                                </div>

                                <div className={classes.inboxCountRoot}>
                                  <div className={classes.inboxTime}>
                                    {moment(new Date(obj.createdAt * 1000)).format("HH:mm")}
                                  </div>
                                  <Badge badgeContent={10} color="primary"></Badge>
                                </div>
                              </div>
                            </>
                          )
                        }
                      })}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={8} lg={8} xl={8}>
                  <div style={{ width: "100%", backgroundColor: "white", borderRadius: "10px 10px 0px 0px", height: "calc(100vh - 320px)" }}>
                    {/* User/group heading */}
                    {chatType == "group" ?
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div
                          className={classes.inboxRoot}
                          style={{ padding: "10px", width: "fit-content" }}
                          onClick={() => { router.history.push(`/groupDetail/${objSelectedGroup.slug}`) }}
                        >
                          <div>
                            <img src={objSelectedGroup.profile_picture ? objSelectedGroup.profile_picture : '/images/male_defualt.svg'} alt="Img" className={classes.inboxImage} />
                          </div>
                          <div className={classes.inboxNameRoot}>
                            <div className={classes.inboxName}>{objSelectedGroup.name}</div>
                            <div className={classes.inboxLastMsg}>{objSelectedGroup.no_of_members} members</div>
                          </div>
                        </div>

                        <div className={classes.menuRoot}>
                          <MoreVertIcon
                            onClick={(e) => {
                              if (!exitAt) {
                                handleClick(e)
                              }
                            }}
                            id={idMenu}
                            style={{ cursor: "pointer" }}
                          />
                          <Popover
                            id={idMenu}
                            open={openMenu}
                            anchorEl={anchorEl}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            style={{ padding: "10px", borderRadius: "10px" }}
                            className={classes.menu}
                          >
                            <div style={{ fontSize: "14px", padding: "10px", width: "200px" }}>
                              <Typography
                                className={classes.typographyMenu}
                                onClick={() => router.history.push(`/groupDetail/${objSelectedGroup.slug}`)}
                              >
                                Group Info
                              </Typography>
                              <Typography
                                className={classes.typographyMenu}
                                onClick={() => onOpenClearChat(objSelectedGroup.groupId)}
                              >
                                Clear chat
                              </Typography>
                              <Typography
                                className={classes.typographyMenu}
                                onClick={() => {
                                  router.history.push({
                                    pathname: `/shareGourp/${objSelectedGroup.slug}`,
                                    search: '',
                                    state: {
                                      objGroup: objSelectedGroup
                                    }
                                  })
                                }}
                              >
                                Share Group
                              </Typography>
                              <Divider />
                              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#F24451" }}>
                                <Typography className={classes.typographyMenu} onClick={() => { setopenExitGroup(true) }}>
                                  Exit Group
                                </Typography>
                                <ExitToAppIcon />
                              </div>
                            </div>
                          </Popover>

                        </div>
                      </div>
                      :
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                          className={classes.inboxRoot}
                          style={{ padding: "10px", width: "fit-content" }}
                          onClick={() => { router.history.push(`/your-matches/view/${objSelectedUser.slug}`) }}
                        >
                          <div>
                            <img src={objSelectedUser.profile_picture ? objSelectedUser.profile_picture : '/images/male_defualt.svg'} alt="Img" className={classes.inboxImage} />
                          </div>
                          <div className={classes.inboxNameRoot}>
                            <div className={classes.inboxName}>{objSelectedUser.name}</div>
                            <div className={classes.inboxLastMsg}>Tap for more info</div>
                          </div>
                        </div>
                        <div style={{ marginRight: "10px" }}>
                          <MoreVertIcon />
                        </div>
                      </div>
                    }
                    <Divider />
                    {/* message display */}
                    <div id="chat" style={{ padding: "20px", height: "calc(100vh - 390px)", overflow: "auto" }} onScroll={onScroll} ref={listInnerRef}>
                      {lstTmpMessage.map((obj, index) => {

                        // console.log(obj, "obj++")
                        if (obj.type == "group") {
                          if (obj.groupEdit == true) {
                            if (obj.createdBy == senderId) {
                              return (
                                <div key={index}>
                                  {/* {lastSeenMsg?.seconds == obj.createdAt.seconds &&
                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "15px 0px" }}>
                                      <Divider style={{ width: "40%" }} />
                                      <div style={{ margin: "0px 5px", fontSize: "12px" }}>
                                        New message
                                      </div>
                                      <Divider style={{ width: "40%" }} />
                                    </div>
                                  } */}
                                  <div className={classes.groupEditMsgRoot}>
                                    <div className={classes.groupEditMsg}>
                                      {obj.textMessage.replace(userName, "you")}
                                    </div>
                                  </div>
                                </div>
                              )
                            } else {
                              return (
                                <div key={index}>
                                  {/* {lastSeenMsg?.seconds == obj.createdAt.seconds &&
                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "15px 0px" }}>
                                      <Divider style={{ width: "40%" }} />
                                      <div style={{ margin: "0px 5px", fontSize: "12px" }}>
                                        New message
                                      </div>
                                      <Divider style={{ width: "40%" }} />
                                    </div>
                                  } */}
                                  <div className={classes.groupEditMsgRoot}>
                                    <div className={classes.groupEditMsg}>
                                      {/* {obj.textMessage} */}
                                      {obj.textMessage.replace(userName, "you")}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          } else {
                            if (obj.createdBy == senderId) {
                              return (
                                <div key={index}>
                                  {/* {lastSeenMsg?.seconds == obj.createdAt.seconds &&
                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "15px 0px" }}>
                                      <Divider style={{ width: "40%" }} />
                                      <div style={{ margin: "0px 5px", fontSize: "12px" }}>
                                        New message
                                      </div>
                                      <Divider style={{ width: "40%" }} />
                                    </div>
                                  } */}
                                  <div className={classes.myMessageRoot}>
                                    <div className={classes.myMessage}>
                                      <div>
                                        {obj.textMessage}
                                      </div>
                                      <div className={classes.myMessageTime}>
                                        {moment(new Date(obj.createdAt * 1000)).format("HH:mm")}
                                        {/* <DoneAllIcon className={classes.myDoubleTick} /> */}
                                        {obj.readBy.length >= objSelectedGroup.no_of_members ?
                                          <DoneAllIcon className={classes.myDoubleTick} />
                                          :
                                          <DoneIcon className={classes.myDoubleTick} />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            } else {
                              return (
                                <div key={index}>
                                  {/* {lastSeenMsg?.seconds == obj.createdAt.seconds &&
                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "15px 0px" }}>
                                      <Divider style={{ width: "40%" }} />
                                      <div style={{ margin: "0px 5px", fontSize: "12px" }}>
                                        New message
                                      </div>
                                      <Divider style={{ width: "40%" }} />
                                    </div>
                                  } */}
                                  <div className={classes.yourMessageRoot}>
                                    <div className={classes.yourMessage}>
                                      <div className={classes.yourName}>
                                        {obj.name}
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                          {obj.textMessage}
                                        </div>
                                        <div className={classes.yourMessageTime}> {moment(new Date(obj.createdAt * 1000)).format("HH:mm")}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }
                        } else {
                          if (obj.senderId == senderId) {
                            return (
                              <div className={classes.myMessageRoot} key={index}>
                                <div className={classes.myMessage}>
                                  <div>
                                    {obj.textMessage}
                                  </div>
                                  <div className={classes.myMessageTime}>
                                    {moment(new Date(obj.createdAt * 1000)).format("HH:mm")}
                                    <DoneAllIcon className={classes.myDoubleTick} />
                                    {/* {obj.readBy && obj.readBy.length >= objSelectedGroup.no_of_members ?
                                      <DoneAllIcon className={classes.myDoubleTick} />
                                      :
                                      <DoneIcon className={classes.myDoubleTick} />
                                    } */}
                                  </div>
                                </div>
                              </div>
                            )
                          } else {
                            return (
                              <div className={classes.yourMessageRoot} key={index}>
                                <div className={classes.yourMessage}>
                                  <div className={classes.yourName}>
                                    {obj.name}
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                      {obj.textMessage}
                                    </div>
                                    <div className={classes.yourMessageTime}> {moment(new Date(obj.createdAt * 1000)).format("HH:mm")}</div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        }
                      })}

                    </div>
                  </div>
                  <div className={classes.inputBoxWrite}>
                    {!exitAt ?
                      <Grid container style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Grid item xs={10} sm={10} md={10} lg={11} xl={11}>
                          <TextField
                            className={classes.mainInputMessage}
                            fullWidth
                            size="small"
                            placeholder="Message..."
                            variant="outlined"
                            value={message}
                            onChange={(e) => { setMessage(e.target.value) }}
                          />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <div className={classes.iconSendBg}>
                            <SendOutlinedIcon
                              type="submit"
                              onClick={(e) => {
                                if (chatType == "group") {
                                  sendMessageGroup(e)
                                } else {
                                  sendMessagePersonal(e)
                                }
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      :
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <Typography className={classes.exitnote}>
                          You can't send message to this group because you're no longer a participant
                        </Typography>
                      </div>
                    }
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <ClearChatDialog
          activityRef={activityRef}
          onClose={onCloseClearChat}
          open={openClearChat}
          onLoad={onload}
        />

        <ExitGroupModel
          setSessionMessage={setSessionMessage}
          slug={objSelectedGroup.slug}
          onClose={onCloseExitGroup}
          open={openExitGroup}
          onLoad={onload}
        />
      </div>
    </>
  );
};
export default GroupChat;
