import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import FilterModel from "../FilterModel";
import { Link } from "react-router-dom";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../../../Helper/ApiHandler";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import { useGeolocated } from "react-geolocated";
import useRouter from "utils/useRouter";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import UserSkeletonSolmateCard from "components/Skeleton/UserSkeletonSolmateCard";

var api = new Api();
const lstGender = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
  { id: "intersex", value: "Intersex" },
  { id: "trans", value: "Trans" },
  { id: "noPreference", value: "No Preference" },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // backgroundColor: "E9EDF0 !important",
    minHeight: "90vh",
    background: "#E9EDF0",
    padding: "35px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("xs")]: {
      height: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "210px",
    },
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "500",
  },
  searchInput: {
    width: "290px",
    marginRight: "10px",
    background: "#E9EDF0",
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "14px",
    },
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
    // [theme.breakpoints.down("sm")]: {
    //   width: "70%",
    // },
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    width: "100%",
    minWidth: "fit-content",
    textAlign: "center",
    // marginTop: "35px",
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "#E9EDF0",
    boxShadow: "none",
    fontWeight: "500",
    height: "35px",
  },
  filterIconBtn: {
    // marginLeft: "10px",
  },
  listBg: {
    background: "#E9EDF0",
    paddingLeft: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  matchesHead: {
    margin: "0",
    // padding: "0px 25px",
    // paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  secondContainer: {
    height: 60,
    alignItems: "center",
    marginTop: 12,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      marginTop: 0,
    },
  },
  cardUser: {
    background: "white",
    padding: "25px 10px",
    paddingBottom: "10px",
    textAlign: "center",
    margin: "7px",
    borderRadius: "15px",
    height: "260px",
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400",
  },
  // textCityName: {
  //   marginTop: "5px",
  //   color: "#6C6C6C",
  //   fontSize: "14px",
  //   maxHeight: "40px",
  //   minHeight: "22px",
  //   width: "100%",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  //   whiteSpace: "nowrap"
  // },
  chipUserDetail: {
    whiteSpace: "nowrap",
    width: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0",
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGrid: {
    // paddingBottom: "60px",
    // padding: "4px 10px 10px 10px",
    // paddingLeft: 10,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 5,
    },
  },
  imageMatches: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  filterSearchBtn: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: "300",
  },
  menuItemSelect: {
    //fontFamily: "Gilroy !important",
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      fontSize: "12px",
    },
    backgroundColor: "#e9edf0",
    border: "1px solid #afafaf",
    borderRadius: "5px",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },

  solRoot: {
    backgroundColor: "white",
    height: "300px",
    width: "100%",
    borderRadius: "10px",
    position: "relative",
    "&:hover ": {
      "& .hide": {
        display: "none",
      },
      "& .hover": {
        display: "block",
      },
    },
  },
  solImage: {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
    objectFit: "cover",
    objectPosition: "top",
  },
  solBottomBorder: {
    position: "absolute",
    // background: "red",
    height: "45px",
    width: "100%",
    bottom: "0",
    borderRadius: "0px 0px 10px 10px",
    backdropFilter: "blur(5px)",
    backgroundColor: "#00000030",
    display: "flex",

    // padding: "10px"
  },
  solBottomBorderName: {
    color: "white",
    paddingLeft: "10px",
    width: "100%",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
  },
  checkRootLabel: {
    margin: 0,
  },
  solBottomHover: {
    position: "absolute",
    height: "170px",
    width: "100%",
    bottom: "0",
    borderRadius: "0px 0px 10px 10px",
    backdropFilter: "blur(5px)",
    display: "none",
    backgroundColor: "#00000030",

    // justifyContent: "space-between",
    // alignItems: "center",
  },
  solBottomHoverName: {
    color: "white",
    fontSize: "18px",
    paddingLeft: "10px",
    paddingBottom: "10px",
    display: "flex",
  },
  solBottomHoverSubTital: {
    color: "white",
    fontSize: "13px",
    paddingLeft: "10px",
  },
  solBottomHoverFooter: {
    height: "50px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    // cursor: "not-allowed"
  },
  newFilterTop: {
    display: "flex",
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
    alignItems: "center",
    // padding: "0px 10px"
  },
  filterRoot: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  hoverName: {
    // width: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  carosalImageBox: {
    height: "100%",
    width: "100%",
    margin: "auto",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "15px",
    // [theme.breakpoints.down("xs")]: {
    //   width: 80,
    //   height: 80,
    // },
  },
}));

const MatchesList = (props) => {
  const { sessionPropsMessage, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [loadingScroll, setLoadingScroll] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const [matches, setMatches] = React.useState([]);
  const [search, setSearch] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(
    props.sessionPropsMessage
  );
  const [lstUniversity, setLstUniversity] = React.useState([]);
  const [items, setItems] = React.useState(Array.from({ length: 20 }));
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setrowsPerPage] = React.useState(12);
  const [totalRecored, setTotalRecored] = React.useState(0);
  let [over, setOver] = React.useState(false);
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    // userDecisionTimeout: 5000,
  });

  const fetchMoreMatches = () => {
    if (matches.length >= totalRecored) {
      setHasMore(false);
      return;
    }

    // setTimeout(() => {
    // setItems(items.concat(Array.from({ length: 20 })))
    setPage(page + 1);
    // onMore()
    // }, 500);
  };

  useEffect(() => {
    if (page !== 0) onMore();
  }, [page]);

  const fetchMoreData = () => {
    if (items.length >= 500) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setItems(items.concat(Array.from({ length: 20 })));
    }, 500);
  };

  const handleClickOpenSignUp = () => {
    setFilterOpen(true);
  };

  // for filter change
  const onFilterChange = (value, key) => {
    if (key === "s") {
      setPage(0);
    }
    let ftr = { ...filter };
    ftr[key] = value;
    setFilter(ftr);
  };

  const setMaltiFilter = (obj) => {
    let ftr = { ...filter };
    Object.keys(obj).forEach(function (key) {
      ftr[key] = obj[key];
    });
    setFilter(ftr);
    setPage(0);
  };

  useEffect(() => {
    if (coords) {
      console.log("filter, coords", filter, coords);
      onLoad();
    }
  }, [filter, coords]);

  useEffect(() => {
    setPage(0);
    getUniversity();
    onCheckIsQuiz();
  }, []);

  const onMessageClick = (
    firebase_uid,
    userName,
    profile_picture,
    user_id,
    slug
  ) => {
    api
      .get(`user/soulmate/getmessagecount`)
      .then((result) => {
        if (result.success === "true") {
          // // Api Call For Fetch The Our Matches
          api
            .get(`user/addmessagetosoulmate/${slug}`)
            .then((result) => {
              if (result.success === "true") {
                router.history.push(`/messages/${firebase_uid}`);
                AsyncLocalStorage.setItem("receiverName", userName);
                AsyncLocalStorage.setItem(
                  "receiverProfilePicuture",
                  profile_picture
                );
                AsyncLocalStorage.setItem("request_status", "pending");
                AsyncLocalStorage.setItem("to_id", user_id);
                AsyncLocalStorage.setItem("to_slug", slug);
              } else {
                console.log(result, "error");
              }
            })
            .catch((err) => {
              console.log(err, "catch error");
            });
        } else {
          router.history.push(`/subscription-plan`);
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
    // userconver: {status: "pending", request_id: null}
    // user/addmessage
  };

  useEffect(() => {
    onLiveLatLngUpdate();
  }, [coords]);

  const onLiveLatLngUpdate = () => {
    if (coords) {
      api
        .post(`user/soulmate/updatelivelatlong`, {
          data: {
            location: "null",
            latitude: coords.latitude,
            longitude: coords.longitude,
          },
        })
        .then((result) => {
          if (result.success === "true") {
            console.log(result, "success");
          } else {
            console.log(result, "error");
          }
        })
        .catch((err) => {
          console.log(err, "catch error");
        });
    }
  };

  const onCheckIsQuiz = () => {
    api
      .get(`user/getquizstatus`)
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data.quiz, "success");
          if (!result.data.quiz) {
            router.history.push("/matching-quiz");
          }
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "catch error");
      });
  };
  const onLoad = () => {
    console.log("onload");
    if (coords) {
      setLoading(true);
      let filterFinal = filter.radius ? filter : { ...filter, radius: 25 };
      // Api Call For Fetch The Our Matches
      // api.get(`user/yoursoulmatematch?latitude=undefined&longitude=undefined&page=${page}&size=${rowsPerPage}`, { params: filterFinal })
      api
        .get(
          `user/yoursoulmatematch?latitude=${coords?.latitude}&longitude=${coords?.longitude
          }&page=${0}&size=${rowsPerPage}`,
          { params: filterFinal }
        )
        .then((result) => {
          if (result.success === "true") {
            setMatches(result.data.user);
            setTotalRecored(result.data.totalItems);
            setLoading(false);
            setInitialLoading(false);
          } else {
            console.log(result, "error");
            setLoading(false);
            setInitialLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setInitialLoading(false);
          console.log(err, "catch error");
        });
    }
  };

  const onMore = () => {
    setLoadingScroll(true);
    // Api Call For Fetch The Our Matches
    let filterFinal = filter.radius ? filter : { ...filter, radius: 25 };
    // api.get(`user/yoursoulmatematch?latitude=undefined&longitude=undefined&page=${page}&size=${rowsPerPage}`, { params: filterFinal })
    api
      .get(
        `user/yoursoulmatematch?latitude=${coords?.latitude}&longitude=${coords?.longitude}&page=${page}&size=${rowsPerPage}`,
        { params: filterFinal }
      )
      .then((result) => {
        if (result.success === "true") {
          const lstTmp = matches.concat(result.data.user);
          setMatches(lstTmp);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
        }
        setLoadingScroll(false);
      })
      .catch((err) => {
        setLoadingScroll(false);
        console.log(err, "catch error");
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleAutocompletChange = (event, value, name) => {
    if (name === "u") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        let ftr = { ...filter };
        ftr["u"] = value.value;
        setFilter(ftr);
      }
    } else if (name === "g") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        let ftr = { ...filter };
        ftr["g"] = value.value.toLowerCase();
        setFilter(ftr);
      }
    }
    setPage(0);
  };
  // Get University
  const getUniversity = async (search) => {
    // Api Call For Fetch University List
    api
      .get(`universitylist?s=` + search)
      .then((result) => {
        if (result.success === "true") {
          setLstUniversity(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const changeOptionBaseOnValue = (e) => {
    getUniversity(e.target.value);
  };

  const addFav = (slug) => {
    api
      .post("user/addfavouritesoulmate", {
        data: { slug: slug, isFavourite: true },
      })
      .then((result) => {
        if (result.success === "true") {
          setPage(0);
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const getAge = (date) => {
    let fullYear = new Date().getFullYear();
    const myArray = date.split("-");
    return fullYear - parseInt(myArray[0]);
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}
        >
          <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.mainRoot}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid item className={classes.container}>
              <div className={classes.listBg}>
                {/* <Container maxWidth="lg"> */}
                {/* <Container maxWidth="md"> */}
                {/* <Box className={classes.newFilterTop}> */}

                {/* <Box className={classes.filterRoot}> */}
                {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}> */}
                <Grid spacing={1} container className={classes.secondContainer}>
                  <Grid item xs={12} sm={8} md={8} lg={8}>
                    <Box flexGrow={1}>
                      <h5 className={classes.matchesHead}> Your Matches </h5>
                    </Box>
                  </Grid>
                  <Grid item xs={10} sm={3} md={3} lg={3}>
                    <Box>
                      <form>
                        <Box>
                          {/* <p className={classes.labelSearch}> Gender </p> */}
                          <FormControl
                            size="small"
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <Autocomplete
                              options={lstGender}
                              // disableClearable={true}
                              clearOnEscape={true}
                              // value={getSelectedUniversityItem()}
                              onInputChange={(e) => {
                                if (e.target.value === "") {
                                  setPage(0);
                                  let ftr = { ...filter };
                                  ftr["g"] = "";
                                  setFilter(ftr);
                                }
                              }}
                              autoHighlight
                              getOptionLabel={(lstGender) => lstGender.value}
                              // fullWidth
                              name="gender"
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {props.value}
                                </Box>
                              )}
                              noOptionsText={"Type for search..."}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Gender"
                                  // autoFocus={false}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                  variant="outlined"
                                  fullWidth
                                  className={classes.selectOptionIn}
                                />
                              )}
                              onChange={(e, value, reason) => {
                                handleAutocompletChange(e, value, "g");
                                // onFilterChange(e.target.value, 'g')
                                if (reason === "clear") {
                                  setPage(0);
                                  let ftr = { ...filter };
                                  ftr["g"] = "";
                                  setFilter(ftr);
                                }
                              }}
                            />
                          </FormControl>
                        </Box>
                      </form>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={1} md={1} lg={1}>
                    <Box>
                      <Box className={classes.filterIconBtn}>
                        <Button
                          variant="outlined"
                          color="default"
                          className={classes.buttonFilter}
                          onClick={handleClickOpenSignUp}
                        // startIcon={}
                        >
                          {/* Refine */}
                          <img
                            src="/images/matchUp/filter.png"
                            alt={"Alt Image"}
                          />
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                {/* </div> */}
                {/* </Box> */}
                {/* </Box> */}
                {/* </Container> */}
                {/* <h5 className={classes.matchesHead}> Your Matches </h5> */}
                <InfiniteScroll
                  dataLength={matches.length}
                  // next={fetchMoreData}
                  next={fetchMoreMatches}
                  hasMore={true}
                  style={{ overflow: "hidden", paddingTop: 3 }}
                  loader={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "95%",
                        margin: "10px",
                      }}
                    ></div>
                  }
                  endMessage={<p style={{ paddingBottom: "60px" }}></p>}
                >
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    className={classes.cardBoxGrid}
                    container
                  >
                    <Grid
                      justifyContent="center"
                      alignItems="center"
                      className={classes.cardBoxGrid}
                      container
                      spacing={2}
                    >
                      {initialLoading ? (
                        <SkeletonLoading />
                      ) : (
                        <>
                          {!loading ? (
                            <>
                              {matches.length > 0 ? (
                                matches.map((item, index) => {
                                  return (
                                    <Grid
                                      key={`item_${index}`}
                                      item
                                      xs={12}
                                      sm={6}
                                      xl={3}
                                      lg={3}
                                      className={classes.cardMatched}
                                    >
                                      <div
                                        className={classes.solRoot}
                                        onMouseOver={() => setOver(true)}
                                        onMouseOut={() => setOver(false)}
                                      >
                                        <div>
                                          <Box
                                            position="absolute"
                                            top={10}
                                            right={10}
                                          >
                                            <CircularProgress
                                              variant="static"
                                              value={100}
                                              size={35}
                                              thickness={4}
                                              className={classes.bottom}
                                            />
                                            <CircularProgress
                                              variant="static"
                                              initialAnimation={true}
                                              // value={80}
                                              value={
                                                item.matchPercent ||
                                                  item.matchPercent !== "NaN"
                                                  ? item.matchPercent
                                                  : 0
                                              }
                                              size={35}
                                              thickness={4}
                                              className={classes.top}
                                              style={{
                                                transform: "rotate(270deg)",
                                              }}
                                            />
                                            <Box
                                              top={0}
                                              left={0}
                                              bottom={5}
                                              right={0}
                                              position="absolute"
                                              display="flex"
                                              alignItems="center"
                                              justifyContent="center"
                                            >
                                              <Typography
                                                variant="caption"
                                                component="div"
                                                color="textSecondary"
                                                style={{
                                                  fontSize: "10px",
                                                  color: "white",
                                                }}
                                              >
                                                {item.matchPercent &&
                                                  item.matchPercent !== "NaN"
                                                  ? item.matchPercent
                                                  : 0}
                                                %{/* {80}% */}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </div>
                                        {/* <img src="/images/matchUp/sol1.jpg" className={classes.solImage} /> */}
                                        <Link
                                          style={{ textDecoration: "none" }}
                                          to={`/your-matches/view/${item.slug}`}
                                        >
                                          <div
                                            className={classes.carosalImageBox}
                                          >
                                            <img
                                              src={
                                                item.profile_picture
                                                  ? item.profile_picture
                                                  : "/images/male_defualt.svg"
                                              }
                                              alt="profile_picture"
                                              className={classes.solImage}
                                            />
                                          </div>
                                          {/* <img
                                            src={
                                              item.profile_picture
                                                ? item.profile_picture
                                                : "/images/male_defualt.svg"
                                            }
                                            alt={"Alt Image"}
                                            className={classes.solImage}
                                          /> */}
                                        </Link>
                                        {/* {over ? */}
                                        {/* // hover  */}
                                        <div
                                          className={`${classes.solBottomHover} hover`}
                                        >
                                          <Link
                                            style={{ textDecoration: "none" }}
                                            to={`/your-matches/view/${item.slug}`}
                                          >
                                            <div
                                              className={
                                                classes.solBottomHoverName
                                              }
                                            >
                                              <div
                                                className={classes.hoverName}
                                              >
                                                {item.first_name}
                                                {"  "}
                                                {item.last_name},
                                              </div>
                                              <div>
                                                {getAge(item.date_of_birth)}
                                              </div>
                                            </div>
                                            <div
                                              className={
                                                classes.solBottomHoverSubTital
                                              }
                                            >
                                              <Tooltip
                                                title={item.location}
                                                placement="top"
                                                clas
                                              >
                                                <div
                                                  style={{
                                                    paddingBottom: "10px",
                                                    width: "90%",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  Lives in {item.location}
                                                </div>
                                              </Tooltip>
                                              <div
                                                style={{
                                                  paddingBottom: "10px",
                                                  width: "100%",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                }}
                                              >
                                                Work as a {item.occupation}
                                              </div>
                                              <div
                                                style={{
                                                  paddingBottom: "10px",
                                                }}
                                              >
                                                {item.nation}
                                              </div>
                                            </div>
                                          </Link>
                                          <Divider
                                            style={{ background: "white" }}
                                          />
                                          <div
                                            className={
                                              classes.solBottomHoverFooter
                                            }
                                          >
                                            <div
                                              style={{
                                                width: "50%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <img
                                                src="/images/matchUp/solMessage.svg"
                                                onClick={(e) => {
                                                  console.log("call");
                                                  // firebase_uid, userName, profile_picture, user_id, slug
                                                  onMessageClick(
                                                    item.firebase_uid,
                                                    item.first_name +
                                                    item.last_name,
                                                    item.profile_picture,
                                                    item.user_id,
                                                    item.slug
                                                  );
                                                }}
                                                alt={"Alt Image"}
                                              />
                                            </div>
                                            <Divider
                                              orientation="vertical"
                                              flexItem
                                              style={{
                                                background: "white",
                                                margin: "5px",
                                                opacity: "30%",
                                              }}
                                            />
                                            <div
                                              style={{
                                                width: "50%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <FormControlLabel
                                                classes={{
                                                  root: classes.checkRootLabel,
                                                }}
                                                control={
                                                  <Checkbox
                                                    icon={
                                                      <FavoriteBorder
                                                        style={{
                                                          color: "white",
                                                        }}
                                                      />
                                                    }
                                                    checkedIcon={
                                                      <Favorite color="primary" />
                                                    }
                                                    name="checkedH"
                                                    onChange={(e) => {
                                                      addFav(item.slug);
                                                    }}
                                                  />
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* // : */}
                                        {/* //  over  */}
                                        <div
                                          className={`${classes.solBottomBorder} hide`}
                                        >
                                          <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <div
                                              className={
                                                classes.solBottomBorderName
                                              }
                                            >
                                              {item.first_name} {item.last_name}
                                              , {getAge(item.date_of_birth)}
                                            </div>
                                          </Box>
                                        </div>
                                        {/* // } */}
                                      </div>
                                    </Grid>
                                  );
                                })
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginTop: "50px"
                                  }}
                                >
                                  <img
                                    src="/images/matchUp/notFoundMatches.svg"
                                    alt="favAlt"
                                  />
                                  <Typography
                                    variant="caption"
                                    component="primary"
                                    color="primary"
                                    style={{
                                      fontSize: "20px",
                                      color: "black",
                                      fontWeight: "600",
                                    }}
                                  >
                                    No Matches Yet!
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    component="primary"
                                    color="primary"
                                    style={{
                                      fontSize: "14px",
                                      color: "gray",
                                      fontWeight: "400",
                                      textAlign: "center"
                                    }}
                                  >
                                    Mark your favorite profile and always have
                                    them here.
                                  </Typography>
                                </div>
                              )}
                            </>
                          ) : (
                            <SkeletonLoading />
                          )}
                        </>
                      )}
                      {loadingScroll && <SkeletonLoading />}
                    </Grid>
                  </Grid>
                </InfiniteScroll>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <FilterModel
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        setMaltiFilter={setMaltiFilter}
      />
    </>
  );
};
const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      // className={classes.cardBoxGrid}
      container
    >
      {[0, 1, 2, 3, 4, 5, 6, 7].map((obj) => {
        return (
          <Grid item xs={12} sm={6} xl={3} lg={3}>
            <UserSkeletonSolmateCard />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default MatchesList;
