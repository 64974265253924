import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRouter from "utils/useRouter";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {

  }
}));

const TextType = (props) => {
  const { ans, setAns, match } = props;
  const classes = useStyles();
  const router = useRouter();
  const [selectedValue, setSelectedValue] = React.useState();

  useEffect(() => {
    if (ans)
      setSelectedValue(ans)
    console.log(ans, "ans----");
  }, [ans]);

  return (
    <div className={classes.root}>
      <TextField
        size="small"
        className={classes.inputProfileEdit}
        InputLabelProps={{
          classes: {
            asterisk: classes.asterisk
          },
          shrink: true,
        }}
        required
        // label="Email"
        value={selectedValue || ""}
        onChange={(e) => {
          setAns([e.target.value])
          setSelectedValue(e.target.value)
        }}
        variant="outlined"
        fullWidth
      // disabled
      />
    </div>
  );
};

export default TextType;
