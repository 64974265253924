import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import Forgotpassword from "components/AuthModel/Forgotpassword";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
  },
  // part 1
  banefits1Root: {
    minHeight: "40vh",
  },
  banefits1Part1: {
    position: "relative",
  },
  banefitImg1: {
    zIndex: 1,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
  },
  banefitBox1: {
    position: "absolute",
    width: "330px",
    height: "280px",
    backgroundColor: "#FFF7F8",
    top: "150px",
    left: "160px",
    [theme.breakpoints.down("xs")]: {
      top: "0",
      left: "0",
    },
  },

  banefits1Part2: {
    zIndex: 0,
  },
  banefits1Part2Title1: {
    fontSize: "36px",
    color: "#FE4B6B",
    fontWeight: "600",
    // width: "670px",
  },
  banefits1Part2Title2: {
    fontSize: "14px",
    color: "#585858",
    fontWeight: "400",
    // width: "470px",
    marginTop: "20px",
  },
  buttonFind: {
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    borderRadius: "20px",
    color: "white",
    height: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
    marginTop: "20px",
  },

  // part 2
  banefits2Root: {
    minHeight: "600px",
  },
  banefits2Part1: {
    position: "relative",
  },
  banefitImg2: {
    zIndex: 1,
    position: "absolute",
    width: "430px",
    height: "370px",
    top: "0px",
    right: "0px",
  },
  banefitBox2: {
    position: "absolute",
    width: "330px",
    height: "280px",
    backgroundColor: "#FFF7F8",
    top: "-50px",
    left: "70px",
  },

  banefits2Part2: {},
  banefits2Part2Title1: {
    fontSize: "36px",
    color: "#FE4B6B",
    fontWeight: "600",
    // width: "670px",
  },
  banefits2Part2Title2: {
    fontSize: "14px",
    color: "#585858",
    fontWeight: "400",
    // width: "470px",
    marginTop: "20px",
  },
  images: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      marginTop: 15,
    },
  },
  gridRoot: {
    padding: "0 25px",
  },
}));

const DormateBanedits = (props) => {
  const classes = useStyles();
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onLoginOpen = () => {
    setLoginOpen(true);
    setForgotpasswordOpen(false);
    setOpen(false);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true);
  };

  const onSignupOpen = () => {
    setLoginOpen(false);
    setOpen(true);
  };

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
  };

  return (
    <>
      <div>
        <Grid
          container
          // spacing={2}
          justify="start"
          style={{ height: "100%", marginTop: 10 }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={4}
            xl={3}
            className={classes.imageList}
          >
            <img
              className={classes.images}
              src="/images/matchUp/dormmateLandingBanefit1.webp"
              alt="TeammatesGridImage"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={4}
            className={classes.gridRoot}
          >
            <div className={classes.banefits1Part2Title1}>
              Our DormMate service is designed for your convenience.
            </div>
            <div className={classes.banefits1Part2Title2}>
              Find people who are looking for dormmates with similar interests
              and connect with them.
            </div>
            <div className={classes.banefits1Part2Title2}>
              It’s where you do your best work. Write your tests and maintain
              JavaScript API and syntax that you are familiar with, so you can
              get productive quickly.
            </div>
            <Button
              variant="contained"
              className={classes.buttonFind}
              onClick={handleClickOpenLogin}
            >
              Find Your Dormmates
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          // spacing={2}
          justify="flex-end"
          style={{ height: "100%", margin: "100px 0" }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={4}
            className={classes.gridRoot}
          >
            <div className={classes.banefits2Part2Title1}>
              Discover more suitable dream space on MatchUpMates
            </div>
            <div className={classes.banefits2Part2Title2}>
              Our AI uses Smart Locators to elaluate hundred of attribites and
              give you visibility into which attribites are used as identifiers,
              how they are weighted and the power to change it.
            </div>
            <div className={classes.banefits2Part2Title2}>
              It’s where you do your best work. Write your tests and maintain
              JavaScript API and syntax that ybou are familiar with, so you can
              get productive quickly.
            </div>
            <Button
              variant="contained"
              className={classes.buttonFind}
              onClick={handleClickOpenLogin}
            >
              Find Your Dormmates
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={4}
            xl={3}
            className={classes.imageList}
          >
            <img
              className={classes.images}
              src="/images/matchUp/dormmateLandingBanefit2.webp"
              alt="TeammatesGridImage"
            />
          </Grid>

          <SignupModel
            open={open}
            setOpen={setOpen}
            setOpenLogin={onLoginOpen}
          />
          <LoginModel
            loginOpen={loginOpen}
            setLoginOpen={setLoginOpen}
            setOpenSignUp={onSignupOpen}
            setForgotpasswordOpen={onForgotpasswordOpen}
          />
          <Forgotpassword
            loginOpen={forgotpasswordOpen}
            setLoginOpen={onLoginOpen}
            setOpenSignUp={onSignupOpen}
          />
        </Grid>
      </div>
    </>
  );
};

export default DormateBanedits;
