import React from "react";
import FavMatchesTeamList from "./components/FavMatchesTeamList";

const FavMatchesTeam = (props) => {
  const { sessionPropsMessage } = (props.location && props.location.state) || {};
  return (
    <>
      <FavMatchesTeamList sessionPropsMessage={sessionPropsMessage} />
    </>
  );
};

export default FavMatchesTeam;
