import React from "react";
import MatchesList from "./components/MatchesList";

const Matches = (props) => {
  const { sessionPropsMessage } = (props.location && props.location.state) || {};
  return (
    <>
      <MatchesList sessionPropsMessage={sessionPropsMessage} />
    </>
  );
};

export default Matches;
