import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Hidden,
  List,
  IconButton,
  Button,
  Divider,
  withStyles,
  Menu,
  MenuItem,
  Tooltip,
  Container,
  // Link,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import { Image } from "components/atoms";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import { Link, useLocation } from "react-router-dom";
import Forgotpassword from "components/AuthModel/Forgotpassword";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  flexGrow: {
    flexGrow: 1,

  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  toolbar: {
    // maxWidth: theme.layout.contentWidth,
    // width: "80%",
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('xs')]: {
      height: "70px",
    },
    // background: "#FFF7F8"
  },
  listItem: {
    cursor: "pointer",
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center",
  },
  listItemText: {
    flex: "0 0 auto",
    whiteSpace: "nowrap",
    textDecoration: "none",
    //fontFamily: "Gilroy !important",
    color: "black",
    fontSize: "14px",
    margin: "5px"
  },
  listItemButton: {
    whiteSpace: "nowrap",
  },
  iconButton: {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down('xs')]: {
      position: "absolute",
      right: 15,
      color: "black",
    },
  },
  logoContainer: {
    width: 100,
    height: "50px !important",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      height: "35 !important",
      textAlign: "left",
      // marginTop:"30px"
    },
  },
  logoImage: {
    width: "259.2px",
    height: "45px",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      // marginTop: "8px"
    },
    padding: "0px"
  },
  buttnGroup: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  signUpFormBg: {
    // background:
    //   "linear-gradient(54.75deg, #22346E 0%, #0071CE 100%, rgba(0, 113, 206, 0) 70.7%)",
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)',
    width: "100%",
    height: "550px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headingSignUp: {
    margin: "0!important",
    color: "white",
  },
  freeMessageUp: {
    textAlign: "center",
  },
  singUpMessage: {
    color: "#0071CE",
    textAlign: "center",
  },
  textFiledBox: {
    width: "90%",
    margin: "20px auto",
  },
  checkboxArea: {
    width: "50%",
    margin: "20px auto",
  },
  startedButtonBox: {
    textAlign: "center",
  },
  startedButton: {
    width: 300,
  },
  memberMessage: {
    textAlign: "center",
    marginTop: "30px",
  },
  socialIcon: {
    position: "absolute",
    right: "0",
  },
  topBarBtm: {
    //fontFamily: "Gilroy !important",
    color: "#FE4B6B",
    textDecoration: "none",
    textTransform: "capitalize",
    width: "120px",
    textDecoration: "none"
  },
  topBarBtmProperty: {
    //fontFamily: "Gilroy !important",
    color: "white",
    textDecoration: "none",
    textTransform: "capitalize",
    width: "120px",
    textDecoration: "none",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)"
  },
}));

const StyledMenu = withStyles({
  paper: {
    // border: "1px solid #d3d4d5",
    marginTop: "8px",
    borderRadius: "5px",
    boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)'
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // "&:focus": {
    //   backgroundColor: "#e5e5e5",
    //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //     color: "black",
    //   },
    // },
    // borderBottom: `1px solid #dedede`,
  },
}))(MenuItem);
const Topbar = (props) => {
  const { onSidebarOpen, pages, ...rest } = props;

  const classes = useStyles();
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onLoginOpen = () => {
    setLoginOpen(true)
    setForgotpasswordOpen(false)
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true)
  };

  const onSignupOpen = () => {
    setLoginOpen(false)
    setOpen(true);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleClickOpenSignUp = () => {
    setOpen(true);
  };

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
    handleClose()
  };
  let query = useLocation();
  const currantPath = query?.pathname
  return (
    <>
      <Toolbar disableGutters className={classes.toolbar} {...rest}>
        <Container maxWidth="lg">
          <Grid justifyContent="center" alignItems="center" container spacing={3}>
            <Grid justifyContent="center" alignItems="center" item xl={4} lg={4} md={4}>
              <div className={classes.logoContainer}>
                <a href="/" >
                  <Image
                    className={classes.logoImage}
                    src="/images/matchUp/topBarlogo.svg"
                    alt="thefront"
                    lazy={false}
                  // style={currantPath === "/" ? { color: "#FE4B6B" } : {}}
                  />
                </a>

              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4}>
              <div className={classes.flexGrow} />
              <Hidden smDown>
                <List className={classes.navigationContainer}>
                  <li>
                    <a
                      color="textSecondary"
                      className={classes.listItemText}
                      // component="a"
                      href="/"
                      style={currantPath === "/" ? { color: "#FE4B6B", borderBottom: "2px solid #FE4B6B" } : {}}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      color="textSecondary"
                      className={classes.listItemText}
                      href="/how-it-work"
                      style={currantPath === "/how-it-work" ? { color: "#FE4B6B", borderBottom: "2px solid #FE4B6B" } : {}}
                    >
                      How it Works
                    </a>
                  </li>
                  <li>
                    <a
                      color="textSecondary"
                      className={classes.listItemText}
                      href="/contact-us"
                      style={currantPath === "/contact-us" ? { color: "#FE4B6B", borderBottom: "2px solid #FE4B6B" } : {}}
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      color="textSecondary"
                      className={classes.listItemText}
                      href="/about-us"
                      style={currantPath === "/about-us" ? { color: "#FE4B6B", borderBottom: "2px solid #FE4B6B" } : {}}
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      color="textSecondary"
                      className={classes.listItemText}
                      href="/blog"
                      style={currantPath === "/blog" ? { color: "#FE4B6B", borderBottom: "2px solid #FE4B6B" } : {}}
                    >
                      Blog
                    </a>
                  </li>
                </List>
              </Hidden>
            </Grid>
            <Grid justifyContent="center" alignItems="center" item xl={4} lg={4} md={4}>
              <Hidden smDown>
                <div className={classes.buttnGroup}>
                  <div onClick={handleClick} style={{ display: "flex", color: "#FE4B6B", fontWeight: "600", cursor: "pointer" }}>
                    <div>
                      My Account
                    </div>
                    <ExpandMoreIcon />
                  </div>

                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    component="a"
                    target="blank"
                    className={classes.topBarBtmProperty}
                    onClick={() => {
                      window.location.replace(process.env.REACT_APP_PROPERTY_LOGIN);
                    }}
                    style={{ width: "175px", fontSize: "12px", paddingTop: "10px", margin: "10px" }}
                  >
                    Create a Listing
                  </Button>
                </div>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <StyledMenuItem>
                    <div
                      style={{ marginRight: "10px" }}
                      className={classes.topBarBtm}
                      onClick={handleClickOpenLogin}
                    >
                      Log in
                    </div>
                  </StyledMenuItem>
                  <Divider style={{ margin: "5px 10px" }} />
                  <StyledMenuItem>
                    <div
                      style={{ marginRight: "10px" }}
                      className={classes.topBarBtm}
                      onClick={handleClickOpenLogin}
                    >
                      Sign up
                    </div>
                  </StyledMenuItem>
                </StyledMenu>
              </Hidden>
            </Grid>
            <Hidden mdUp>
              <IconButton
                className={classes.iconButton}
                onClick={onSidebarOpen}
                aria-label="Menu"
              >
                <MenuIcon style={{ fontSize: "30px" }} />
              </IconButton>
            </Hidden>
          </Grid>
        </Container>
      </Toolbar>

      {/* signUp Form Dialoug  */}
      <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
      <LoginModel loginOpen={loginOpen} setLoginOpen={setLoginOpen} setOpenSignUp={onSignupOpen} setForgotpasswordOpen={onForgotpasswordOpen} />
      <Forgotpassword loginOpen={forgotpasswordOpen} setLoginOpen={onLoginOpen} setOpenSignUp={onSignupOpen} />
    </>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
};

export default Topbar;
