import React from "react";
import MatchesTeamList from "./components/MatchesTeamList";

const MatchesTeam = (props) => {
  const { sessionPropsMessage } = (props.location && props.location.state) || {};
  return (
    <>
      <MatchesTeamList sessionPropsMessage={sessionPropsMessage} />
    </>
  );
};

export default MatchesTeam;
