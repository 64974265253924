import React from 'react'
import MessageTheme from './component/MessageTheme'

const ChatTheme = () => {
    return (
        <>
          <MessageTheme /> 
        </>
    )
}

export default ChatTheme
