import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 10px 10px 10px",
    "& .MuiButton-root": {
      textTransform: "capitalize",
    },
  },
  dialogRoot: {
    padding: "10px",
    "& .MuiDialog-paper": {
      // overflow: "visible"
      borderRadius: "10px",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgb(0 0 0 / 52%)",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
  },
  btnSave: {
    borderRadius: "5px",
    backgroundImage:
      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white",
  },
  btnCancel: {
    padding: "5px 0",
    borderRadius: "5px",
  },
  flexWithPading: {
    display: "flex",
    justifyContent: "center",
    // padding: "10px",
  },
  flex: {
    height: 100,
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 500,
    textAlign: "center",
    // [theme.breakpoints.down("xs")]: {
    //   textAlign: "center",
    // },
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500,
  },
}));
function RemoveTeamUser(props) {
  const classes = useStyles();

  const {
    onClose,
    open,
    removeData,
    onClickRemoveUser,
    setOpen,
    onRemoveUserLoading,
  } = props;

  return (
    <Dialog
      className={classes.dialogRoot}
      onClose={onClose}
      maxWidth="xs"
      open={open}
    >
      <Grid container justify="center" style={{ padding: "20px" }}>
        <Grid item xs={12} className={classes.flex}>
          <img alt="Logo" src="/images/delete_model.svg" />
        </Grid>
        <Grid item xs={12} className={classes.flexWithPading}>
          <Typography component="h5" variant="h5" className={classes.title}>
            Are you sure you want to remove the {removeData?.first_name}{" "}
            {removeData?.last_name}?
          </Typography>
        </Grid>
        {/* <Grid item xs={12} className={classes.flex}>
          <Typography
            component="h6"
            variant="subtitle2"
            className={classes.subTitle}
          >
          </Typography>
        </Grid> */}

        <Grid
          container
          spacing={2}
          item
          xs={12}
          className={classes.dialogButtonHeader}
        >
          {/* <div className={classes.dialogButtonHeader}> */}
          <Grid item sm={5} xs={12}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                setOpen(false);
              }}
              className={classes.btnCancel}
            >
              No, Cancel
            </Button>
            &nbsp;&nbsp;
          </Grid>
          <Grid item sm={5} xs={12}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={onRemoveUserLoading}
              // onClick={() => {
              //   handleRetack();
              // }}
              onClick={(e) => {
                onClickRemoveUser(e, removeData?.user_id);
              }}
              className={classes.btnSave}
            >
              Yes
              {onRemoveUserLoading && (
                <>
                  &nbsp;
                  <CircularProgress
                    size={20}
                    style={{ color: "white", marginLeft: 10 }}
                  />
                </>
              )}
            </Button>
          </Grid>
          {/* </div> */}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default RemoveTeamUser;
