import React from 'react'
import GroupList from './component/GroupList';

const MyGroup = () => {
    return (
        <>
            <GroupList />
        </>
    )
}

export default MyGroup;
