import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Snackbar,
  TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import validate from "validate.js";
import { Helmet } from "react-helmet";
import Api from "../../../../Helper/ApiHandler";

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
  phone: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 15, message: "is too long (maximum is 15 digit)" },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "Enter only number",
    },
  },
  message: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    background: "#E5E5E5",
    padding: "50px 0",
    minHeight: "60vh",
    display: "flex",
    justifyContent: "center",
  },
  whitePaperReset: {
    background: "white",
    borderRadius: "10px",
    width: "875px",
    height: "475px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      margin: "0px 30px",
    },
  },
  headingSignUp: {
    margin: "0px 0px 0px 40px",
    color: "white",
    fontWeight: 700,
    fontSize: "30px !important",
  },
  signINMessage: {
    color: "white",
    margin: "40px 10px 40px 40px",
    marginTop: "",
    display: "flex",
    //fontFamily: "Gilroy !important",
  },
  signINMessageTextPrimary: {
    fontweight: 500,
    fontSize: "18px",
  },
  signINMessageTextSecondary: {
    fontweight: 400,
    fontSize: "14px",
    color: "white",
  },
  signUpFormBg: {
    // background:
    //   "linear-gradient(54.75deg, #22346E 0%, #0071CE 100%, rgba(0, 113, 206, 0) 70.7%)",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    width: "100%",
    height: "475px",
    position: "relative",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      // height: "250px",
      display: "none",
    },
    padding: "70px 5px",
  },
  dotImageTop: {
    position: "absolute",
    top: "0",
    left: "0",
  },
  dotImageBottom: {
    position: "absolute",
    bottom: "0",
    right: "0",
  },
  saveButton: {
    width: "220px",
    height: "40px",
    fontSize: "15px",
    borderRadius: "10px",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  },
  inputProfileEdit: {
    width: "90%",
    margin: "5px 15px",
    //fontFamily: "Gilroy !important",
  },
  headingReset: {
    padding: "15px !important",
    margin: "0",
    fontSize: "22px !important",
    fontWeight: "700 !important",
    textAlign: "center",
  },
  // imgaeGrid: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   [theme.breakpoints.down('sm')]: {
  //     borderBottom: "1px solid #b7b7b7"
  //   }
  // },
  bottonRoot: {
    padding: "30px 0px",
    display: "flex",
    justifyContent: "center",
  },
  formRoot: {
    padding: "20px 0px 0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  headingImage: {
    [theme.breakpoints.down("xs")]: {
      height: "170px",
    },
  },
  propertyImageRoot: {
    height: "400px",
    backgroundColor: "rgb(232 238 241)",
  },
}));

const ContactUsDetail = (props) => {
  const classes = useStyles();
  const [meta, setMeta] = React.useState({});
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  const handleFieldChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(() => {
    onLoadMeta();
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formState.isValid === true) {
      api
        .post(`user/contactus`, {
          data: formState.values,
          skipAuth: true,
        })
        .then((result) => {
          if (result.success === "true") {
            setSessionMessage({
              message: result.message,
              type: "success",
              open: true,
            });
            setFormState({
              isValid: false,
              values: {},
              touched: {},
              errors: {},
            });
            // router.push("/")
          } else {
            setSessionMessage({
              message: result.message,
              type: "error",
              open: true,
            });
          }
        })
        .catch((err) => {
          console.log(err, "error catch");
        });
    }
  };

  const onLoadMeta = () => {
    // For meta
    api
      .post("staticmetadata", {
        data: { type: "contactus" },
      })
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data, "result");
          setMeta(result.data, "result.data");
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };
  return (
    <>
      <Helmet>
        <title>Contact Us | MatchUpMates | Match, Chat, Meet</title>
        {/* <meta name="description" content={meta.meta_description} />
        <meta name="keywords" content={meta.meta_keyword} />

        <meta itemprop="name" content={meta.page_name} />
        <meta itemprop="description" content={meta.meta_description} />
        <meta itemprop="image" content={meta.meta_image} />

        <meta property="og:url" content="https://matchupmates.com" />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={meta.meta_title} />
        <meta property="og:description" content={meta.meta_description} />
        <meta property="og:image" content={meta.meta_image} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={meta.meta_title} />
        <meta name="twitter:description" content={meta.meta_description} />
        <meta name="twitter:image" content={meta.meta_image} /> */}
      </Helmet>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div>
        <div
          className={classes.propertyImageRoot}
          style={{
            backgroundImage: "url(/images/matchUp/contactBanner.webp)",
            position: "relative",
          }}
        >
          <Box
            position="absolute"
            height={"100%"}
            width={"100%"}
            style={{
              top: "0",
              backgroundColor: "#00000036",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                fontWeight: "500",
              }}
            >
              {" "}
              Contact Us{" "}
            </h1>
          </Box>
        </div>
        <div className={classes.cardRoot}>
          <Grid container className={classes.whitePaperReset}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.imgaeGrid}
            >
              <div className={classes.signUpFormBg}>
                <h3 className={classes.headingSignUp}>CONTACT US</h3>
                <div className={classes.hr}></div>
                {/* <div className={classes.signINMessage}>
                  <div>
                    <img src="/images/locationWhite.svg" alt="aa" /> &nbsp;
                  </div>
                  <div>
                    <div className={classes.signINMessageTextPrimary}>
                      Address
                    </div>
                    <div className={classes.signINMessageTextSecondary}>
                      Firs Cottage, Adams Road, Kirk Langley,
                      DE6 4LW
                    </div>
                  </div>
                </div> */}
                <div className={classes.signINMessage}>
                  <div>
                    <img src="/images/QuestionMark.svg" alt="aa" /> &nbsp;
                  </div>
                  <div>
                    <div className={classes.signINMessageTextPrimary}>
                      Need Help?
                    </div>
                    <Link
                      href={"mailto:support@matchupmates.com"}
                      className={classes.signINMessageTextSecondary}
                    >
                      support@matchupmates.com
                    </Link>
                  </div>
                </div>
                <img
                  className={classes.dotImageTop}
                  src="/images/matchUp/dotsLogin.png"
                  alt={"Alt Image"}
                // src={dotsLogin}
                />
                <img
                  className={classes.dotImageBottom}
                  src="/images/matchUp/dotsLogin.png"
                  alt={"Alt Image"}
                // src={dotsLogin}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              style={{ display: "flex" }}
            >
              <Divider orientation="vertical" />
              <div className={classes.formRoot}>
                <form onSubmit={(event) => handleSubmit(event)}>
                  {/* <h2 className={classes.headingReset}> Contact Us</h2> */}
                  <TextField
                    id="outlined-basic"
                    size="small"
                    className={classes.inputProfileEdit}
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                      shrink: true,
                    }}
                    required
                    error={hasError("name")}
                    helperText={
                      hasError("name") ? formState.errors.name[0] : null
                    }
                    label="Name"
                    name="name"
                    value={formState.values.name || ""}
                    onChange={handleFieldChange}
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    size="small"
                    className={classes.inputProfileEdit}
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                      shrink: true,
                    }}
                    required
                    error={hasError("phone")}
                    helperText={
                      hasError("phone") ? formState.errors.phone[0] : null
                    }
                    label="Phone"
                    name="phone"
                    value={formState.values.phone || ""}
                    onChange={handleFieldChange}
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    size="small"
                    className={classes.inputProfileEdit}
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                      shrink: true,
                    }}
                    required
                    error={hasError("email")}
                    helperText={
                      hasError("email") ? formState.errors.email[0] : null
                    }
                    label="Email"
                    name="email"
                    value={formState.values.email || ""}
                    onChange={handleFieldChange}
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    size="small"
                    className={classes.inputProfileEdit}
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                      shrink: true,
                    }}
                    required
                    multiline
                    rows={5}
                    error={hasError("message")}
                    helperText={
                      hasError("message") ? formState.errors.message[0] : null
                    }
                    label="Message"
                    name="message"
                    value={formState.values.message || ""}
                    onChange={handleFieldChange}
                    variant="outlined"
                    fullWidth
                  />
                  <div className={classes.bottonRoot}>
                    <Button
                      className={classes.saveButton}
                      variant="contained"
                      color="primary"
                      disabled={!formState.isValid}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ContactUsDetail;
