import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Box, Avatar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { withStyles } from "@material-ui/core/styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import RetackModel from "components/RetackModel/RetackModel";
import Api from "../../../../Helper/ApiHandler";
import AppDataContext from "Helper/AppDataContext";
import firebase from "firebase/app";
import useRouter from 'utils/useRouter';
import { isInteger } from "validate.js";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  root: {},
  flexGrow: {
    flexGrow: 1,
  },
  mainDivSideBar: {
    width: "344px",
    backgroundColor: "white !important",
    marginTop: "10px",
    marginBottom: "10px",
    height: "100%",
    borderRadius: "12px",
    [theme.breakpoints.between(960, 1000)]: {
      width: "300px",
    },
    [theme.breakpoints.between(1000, 1040)]: {
      width: "320px",
    },
    [theme.breakpoints.between(1040, 1080)]: {
      width: "330px",
    },
    [theme.breakpoints.between(1110, 1130)]: {
      width: "350px",
    },
    [theme.breakpoints.between(1130, 1160)]: {
      width: "360px",
    },
    [theme.breakpoints.between(1160, 1180)]: {
      width: "370px",
    },
    [theme.breakpoints.between(1180, 1220)]: {
      width: "380px",
    },
    [theme.breakpoints.between(1220, 1260)]: {
      width: "390px",
    },
    [theme.breakpoints.between(1260, 1280)]: {
      width: "400px",
    },
  },
  smallHeight: {
    // width: 300,
    backgroundColor: "white !important",
    // marginTop: "10px",
    marginBottom: "10px",
    height: "540px",
    // height: "595px",
    borderRadius: "5px",
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  toolbar: {
    // maxWidth: theme.layout.contentWidth,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    background: "#FE4B6B",
  },
  detailTopBar: {
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    height: "65px",
    justifyContent: "flex-end",
  },
  avtarImage: {
    marginLeft: "15px",
  },
  iconMessage: {
    color: "white",
    fontSize: "32px",
  },
  profileMenuImg: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "top",
  },
  nameHeadingMenu: {
    margin: "0",
    fontSize: "18px",
    // marginLeft: "10px",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  universitytext: {
    margin: "0",
    marginLeft: "10px",
    color: "gray",
  },
  editProfileLink: {
    margin: "0",
    marginLeft: "10px",
  },
  sidebarIcon: {
    opacity: 1,
  },
  sidebarIconOpacity: {
    opacity: 0.5,
  },
  linkMenu: {
    // textDecoration: "none",
    // color: "#00000042",
    textDecoration: "none",
    color: "black",
    // borderLeft: "3px solid #0071CE",
    fontSize: "13px",
    height: "45px",
  },
  linkMenuActive: {
    textDecoration: "none",
    color: "black",
    borderLeft: "2px solid #FE4B6B",
    height: "45px",
    fontSize: "13px",
  },
  name: {
    opacity: 0.5,
  },
  selsectedName: {
    opacity: 1,
  },
  menuRoot: {
    borderBottom: `1px solid black`,
    height: "60px",
    //fontFamily: "Gilroy !important",
    opacity: "0.8",
  },
  divider: {
    margin: "5px 20px 5px 20px",
  },
  // test:{
  //   fontFamily: "Gilroy !important"
  // },
  "& .MuiTypography-body1": {
    fontFamily: "Gilroy !important",
  },
  unreadNotification: {
    backgroundColor: "#FE4B6B",
    height: "18px",
    width: "18px",
    borderRadius: "50%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "11px",
  },
  fixedHeader: {
    position: "fixed",
    top: "0px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    marginTop: "8px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

// const MenuItem = withStyles((theme) => ({
//   root: {
//     // "&:focus": {
//     //   backgroundColor: "#e5e5e5",
//     //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
//     //     color: "black",
//     //   },
//     // },
//     borderBottom: `1px solid #dedede`,
//     height: "60px",
//     //fontFamily: "Gilroy !important",
//   },
// }))(MenuItem);

const ListMenuSideBar = (props) => {
  let router = useRouter();
  const { className, sidebar, onClose, ...rest } = props;
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [profile_picture, setProfile_picture] = React.useState(null);
  const [username, setUsername] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [unreadNotification, setUnreadNotification] = React.useState(0);
  const [msgCount, setMsgCount] = React.useState(0);
  const [currentModule, setCurrentModule] = React.useState("");
  const [fixed, setFixed] = React.useState(false);
  const db = firebase.firestore();

  const classes = useStyles();
  const history = useHistory();
  const handleRetackClose = () => {
    setOpen(false);
  };
  const onOpenSetRetack = () => {
    // onClose()
    setOpen(true);
  };
  console.log(history, "history");
  useEffect(() => {
    appContext?.isNewNotification && onLoad();
    appContext?.userProfile && setProfile_picture(appContext.userProfile);
    appContext?.userName && setUsername(appContext.userName);
  }, [appContext]);

  useEffect(() => {
    onLoad();
    messgaeCount();
  }, []);

  const messgaeCount = async () => {
    let senderId = await AsyncLocalStorage.getItem("senderId");
    let currantModule = await AsyncLocalStorage.getItem("currentModule");
    if (!senderId) {
      return;
    }
    let collectionName =
      currantModule === "Dormmates"
        ? "dormmatesChatRooms"
        : currantModule === "Roommates"
          ? "roommatesChatRooms"
          : "dormmatesChatRooms";

    const CollectionData = db.collection(collectionName);

    let inboxRef = CollectionData.doc(senderId).collection("inbox");
    inboxRef.orderBy("timestamp", "desc").onSnapshot((querySnapshot) => {
      let temp = [];
      let tempCount = 0;
      querySnapshot.forEach((doc) => {
        temp.push(doc.data());
        if (isInteger(doc.data().count))
          tempCount = tempCount + doc.data().count;
      });
      // console.log(temp, "temp")
      console.log(temp, "tempCount")
      setMsgCount(tempCount);
    });
  };

  const controlSidebar = async () => {
    if (window.scrollY > 160) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlSidebar);
    return () => {
      window.removeEventListener("scroll", controlSidebar);
    };
    //eslint-disable-next-line
  }, [window.scrollY]);

  const onLoad = async () => {
    let userProfile = await AsyncLocalStorage.getItem("userProfile");
    let Username = await AsyncLocalStorage.getItem("userName");
    const getcurrentModule = await AsyncLocalStorage.getItem("currentModule");

    setCurrentModule(getcurrentModule);
    setProfile_picture(userProfile);
    setUsername(Username);
    api
      .get(`user/unreadnotification`)
      .then((result) => {
        if (result.success === "true") {
          setUnreadNotification(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  function onSeeAll() {
    // Api Call For Read notification
    api
      .get(`user/readallnotification`)
      .then((result) => {
        if (result.success === "true") {
          setUnreadNotification(0);
          setAppContext({ ...appContext, isNewNotification: true });
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  }

  const logOutHandler = async () => {
    let device_token = await AsyncLocalStorage.getItem("device_token");
    let objData = { device_token: device_token ? device_token : "" };
    localStorage.clear();
    history.push("/");
    // {live-url}}api/user/logout
    api
      .post(`user/logout`, {
        data: objData,
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem(
            "device_token",
            device_token ? device_token : ""
          );
          // handleClose();
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  let query = useLocation();
  const currantPath = query.pathname;

  return (
    <Box className={fixed && classes.fixedHeader}>
      <div
        className={
          sidebar === "sidebar" ? classes.smallHeight : classes.mainDivSideBar
        }
      >
        <Link className={classes.linkMenu} onClick={onClose} to="/your-profile">
          <MenuItem style={{ paddingTop: "13px" }}>
            <ListItemIcon>
              <Avatar
                className={classes.profileMenuImg}
                src={
                  profile_picture != "undefined" && profile_picture !== "null"
                    ? profile_picture
                    : "/images/male_defualt.svg"
                }
                alt={"Alt Image"}
              />
              {/* <img
                className={classes.profileMenuImg}
                src={
                  profile_picture != "undefined" && profile_picture !== "null"
                    ? profile_picture
                    : "/images/male_defualt.svg"
                }
                alt={"Alt Image"}
              /> */}
            </ListItemIcon>
            <Grid>
              <h5 className={classes.nameHeadingMenu}> {username} </h5>
            </Grid>
          </MenuItem>
        </Link>
        <Divider style={{ margin: "5px 0px" }} />
        <Link className={classes.linkMenu} onClick={onClose} to="/your-matches">
          <MenuItem
            classes={{
              root:
                currantPath === "/your-matches"
                  ? classes.linkMenuActive
                  : classes.linkMenu,
            }}
          >
            <ListItemIcon>
              <img
                src="/images/matchUp/Handshake.svg"
                className={
                  currantPath === "/your-matches"
                    ? classes.sidebarIcon
                    : classes.sidebarIconOpacity
                }
                alt={"Alt Image"}
              />
            </ListItemIcon>
            {/* <ListItemText primary="Your Matches" /> */}
            <p
              className={{
                root:
                  currantPath === "/your-matches"
                    ? classes.selsectedName
                    : classes.name,
              }}
            >
              {" "}
              Your Matches
            </p>
          </MenuItem>
        </Link>
        <Divider className={classes.divider} />
        <Link className={classes.linkMenu} onClick={onClose} to="/my-favourite">
          <MenuItem
            classes={{
              root:
                currantPath === "/my-favourite"
                  ? classes.linkMenuActive
                  : classes.linkMenu,
            }}
          >
            <ListItemIcon>
              <img
                src="/images/matchUp/favouriteIcon.svg"
                className={
                  currantPath === "/my-favourite"
                    ? classes.sidebarIcon
                    : classes.sidebarIconOpacity
                }
                alt={"Alt Image"}
              />
            </ListItemIcon>
            {/* <ListItemText primary="My Favourite" /> */}
            <p
              className={{
                root:
                  currantPath === "/my-favourite"
                    ? classes.selsectedName
                    : classes.name,
              }}
            >
              {" "}
              My Favourite{" "}
            </p>
          </MenuItem>
        </Link>
        <Divider className={classes.divider} />
        {currentModule !== "Soulmates" ? (
          <>
            <Link className={classes.linkMenu} onClick={onClose} to="/my-group">
              <MenuItem
                classes={{
                  root:
                    currantPath === "/my-group"
                      ? classes.linkMenuActive
                      : classes.linkMenu,
                }}
              >
                <ListItemIcon>
                  <img
                    src="/images/matchUp/dormates.svg"
                    className={
                      currantPath === "/my-group"
                        ? classes.sidebarIcon
                        : classes.sidebarIconOpacity
                    }
                    alt={"Alt Image"}
                  />
                </ListItemIcon>
                {/* <ListItemText primary="My DormMates" /> */}
                <p
                  className={{
                    root:
                      currantPath === "/my-group"
                        ? classes.selsectedName
                        : classes.name,
                  }}
                >
                  {currentModule === "Roommates"
                    ? "My RoomMates"
                    : currentModule === "Dormmates"
                      ? "My DormMates"
                      : "My Teams"}{" "}
                </p>
              </MenuItem>
            </Link>
            <Divider className={classes.divider} />
          </>
        ) : (
          ""
        )}
        {/* <Link className={classes.linkMenu} onClick={onClose} to="/settings">
          <MenuItem classes={{ root: currantPath === "/settings" ? classes.linkMenuActive : classes.linkMenu }}>
            <ListItemIcon>
              <img
                src="/images/matchUp/dormates.svg"
                className={currantPath === "/settings" ? classes.sidebarIcon : classes.sidebarIconOpacity}
              />
            </ListItemIcon>
            <p className={{ root: currantPath === "/settings" ? classes.selsectedName : classes.name }}>My Block</p>
          </MenuItem>
        </Link>
        <Divider className={classes.divider} /> */}

        <Link className={classes.linkMenu} onClick={onClose} to="/your-profile">
          <MenuItem
            classes={{
              root:
                currantPath === "/your-profile"
                  ? classes.linkMenuActive
                  : classes.linkMenu,
            }}
          >
            <ListItemIcon>
              <img
                src="/images/matchUp/myProfile.svg"
                className={
                  currantPath === "/your-profile"
                    ? classes.sidebarIcon
                    : classes.sidebarIconOpacity
                }
                alt={"Alt Image"}
              />
            </ListItemIcon>
            {/* <ListItemText primary="My Profile" /> */}
            <p
              className={{
                root:
                  currantPath === "/your-profile"
                    ? classes.selsectedName
                    : classes.name,
              }}
            >
              My Profile
            </p>
          </MenuItem>
        </Link>
        <Divider className={classes.divider} />

        <Link className={classes.linkMenu} onClick={onClose} to={router.match.path == "/messages/:uid" ? router.match.url : "/messages"}>
          <MenuItem
            classes={{
              root:
                currantPath === "/messages"
                  ? classes.linkMenuActive
                  : classes.linkMenu,
            }}
          >
            <ListItemIcon>
              <img
                src="/images/matchUp/messages.svg"
                className={
                  currantPath === "/messages"
                    ? classes.sidebarIcon
                    : classes.sidebarIconOpacity
                }
                alt={"Alt Image"}
              />
            </ListItemIcon>
            {/* <ListItemText primary="Message" /> */}
            <p
              className={{
                root:
                  currantPath === "/messages"
                    ? classes.selsectedName
                    : classes.name,
              }}
            >
              Messages
            </p>
            &nbsp;&nbsp;
            {msgCount !== 0 && (
              <span className={classes.unreadNotification}>{msgCount}</span>
            )}
          </MenuItem>
        </Link>
        <Divider className={classes.divider} />
        {currentModule == "Roommates" ||
          currentModule == "Dormmates" ||
          currentModule == "Soulmates" ? (
          <>
            <Link
              className={classes.linkMenu}
              onClick={() => onOpenSetRetack()}
              to={currantPath}
            >
              <MenuItem
                classes={{
                  root:
                    currantPath === "/matching-quiz"
                      ? classes.linkMenuActive
                      : classes.linkMenu,
                }}
              >
                <ListItemIcon>
                  <img
                    src="/images/matchUp/retakeQuiz.svg"
                    className={
                      currantPath === "/matching-quiz"
                        ? classes.sidebarIcon
                        : classes.sidebarIconOpacity
                    }
                    alt={"Alt Image"}
                  />
                </ListItemIcon>
                {/* <ListItemText primary="Retake Matching Quiz" /> */}
                <p
                  className={{
                    root:
                      currantPath === "/matching-quiz"
                        ? classes.selsectedName
                        : classes.name,
                  }}
                >
                  Retake Matching Quiz
                </p>
              </MenuItem>
            </Link>
            <Divider className={classes.divider} />
          </>
        ) : (
          ""
        )}

        {currentModule == "Soulmates" && (
          <>
            <Link
              className={classes.linkMenu}
              onClick={onClose}
              to="/subscription-plan"
            >
              <MenuItem
                classes={{
                  root:
                    currantPath === "/subscription-plan"
                      ? classes.linkMenuActive
                      : classes.linkMenu,
                }}
              >
                <ListItemIcon>
                  <img
                    src="/images/matchUp/subscripation.svg"
                    className={
                      currantPath === "/subscription-plan"
                        ? classes.sidebarIcon
                        : classes.sidebarIconOpacity
                    }
                  />
                </ListItemIcon>
                <p
                  className={{
                    root:
                      currantPath === "/subscription-plan"
                        ? classes.selsectedName
                        : classes.name,
                  }}
                >
                  Subscription
                </p>
              </MenuItem>
            </Link>
            <Divider className={classes.divider} />
          </>
        )}
        <Link
          className={classes.linkMenu}
          onClick={onClose}
          to="/notifications"
        >
          <MenuItem
            classes={{
              root:
                currantPath === "/notifications"
                  ? classes.linkMenuActive
                  : classes.linkMenu,
            }}
            onClick={() => onSeeAll()}
          >
            <ListItemIcon>
              <img
                src="/images/matchUp/notification.svg"
                className={
                  currantPath === "/notifications"
                    ? classes.sidebarIcon
                    : classes.sidebarIconOpacity
                }
                alt={"Alt Image"}
              />
            </ListItemIcon>
            {/* <ListItemText primary="Subscription" /> */}
            <p
              className={{
                root:
                  currantPath === "/notifications"
                    ? classes.selsectedName
                    : classes.name,
              }}
            >
              Notifications
            </p>
            &nbsp;&nbsp;
            {unreadNotification !== 0 && (
              <span className={classes.unreadNotification}>
                {unreadNotification}
              </span>
            )}
          </MenuItem>
        </Link>
        <Divider className={classes.divider} />
        <Link className={classes.linkMenu} onClick={onClose} to="/settings">
          <MenuItem
            classes={{
              root:
                currantPath === "/settings"
                  ? classes.linkMenuActive
                  : classes.linkMenu,
            }}
          >
            <ListItemIcon>
              <img
                src="/images/matchUp/setting.svg"
                className={
                  currantPath === "/settings"
                    ? classes.sidebarIcon
                    : classes.sidebarIconOpacity
                }
                alt={"Alt Image"}
              />
            </ListItemIcon>
            {/* <ListItemText primary="My DormMates" /> */}
            <p
              className={{
                root:
                  currantPath === "/settings"
                    ? classes.selsectedName
                    : classes.name,
              }}
            >
              Settings
            </p>
          </MenuItem>
        </Link>
        <Divider className={classes.divider} />
        <div className={classes.linkMenu} onClick={() => logOutHandler()}>
          <MenuItem
            classes={{
              root: classes.linkMenu,
            }}
          >
            <ListItemIcon>
              <img
                src="/images/matchUp/logout.svg"
                className={classes.sidebarIconOpacity}
                alt={"Alt Image"}
              />
            </ListItemIcon>
            <p
              className={{
                root: classes.name,
              }}
            >
              Logout
            </p>
          </MenuItem>
        </div>
        <Box pb={1}></Box>
      </div>
      <RetackModel open={open} onClose={handleRetackClose} />
    </Box>
  );
};

export default ListMenuSideBar;
