import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Button, Container, Grid } from "@material-ui/core";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
  },
  howItsWorkRoot: {
    position: "relative",
  },
  howItsWorkPrimaryTitle: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30%",
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  howItsWorkSubTitle: {
    background: "#FFFFFF",
    height: 2,
    margin: "20px auto",
    // marginTop: 40,
    width: "15%",
  },

  // card
  howItsWorkCardRoot: {
    position: "absolute",
    top: "250px",
    display: "flex",
    justifyContent: "center",
  },
  cardPossition: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  howItsWorkCard: {
    height: "400px",
    width: "300px",
    // boxShadow: "4px 8px 22px rgba(189, 188, 188, 0.4)",
    borderRadius: "20px",
    // background: "white",

    zIndex: 1000,
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  howItsWorkCard2: {
    height: "400px",
    width: "300px",
    boxShadow: "4px 8px 22px rgba(189, 188, 188, 0.4)",
    borderRadius: "20px",
    background: "white",
    [theme.breakpoints.up("md")]: {
      marginTop: "80px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardCenter: {
    display: "flex",
    justifyContent: "center",
  },
  dormmateLandingCardImage: {},
  // dormmateLandingCardText: {
  soulmateLandingCardTitle: {
    fontSize: "20px",
    fontWeight: 600,
    marginTop: "20px",
    textAlign: "center",
  },
  soulmateLandingCardText: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: "20px",
    textAlign: "center",
  },
  propertyImage: {
    width: "100%",
    filter: "brightness(0.5)",
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
    },
  },
  propertyImageLabel: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
    width: "100%",
    height: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   top: "10px",
    // },
    // display: "flex",
    // justifyContent: "center",
    // left: "50%"
  },
  questionText: {
    fontSize: "32px",
    color: "#FFFFFF",
    fontWeight: "600",
  },
  learMoreDetailButton: {
    textTransform: "capitalize",
    width: "40%",
    height: "40px",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: 400,
    borderRadius: 25,
  },
}));

const TeammateLandingQuestion = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} className={classes.howItsWorkRoot}>
            <img
              src="/images/matchUp/TeammatesLanding1.jpg"
              alt="TeammatesLanding"
              className={classes.propertyImage}
            />
            <div className={classes.propertyImageLabel}>
              <div className={classes.howItsWorkPrimaryTitle}>
                <Grid container>
                  <Grid item xs={12}>
                    <h1 className={classes.questionText}>
                      Question about Teammate? Ask a Superhost
                    </h1>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      className={classes.learMoreDetailButton}
                      variant="contained"
                      // style={{ color: "#FFFFFF", width: "25%", height: 45 }}
                      // color="primary"
                      onClick={() => {
                        history.push("/faq");
                      }}
                    >
                      Learn More
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TeammateLandingQuestion;
