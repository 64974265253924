import React, { useEffect } from 'react'
import QuizQuestions from './component/SelecteUniversity'
import useRouter from "utils/useRouter";

const QuizMatching = (props) => {
    const { step3, sessionPropsMessage } = (props.location && props.location.state) || {};
    const router = useRouter();

    useEffect(() => {
        if (!step3) {
            router.history.push("/")
        }
    }, [step3]);

    return (
        <>
            <QuizQuestions sessionPropsMessage={sessionPropsMessage} />
        </>
    )
}

export default QuizMatching
