import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import useRouter from "utils/useRouter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 15px 0px",
  },
  addButton: {
    borderRadius: "10px",
    background: "linear-gradient(347.29deg, #E84A62 1.03%, #F4A360 113.2%)",
    color: "white",
    height: "38px",
    fontSize: "14px",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  searchInput: {
    // marginRight: "10px",
    background: "#E9EDF0",
    // [theme.breakpoints.down("xs")]: {
    //   width: "240px",
    // },
    "& .MuiOutlinedInput-notchedOutline": {
      // borderWidth: 0
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px",
    },
  },
  matchesHead: {
    margin: "0px !important",
  },
}));

function GroupSearchBar(props) {
  const { setSearch, heading } = props;
  const classes = useStyles();
  const router = useRouter();

  return (
    <div className={classes.root}>
      <Grid spacing={2} container style={{ alignItems: "center" }}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <div>
            <h5 className={classes.matchesHead}> {heading} </h5>
          </div>
        </Grid>
        <Grid container spacing={1} item xs={12} sm={8} md={8} lg={8}>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          > */}
          <Grid item xs={7} sm={8} md={8} lg={8}>
            <form
              onSubmit={(e) => e.preventDefault()}
              // className={classes.formControl}
            >
              <Box style={{ display: "flex" }}>
                <TextField
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  className={classes.searchInput}
                  fullWidth
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon
                          style={{ cursor: "pointer" }}
                          color="primary"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </form>
            {/* &nbsp;&nbsp;&nbsp; */}
          </Grid>
          <Grid item xs={5} sm={4} md={4} lg={4}>
            <Button
              variant="contained"
              color="default"
              className={classes.addButton}
              fullWidth
              startIcon={<AddIcon />}
              onClick={(e) => {
                router.history.push("/addNewGroupMemberNew");
              }}
            >
              New Group
            </Button>
          </Grid>
          {/* </div> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default GroupSearchBar;
