import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Container, Grid, TextField, Snackbar } from "@material-ui/core";
import { Helmet } from "react-helmet";
import MuiAlert from '@material-ui/lab/Alert';

import Api from '../../../../Helper/ApiHandler';
const fs = require('fs')
const path = require('path');


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


var api = new Api();
const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 }
  },
}

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
    // minHeight: "90vh"
  },
  aboutStep: {
    textAlign: "center",
    margin: "0",
    paddingTop: "25px",
    color: "white",
    zIndex: 1
    //fontFamily: "Gilroy !important",
  },
  contantAbout: {
    fontSize: "16px",
    color: "gray",
    textAlign: "justify",
    paddingBottom: "30px"
  },
  contantBoxAbout: {
    marginTop: "50px",
    // height:"380px",
    [theme.breakpoints.down('xs')]: {
      height: "auto",
    },
  },
  topPart: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    // height: "400px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  cardRoot: {
    width: "100%",
    color: "white",
    borderRadius: "10px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    margin: "50px",
    padding: "50px",
    [theme.breakpoints.down('xs')]: {
      margin: "20px",
      padding: "20px"
    }
  },
  cardRoot2: {
    marginTop: "10px",
  },
  topPartImage1: {
    width: "100%"
  }
}));

const UnsubscribeDetail = (props) => {
  const classes = useStyles();

  const [about, setAbout] = React.useState('');
  const [meta, setMeta] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false
  });

  useEffect(() => {
    // onLoad()
    // onLoadMeta()
  }, []);
  const hasError = (field) => (formState.errors[field] ? true : false);

  const handleFieldChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const onUnsubscribe = async (event) => {
    event.preventDefault();
    setDisabled(true)
    setLoading(true)
    api.post('user/unsubscribe', {
      data: formState.values,
      skipAuth: true
    })
      .then((result) => {
        if (result.success === "true") {
          setSessionMessage({
            message: result.message,
            type: 'success',
            open: true
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error")
          setSessionMessage({
            message: result.message,
            type: 'error',
            open: true
          });
        }
        setDisabled(false)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "error catch")
        setSessionMessage({
          message: err.message,
          type: 'error',
          open: true
        });
        // setLoading(false)
        setDisabled(false)
        setLoading(false)
      });
  }
  const onLoad = () => {
    setLoading(true)
    // Api Call For Fetch The About
    api.get(`user/cms/about1sp`)
      .then((result) => {
        if (result.success === "true") {
          setAbout(result.data.details)
        } else {
          console.log(result, "error")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "catch error")
        setLoading(false)
      });
  };

  // const onLoadMeta = () => {
  //   // For meta
  //   api.post('staticmetadata', {
  //     data: { type: "about" }
  //   })
  //     .then((result) => {
  //       if (result.success === "true") {
  //         console.log(result.data, "result")
  //         setMeta(result.data, 'result.data')
  //       } else {
  //         console.log(result, "success === false")
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "error catch")
  //     });
  // }

  const handleErorrClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}
        >
          <Alert
            onClose={handleErorrClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.mainRoot}>
        <div className={classes.topPart}>
          <img src="/images/matchUp/Unsubscribe.png" alt="Img" className={classes.topPartImage1} />
          {/* <img src="/images/matchUp/Unsubscribe.png" alt="" className={classes.topPartImage2} /> */}
          {/* <h1 className={classes.aboutStep}>
            Hi Purvik, do you wanna leave us and go, seriously?
          </h1> */}
        </div>
        <Container maxWidth="lg">
          <Grid container style={{ justifyContent: "center" }}>
            <Grid item xl={8} lg={8} xs={8} className={classes.cardRoot}>
              <div className={classes.cardRoot1}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk
                    },
                    shrink: true,
                  }}
                  required
                  error={hasError('email')}
                  helperText={hasError('email') ? formState.errors.email[0] : null}
                  className={classes.inputProfileEdit}
                  label="Email"
                  name="email"
                  value={formState.values.email || ""}
                  onChange={handleFieldChange}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div className={classes.cardRoot2}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)", textTransform: "capitalize" }}
                  onClick={(e) => {
                    onUnsubscribe(e)
                  }}
                  disabled={disabled}
                >
                  Unsubscribe
                  {loading && <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></>}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default UnsubscribeDetail;
