import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  Snackbar,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import TelegramIcon from "@material-ui/icons/Telegram";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { Link as browserLink, useLocation } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Checkbox from '@material-ui/core/Checkbox';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import ReactFancyBox from "react-fancybox";
import useRouter from 'utils/useRouter';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BlockIcon from '@material-ui/icons/Block';
import MuiAlert from '@material-ui/lab/Alert';
import Api from '../../Helper/ApiHandler';
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ExitGroupModel from "components/ExitGroupModel/ExitGroupModel";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #c4c4c4',
  },
  indicator: {
    backgroundColor: '#FE4B6B',
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize: "18px",
    fontWeight: "500px",
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#FE4B6B',
      opacity: 1,
    },
    '&$selected': {
      color: '#FE4B6B',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#FE4B6B',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    minHeight: "90vh",
    // marginBottom: "60px",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    [theme.breakpoints.only('md')]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only('lg')]: {
      width: "28%",
    },
    [theme.breakpoints.down('xl')]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.only('md')]: {
      width: "66.67%",
    },
    [theme.breakpoints.only('lg')]: {
      width: "72%",
    },
    [theme.breakpoints.only('xl')]: {
      width: "72%",
    },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up('md')]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px"
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    margin: "10px 10px 0px 10px",
    // marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex"
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "10px 10px 10px 10px",
  },
  nextButton: {
    borderRadius: "5px",
    textTransform: "capitalize",
    minWidth: "0px",
    padding: "5px 10px"
  },
  addUserButton: {
    borderRadius: "5px",
    textTransform: "capitalize",
    minWidth: "0px",
    padding: "5px 10px",
    background: "linear-gradient(347.29deg, #E84A62 1.03%, #F4A360 113.2%)",
    color: "white",
    marginRight: "10px"
  },

  groupNameRoot: {
    display: "flex",
    alignItems: "center",
    "&:hover ": {
      "& .hide": {
        display: "block"
      },
    }
  },
  groupName: {
    fontSize: "24px",
    fontWeight: "600",
    textTransform: "capitalize"
  },
  groupNameEditIcon: {
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer"
  },
  groupNameEditIconRoot: {
    display: "none"
  },
  groupDescription: {
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "capitalize"
  },
  formHr: {
    width: "100%",
    background: "#FFFFFF",
    opacity: "0.1",
    border: "1px solid #000000",
    margin: "20px 0px"
  },
  memberRoot: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up('md')]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  acceptButton: {
    padding: "0px 15px",
    borderRadius: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
    color: "white",
    background: "#F24451",
    "&:hover": {
      background: "#F24451",
    },
    borderColor: "#F24451"
  },
  deleteButton: {
    padding: "0px 15px",
    borderRadius: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
    color: "#F24451",
    backgroundColor: "#F9EBEB",
    borderColor: "#F24451"
  },
  menu: {
    cursor: "pointer",
    "& .MuiPaper-rounded": {
      marginTop: "10px",
      borderRadius: "10px"
    }
  },
  typographyMenu: {
    fontSize: "14px",
    padding: "7px"
  },
  adminChip: {
    position: "absolute",
    fontSize: "10px",
    background: "linear-gradient(168.55deg, #E84A62 0%, #ED6E61 44.79%, #F4A360 100%)",
    color: "white",
    padding: "3px 10px",
    borderRadius: "5px",
    bottom: "-4px",
    margin: "4px"

  }
}));

const GroupDetailDisplayNormal = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();
  const {
    onLoad,
    groupData,
    setGroupData,
    userName,
    setUserName,
    firebase_uid,
    setFirebase_uid,
    isLoading,
    setIsLoading,
    isFavourite,
    setIsFavourite,
    isBlock,
    setIsBlock,
    userList,
    setUserList,

  } = props

  const [value, setValue] = React.useState(0);
  const [isNameEdit, setIsNameEdit] = React.useState("");
  const [isDescriptionEdit, setIsDescriptionEdit] = React.useState("");
  const [userSlug, setUserSlug] = React.useState("");

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: '',
    type: '',
    open: false
  });
  const [openExitGroup, setopenExitGroup] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const idMenu = openMenu ? 'simple-popover' : undefined;


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let query = useLocation();
  const slug = query.pathname.split("/")[2]
  // const matchPercent = query.pathname.split("/")[4]

  const handleChange = (event, newValue) => {
    setValue(newValue)
  };

  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  const onEdit = (key, value) => {
    api.put(`user/teammate/editgroup/${groupData.slug}`, {
      data: {
        [key]: value,
      }
    })
      .then((result) => {
        if (result.success === "true") {
          setSessionMessage({
            message: result.message,
            type: 'success',
            open: true
          });
          setIsNameEdit("")
          setIsDescriptionEdit("")
          onLoad()
        } else {
          setSessionMessage({
            message: result.message,
            type: 'error',
            open: true
          });
        }
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: 'error',
          open: true
        });
      });
  }


  const onCloseExitGroup = () => {
    setopenExitGroup(false)
  }


  const onConfirm = (id, status) => {
    api.put(`user/teammate/confirmrequest/${groupData.slug}`, {
      data: {
        from_id: id,
        status: status
      }
    })
      .then((result) => {
        if (result.success === "true") {
          setSessionMessage({
            message: result.message,
            type: 'success',
            open: true
          });
          onLoad()
        } else {
          setSessionMessage({
            message: result.message,
            type: 'error',
            open: true
          });
        }
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: 'error',
          open: true
        });
      });
  }

  const handelStatusChange = (slug, status) => {
    let newSatus = status == "private" ? "public" : "private"
    api.put(`user/teammate/changegrouptype/${slug}`, {
      data: { group_type: newSatus }
    }).then((result) => {
      if (result.success === "true") {
        // console.log(result, "result")
        setSessionMessage({ message: result.message, type: 'success', open: true });

        // let lstorg = [...lstOrganization];
        // const index = lstorg.findIndex(obj => obj.slug === slug);
        // lstorg[index] = { ...lstorg[index], is_active: !lstorg[index].is_active }
        // setLstOrganization(lstorg);
        let objTmp = { ...groupData, group_type: newSatus }
        setGroupData(objTmp)

      } else {
        setSessionMessage({ message: result.message, type: 'error', open: true });
      }
    }).catch((err) => {
      console.log(err, "error catch")
    });
  }

  console.log(isNameEdit, "isNameEdit")
  console.log(groupData, "userData")
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              {isLoading ?
                <SkeletonLoading />
                :
                <Grid container>
                  <Grid item xs={12} lg={12} xl={12} className={classes.detailBox}>
                    <Box onClick={() => router.history.goBack()} className={classes.backRoot}>
                      <IconButton aria-label="delete" style={{ color: "black", marginRight: "5px" }} size="small">
                        <ArrowBackIcon fontSize="inherit" />
                      </IconButton>
                      <h5 className={classes.headingPage}> Message </h5>
                    </Box>
                    <div style={{ padding: "10px 40px" }}>
                      <div>
                        <img src={groupData.group_profile_pic ? groupData.group_profile_pic : "/images/matchUp/roommateLandingPaart11.webp"} alt="imageback" style={{ width: "100%", height: "400px", objectFit: "cover", borderRadius: "10px" }} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                        <div>
                          <div className={classes.groupNameRoot}>
                            <div className={classes.groupName}>
                              {groupData.group_subject}
                            </div>
                          </div>
                          <div className={classes.groupDescription}>
                            {groupData.group_type} Created by {groupData.createdBy}, {groupData.createdAt}
                          </div>
                        </div>
                        <div>
                          <Button
                            className={classes.nextButton}
                            variant="outlined"
                            color="primary"
                            onClick={handleClick} id={idMenu}
                          >
                            <MoreVertIcon />
                          </Button>
                          <Popover
                            id={idMenu}
                            open={openMenu}
                            anchorEl={anchorEl}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            style={{ padding: "10px", borderRadius: "10px" }}
                            className={classes.menu}
                          >
                            <div style={{ fontSize: "14px", padding: "10px", width: "300px" }}>
                              <Typography
                                className={classes.typographyMenu}
                                onClick={() => {
                                  router.history.push({
                                    pathname: `/shareGourp/${groupData.slug}`,
                                    search: '',
                                    state: {
                                      objGroup: groupData
                                    }
                                  })
                                }}
                              >
                                Share
                              </Typography>
                              {/* <Divider /> */}
                              {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "red" }}>
                                <Typography className={classes.typographyMenu}>Exit Group</Typography>
                              </div> */}
                            </div>
                          </Popover>
                        </div>
                      </div>
                      <hr className={classes.formHr} />
                      <div className={classes.groupNameRoot}>
                        <div className={classes.groupDescription}>
                          {groupData.group_description}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={12} xl={12} xs={12} className={classes.memberRoot}>
                    <h5 className={classes.headingPage} style={{ padding: "10px 30px" }}> Members : {userList.length} Members </h5>
                    <Box>
                      <AntTabs value={value} className={classes.tabHade} onChange={handleChange} aria-label="ant example" style={{ marginLeft: "25px" }}>
                        <AntTab label="Members" />
                      </AntTabs>
                    </Box>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <Grid container spacing={2} style={{ padding: "30px" }}>
                        {userList && userList.map((obj) => {
                          return (
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              md={2}
                              lg={1}
                              onClick={() => {
                                if (obj.slug != groupData.slug) {
                                  router.history.push(`/your-matches/view/${obj.slug}`)
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
                                <img src={obj.profile_picture ? obj.profile_picture : "/images/male_defualt.svg"} alt="obj.photo" style={{ borderRadius: "50%", width: "60px", height: "60px", objectFit: "cover" }} />
                                {obj.is_group_admin &&
                                  <div className={classes.adminChip}>
                                    Admin
                                  </div>}
                              </div>
                              {obj.slug === groupData.slug ?
                                <div style={{ fontSize: "10px", textAlign: "center" }}>
                                  You
                                </div>
                                :
                                <div style={{
                                  fontSize: "10px",
                                  textAlign: "center",
                                  overflow: "hidden",
                                  width: "100%",
                                  height: "30px"
                                }}>
                                  {obj.first_name} {obj.last_name}
                                </div>
                              }


                            </Grid>
                          )
                        })}
                      </Grid>
                    </TabPanel>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Container>
        <ExitGroupModel
          setSessionMessage={setSessionMessage}
          slug={groupData.slug}
          onClose={onCloseExitGroup}
          open={openExitGroup}
        />
      </div>
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" height={500} style={{ margin: "5px" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" height={250} style={{ margin: "5px" }} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GroupDetailDisplayNormal;
