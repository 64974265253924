import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Helmet } from "react-helmet";
import Api from "../../../../Helper/ApiHandler";
import Comminsoon from "views/Comminsoon/Comminsoon";

var api = new Api();

const lstFaq = [
  {
    q: "How Do I signup with MatchUpMates.com ?",
    a:
      "Click 'Sign Up' and login with your email address and / or phone authentication.",
  },
  {
    q: "Who can use MatchUpMates.com app ?",
    a:
      "Majority of user are from High school seniors, college students, and graduates school students use MatchUpMates.com to find likeminded dormmate for their freshmen year.",
  },
  {
    q: "Usage of MatchUpMates.com app?",
    a:
      "Dormmate matching solution to enhance the educational housing process for incoming first year students at colleges and universities.Encourage the efficient use of advanced technology and algorithms to facilitate long - term friendships between the dormmates.",
  },
  {
    q: "Why one should use this application for dormmate solution. ",
    a:
      "Dormmate is a process that is currently carried out through social media and the personal network or random match by the college administration.This problem faced by parents for their children to seek out like - minded roommates matching solutions.",
  },
  {
    q: "Why dormmate is important for first year of college students ?",
    a:
      "MatchUpMates.com can easily connect with admitted students after answering 15 simple questions to match with the prefer demographics and social choices.",
  },
  {
    q: "What Challenge student face without knowing there dormmate ?",
    a:
      "Student will learn more about their roommate once they move in, but until then they don't know what to expect which is challenging for them and scary for parents.",
  },
  {
    q: "I know someone whom I am planning to live.",
    a:
      "If your friend got admitted to same university or college, ask them to login at MatchUpMates.com and create profile so you all can explore your personalities and know each other better than before.If you both agree to live together, the next step is to find out how your school accepts dormmate request.",
  },
  {
    q: "I do not see my college on MatchUpMates.com! How can we add it ?",
    a:
      "If your college isn't listed, email us at contact@MatchupMate.com and we will add it! Be sure to include the full name of the school and the state it's located in.Once added, don't forget to tell other students going to your school about us so you'll have matches to choose from!",
  },
  {
    q: "Are any membership fees involving for MatchUpMates.com or it is free ?",
    a:
      "Account is free of charge to get perfect dormmate.Please share your experience on your social media so  we have good database for future students.",
  },
  {
    q: "How should I refer my friends to MatchUpMates.com ?",
    a:
      "Please post a link of our site in your college class group.These groups are usually for you to connect with your classmates.You can also ask your class group administrator to make a post about our site and add comment with how it can help everyone find the best roommates.Tweet about us! Use #MatchUpMates and our Twitter handle @MatchUpMates, as well as the hashtag for your school or class. Post a FB status update linking to our site! ",
  },
  {
    q: "How can I change my school networks ?",
    a:
      "Absolutely! You can update school name by clicking 'Select Primary University or Secondary University' on your Profile Dashboard and change your selection.",
  },
  {
    q: "What is happening behind the screen ?",
    a:
      "MatchUpMates.com Introduces advanced dormmate selection Technology to allow students to choose the fate of his / her room partner by providing clear decision - making criteria to focus on the future.",
  },
  {
    q: "How should I change my answers to profile quiz ?",
    a: "Select 'Retake the Quiz' from the Dashboard and update your answers.",
  },
  {
    q: "Does College matches students as dormmates ?",
    a:
      "MatchUpMates.com provides a medium for students to connect with and identify potential dormmates based on answers in their personal profile.Students are responsible to communicate with college on their dormmate match.",
  },
  {
    q:
      "If I send a dormmate pair request to my college, does that mean we will be paired ?",
    a:
      "Many schools allow students to submit mutual dormmate requests, and they do their best to honor those requests.Our website allows students to meet best match based on the criteria, but students need to communicate with collage or universities to get paired. ",
  },
  {
    q: "How do I deactivate my account ?",
    a:
      "Click the 'deactivate account' link on your User Dashboard.If you're on mobile, you'll find the link under the Menu.",
  },
  {
    q: "How do I reactivate my account ?",
    a:
      "Send us an email at ContactMatchupMate.com with your MatchUpMates username and we will reactivate your account for you.",
  },
  {
    q: "MatchUpMates is a cool idea - what else you got ?",
    a:
      "We've also created Roommate, Teammate and Soulmate, you can find the best mates in all walks of your life at one place.",
  },
  {
    q: "Does my profile picture is editable ?",
    a:
      "You can always “Edit your Profile' and update your profile picture. Unless the picture size is large make sure it is under 5MB by resizing. If you still have issue, please do not hesitate to email us at contact@MatchUpMates.com with your username and attach the file you want to use as your picture",
  },
  {
    q:
      "Should it be recommended for parents to create their account at MatchUpMates.com ?",
    a:
      "We will discourage doing that as our algorithm works based on the question answered by real user and even parents know their children and their chemistry but not recommended for parents.Let your children grow up and understand what they needed.",
  },
  {
    q: "I chose wrong gender; how can I fix it ?",
    a:
      "Primary profile field is not editable by user please send us an email at contact @MatchupMate.com with your username, and we'll help you get information updated.",
  },
];

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
    minHeight: "90vh",
  },
  aboutStep: {
    textAlign: "center",
    margin: "0",
    paddingTop: "25px",
    color: "white",
    //fontFamily: "Gilroy !important",
  },
  contantAbout: {
    fontSize: "16px",
    color: "gray",
    textAlign: "justify",
  },
  contantBoxAbout: {
    marginTop: "50px",
    // height:"380px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  topPart: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    height: "400px",
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topPartImage1: {
    position: "absolute",
    height: "330px",
    width: "511px",
    bottom: "-45px",
    left: "310px",
  },
  topPartImage2: {
    position: "absolute",
    height: "300px",
    width: "173px",
    bottom: "10px",
    right: "150px",
  },
  startQuizMain: {
    marginBottom: "20px",
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
  },
}));

const PrivacyPolicyDetail = (props) => {
  const classes = useStyles();
  const [faq, setFaq] = React.useState("");
  const [meta, setMeta] = React.useState({});

  useEffect(() => {
    onLoad();
    onLoadMeta();
  }, []);

  const onLoad = () => {
    // setIsLoading(true)
    // Api Call For Fetch The Privacy Policy
    api
      .get(`user/cms/faq3sp`)
      .then((result) => {
        if (result.success === "true") {
          setFaq(result.data.details);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const onLoadMeta = () => {
    // For meta
    api
      .post("staticmetadata", {
        data: { type: "faqroommate" },
      })
      .then((result) => {
        if (result.success === "true") {
          // console.log(result.data, "success");
          setMeta(result.data, "result.data");
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };
  return (
    <>
      <Helmet>
        <title>FAQ Roommate | MatchUpMates</title>
        {/* <meta name="description" content={meta.meta_description} />
        <meta name="keywords" content={meta.meta_keyword} />

        <meta itemprop="name" content={meta.page_name} />
        <meta itemprop="description" content={meta.meta_description} />
        <meta itemprop="image" content={meta.meta_image} />

        <meta property="og:url" content="https://matchupmates.com" />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={meta.meta_title} />
        <meta property="og:description" content={meta.meta_description} />
        <meta property="og:image" content={meta.meta_image} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={meta.meta_title} />
        <meta name="twitter:description" content={meta.meta_description} />
        <meta name="twitter:image" content={meta.meta_image} /> */}
      </Helmet>

      {process.env.REACT_APP_ROOMMATES == "true" ? (
        <div className={classes.mainRoot}>
          <div className={classes.topPart}>
            {/* <img src="/images/matchUp/faq.png" alt="" className={classes.topPartImage1} />
          <img src="/images/matchUp/faq2.png" alt="" className={classes.topPartImage2} /> */}
            <h1 className={classes.aboutStep}> Faq RoomMates</h1>
          </div>
          <Container maxWidth="lg">
            <div style={{ padding: "60px 0px" }}>
              <div
                style={{
                  color: "#FE4B6B",
                  fontSize: "24px",
                  fontWeight: "400",
                  margin: "20px 0px",
                }}
              >
                Getting Started
              </div>
              {lstFaq.map((obj) => {
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        {obj.q}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{obj.a}</Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </Container>
        </div>
      ) : (
        <Comminsoon />
      )}
    </>
  );
};

export default PrivacyPolicyDetail;
