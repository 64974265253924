import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import FilterModel from "../FilterModel";
import { Link } from "react-router-dom";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import MuiAlert from "@material-ui/lab/Alert";
import { Skeleton } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles } from "@material-ui/styles";
import Header from "../Header";
import ClearIcon from "@material-ui/icons/Clear";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import Tooltip from "@material-ui/core/Tooltip";

import Api from "../../../../Helper/ApiHandler";
import UserSkeletonCard from "components/Skeleton/UserSkeletonCard";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #c4c4c4",
  },
  indicator: {
    backgroundColor: "#FE4B6B",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: "500px",
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#FE4B6B",
      opacity: 1,
    },
    "&$selected": {
      color: "#FE4B6B",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#FE4B6B",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // backgroundColor: "E9EDF0 !important",
    minHeight: "calc(100vh - 125px)",
    // minHeight: "90vh",
    background: "#E9EDF0",
    paddingTop: "35px",
    // marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15px",
    },
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
    [theme.breakpoints.down("xs")]: {
      height: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "210px",
    },
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "500",
  },
  // rootTab: {
  //   marginLeft: "10px",
  //   marginTop: "10px"
  // },
  searchInput: {
    width: "290px",
    marginRight: "10px",
    background: "#E9EDF0",
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "14px",
    },
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    textAlign: "center",
    marginTop: "35px",
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "#E9EDF0",
    boxShadow: "none",
    fontWeight: "500",
  },
  filterIconBtn: {
    marginLeft: "10px",
  },
  listBg: {
    background: "#E9EDF0",
  },
  matchesHead: {
    margin: "0",
    padding: "20px 30px",
    paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px",
  },
  cardUser: {
    background: "white",
    padding: "20px 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "55px 10px",
    },
    paddingBottom: "10px",
    textAlign: "center",
    margin: "7px",
    borderRadius: "15px",
    height: "260px",
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400",
  },
  textCityName: {
    marginTop: "5px",
    color: "#6C6C6C",
    fontSize: "14px",
    maxHeight: "40px",
    minHeight: "22px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chipUserDetail: {
    whiteSpace: "nowrap",
    width: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0",
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGrid: {
    marginBottom: "30px",
    padding: "10px",
  },
  imageMatches: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  filterSearchBtn: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: "300",
  },
  menuItemSelect: {
    //fontFamily: "Gilroy !important",
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      fontSize: "12px",
    },
    backgroundColor: "#e9edf0",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  carosalImageBox: {
    height: "120px",
    width: "120px",
    margin: "auto",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      width: 80,
      height: 80,
    },
  },
}));

const MatchesList = (props) => {
  const { sessionPropsMessage, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingScroll, setLoadingScroll] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const [favourite, setFavourite] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(
    props.sessionPropsMessage
  );
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(12);
  const [totalRecored, setTotalRecored] = React.useState(0);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    AsyncLocalStorage.setItem("tabFavValue", newValue);
    // MatchesRoommate
  };

  useEffect(() => {
    getCurrantTab();
  }, []);

  const getCurrantTab = async () => {
    let tmp = await AsyncLocalStorage.getItem("tabFavValue");
    setValue(parseInt(tmp));
  };

  const fetchMoreMatches = () => {
    if (favourite.length >= totalRecored) {
      setHasMore(false);
      return;
    }

    // setTimeout(() => {
    // setItems(items.concat(Array.from({ length: 20 })))
    setPage(page + 1);
    // onMore()
    // }, 500);
  };

  useEffect(() => {
    if (page !== 0) onMore();
  }, [page]);

  const setMaltiFilter = (obj) => {
    let ftr = { ...filter };
    Object.keys(obj).forEach(function (key) {
      ftr[key] = obj[key];
    });
    setFilter(ftr);
  };

  useEffect(() => {
    onLoad();
  }, [filter]);

  useEffect(() => {
    setPage(0);
  }, []);

  const onLoad = () => {
    setLoading(true);
    // Api Call For Fetch The Our Matches
    api
      .get(`user/yourfavouriteroommatelist?page=${page}&size=${rowsPerPage}`, {
        params: filter,
      })
      .then((result) => {
        if (result.success === "true") {
          setFavourite(result.data.user);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
          setFavourite([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "catch error");
      });
  };

  const onMore = () => {
    setLoadingScroll(true);
    // Api Call For Fetch The Our Matches

    api
      .get(`user/yourfavouriteroommatelist?page=${page}&size=${rowsPerPage}`, {
        params: filter,
      })
      .then((result) => {
        if (result.success === "true") {
          const lstTmp = favourite.concat(result.data.user);
          setFavourite(lstTmp);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
          setFavourite([]);
        }
        setLoadingScroll(false);
      })
      .catch((err) => {
        setLoadingScroll(false);
        console.log(err, "catch error");
      });
  };

  const onClickLoad = () => {
    // Api Call For Fetch The Our Matches

    api
      .get(`user/yourfavouriteroommatelist?page=${page}&size=${rowsPerPage}`, {
        params: filter,
      })
      .then((result) => {
        if (result.success === "true") {
          const lstTmp = favourite.concat(result.data.user);
          setFavourite(lstTmp);
          setTotalRecored(result.data.totalItems);
        } else {
          console.log(result, "error");
          setFavourite([]);
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const removeFav = (slug) => {
    api
      .post("user/addfavouriteroommate", {
        data: { slug: slug, isFavourite: true },
      })
      .then((result) => {
        if (result.success === "true") {
          onClickLoad();
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  return (
    <>
      <Container maxWidth="lg">
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
      </Container>
      <div className={classes.mainRoot}>
        <Container maxWidth="lg">
          <Grid spacing={1} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid item className={classes.container}>
              <div className={classes.listBg}>
                {/* <Container maxWidth="lg"> */}
                <Grid item xs={12}>
                  <AntTabs
                    value={value}
                    className={classes.tabHade}
                    onChange={handleChange}
                    aria-label="ant example"
                    style={{ marginLeft: "25px", marginRight: "25px", marginTop: "28px" }}
                  >
                    <AntTab label="Favourite Properties" />
                    <AntTab label="Favourite Matches" />
                  </AntTabs>
                  <div className={classes.rootTab}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <Header />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      <InfiniteScroll
                        dataLength={favourite.length}
                        // next={fetchMoreData}
                        next={fetchMoreMatches}
                        hasMore={true}
                        style={{ overflow: "hidden" }}
                        loader={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "95%",
                              margin: "10px",
                            }}
                          ></div>
                        }
                      // endMessage={
                      //   <p style={{ paddingBottom: "60px" }}>
                      //   </p>
                      // }
                      >
                        <Grid
                          justifyContent="center"
                          alignItems="center"
                          className={classes.cardBoxGrid}
                          container
                        >
                          {/* > */}
                          {/* {items.map((i, index) => (
                        <div style={style} key={index}>
                          div - #{index}
                        </div>
                      ))} */}
                          <Grid
                            justifyContent="center"
                            alignItems="center"
                            className={classes.cardBoxGrid}
                            container
                          >
                            {!loading ? (
                              <>
                                {favourite.length ? (
                                  favourite.map((item, index) => (
                                    <Grid
                                      key={index}
                                      item
                                      xs={12}
                                      sm={6}
                                      xl={3}
                                      lg={3}
                                      className={classes.cardMatched}
                                    >
                                      {/* <Link
                          style={{ textDecoration: "none" }}
                          to={`/your-matches/view/${item.users.slug}/${item.matchPercent}`}
                        > */}
                                      <div
                                        style={{
                                          display: "flex",
                                          position: "relative",
                                        }}
                                      >
                                        <Box
                                          top={30}
                                          bottom={8}
                                          left={20}
                                          position="absolute"
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          <ClearIcon
                                            style={{
                                              color: "#80808059",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              removeFav(item.slug);
                                            }}
                                          />
                                        </Box>
                                        <Tooltip
                                          title={`${capitalizeFirstLetter(
                                            item.first_name
                                          )} and you  match ${item.matchPercent !== "NaN"
                                            ? item.matchPercent
                                            : 0
                                            }%`}
                                          placement="top"
                                        >
                                          <Box
                                            position="absolute"
                                            top={20}
                                            right={20}
                                          >
                                            <CircularProgress
                                              variant="static"
                                              value={100}
                                              size={35}
                                              thickness={4}
                                              className={classes.bottom}
                                            />
                                            <CircularProgress
                                              variant="static"
                                              value={item.matchPercent}
                                              // value={25}
                                              size={35}
                                              thickness={4}
                                              className={classes.top}
                                              style={{
                                                transform: "rotate(270deg)",
                                              }}
                                            />

                                            <Box
                                              top={0}
                                              left={0}
                                              bottom={8}
                                              right={0}
                                              position="absolute"
                                              display="flex"
                                              alignItems="center"
                                              justifyContent="center"
                                            >
                                              <Typography
                                                variant="caption"
                                                component="div"
                                                color="textSecondary"
                                                style={{
                                                  fontSize: "12px",
                                                  paddingTop: "2px",
                                                }}
                                              >
                                                {item.matchPercent &&
                                                  item.matchPercent !== "NaN"
                                                  ? item.matchPercent
                                                  : 0}
                                                %
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Tooltip>
                                      </div>
                                      <Link
                                        style={{ textDecoration: "none" }}
                                        to={`/favourit/view/${item.slug}`}
                                      >
                                        <div className={classes.cardUser}>
                                          <div
                                            className={classes.carosalImageBox}
                                          >
                                            <img
                                              src={
                                                item.profile_picture
                                                  ? item.profile_picture
                                                  : "/images/male_defualt.svg"
                                              }
                                              alt="profile_picture"
                                              className={classes.imageMatches}
                                            />
                                          </div>
                                          {/* <img
                                            src={
                                              item.profile_picture
                                                ? item.profile_picture
                                                : "/images/male_defualt.svg"
                                            }
                                            alt="User Image"
                                            className={classes.imageMatches}
                                          /> */}
                                          <Tooltip
                                            title={
                                              capitalizeFirstLetter(
                                                item.first_name
                                              ) +
                                              "  " +
                                              capitalizeFirstLetter(
                                                item.last_name
                                              )
                                            }
                                            placement="top"
                                          >
                                            <h4
                                              className={classes.nameTextCard}
                                            >
                                              {capitalizeFirstLetter(
                                                item.first_name
                                              )}
                                            </h4>
                                          </Tooltip>
                                          <Tooltip
                                            title={"Location"}
                                            placement="top"
                                          >
                                            <p className={classes.textCityName}>
                                              {item.location}
                                            </p>
                                          </Tooltip>
                                          {item.gender && item.zodiac && (
                                            <div className={classes.hrLine} />
                                          )}
                                          {item.gender && (
                                            <>
                                              {!item.zodiac && (
                                                <div
                                                  className={classes.hrLine}
                                                />
                                              )}
                                            </>
                                          )}
                                          {item.zodiac && (
                                            <>
                                              {!item.gender && (
                                                <div
                                                  className={classes.hrLine}
                                                />
                                              )}
                                            </>
                                          )}
                                          <div>
                                            {item.gender && (
                                              <Tooltip
                                                title={item.gender}
                                                placement="top"
                                              >
                                                <Chip
                                                  className={
                                                    classes.chipUserDetail
                                                  }
                                                  label={capitalizeFirstLetter(
                                                    item.gender
                                                  )}
                                                />
                                              </Tooltip>
                                            )}
                                            {item.zodiac && (
                                              <Tooltip
                                                title={item.zodiac}
                                                placement="top"
                                              >
                                                <Chip
                                                  className={
                                                    classes.chipUserDetail
                                                  }
                                                  label={capitalizeFirstLetter(
                                                    item.zodiac
                                                  )}
                                                />
                                              </Tooltip>
                                            )}
                                          </div>
                                        </div>
                                      </Link>
                                      {/* </Link> */}
                                    </Grid>
                                  ))
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      marginTop: "50px"
                                    }}
                                  >
                                    <img
                                      src="/images/matchUp/notFoundMatches.svg"
                                      alt="favAlt"
                                    />
                                    <Typography
                                      variant="caption"
                                      component="primary"
                                      color="primary"
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                        fontWeight: "600",
                                      }}
                                    >
                                      No Favourites Yet!
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      component="primary"
                                      color="primary"
                                      style={{
                                        fontSize: "14px",
                                        color: "gray",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Mark your favorite profile and always have
                                      them here.
                                    </Typography>
                                  </div>
                                )}
                              </>
                            ) : (
                              <SkeletonLoading />
                            )}
                          </Grid>
                        </Grid>
                      </InfiniteScroll>
                    </TabPanel>
                    {/* </SwipeableViews> */}
                  </div>
                </Grid>
                {/* <h5 className={classes.matchesHead}> Your Matches Roommate</h5> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <FilterModel
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        setMaltiFilter={setMaltiFilter}
      />
    </>
  );
};
const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Grid justifyContent="center" alignItems="center" container>
      {[0, 1, 2, 3, 4, 5, 6, 7].map((obj) => {
        return (
          <Grid item xs={12} sm={6} xl={3} lg={3}>
            <UserSkeletonCard />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default MatchesList;
