import React, { useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Link,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from "../../../../Helper/ApiHandler";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import MuiAlert from "@material-ui/lab/Alert";
import { Skeleton } from "@material-ui/lab";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import Cookies from 'js-cookie';

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    // height: "100vh"
    minHeight: "calc(100vh - 125px)",
  },
  questionsText: {
    fontSize: "20px",
    padding: "20px",
    color: "white",
    fontWeight: "500",
    margin: "0",
  },
  questionsBox: {
    // background: "#0071CE",
    backgroundImage:
      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    borderRadius: "10px 10px 0px 0px",
  },
  startQuizBox: {
    background: "#fff",
    borderRadius: "10px 10px 0px 0px",
  },
  startText: {
    color: "black",
    fontSize: "20px",
    padding: "20px",
    fontWeight: "600",
    margin: "0",
  },
  answerBox: {
    background: "white",
    borderRadius: "0px 0px 10px 10px",
  },
  headingAns: {
    margin: "0",
    padding: "20px",
    paddingBottom: "0",
    fontSize: "18px",
    fontWeight: "500",
    marginLeft: "10px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    height: "auto",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
    "& .MuiChip-label": {
      whiteSpace: "break-spaces",
      padding: "5px",
    },
  },
  chipGruop: {
    padding: "10px 20px",
  },
  lineHorizontal: {
    width: "95%",
    // borderBottom: "2px solid #000000",
    opacity: "0.1",
    margin: "0 auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 25px",
  },
  nextButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "#d5606045",
    // width: "80px",
    height: "30px",
  },
  skipButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    textDecoration: "underline",
    marginTop: "5px",
    cursor: "pointer",
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
  },
  buttonStart: {
    width: "300px",
    fontSize: "14px",
    backgroundImage:
      "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    textTransform: "capitalize",
    margin: "20px 0px",
  },
  pagination: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundImage:
        "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
      color: "white",
    },
  },
  mainStartQuixBox: {
    textAlign: "center",
    padding: "15px 0",
  },
  textNewStep: {
    fontSize: "16px !important",
    fontWeight: "400",
    width: "60%",
    textAlign: "center",
    margin: "19px auto",
  },
  startQuizMain: {
    marginBottom: "20px",
  },
  autocompletChip: {
    background: "white",
    "& .MuiChip-root": {
      borderRadius: "5px",
      background: "#CCE3F5",
      height: "auto",
    },
    "& .MuiChip-outlined": {
      border: "none",
      color: "#22346E",
      padding: "2px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    color: "#22346E",
  },
}));

const QuizQuestions = (props) => {
  const { sessionPropsMessage } = props;
  const classes = useStyles();
  const router = useHistory();

  const [startQuiz, setStartQuiz] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [questionNo, setQuestionNo] = React.useState(0);
  const [oldAnswer, setOldAnswer] = React.useState([]);
  const [answer, setAnswer] = React.useState([]);
  const [roommateAnswer, setRoommateAnswer] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(
    sessionPropsMessage
  );
  const [selected, setSelected] = React.useState("");

  const [data, setData] = React.useState([]);

  useEffect(() => { }, [questionNo]);

  useEffect(() => {
    getCurrentModule();
  }, []);

  const getCurrentModule = async () => {
    let tmp = await AsyncLocalStorage.getItem("currentModule");
    setSelected(tmp);
  };

  // // Get University
  // const getUniversity = async (search) => {
  //   console.log(search, "search")
  //   // Api Call For Fetch University List
  //   api.get(`universitylist?s=` + search)
  //     .then((result) => {
  //       if (result.success === "true") {
  //         setLstUniversity(result.data)
  //       } else {
  //         console.log(result, "error")
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "catch error")
  //     });
  // }

  useEffect(() => {
    // Api Call For Retack Quiz
    api
      .get("user/retakequiz")
      .then((result) => {
        if (result.success === "true") {
          console.log(result, "success");
          setOldAnswer(result.data);
          setAnswer(result.data[0].answer);
          setRoommateAnswer(result.data[0].roommate_answer);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  }, []);

  // Start The Quiz
  const StartTheQuizHandler = () => {
    setStartQuiz(true);

    // Api Call For Fetch The Quiz Questions And Other Option
    api
      .get("user/criteria")
      .then((result) => {
        if (result.success === "true") {
          setData(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  // Select Your Answer
  const handleSelectAnswer = (item, ansType) => () => {
    if (ansType === "Multi Choose") {
      let ans = [...answer];
      const index = ans.indexOf(item);
      if (ans.includes(item)) {
        ans.splice(index, 1);
      } else {
        ans.push(item);
      }
      setAnswer(ans);
    } else if (ansType === "Radio") {
      let ans = [...answer];
      ans[0] = item;
      setAnswer(ans);
    }
  };

  // Select RoomMates Answer
  const handleSelectRoommateAnswer = (item, ansType) => () => {
    if (ansType === "Multi Choose") {
      let ans = [...roommateAnswer];
      const index = ans.indexOf(item);
      if (ans.includes(item)) {
        ans.splice(index, 1);
      } else {
        ans.push(item);
      }
      setRoommateAnswer(ans);
    } else if (ansType === "Radio") {
      let ans = [...roommateAnswer];
      ans[0] = item;
      setRoommateAnswer(ans);
    }
  };

  // Next
  const onNext = (is_skip) => {
    console.log(answer, "ansewer");
    let objAnswer = {
      criteria_id: data[questionNo].criteria_id,
      answer: answer,
      roommate_answer: roommateAnswer,
      is_skip: is_skip,
    };
    setLoading(true);
    // Api Call For Send The User Answers
    api
      .post("user/answer", {
        data: objAnswer,
      })
      .then((result) => {
        setAnswer([]);
        setRoommateAnswer([]);
        if (result.success === "true") {
          let lstTmp = [...oldAnswer]
          lstTmp[questionNo] = { ...lstTmp[questionNo], answer: answer, roommate_answer: roommateAnswer }
          setOldAnswer(lstTmp)

          setQuestionNo(questionNo + 1);
          // setAnswer(result.data[0].answer)
          // setRoommateAnswer(result.data[0].roommate_answer)
          if (oldAnswer[questionNo + 1].answer.length) {
            let filter = oldAnswer[questionNo + 1].answer.filter((obj) =>
              data[questionNo + 1].options.includes(obj)
            );
            setAnswer(filter);
          } else {
            setAnswer([]);
          }
          if (oldAnswer[questionNo + 1].roommate_answer.length) {
            let filter = oldAnswer[
              questionNo + 1
            ].roommate_answer.filter((obj) =>
              data[questionNo + 1].options.includes(obj)
            );
            setRoommateAnswer(filter);
          } else {
            setRoommateAnswer([]);
          }
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setLoading(false);
      });
  };

  // Finish
  const onFinish = (is_skip) => {
    onNext(is_skip);
    let roommateRedirectTo = Cookies.get('roommateRedirectTo')
    if (roommateRedirectTo) {
      Cookies.remove('roommateRedirectTo')
      router.push(roommateRedirectTo)
    } else {
      router.push("/your-matches");
    }
    AsyncLocalStorage.setItem("quiz", true);
    // window.location.replace("/your-matches")
  };

  // Skip
  const onSkip = () => {
    onNext(true);
  };
  const onPaginationChange = (event, value) => {
    // questionNo + 1
    if (questionNo > value - 1) {
      setQuestionNo(value - 1);
      setAnswer(oldAnswer[value - 1].answer);
      setRoommateAnswer(oldAnswer[value - 1].roommate_answer);
    }
  };
  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const moduleNameChange = (name) => {
    if (name === "Dormmates") {
      return "DormMates";
    } else if (name === "Roommates") {
      return "RoomMates";
    } else if (name === "Soulmates") {
      return "SoulMates";
    } else if (name === "Teammates") {
      return "TeamMates";
    } else {
      return name;
    }
  };
  console.log(data, "data");
  return (
    <>
      <div className={classes.bgProfilePage}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Container maxWidth="md">
          <Grid container>
            {startQuiz ? (
              <>
                {data.length ? (
                  <Grid item xl={12} lg={12} xs={12}>
                    <Box className={classes.questionsBox}>
                      <p className={classes.questionsText}>
                        Q.{data[questionNo].id}
                        <span style={{ marginLeft: "10px" }}>
                          {data[questionNo].question}
                        </span>
                      </p>
                    </Box>
                    <Box className={classes.answerBox}>
                      <h5 className={classes.headingAns}> Your Answer </h5>
                      <div className={classes.chipGruop}>
                        {data[questionNo].options.map((item, index) => {
                          return (
                            <Chip
                              label={item}
                              onClick={handleSelectAnswer(
                                item,
                                data[questionNo].option_type
                              )}
                              style={{
                                background: answer.includes(item)
                                  ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                                  : "#DFDFDF",
                                color: answer.includes(item)
                                  ? "white"
                                  : "#535353",
                              }}
                              className={classes.chipUserDetail}
                            />
                          );
                        })}
                      </div>
                      <div className={classes.lineHorizontal} />
                      {/* {questionNo !== 0 && */}
                      <h5 className={classes.headingAns}>
                        Preferred {moduleNameChange(selected)} Answer
                      </h5>
                      {/* } */}

                      <div className={classes.chipGruop}>
                        {data[questionNo].options.map((item, index) => {
                          // if (questionNo !== 0)
                          return (
                            <>
                              <Chip
                                label={item}
                                onClick={handleSelectRoommateAnswer(
                                  item,
                                  data[questionNo].option_type
                                )}
                                style={{
                                  background: roommateAnswer.includes(item)
                                    ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                                    : "#DFDFDF",
                                  color: roommateAnswer.includes(item)
                                    ? "white"
                                    : "#535353",
                                }}
                                className={classes.chipUserDetail}
                              />
                            </>
                          );
                          // else
                          //   return ""
                        })}
                      </div>

                      <div className={classes.lineHorizontal} />
                      <div className={classes.buttonGroup}>
                        <div>
                          {data[questionNo].is_skip && (
                            <Link
                              className={classes.skipButton}
                              onClick={() => {
                                onSkip();
                              }}
                            >
                              Skip
                            </Link>
                          )}
                        </div>

                        <Pagination
                          count={data.length}
                          hidePrevButton
                          hideNextButton
                          shape="rounded"
                          // color="primary"
                          size="small"
                          defaultPage={1}
                          page={questionNo + 1}
                          className={classes.pagination}
                          onChange={onPaginationChange}
                        />

                        {data.length > questionNo + 1 ? (
                          <Button
                            className={classes.nextButton}
                            variant="outlined"
                            color="primary"
                            // disabled={questionNo !== 0 ? roommateAnswer.length && answer.length ? false : true : answer.length ? false : true}
                            disabled={
                              roommateAnswer.length && answer.length
                                ? false
                                : true
                            }
                            onClick={() => {
                              onNext(false);
                            }}
                          >
                            {!loading ? (
                              "Next"
                            ) : (
                              <>
                                &nbsp;
                                <CircularProgress size={15} />
                              </>
                            )}
                          </Button>
                        ) : (
                          <Button
                            className={classes.nextButton}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              onFinish(false);
                            }}
                            disabled={
                              roommateAnswer.length && answer.length
                                ? false
                                : true
                            }
                          >
                            {!loading ? (
                              "Finish"
                            ) : (
                              <>
                                &nbsp;
                                <CircularProgress size={15} />
                              </>
                            )}
                          </Button>
                        )}
                      </div>
                    </Box>
                  </Grid>
                ) : (
                  <SkeletonLoading />
                )}
              </>
            ) : (
              <Grid item xl={12} lg={12} xs={12}>
                <Box className={classes.startQuizMain}>
                  <Box className={classes.startQuizBox}>
                    <p className={classes.startText}>Matching Quiz</p>
                  </Box>
                  <div className={classes.hrLine} />
                  <Box className={classes.answerBox}>
                    <Box className={classes.mainStartQuixBox}>
                      <img src="/images/startQuiz.svg" alt="startQuiz" />
                      <p className={classes.textNewStep}>
                        In this step, you’ll answer some questions <br />
                        that will help us match you with potential{" "}
                        {moduleNameChange(selected)}
                      </p>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonStart}
                        endIcon={
                          <img
                            src="/images/matchUp/startAerrow.png"
                            alt="startAerrow"
                          />
                        }
                        onClick={StartTheQuizHandler}
                      >
                        Start The Quiz
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} xl={12} lg={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={"260px"}
            style={{ borderRadius: "10px" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default QuizQuestions;
