import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Step,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
    // minHeight: "90vh"
  },
  propertyImage: {
    width: "100vw",
  },
  Findahome: {
    fontSize: "50px !important",
    color: "#FE4B6B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !important",
      width: "auto",
    },
    fontWeight: 700,
    margin: 0,
  },
  FindahomeSub: {
    fontSize: "35px !important",
    color: "#FE4B6B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      width: "auto",
    },
    fontWeight: 400,
    margin: 0,
  },
  propertyImageLabel: {
    position: "absolute",
    top: "100px",
    left: "200px",
    [theme.breakpoints.down("sm")]: {
      top: "20px",
      left: "10px",
    },
  },
  howItsWork: {
    fontSize: "40px !important",
    color: "#FE4B6B",
    fontWeight: 500,
    textAlign: "center",
    margin: 0,
  },
  howItsWorkStaperSub: {
    margin: "auto",
    textAlign: "center",
    fontSize: "14px",
    width: "275px",
  },
  aboutTitle: {
    fontSize: "25px !important",
    color: "#FE4B6B",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    // margin: 0
  },
  aboutTitleSub: {
    fontSize: "35px !important",
    color: "#FE4B6B",
    fontWeight: 500,
    margin: 0,
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  aboutImage1: {
    width: "24px",
  },
  propertyImage1: {
    width: "394px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",  
    },
    // [theme.breakpoints.down("lg")]: {
    //   width: "100%",
    // },
    height: "500px",
    objectFit: "cover",
    borderRadius: 10,
  },
  propertyTitle: {
    fontSize: "35px !important",
    color: "#FE4B6B",
    fontWeight: 500,
    margin: 0,
    // width: "500px",
    marginTop: "10px",
  },
  propertyTitle2: {
    fontSize: "24px !important",
    color: "#FE4B6B",
    fontWeight: 500,
    // width: "500px",
    margin: 0,
    marginTop: "10px",
  },
  propertyTitle3: {
    fontSize: "14px !important",
    color: "#FE4B6B",
    fontWeight: 400,
    margin: 0,
    // width: "500px",
    marginTop: "10px",
  },
  customerImage: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  customerName: {
    fontSize: "16px",
    color: "#FE4B6B",
    fontWeight: 400,
    margin: 0,
    marginTop: "10px",
  },
  customerCity: {
    fontSize: "12px",
    color: "#FE4B6B",
    fontWeight: 400,
    margin: 0,
  },
  preBtn: {
    width: "80px",
    height: "30px",
  },
  nextBtn: {
    width: "80px",
    height: "30px",
  },
  slider: {
    display: "flex",
    flexDirection: "column",
    padding: "0 30px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 ",
    },
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function getSteps() {
  return [
    {
      label: "Research Subursbs",
      subLabel:
        "Explore median unit and house prices, real estate market data, demography, and lifestyle information for suburbs.",
    },
    {
      label: "Instant Valuation",
      subLabel:
        "Our online valuation service gives you a free and instant estimate of your home's current value.",
    },
    {
      label: "Track Property",
      subLabel: "Find a suitable rental property or shared accommodation",
    },
  ];
}
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SearchIcon />,
    2: <GroupAddIcon />,
    3: <HomeIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const PrivacyPolicyDetail = (props) => {
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(3);

  useEffect(() => {
    // onLoad()
  }, []);

  // const onLoad = () => {
  //   // setIsLoading(true)
  //   // Api Call For Fetch The Privacy Policy
  //   api.get(`user/cms/faq3sp`)
  //     .then((result) => {
  //       if (result.success === "true") {
  //         setFaq(result.data.details)
  //       } else {
  //         console.log(result, "error")
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "catch error")
  //     });
  // };

  return (
    <>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} style={{ position: "relative" }}>
            <img
              src="/images/matchUp/propertyLanding.png"
              alt="propertyLanding"
              className={classes.propertyImage}
            />
            <div className={classes.propertyImageLabel}>
              <h1 className={classes.Findahome}> Find a home </h1>
              <h1 className={classes.FindahomeSub}>
                {" "}
                that suits your lifestyle{" "}
              </h1>
            </div>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            xl={12}
            lg={12}
            xs={12}
            style={{ background: "#FFF7F8", padding: "50px" }}
          >
            <Container>
              <h1 className={classes.howItsWork}> How it works? </h1>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
                style={{ background: "#FFF7F8" }}
              >
                {steps.map((obj) => (
                  <Step key={obj.label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {obj.label}
                    </StepLabel>
                    <div className={classes.howItsWorkStaperSub}>
                      {obj.subLabel}
                    </div>
                  </Step>
                ))}
              </Stepper>
            </Container>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            item
            xl={12}
            lg={12}
            xs={12}
            style={{
              background: "#FFF7F8",
              // padding: "50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Container>
              <h1 className={classes.howItsWork}> How it works? </h1>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                style={{ background: "#FFF7F8", alignItems: "center" }}
                orientation="vertical"
              >
                {steps.map((obj) => (
                  <Step key={obj.label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {obj.label}
                    </StepLabel>
                    <div className={classes.howItsWorkStaperSub}>
                      {obj.subLabel}
                    </div>
                  </Step>
                ))}
              </Stepper>
            </Container>
          </Grid>
        </Hidden>
        <Grid item xl={12} lg={12} xs={12}>
          <Container>
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
              style={{ background: "white", margin: "100px 0" }}
            >
              <h1 className={classes.aboutTitle}> About Us </h1>
              <h1 className={classes.aboutTitleSub}>
                The Benefit that will <br />
                make you comfort
              </h1>
              <Grid container style={{ marginTop: 30 }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  style={{ display: "flex", marginTop: 15 }}
                  justify="center"
                >
                  <div style={{ marginRight: "20px" }}>
                    <img
                      src="/images/matchUp/aboutImage1.svg"
                      alt="propertyLanding"
                      className={classes.aboutImage1}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        marginBottom: "15px",
                      }}
                    >
                      Private Security
                    </div>
                    <div style={{ fontSize: "14px" }}>
                      Explore median unit and house prices, real estate market
                      data, demography, and lifestyle information for suburbs.
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  style={{ display: "flex", marginTop: 15 }}
                  justify="center"
                >
                  <div style={{ marginRight: "20px" }}>
                    <img
                      src="/images/matchUp/aboutImage2.svg"
                      alt="propertyLanding"
                      className={classes.aboutImage1}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        marginBottom: "15px",
                      }}
                    >
                      Parking Space
                    </div>
                    <div style={{ fontSize: "14px" }}>
                      Explore median unit and house prices, real estate market
                      data, demography, and lifestyle information for suburbs.
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  style={{ display: "flex", marginTop: 15 }}
                  justify="center"
                >
                  <div style={{ marginRight: "20px" }}>
                    <img
                      src="/images/matchUp/aboutImage3.svg"
                      alt="propertyLanding"
                      className={classes.aboutImage1}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        marginBottom: "15px",
                      }}
                    >
                      Clean Environment
                    </div>
                    <div style={{ fontSize: "14px" }}>
                      Explore median unit and house prices, real estate market
                      data, demography, and lifestyle information for suburbs.
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        {/* <Grid container item xl={10} lg={10} xs={12} style={{ background: "white" }} >
          <h1 className={classes.aboutTitle}> About Us </h1>
          <h1 className={classes.aboutTitleSub}>
            The Benefit that will
            <br /> make you comfort{" "}
          </h1>
          <Grid container style={{ marginTop: "50px" }}>
            <Grid
              item
              md={4}
              lg={4}
              sm={12}
              style={{ display: "flex" }}
              justify="center"
            >
              <div style={{ marginRight: "20px" }}>
                <img
                  src="/images/matchUp/aboutImage1.svg"
                  alt="propertyLanding"
                  className={classes.aboutImage1}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginBottom: "15px",
                  }}
                >
                  Private Security
                </div>
                <div style={{ fontSize: "14px", width: "275px" }}>
                  Explore median unit and house prices, real estate market data,
                  demography, and lifestyle information for suburbs.
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              lg={4}
              sm={12}
              style={{ display: "flex" }}
              justify="center"
            >
              <div style={{ marginRight: "20px" }}>
                <img
                  src="/images/matchUp/aboutImage2.svg"
                  alt="propertyLanding"
                  className={classes.aboutImage1}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginBottom: "15px",
                  }}
                >
                  Parking Space
                </div>
                <div style={{ fontSize: "14px", width: "275px" }}>
                  Explore median unit and house prices, real estate market data,
                  demography, and lifestyle information for suburbs.
                </div>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              lg={4}
              sm={12}
              style={{ display: "flex" }}
              justify="center"
            >
              <div style={{ marginRight: "20px" }}>
                <img
                  src="/images/matchUp/aboutImage3.svg"
                  alt="propertyLanding"
                  className={classes.aboutImage1}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginBottom: "15px",
                  }}
                >
                  Clean Environment
                </div>
                <div style={{ fontSize: "14px", width: "275px" }}>
                  Explore median unit and house prices, real estate market data,
                  demography, and lifestyle information for suburbs.
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid> */}

        <Grid
          container
          item
          xl={12}
          lg={12}
          xs={12}
          style={{
            justifyContent: "center",
            backgroundImage: "linear-gradient(to bottom,white 50%,#FFF7F8 50%)",
            marginTop: "75px",
            paddingTop: "75px",
            paddingBottom: "50px",
          }}
        >
          {/* <Box style={{ display: "flex", justifyContent: "center" }}> */}
          <Grid
            item
            xl={3}
            lg={4}
            md={4}
            sm={6}
            xs={10}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src="/images/matchUp/property2.png"
              alt="propertyLanding"
              className={classes.propertyImage1}
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={5}
            sm={6}
            xs={8}
            className={classes.slider}
            // style={{ marginLeft: "30px" }}
          >
            {/* <div style={{ height: "38%" }}> */}
            <h1 className={classes.propertyTitle}>
              Comments from our
              <br /> happy hosters
            </h1>
            {/* </div> */}
            {/* <div style={{ height: "13%" }}> */}
            <img
              src="/images/matchUp/quote.svg"
              width={"100px"}
              alt="propertyLanding"
              className={classes.quteImage}
              style={{ marginTop: 40 }}
            />
            {/* </div> */}
            {/* <div style={{ height: "52%", paddingTop: "10px" }}> */}
            <h1 className={classes.propertyTitle2}>Thank you so much </h1>
            <h1 className={classes.propertyTitle3}>
              I love hosting my eco-home so people can <br /> connect with
              nature and their loved ones.
            </h1>
            <div style={{ display: "flex", margin: "20px 0px" }}>
              <img
                src="/images/matchUp/bsol1.jpg"
                alt="propertyLanding"
                className={classes.customerImage}
              />
              <div style={{ marginLeft: "10px" }}>
                <div className={classes.customerName}>Daizy Johnson</div>
                <div className={classes.customerCity}>New York</div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <Button
                variant="outlined"
                className={classes.preBtn}
                color="primary"
              >
                <ArrowBackIcon />
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                className={classes.nextBtn}
                color="primary"
              >
                <ArrowForwardIcon />
              </Button>
            </div>
            {/* </div> */}
          </Grid>
          {/* </Box> */}
        </Grid>
      </div>
    </>
  );
};

export default PrivacyPolicyDetail;
