import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import Forgotpassword from "components/AuthModel/Forgotpassword";

const useStyles = makeStyles((theme) => ({
  Findahome_text: {
    fontSize: "18px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#FE4B6B",
    fontWeight: 400,
    margin: "10px 0",
    letterSpacing: "5px",
  },
  Findahome: {
    fontSize: "30px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#FE4B6B",
    fontWeight: 700,
    margin: "10px 0",
    // width: "60%",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
  Findahome_Detail: {
    fontSize: "18px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#656464",
    fontWeight: 300,
    margin: "10px 0",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  join_Text: {
    fontSize: "18px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#656464",
    fontWeight: 400,
    margin: "15px 0",
    width: "70%",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  join_icon: {
    top: 2,
    position: "relative",
    marginLeft: 10,
    cursor: "pointer",
  },
  images: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0",
    },
  },
  gridRoot: {
    // [theme.breakpoints.down("xs")]: {
      padding: "0 50px",
    // },
  },
}));

const TeammateCommunity = (props) => {
  const classes = useStyles();
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onLoginOpen = () => {
    setLoginOpen(true);
    setForgotpasswordOpen(false);
    setOpen(false);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true);
  };

  const onSignupOpen = () => {
    setLoginOpen(false);
    setOpen(true);
  };

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
  };

  return (
    <>
      <div>
        <Grid
          container
          justify="center"
          style={{ height: "100%", margin: "150px 0" }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={4}
            xl={4}
            className={classes.imageList}
          >
            <img
              className={classes.images}
              src="/images/matchUp/TeammatesGridImage4.jpg"
              alt="TeammatesGridImage"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={5}
            className={classes.gridRoot}
          >
            <h5 className={classes.Findahome_text}>TEAMMATES</h5>
            <h1 className={classes.Findahome}>Teammate Community</h1>
            <h4 className={classes.Findahome_Detail}>
              Teammates can help boost morale and make the work environment
              better for everyone. MatchUpMates will help you create a stronger
              bond with your teammates.
            </h4>
            <h3 className={classes.join_Text} onClick={handleClickOpenLogin}>
              Join Now
              <img
                className={classes.join_icon}
                src="/images/matchUp/JoinNoIcon.svg"
                alt="JoinNoIcon"
                onClick={handleClickOpenLogin}
              />
            </h3>
          </Grid>
        </Grid>

        <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
        <LoginModel
          loginOpen={loginOpen}
          setLoginOpen={setLoginOpen}
          setOpenSignUp={onSignupOpen}
          setForgotpasswordOpen={onForgotpasswordOpen}
        />
        <Forgotpassword
          loginOpen={forgotpasswordOpen}
          setLoginOpen={onLoginOpen}
          setOpenSignUp={onSignupOpen}
        />
      </div>
    </>
  );
};

export default TeammateCommunity;
