import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Payment from "./Payment.js"
import moment from 'moment';
import Api from '../../../../Helper/ApiHandler';
import NoRecordFound from "views/NoRecordFound/index.js";
import PerfectScrollbar from 'react-perfect-scrollbar';

var api = new Api();

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    minHeight: "90vh",
    padding: "35px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "30px 10px 18px 10px"
  },
  mainAppTab: {
    backgroundColor: "#E5E5E5",
    color: "black",
    boxShadow: "none",
    textTransform: "capitalize",
    borderBottom: `1px solid gray`,
  },
  tabNameLabel: {
    textTransform: "capitalize",
    fontWeight: "bold",
    minWidth: "100px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "120px",
    },
  },
  mainCardDetail: {
    background: "white",
    position: "relative",
    // height: "350px",
    height: "200px",
    borderRadius: "10px",
  },
  halfColourDiv: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    height: "80px",
    textAlign: "center",
    color: "white",
    borderRadius: "10px 10px 0 0",
  },
  headingPlan: {
    paddingTop: "10px",
    fontSize: "15px",
    fontWeight: "400",
    marginTop: "0px"
  },
  whitePaperDetail: {
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    width: "85%",
    margin: "0 auto",
    // height: "230px",
    height: "90px",
    position: "absolute",
    top: "50px",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "5px",
    background: "white",
    textAlign: "center",
  },
  priceMates: {
    margin: "0",
    marginTop: "5px",
    marginBottom: "5px",
    fontWeight: "500"
  },
  timeValueSubs: {
    margin: "0",
    color: "gray",
    fontSize: "14px",
  },
  detailCardSubs: {
    margin: "0",
    fontSize: "13px",
    paddingTop: "5px",
  },
  deatilBox: {
    textAlign: "left",
    paddingTop: "10px",
    paddingLeft: "8px",
  },
  imageDetail: {
    marginRight: "5px",
  },
  lastBtnFlex: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: "-7px",
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    justifyContent: "center",
    color: "#36D860",
  },
  buttonCardSelect: {
    position: "absolute",
    bottom: "15px",
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    color: "#36D860",
    textAlign: "center",
  },
  buttonSelect: {
    textTransform: "capitalize",
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)',
    fontWeight: "400",
    height: "30px"
  },
  responsiveHide: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    [theme.breakpoints.only('md')]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only('lg')]: {
      width: "28%",
    },
    [theme.breakpoints.down('xl')]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: "28px !important",
      width: "66.67%",
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: "28px !important",
      width: "72%",
    },
    [theme.breakpoints.only('xl')]: {
      paddingLeft: "28px !important",
      width: "72%",
    },
  },
  bottomLastCard: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px"
    },
  },
  socialIcon: {
    position: "absolute",
    right: "-10px",
    top: "-24px",
    cursor: "pointer"
  },
  tableRoot: {
    "& .MuiTable-root": {
      border: "1px solid gray",
      width: "100%",
      display: "table",
      // borderRadius: "10px",
      borderSpacing: "inherit",
      borderCollapse: "inherit"
    }
  }
}));

const Subscription = (props) => {
  const classes = useStyles();
  const [objSubscription, setLstSubscription] = React.useState([]);
  const [lstSub, setLstSub] = React.useState([]);
  const [lstPaymentLog, setLstPaymentLog] = React.useState([]);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState(false);
  const [paymentAmount, setPaymentAmount] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [planExpire, setPlanExpire] = React.useState(undefined);

  useEffect(() => {
    onLoad()
  }, []);


  const onLoad = () => {

    // api/user/paymentlog
    api.get(`user/paymentlog`)
      .then((result) => {
        if (result.success === "true") {
          setLstPaymentLog(result.data.user)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });


    // Api Call For Fetch The User Data
    api.get(`user/getsubscrlist`)
      .then((result) => {
        if (result.success === "true") {
          let lstTmp = []
          // lstTmp.push({
          //   plan_name: "free_amount",
          //   price: result.data.free_amount,
          //   time: "7 day",
          //   is_current: result.data.subscription_type === "free_amount" ? true : false
          // })
          // day30_amount: 500
          // day90_amount: 800
          // day180_amount: 1000
          // day365_amount: 1200
          // id: 1368
          // module_id: 4
          // module_name: "Soulmates"
          // subscription_date: "trial"
          // subscription_type: "trial"
          lstTmp.push({
            plan_name: "day30_amount",
            price: result.data.day30_amount,
            time: "1 Month",
            is_current: result.data.subscription_type === "day30_amount" ? true : false
          })
          lstTmp.push({
            plan_name: "day90_amount",
            price: result.data.day90_amount,
            time: "3 Month",
            is_current: result.data.subscription_type === "day90_amount" ? true : false
          })
          lstTmp.push({
            plan_name: "day180_amount",
            price: result.data.day180_amount,
            time: "6 Month",
            is_current: result.data.subscription_type === "day180_amount" ? true : false
          })
          lstTmp.push({
            plan_name: "day365_amount",
            price: result.data.day365_amount,
            time: "1 Year",
            is_current: result.data.subscription_type === "day365_amount" ? true : false
          })
          setLstSub(lstTmp)
          setLstSubscription(result.data)
          setPlanExpire(result.data.subscription_date)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  };

  const onSelectePlan = async (event, type) => {
    let data = {
      module_id: 1,
      subscription_type: type
    }
    // user/createusersubscr
    api.post('user/createusersubscr', {
      data: data
    })
      .then((result) => {
        if (result.success === "true") {
          console.log(result, "result")
        } else {
          console.log(result, "success === false")
        }
      })
      .catch((err) => {
        console.log(err, "error catch")
      });
  }

  const handleClosePayment = (value) => {
    setOpenPayment(false);
    setSelectedValue(value);
    onLoad()
  };

  return (
    <>
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>

            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item style={{ padding: "10px" }}>
              <h4 className={classes.headingPage}> Subscription Plans </h4>
              {/* <hr /> */}
              <Grid spacing={2} container>
                {lstSub.map((obj) => {
                  return (
                    <Grid item xl={3} lg={3} xs={12} sm={6}>
                      <div className={classes.mainCardDetail}>
                        <div className={classes.halfColourDiv}>
                          <h4 className={classes.headingPlan}> {obj.time} </h4>
                        </div>
                        <div className={classes.whitePaperDetail}>
                          <h2 className={classes.priceMates}>
                            {`${obj.price}$`}
                          </h2>
                        </div>
                        <div className={classes.buttonCardSelect}>
                          {obj.is_current ?
                            // <div className={classes.lastBtnFlex}>
                            //   <img
                            //     src="/images/matchUp/active.png"
                            //     style={{ marginRight: "5px" }}
                            //     alt="actvie sticker"
                            //   />
                            //   <p> Current Plan </p>
                            // </div>
                            <Button
                              className={classes.buttonSelect}
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                // onBuy(obj.plan_name)
                                setOpenPayment(true)
                                setPaymentAmount(obj.price)
                                setPaymentType(obj.plan_name)
                                // console.log(obj, "ooooooo")
                              }}
                            >
                              Select plan
                            </Button>
                            :
                            <Button
                              className={classes.buttonSelect}
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                // onBuy(obj.plan_name)
                                setOpenPayment(true)
                                setPaymentAmount(obj.price)
                                setPaymentType(obj.plan_name)
                                // console.log(obj, "ooooooo")
                              }}
                            >
                              Select plan
                            </Button>
                          }
                        </div>
                      </div>
                    </Grid>
                  )
                })}
                {planExpire !== "trial" && <Grid item xs={12} style={{ display: "flex", justifyContent: "center", marginTop: "35px" }}>
                  <div style={{ fontSize: "16px", fontWeight: "400" }}>
                    Your plan will be expired on
                  </div>&nbsp;&nbsp;
                  <div
                    style={moment(planExpire).diff(moment(), 'days') <= 7 ? { fontSize: "16px", fontWeight: "400", color: "red" } : { fontSize: "16px", fontWeight: "400", color: "green" }}
                  >
                    {moment(planExpire).format("DD/MM/YYYY")}
                  </div>
                </Grid>}
                <Grid item xs={12} className={classes.tableRoot}>
                  <PerfectScrollbar style={{ overflowX: "auto" }}>
                    <Table style={{ overflowX: "auto" }}>
                      <TableHead style={{ background: "#c9c9c9" }}>
                        <TableRow>
                          <TableCell>Transaction ID</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell align="right">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lstPaymentLog.length ? (
                          lstPaymentLog.map((transaction, index) => (
                            <TableRow hover key={transaction.id}>
                              <TableCell>
                                {transaction.transaction_id != null
                                  ? transaction.transaction_id
                                  : ''}
                              </TableCell>
                              <TableCell>
                                {moment(transaction.payment_date).format('LL')}
                              </TableCell>
                              <TableCell>Morgan John</TableCell>
                              <TableCell>{transaction.amount}</TableCell>
                              <TableCell
                                className={
                                  transaction.status == 'Completed' && classes.statusCompleted ||
                                  transaction.status == 'Pending' && classes.statusPending ||
                                  transaction.status == 'Processed' && classes.statusProcessing
                                }
                                align="right">
                                {transaction.status}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <NoRecordFound loading={false} noOfColumn={5} />
                        )}
                      </TableBody>
                    </Table>

                  </PerfectScrollbar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Dialog onClose={handleClosePayment} aria-labelledby="simple-dialog-title" open={openPayment} maxWidth={"lg"}>
            <DialogTitle id="simple-dialog-title">
              <div style={{ fontSize: "18px" }}>
                Subscription Payment
              </div>
              <div style={{ display: "flex", position: "relative" }}>
                <CloseIcon className={classes.socialIcon} onClick={handleClosePayment} />
              </div>
            </DialogTitle>
            <Payment paymentType={paymentType} paymentAmount={paymentAmount} handleClosePayment={handleClosePayment} />
          </Dialog>
          {/* <CheckoutForm /> */}
        </Container>
      </div>
    </>
  );
};

export default Subscription;
