import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
  },
  howItsWorkRoot: {
    // backgroundImage: "linear-gradient(to bottom,#FFF7F8 50%,white 50%)",
    background: "#FFF7F8",
    // minHeight: "900px",
    position: "relative",
  },
  howItsWorkPrimaryTitle: {
    fontSize: "32px",
    color: "#FE4B6B",
    fontWeight: "600",
    textAlign: "center",
    paddingTop: "20px",
  },
  howItsWorkSubTitle: {
    background: "#FE4B6B",
    height: 2,
    margin: "40px auto",
    // marginTop: 40,
    width: "15%",
  },

  // card
  howItsWorkCardRoot: {
    position: "absolute",
    top: "250px",
    display: "flex",
    justifyContent: "center",
  },
  cardPossition: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  howItsWorkCard: {
    width: "300px",
    borderRadius: "20px",
    zIndex: 1000,
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  howItsWorkCard2: {
    height: "400px",
    width: "300px",
    boxShadow: "4px 8px 22px rgba(189, 188, 188, 0.4)",
    borderRadius: "20px",
    background: "white",
    [theme.breakpoints.up("md")]: {
      marginTop: "80px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardCenter: {
    display: "flex",
    justifyContent: "center",
  },
  dormmateLandingCardImage: {},
  // dormmateLandingCardText: {
  soulmateLandingCardTitle: {
    fontSize: "20px",
    fontWeight: 600,
    marginTop: "20px",
    textAlign: "center",
  },
  soulmateLandingCardText: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: "10px",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  Wave: {
    position: "absolute",
    bottom: 0,
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  Wave_Two: {
    position: "absolute",
    right: 0,
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
}));

const SoulmateLandingTrust = (props) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} className={classes.howItsWorkRoot}>
            <img
              src="/images/matchUp/SoulmateLandingWave1.svg"
              alt="SoulmateLandingWave1"
              className={classes.Wave}
            />
            <img
              src="/images/matchUp/SoulmateLandingWave1.svg"
              alt="SoulmateLandingWave1"
              className={classes.Wave_Two}
            />
            <div className={classes.howItsWorkSubTitle}></div>
            <div className={classes.howItsWorkPrimaryTitle}>
              Trusted site to find your Perfacte Soulmate
            </div>
            <Container maxWidth={"lg"}>
              <Grid item xl={12} lg={12} xs={12}>
                <Box
                  display={"flex"}
                  justifyContent={"space-around"}
                  mt={5}
                  mb={5}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classes.cardCenter}
                    >
                      <Box className={classes.howItsWorkCard}>
                        <img
                          src="/images/matchUp/SoulmateLandingTrustIcon-1.svg"
                          alt="SoulmateLandingTrustIcon-1"
                          className={classes.dormmateLandingCardImage}
                        />
                        <div className={classes.soulmateLandingCardTitle}>
                          Verified Profiles
                        </div>
                        <div className={classes.soulmateLandingCardText}>
                          Every profile is human-verified for its authenticity
                        </div>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classes.cardCenter}
                    >
                      <Box className={classes.howItsWorkCard}>
                        <img
                          src="/images/matchUp/SoulmateLandingTrustIcon-2.svg"
                          alt="SoulmateLandingTrustIcon-2.svg"
                          className={classes.dormmateLandingCardImage}
                        />
                        <div className={classes.soulmateLandingCardTitle}>
                          Extensive Privacy Controls
                        </div>
                        <div className={classes.soulmateLandingCardText}>
                          Control your profile visibility, private photos,
                          incognito mode, and more
                        </div>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classes.cardCenter}
                    >
                      <Box className={classes.howItsWorkCard}>
                        <img
                          src="/images/matchUp/SoulmateLandingTrustIcon-3.svg"
                          alt="SoulmateLandingTrustIcon-3.svg"
                          className={classes.dormmateLandingCardImage}
                        />
                        <div className={classes.soulmateLandingCardTitle}>
                          Contact Filters
                        </div>
                        <div className={classes.soulmateLandingCardText}>
                          Filter out those who do not meet your expectations
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} xs={12}>
                <Box
                  display={"flex"}
                  justifyContent={"space-around"}
                  mt={5}
                  mb={5}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classes.cardCenter}
                    >
                      <Box className={classes.howItsWorkCard}>
                        <img
                          src="/images/matchUp/SoulmateLandingTrustIcon-4.svg"
                          alt="SoulmateLandingTrustIcon-4.svg"
                          className={classes.dormmateLandingCardImage}
                        />
                        <div className={classes.soulmateLandingCardTitle}>
                          Instant Chat
                        </div>
                        <div className={classes.soulmateLandingCardText}>
                          Chat live with members online or leave offline
                          messages
                        </div>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classes.cardCenter}
                    >
                      <Box className={classes.howItsWorkCard}>
                        <img
                          src="/images/matchUp/SoulmateLandingTrustIcon-5.svg"
                          alt="SoulmateLandingTrustIcon-5.svg"
                          className={classes.dormmateLandingCardImage}
                        />
                        <div className={classes.soulmateLandingCardTitle}>
                          Curated Matches
                        </div>
                        <div className={classes.soulmateLandingCardText}>
                          Daily curated matches that meet your expectations
                        </div>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className={classes.cardCenter}
                    >
                      <Box className={classes.howItsWorkCard}>
                        <img
                          src="/images/matchUp/SoulmateLandingTrustIcon-6.svg"
                          alt="SoulmateLandingTrustIcon-6.svg"
                          className={classes.dormmateLandingCardImage}
                        />
                        <div className={classes.soulmateLandingCardTitle}>
                          Advanced Search
                        </div>
                        <div className={classes.soulmateLandingCardText}>
                          12+ search filters that help you discover that special
                          someone
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SoulmateLandingTrust;
