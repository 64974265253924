import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, Chip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slider from "@material-ui/core/Slider";

import Api from "../../../../Helper/ApiHandler";

var api = new Api();

const useStyles = makeStyles((theme) => ({
  root: {},
  headerModel: {
    height: "70px",
    width: "100%",
    // background: "#0071CE",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  },
  resultHead: {
    margin: "0",
    padding: "0px 30px",
    color: "white",
    lineHeight: "70px",
    fontWeight: 500,
    fontSize: "20px !important",
    //fontFamily: "Gilroy !important",
  },
  subHeading: {
    margin: "0",
    padding: "10px 40px",
    fontWeight: "600",
    fontSize: "16px !important",
  },
  chipUserDetail: {
    margin: "5px 5px",
    padding: "0 5px",
    color: "#22346E",
    fontWeight: "400",
    //fontFamily: "Gilroy !important",
  },
  chipGruop: {
    padding: "5px 30px",
  },
  chipBoxTotal: {
    padding: "20px 0",
    overflowY: "scroll",
    height: "350px",
  },
  startedButtonBox: {
    textAlign: "center",
    marginTop: "14px",
    marginBottom: "14px",
  },
  closeButton: {
    width: 150,
    height:"40px",
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    // background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)'
  },
  startedButton: {
    width: 150,
    height:"40px",
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  },
  closeIcon: {
    position: "absolute",
    right: "0",
    top: "10px",
    color: "white",
  },
  socialIcon: {
    position: "absolute",
    // right: "35px",
    right: "20px",
    top: "15px",
    color: "white",
  },
  rootModel: {
    "& .MuiDialog-paperScrollPaper": {
      height: "500px",
    },
  },
  SliderRoot: {
    display: "flex",
    justifyContent: "center",
    "& .MuiSlider-root": {
      width: "80%",
    },
  },
}));
const lstPetPolicy = [
  { key: "Cats Ok", value: "Cats Ok" },
  { key: "Dogs Ok", value: "Dogs Ok" },
  { key: "Cats & Dogs Ok", value: "Cats & Dogs Ok" },
  { key: "No Pets", value: "No Pets" },
];

const FilterModelRoommate = ({ filterOpen, setFilterOpen, setMaltiFilter }) => {
  const classes = useStyles();
  const [selectedZodiac, setSelectedZodiac] = React.useState([]);
  const [selectedPet, setSelectedPet] = React.useState([]);
  const [selectedActivities, setSelectedActivities] = React.useState([]);
  const [selectedPrice, setSelectedPrice] = React.useState([]);
  const [selectedLifeStyle, setSelectedLifeStyle] = React.useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = React.useState([]);
  const [lstZodiac, setLstZodiac] = React.useState([]);
  const [lstPlannedActivities, setLstPlannedActivities] = React.useState([]);
  const [lstPrice, setLstPrice] = React.useState([
    { key: "any", value: "ANY" },
    { key: "100-300", value: "$100-$300" },
    { key: "300-500", value: "$300-$500" },
    { key: "500-750", value: "$500-$750" },
    { key: "750-999", value: "$750-$999" },
    { key: "above-1000)", value: "ABOVE $1000)" },
  ]);
  const [lstInterests, setLstInterests] = React.useState([]);
  const [lstPropertyType, setLstPropertyType] = React.useState([
    { key: "Apartments", value: "Apartments" },
    { key: "Townhouse", value: "Townhouse" },
    { key: "Condos", value: "Condos" },
    { key: "Secondary Unit", value: "Secondary Unit" },
    { key: "Bed & Breakfast", value: "Bed & Breakfast" },
    { key: "House", value: "House" },
    { key: "Unique Space", value: "Unique Space" },
    { key: "Boutique hotel", value: "Boutique hotel" },
  ]);
  const [range, setRange] = React.useState(25);

  useEffect(() => {
    getRefineList();
  }, []);
  // -----------------------------------
  // Get Refine List
  const getRefineList = async () => {
    // Api Call For Fetch Refine List List
    api
      .get(`user/amenitieslist`)
      .then((result) => {
        if (result.success === "true") {
          // setLstInterests(result.data)
          result.data.map((obj) => {
            if (obj.key === "Amenities") {
              setLstInterests(obj.value);
            } else if (obj.key === "Beds") {
              setLstZodiac(obj.value);
            } else if (obj.key === "Bathrooms") {
              setLstPlannedActivities(obj.value);
            }
            return [];
          });
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  // Select Pet policy
  const onPetChange = (item) => {
    let selected = [...selectedPet];
    selected[0] = item;
    console.log(selected, "selected");
    setSelectedPet(selected);
  };

  // Select Zodiac
  const onZodiacChange = (item) => {
    let selected = [...selectedZodiac];
    selected[0] = item;
    setSelectedZodiac(selected);
  };

  // Select Activities
  const onActivitiesChange = (item) => {
    let selected = [...selectedActivities];
    selected[0] = item;
    setSelectedActivities(selected);
  };

  // Select price
  const onPriceChange = (item) => {
    let selected = [...selectedPrice];
    selected[0] = item;
    setSelectedPrice(selected);
  };

  // Select LifeStyle
  const onLifeStyleChange = (item) => {
    let selected = [...selectedLifeStyle];
    const index = selected.indexOf(item);
    if (selected.includes(item)) {
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }
    setSelectedLifeStyle(selected);
  };

  // Select property type
  const onPropertyTypeChange = (item) => {
    let selected = [...selectedPropertyType];
    const index = selected.indexOf(item);
    if (selected.includes(item)) {
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }
    setSelectedPropertyType(selected);
  };

  // Apply
  const onApply = () => {
    // let b = [...selectedZodiac].join()
    // let bt = [...selectedActivities].join()
    // let a = [...selectedLifeStyle].join()

    // let obj = {
    //   b: b,
    //   bt: bt,
    //   a: a,
    // }
    let obj = {};
    if (selectedZodiac.length > 0) {
      obj.b = [...selectedZodiac].join();
    }
    if (selectedActivities.length > 0) {
      obj.bt = [...selectedActivities].join();
    }
    if (selectedLifeStyle.length > 0) {
      obj.a = [...selectedLifeStyle].join();
    }
    if (selectedPet.length > 0) {
      obj.pet = [...selectedPet].join();
    }
    if (selectedPropertyType.length > 0) {
      obj.ptype = [...selectedPropertyType].join();
    }
    if (selectedPrice.length > 0) {
      obj.price = [...selectedPrice].join();
    }

    if (range) {
      obj.radius = range;
    }

    setMaltiFilter(obj);
    setFilterOpen(false);
  };

  // -----------------------------------

  const handleCloseSignUp = () => {
    setFilterOpen(false);
  };

  const closeHandlerModel = () => {
    setFilterOpen(false);
  };

  const clearFilter = () => {
    setSelectedZodiac([]);
    setSelectedActivities([]);
    setSelectedLifeStyle([]);
    setSelectedPrice([]);
    setSelectedPropertyType([]);
    setSelectedPet([]);
    let obj = {
      b: "",
      bt: "",
      a: "",
      pet: "",
      ptype: "",
      price: "",
    };
    setFilterOpen(false);
    setMaltiFilter(obj);
  };

  function valuetext(value) {
    setRange(value);
    return `${value}°C`;
  }
  // console.log(range, "range")
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={filterOpen}
        onClose={handleCloseSignUp}
        aria-labelledby="max-width-dialog-title"
        className={classes.rootModel}
      >
        <Grid container>
          <Grid item sm={12} xs={12}>
            <div className={classes.headerModel}>
              <Button
                className={classes.socialIcon}
                onClick={clearFilter}
                variant="outlined"
              >
                Clear
              </Button>
              <h2 className={classes.resultHead}> Refine Your Result </h2>
            </div>
          </Grid>
          <div className={classes.chipBoxTotal}>
            {/* <Grid item sm={12} xs={12}>
              <h3 className={classes.subHeading}> Pet Policy </h3>
              <div className={classes.chipGruop}>
                {lstPetPolicy.map((item) => (
                  <Chip
                    className={classes.chipUserDetail}
                    label={item.value}
                    onClick={() => {
                      onPetChange(item.key);
                    }}
                    style={{
                      background: selectedPet.includes(item.key)
                        ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                        : "lightgray",
                      color: selectedPet.includes(item.key) ? "white" : "black",
                    }}
                  />
                ))}
              </div>
            </Grid> */}

            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> Property type </h3>
                <div className={classes.chipGruop}>
                  {lstPropertyType.map((item) => {
                    return (
                      <Chip
                        className={classes.chipUserDetail}
                        label={item.value}
                        onClick={() => {
                          onPropertyTypeChange(item.key);
                        }}
                        style={{
                          background: selectedPropertyType.includes(item.key)
                            ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                            : "lightgray",
                          color: selectedPropertyType.includes(item.key)
                            ? "white"
                            : "black",
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> Price </h3>
                <div className={classes.chipGruop}>
                  {lstPrice.map((item) => (
                    <Chip
                      className={classes.chipUserDetail}
                      label={item.value}
                      onClick={() => {
                        onPriceChange(item.key);
                      }}
                      style={{
                        background: selectedPrice.includes(item.key)
                          ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                          : "lightgray",
                        color: selectedPrice.includes(item.key)
                          ? "white"
                          : "black",
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <h3 className={classes.subHeading}> Range (miles) </h3>
              <div className={classes.SliderRoot}>
                <Slider
                  defaultValue={range}
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="on"
                  step={10}
                  // marks
                  min={10}
                  max={100}
                />
              </div>
            </Grid>
            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> No. of Beds</h3>
                <div className={classes.chipGruop}>
                  {lstZodiac.map((item) => (
                    <Chip
                      className={classes.chipUserDetail}
                      label={item.value}
                      onClick={() => {
                        onZodiacChange(item.key);
                      }}
                      style={{
                        background: selectedZodiac.includes(item.key)
                          ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                          : "lightgray",
                        color: selectedZodiac.includes(item.key)
                          ? "white"
                          : "black",
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> Bathrooms </h3>
                <div className={classes.chipGruop}>
                  {lstPlannedActivities.map((item) => (
                    <Chip
                      className={classes.chipUserDetail}
                      label={item.value}
                      onClick={() => {
                        onActivitiesChange(item.key);
                      }}
                      style={{
                        background: selectedActivities.includes(item.key)
                          ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                          : "lightgray",
                        color: selectedActivities.includes(item.key)
                          ? "white"
                          : "black",
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item sm={12} xs={12}>
              <div>
                <h3 className={classes.subHeading}> Aminities </h3>
                <div className={classes.chipGruop}>
                  {lstInterests.map((item) => {
                    return (
                      <Chip
                        className={classes.chipUserDetail}
                        label={item.value}
                        onClick={() => {
                          onLifeStyleChange(item.value);
                        }}
                        style={{
                          background: selectedLifeStyle.includes(item.value)
                            ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)"
                            : "lightgray",
                          color: selectedLifeStyle.includes(item.value)
                            ? "white"
                            : "black",
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </Grid>
          </div>
          <Grid item sm={12} xs={12}>
            <hr />
            <Box mb={3} mt={3} className={classes.startedButtonBox}>
              <Button
                className={classes.closeButton}
                onClick={closeHandlerModel}
                variant="outlined"
                // size="large"
                // color="primary"
              >
                Close
              </Button>
              &nbsp;&nbsp;
              <Button
                className={classes.startedButton}
                variant="contained"
                size="large"
                color="primary"
                disabled={
                  !range &&
                  !selectedZodiac.length &&
                  !selectedActivities.length &&
                  !selectedLifeStyle.length
                    ? true
                    : false
                }
                onClick={() => {
                  onApply();
                }}
              >
                Apply
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default FilterModelRoommate;
