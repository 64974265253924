import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRouter from "utils/useRouter";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Checkbox, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {

  }
}));

const CheckboxType = (props) => {
  const { selected, setSelected, ans, setAns, gridNum, options } = props;
  const classes = useStyles();
  const router = useRouter();
  // const [selected, setSelected] = React.useState(ans ? ans : []);

  const handleChange = (value) => {
    let selected_value = [...selected]
    let index = selected_value.indexOf(value)
    if (index > -1) {
      selected_value.splice(index, 1)
    } else {
      selected_value.push(value)
    }
    setSelected(selected_value)
    setAns(selected_value)
  };
  useEffect(()=>{
    setSelected(ans)
    setAns(ans)
  },[])
  console.log(selected, "selected_value")
  return (
    <div className={classes.root}>
      <FormGroup>
        <Grid container>
          {options.map((value) => {
            return (
              <Grid item xs={12} sm={12} md={gridNum}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected?.indexOf(value) !== -1 ? true : false}
                      onChange={() => handleChange(value)}
                      name={value}
                      color="primary"
                    />}
                  label={value}
                />
              </Grid>
            )
          })}
        </Grid>
      </FormGroup>
    </div>
  );
};

export default CheckboxType;
