import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TelegramIcon from "@material-ui/icons/Telegram";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { Link as browserLink, useLocation } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Checkbox from "@material-ui/core/Checkbox";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import ReactFancyBox from "react-fancybox";
import useRouter from "utils/useRouter";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";
import BlockIcon from "@material-ui/icons/Block";
import Cookies from 'js-cookie';

import Api from "../../../../Helper/ApiHandler";
import Fancybox from "components/fancybox";
var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    // marginBottom: "60px",
    minHeight: "calc(100vh - 125px)",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  textUserDetail: {
    // margin: "0",
    color: "gray",
    margin: "5px 0",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkitLineClamp": 3,
    "-webkitBoxOrient": "vertical",
    [theme.breakpoints.down("sm")]: {
      "-webkitLineClamp": 2,
    },
    [theme.breakpoints.down("xs")]: {
      "-webkitLineClamp": 4,
    },
    overflow: "hidden",
  },
  directMessageBtn: {
    // bottom: "10px",
    // position: "absolute",
    // marginTop: "30px",
    textTransform: "capitalize",
    background: "#d5606045",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      // width: "100px",
    },
  },
  varticalLine: {
    height: "180px",
    width: "1px",
    background: "gray",
    borderBottom: "1px solid #000000",
    opacity: "0.3",
    marginBottom: "15px",
  },
  detailGridBox: {
    position: "relative",
    padding: "0 20px",
  },
  educationDetail: {
    display: "flex",
    fontSize: "14px !important",
  },
  textEducation: {
    margin: "0",
    marginLeft: "10px",
    fontSize: "16px",
  },
  imgBoxView: {
    marginTop: "10px",
  },
  picsGrid: {
    background: "white",
    borderRadius: "10px",
    padding: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  lifeStyleHeading: {
    margin: "5px 10px",
    color: "gray",
    fontSize: "15px !important",
    fontWeight: "400",
  },
  imageLifStyle: {
    "& .react-fancybox .thumbnail img": {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
      margin: "5px",
      objectFit: "cover",
      cursor: "pointer",
    },
  },
  imageBoxStyle: {
    border: "2px solid #FE4B6B",
    borderRadius: "100%",
    textAlign: "center",
    padding: "3px",
    width: "90px",
    height: "90px",
    [theme.breakpoints.down("xs")]: {
      width: "70px",
      height: "70px",
    },
  },
  aboutHeading: {
    color: "grey",
    margin: "8px 0",
    marginLeft: "10px",
    fontSize: "15px !important",
    fontWeight: "400",
  },
  textParagraph: {
    marginLeft: "10px",
    fontSize: "16px",
    fontWeight: "600",
  },
  imageQuote: {
    textAlign: "right",
  },
  quotedImage: {
    width: "80px",
    height: "50px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
  },
  chipGruop: {
    marginBottom: "20px",
  },
  gridProfile: {
    marginTop: "10px",
  },
  userDetailMain: {
    position: "relative",
    padding: "0px 10px 0px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  favouriteIcon: {
    position: "absolute",
    top: "130px",
    right: "30px",
    color: "	#DC143C",
    border: "1px solid gray",
    padding: "2px",
    borderRadius: "6px",
    height: "30px",
    width: "30px",
    [theme.breakpoints.down("xs")]: {
      top: "120px",
      right: "10px",
    },
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "10px 10px 10px 20px",
  },
  nameUserView: {
    fontWeight: "600",
    fontSize: "22px",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    },
  },
  hrLine: {
    borderBottom: "2px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  yourImageFix: {
    width: "150px",
    height: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "120px",
      height: "120px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  smallHeightChange: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  sidelistAns: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  sidelistlabel: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    width: "80px",
  },
  university: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    // width: "150px",
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    display: "flex",
    alignItems: "center",
  },
  imgViewDetail: {
    width: "20px",
  },

  showtext: {
    color: "gray",
    margin: "5px 0",
    wordBreak: "break-all",
    overflow: "hidden",
  },

  viewMore: {
    textAlign: "end",
    color: "#FE4B6B",
    margin: "5px 0",
    fontSize: 12,
    wordBreak: "break-all",
    overflow: "hidden",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const ViewTeamProfile = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const [userData, setUserData] = React.useState({});
  const [userName, setUserName] = React.useState("");
  const [firebase_uid, setFirebase_uid] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFavourite, setIsFavourite] = React.useState(false);
  const [isBlock, setIsBlock] = React.useState(false);
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  const [hideText, setHideText] = React.useState(false);

  const handleShowText = () => {
    setHideText(true);
  };
  const handleHideText = () => {
    setHideText(false);
  };

  let query = useLocation();
  const slug = query.pathname.split("/")[3];

  useEffect(() => {
    // onLoad()
    (() => {
      setIsLoading(true);
      // Api Call For Fetch The Our Matches
      api
        .get(`user/teammate/viewparticipant/${slug}`)
        .then((result) => {
          if (result.success === "true") {
            setUserData(result.data);
            setUserName(result.data.first_name + " " + result.data.last_name);
            setFirebase_uid(result.data.firebase_uid);
            setIsLoading(false);
            setIsFavourite(
              result.data.userfavour ? result.data.userfavour : false
            );
            setIsBlock(result.data.is_blocked);
          } else {
            console.log(result, "error");
          }
        })
        .catch((err) => {
          console.log(err, "catch error");
        });
    })();
  }, [slug]);

  // const onLoad = () => {
  //   setIsLoading(true)
  //   // Api Call For Fetch The Our Matches
  //   api.get(`user/yourmatch/${slug}`)
  //     .then((result) => {
  //       if (result.success === "true") {
  //         setUserData(result.data)
  //         setUserName(result.data.first_name + " " + result.data.last_name)
  //         setFirebase_uid(result.data.firebase_uid)
  //         setIsLoading(false)
  //         setIsFavourite(result.data.userfavour ? result.data.userfavour : false)
  //       } else {
  //         console.log(result, "error")
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "catch error")
  //     });
  // };

  const onFavourite = (value) => {
    api
      .post("user/addfavourite", {
        data: { slug: slug, isFavourite: value },
      })
      .then((result) => {
        if (result.success === "true") {
          // onLoad()
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
          setIsFavourite(!value);
        } else {
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onBlock = (value) => {
    api
      .post(`user/blockprofile/${slug}`, {
        data: { is_blocked: value },
      })
      .then((result) => {
        if (result.success === "true") {
          // onLoad()
          setIsBlock(value);
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  const onMessageClick = (firebase_uid) => {
    // Api Call For Fetch The Our Matches
    api
      .get(`user/teammate/messagetomember/${slug}`)
      .then((result) => {
        if (result.success === "true") {
          var tmpId = Cookies.get('curr_receiverId');

          Cookies.set('prev_receiverId', tmpId, { expires: 365 });
          Cookies.set('curr_receiverId', firebase_uid, { expires: 365 });

          console.log(firebase_uid, "firebase_uid");
          // router.history.push(`/messages/${firebase_uid}`);
          router.history.push(`/chatroomTest/${firebase_uid}`);

          AsyncLocalStorage.setItem("receiverId", firebase_uid);
          AsyncLocalStorage.setItem("receiverName", userName);
          AsyncLocalStorage.setItem(
            "receiverProfilePicuture",
            userData.profile_picture
          );
          AsyncLocalStorage.setItem("chat_type", "personal");

          // AsyncLocalStorage.setItem("request_id", userData.userconver.request_id)
          AsyncLocalStorage.setItem("request_status", "pending");
          AsyncLocalStorage.setItem("to_id", userData.user_id);
          AsyncLocalStorage.setItem("to_slug", slug);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              {isLoading ? (
                <SkeletonLoading />
              ) : (
                <>
                  {userData && (
                    <>
                      <Grid className={classes.detailBox} container>
                        <Grid item xs={12} lg={12} xl={12}>
                          <Box
                            onClick={() => router.history.goBack()}
                            className={classes.backRoot}
                          >
                            <IconButton
                              aria-label="delete"
                              style={{ color: "black", marginRight: "5px" }}
                              size="small"
                            >
                              <ArrowBackIcon fontSize="inherit" />
                            </IconButton>
                            <h5 className={classes.headingPage}>
                              {" "}
                              Your Matches
                            </h5>
                          </Box>
                        </Grid>
                        <Grid item xs={5} lg={3} xl={3} md={3}>
                          <div
                            className={classes.imgBoxView}
                            style={{ marginLeft: "20px" }}
                          >
                            <img
                              className={classes.yourImageFix}
                              src={
                                userData.profile_picture
                                  ? userData.profile_picture
                                  : "/images/male_defualt.svg"
                              }
                              alt="userPhotoLoad"
                            />
                          </div>
                        </Grid>
                        <Grid
                          className={classes.detailGridBox}
                          item
                          xs={7}
                          xl={4}
                          lg={4}
                          md={9}
                        >
                          <h3 className={classes.nameUserView}>
                            {" "}
                            {capitalizeFirstLetter(userName)}
                          </h3>
                          {/* <p className={classes.textUserDetail}> {userData.gender} 2026 </p> lifestyle_photos*/}
                          {/* <p className={classes.textUserDetail}>
                            {userData.bio}
                          </p> */}
                          {userData.bio && (
                            <p
                              className={
                                hideText === true
                                  ? classes.showtext
                                  : classes.textUserDetail
                              }
                            >
                              <Hidden smUp>
                                {hideText === false
                                  ? userData.bio.slice(0, 40)
                                  : userData.bio}
                              </Hidden>
                              <Hidden xsDown>
                                {hideText === false
                                  ? userData.bio.slice(0, 100)
                                  : userData.bio}
                              </Hidden>
                              {hideText ? (
                                <p
                                  className={classes.viewMore}
                                  onClick={handleHideText}
                                >
                                  Show Less
                                </p>
                              ) : (
                                <span
                                  className={classes.viewMore}
                                  onClick={handleShowText}
                                >
                                  {userData.bio.length >= 40
                                    ? "....View More"
                                    : ""}
                                </span>
                              )}
                            </p>
                          )}
                          <Button
                            variant="outlined"
                            // component={browserLink}
                            // to={`/messages/${firebase_uid}`}
                            color="primary"
                            className={classes.directMessageBtn}
                            onClick={() => onMessageClick(firebase_uid)}
                            startIcon={<TelegramIcon />}
                          >
                            Messsage
                          </Button>
                          {/* <Tooltip title={`${capitalizeFirstLetter(userData.first_name)} and you  match ${userData.matchPercent !== "NaN" ? userData.matchPercent : 0}%`} placement="top">
                            <Box position="absolute" top={-5} right={5}>
                              <CircularProgress
                                variant="static"
                                value={userData.matchPercent !== "NaN" ? userData.matchPercent : 0}
                                size={40}
                                style={{ transform: "rotate(270deg)" }}
                              />
                              <Box
                                top={0}
                                left={0}
                                bottom={8}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                  style={{ fontWeight: "bold", paddingTop: "8px" }}
                                >{userData.matchPercent !== "NaN" ? userData.matchPercent : 0}%</Typography>
                              </Box>
                            </Box>
                          </Tooltip> */}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          xl={5}
                          lg={5}
                          style={{ display: "flex" }}
                        >
                          <Divider orientation="vertical" />
                          <Box className={classes.userDetailMain}>
                            <Box my={1} className={classes.educationDetail}>
                              <div>
                                <img
                                  className={classes.imgViewDetail}
                                  src={
                                    userData.gender === "male"
                                      ? "/images/matchUp/man.png"
                                      : userData.gender === "female"
                                        ? "/images/matchUp/woman.png"
                                        : "/images/matchUp/rainbow.png"
                                  }
                                  alt="degree_view"
                                />
                              </div>
                              &nbsp;&nbsp;
                              {/* <h5 className={classes.sidelistlabel}>Primary university</h5>&nbsp;&nbsp; */}
                              <h5 className={classes.sidelistlabel}>Gender</h5>
                              &nbsp;&nbsp;
                              <h5 className={classes.sidelistAns}>
                                {userData.gender
                                  ? capitalizeFirstLetter(userData.gender)
                                  : "N/A"}
                              </h5>
                            </Box>

                            <Box my={1} className={classes.educationDetail}>
                              <div>
                                <img
                                  className={classes.imgViewDetail}
                                  src="/images/matchUp/home_view.png"
                                  alt="degree_view"
                                  width={"20px"}
                                />
                              </div>
                              &nbsp;&nbsp;
                              <h5 className={classes.sidelistlabel}>
                                Hometown
                              </h5>
                              &nbsp;&nbsp;
                              <h5 className={classes.sidelistAns}>
                                {userData.location ? userData.location : "N/A"}
                              </h5>
                            </Box>

                            <Box my={1} className={classes.educationDetail}>
                              <div>
                                <img
                                  className={classes.imgViewDetail}
                                  src="/images/matchUp/zodiac.png"
                                  alt="degree_view"
                                  width={"20px"}
                                />
                              </div>
                              &nbsp;&nbsp;
                              <h5 className={classes.sidelistlabel}>Zodiac</h5>
                              &nbsp;&nbsp;
                              <h5 className={classes.sidelistAns}>
                                {userData.zodiac ? userData.zodiac : "N/A"}
                              </h5>
                            </Box>
                          </Box>
                        </Grid>
                        {/* <Grid item xs={12} style={{ position: "absolute", display: "flex", justifyContent: "flex-end", padding: "10px", bottom: "0px", right: "0px" }}>
                          {isBlock ?
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ textTransform: "capitalize", marginRight: "10px", borderRadius: "10px" }}
                              onClick={() => {
                                onBlock(false)
                              }}
                              startIcon={<img
                                src="/images/matchUp/active.png"
                                // style={{ marginRight: "5px" }}
                                alt="actvie sticker"
                              />}
                            >
                              unblock
                            </Button>
                            :
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ textTransform: "capitalize", marginRight: "10px", borderRadius: "10px" }}
                              onClick={() => {
                                onBlock(true)
                              }}
                              startIcon={<BlockIcon />}
                            >
                              Block
                            </Button>
                          }


                          <Checkbox
                            icon={<FavoriteBorder />}
                            checkedIcon={<Favorite style={{ color: "#d52020" }} />}
                            style={{ border: "1px solid black", borderRadius: "10px" }}
                            checked={isFavourite}
                            onChange={() => {
                              onFavourite(isFavourite)
                            }}
                          />
                        </Grid> */}
                      </Grid>
                      {userData.lifestyle_photos ? (
                        <Grid className={classes.picsGrid} container>
                          <Grid item xs={12} xl={12} lg={12}>
                            <h5 className={classes.lifeStyleHeading}>
                              {" "}
                              LifeStyle Pics{" "}
                            </h5>
                            <Box display="flex" alignItems="center">
                              {userData.lifestyle_photos ? (
                                <>
                                  {userData.lifestyle_photos.map(
                                    (value, index) => {
                                      return (
                                        <div className={classes.imageLifStyle}>
                                          <Fancybox>
                                            <a
                                              data-fancybox="gallery"
                                              href={
                                                value
                                                  ? value
                                                  : "/images/male_defualt.svg"
                                              }
                                            >
                                              <img
                                                // className={classes.yourImageFix}
                                                src={
                                                  value
                                                    ? value
                                                    : "/images/male_defualt.svg"
                                                }
                                                alt="userPhoto Load"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  borderRadius: "50%",
                                                  margin: "0 5px",
                                                  top: 2,
                                                  position: "relative",
                                                  objectFit: "cover",
                                                }}
                                              />
                                            </a>
                                          </Fancybox>
                                          {/* <ReactFancyBox
                                          // thumbnail={userData.lifestyle_photos.map((value) => value)}
                                          // image={userData.lifestyle_photos.map((value) => value)}
                                          thumbnail={value}
                                          image={value}
                                          defaultThumbnailWidth={"100px"}
                                          defaultThumbnailHeight={"100px"}
                                        /> */}
                                        </div>
                                      );
                                    }
                                  )}
                                  {/* {userData.lifestyle_photos.map((value) => {
                                  return (
                                    <Box className={classes.imageBoxStyle} m={1}>
                                      <img
                                        className={classes.imageLifStyle}
                                        src={value}
                                      />
                                    </Box>
                                  )
                                })} */}
                                </>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    component="primary"
                                    color="primary"
                                    style={{
                                      fontSize: "14px",
                                      color: "gray",
                                      fontWeight: "400",
                                      textAlign: "center"
                                    }}
                                  >
                                    No Lifestyle Photo
                                  </Typography>
                                </div>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid className={classes.picsGrid} container>
                        <Grid item xs={12}>
                          <h3 className={classes.aboutHeading}> University </h3>
                          <div className={classes.chipGruop}>
                            <div
                              className={classes.universityRoot}

                            // style={{
                            //   marginTop: "15px",
                            //   display: "flex",
                            //   marginLeft: "10px",
                            // }}
                            >
                              <h5 className={classes.university}>
                                Primary university:
                              </h5>
                              &nbsp;&nbsp;
                              <h5 className={classes.sidelistAns}>
                                {userData.p_university
                                  ? userData.p_university
                                  : "N/A"}
                              </h5>
                            </div>

                            <div
                              className={classes.universityRoot}

                            // style={{
                            //   marginTop: "5px",
                            //   display: "flex",
                            //   marginLeft: "10px",
                            // }}
                            >
                              <h5 className={classes.university}>
                                Secondary university:
                              </h5>
                              &nbsp;&nbsp;
                              <h5 className={classes.sidelistAns}>
                                {userData.s_university
                                  ? userData.s_university
                                  : "N/A"}
                              </h5>
                            </div>
                          </div>
                          <div className={classes.hrLine} />
                        </Grid>
                        <Grid item xs={12}>
                          <h3 className={classes.aboutHeading}>
                            {" "}
                            Liked Activities{" "}
                          </h3>
                          <div className={classes.chipGruop}>
                            {userData.planned_activities ? (
                              userData.planned_activities.map((item, index) => (
                                <Chip
                                  className={classes.chipUserDetail}
                                  label={item}
                                />
                              ))
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="primary"
                                  color="primary"
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    fontWeight: "400",
                                  }}
                                >
                                  No Liked Activities found
                                </Typography>
                              </div>
                            )}
                          </div>
                          <div className={classes.hrLine} />
                        </Grid>
                        <Grid item xs={12}>
                          <h3 className={classes.aboutHeading}> Interests </h3>
                          <div className={classes.chipGruop}>
                            {userData.interests ? (
                              userData.interests.map((item, index) => (
                                <Chip
                                  className={classes.chipUserDetail}
                                  label={item}
                                />
                              ))
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="primary"
                                  color="primary"
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    fontWeight: "400",
                                  }}
                                >
                                  No Interests found
                                </Typography>
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} md={4} lg={2} xl={3}>
          <Skeleton variant="circle" width={100} height={100} />
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={5} xl={4}>
          <Skeleton variant="rect" height={30} style={{ margin: "5px" }} />
          <Skeleton
            variant="rect"
            width={150}
            height={20}
            style={{ margin: "5px" }}
          />
          <Skeleton
            variant="rect"
            width={250}
            height={20}
            style={{ margin: "5px" }}
          />
          <Skeleton
            variant="rect"
            width={200}
            height={30}
            style={{ margin: "5px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <div>
            <Skeleton variant="rect" height={30} style={{ margin: "5px" }} />
            <Skeleton
              variant="rect"
              width={150}
              height={20}
              style={{ margin: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={300}
              height={20}
              style={{ margin: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={200}
              height={30}
              style={{ margin: "5px" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div style={{ padding: "30px 10px" }}>
            <div>
              <Skeleton
                variant="rect"
                width={150}
                height={20}
                style={{ margin: "5px" }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="circle"
                width={75}
                height={75}
                style={{ margin: "5px" }}
              />
              <Skeleton
                variant="circle"
                width={75}
                height={75}
                style={{ margin: "5px" }}
              />
              <Skeleton
                variant="circle"
                width={75}
                height={75}
                style={{ margin: "5px" }}
              />
              <Skeleton
                variant="circle"
                width={75}
                height={75}
                style={{ margin: "5px" }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton
            variant="rect"
            width={150}
            height={20}
            style={{ margin: "5px" }}
          />
          <Skeleton variant="rect" height={250} style={{ margin: "5px" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton
            variant="rect"
            width={150}
            height={20}
            style={{ margin: "5px" }}
          />
          <div style={{ display: "flex" }}>
            <Skeleton
              variant="rect"
              width={50}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={60}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={70}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={50}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton
            variant="rect"
            width={150}
            height={20}
            style={{ margin: "5px" }}
          />
          <div style={{ display: "flex" }}>
            <Skeleton
              variant="rect"
              width={50}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={60}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={70}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={65}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
            <Skeleton
              variant="rect"
              width={50}
              height={30}
              style={{ margin: "5px", borderRadius: "20px" }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ViewTeamProfile;
