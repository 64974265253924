import { Grid, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles((theme) => ({
  cardUser: {
    background: "white",
    // padding: "25px 10px",
    // paddingBottom: "10px",
    textAlign: "center",
    margin: "7px",
    borderRadius: "10px",
    height: "auto",
  },
}));

const UserSkeletonRoomCard = () => {
  const classes = useStyles();
  return (
    <div
      className={classes.cardUser}
      style={{
        height: 250,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "5px 3px 11px #00000017",
        position: "relative",
      }}
    >
      {/* <Skeleton
        animation="wave"
        variant="circle"
        width={120}
        height={120}
        style={{
          right: 10,
          top: 10,
          width: 30,
          position: "absolute",
          height: 30,
        }}
      /> */}
      <Skeleton
        animation="wave"
        width={"100%"}
        height={260}
        style={{
          position: "absolute",
          top: -62.5,
          borderRadius: "12px",
        }}
      />

      <Skeleton
        animation="wave"
        height={20}
        width="60%"
        style={{ marginTop: 160 }}
      />
      <Skeleton animation="wave" height={20} width="70%" />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 5,
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Skeleton
          animation="wave"
          height={35}
          width={60}
          style={{
            margin: "0 10px",
            borderRadius: 20,
          }}
        />
      </div>
    </div>
  );
};

export default UserSkeletonRoomCard;
