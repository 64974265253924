import React, { useContext, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Container, Grid, Box, Button, Typography, Snackbar, CircularProgress } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import useRouter from 'utils/useRouter';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import AppDataContext from "Helper/AppDataContext";
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Api from '../../Helper/ApiHandler';
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "90vh"
  },
  headingStep: {
    textAlign: "center",
    padding: "35px 0",
    margin: "0",
    fontSize: "40px !important",
    color: "#FE4B6B",
    backgroundColor: '#f6f6f6',
    fontWeight: 500
  },
  bgMainService: {
    background: "#f6f6f6",
    minHeight: "90vh"
  },
  cardBox: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "space-between",
    background: "white",
    width: "319px",
    // height: "300px",
    height: "275px",
    marginBottom: "55px",
    boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.1)',
    textAlign: "center",
    paddingBottom: "20px",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.05)"
    },
    cursor: "pointer"
  },
  selectedcardBox: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "space-between",
    background: "#FFF7F8",
    width: "319px",
    // height: "300px",
    height: "275px",
    marginBottom: "55px",
    boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.1)',
    textAlign: "center",
    paddingBottom: "20px",
    transition: "transform .2s",
    border: "3px solid #FE4B6B",
    "&:hover": {
      transform: "scale(1.05)"
    },
    cursor: "pointer"
  },
  imgServiceRoot: {
    minHeight: "135px",
  },
  imgService: {
    // width: "100px",
    height: "100%",
  },
  textCard: {
    padding: "10px 20px",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: 300
  },
  learMoreButton: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    width: "141px",
    height: "45px",
    background: 'linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)',
    fontWeight: 400
  },
  cardRoot: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: '#f6f6f6'
  },
  cardHeader: {
    fontSize: "24px",
    fontWeight: 500
  },
  heading: {
    fontSize: "20px",
    color: "#FE4B6B",
    textAlign: "center",
    padding: "50px"
    // padding: "50px 150px"
  }
}));

const SelecteModuleRoommate = (props) => {
  const { className, ...rest } = props;
  const { step2 } = (props.location && props.location.state) || {};

  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [selectedModule, setSelectedModule] = React.useState("Roommates")
  const [loading, setLoding] = React.useState(false)
  const [sessionMessage, setSessionMessage] = React.useState({
    message: '',
    type: '',
    open: false
  });

  useEffect(() => {
    if (!step2) {
      router.history.push("/")
    }
  }, [step2]);

  const siwtchModule = (valueSelecte) => {
    if (valueSelecte === "Roommates") {
      setAppContext({ ...appContext, currentModule: valueSelecte })
      AsyncLocalStorage.setItem("currentModule", valueSelecte)
      // window.location.replace("/signup-details")
      // router.history.push("/signup-details")
      console.log("call");
      router.history.push({
        pathname: `/signup-details`,
        search: '',
        state: {
          step2: true
        }
      })
    } else {
      // window.location.replace("/property-signup-details")
      // router.history.push("/property-signup-details")
      window.location.replace(process.env.REACT_APP_PROPERTY_RAGISTRATION)
    }
  }

  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }
  return (
    <Fade in={true} timeout={600}>
      <div className={classes.bgMainService}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleClose}
            style={{ zIndex: "22222" }}
          >
            <Alert
              onClose={handleClose}
              severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        {/* <h1 className={classes.headingStep}> Select Your Module </h1> */}
        {/* <div className={classes.heading} onClick={() => { router.history.push("/selecte-module") }}> */}
        {/* <ArrowBackIcon className={classes.backIcon} />&nbsp;&nbsp; */}
        <div className={classes.heading}>
          Choose your preference
        </div>
        {/* </div> */}
        <Box className={classes.cardRoot} px={2}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Box pt={3} className={selectedModule === "Roommates" ? classes.selectedcardBox : classes.cardBox} onClick={() => { setSelectedModule("Roommates") }}>
                <div className={classes.imgServiceRoot}>
                  <img
                    className={classes.imgService}
                    src="/images/matchUp/roommatesSelecte1.svg"
                    alt="step Dormates icon"
                  />
                </div>
                <div className={classes.cardHeader}> Find RoomMates </div>
                <p className={classes.textCard}>
                  Find roommates who match your personalities
                </p>
              </Box>
            </Grid>
            <Grid item>
              <Box className={selectedModule === "Property" ? classes.selectedcardBox : classes.cardBox} onClick={() => { setSelectedModule("Property") }}>
                <div className={classes.imgServiceRoot}>
                  <img
                    className={classes.imgService}
                    src="/images/matchUp/propertySelecte1.svg"
                    alt="step Dormates icon"
                  />
                </div>
                <div className={classes.cardHeader}> Register Property </div>
                <p className={classes.textCard}>
                  Register and manage your property
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            style={{
              background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
              color: "white",
              borderRadius: "20px",
              width: "200px"
            }}
            onClick={() => {
              siwtchModule(selectedModule)
            }}
          >
            Next
            {loading && <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></>}
          </Button>
        </div>
      </div>
    </Fade >
  );
};

export default SelecteModuleRoommate;

SelecteModuleRoommate.propTypes = {
  className: PropTypes.string,
};
