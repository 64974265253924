import React from "react";
import ContactUsDetail from "./component/ContactUsDetail/index";

const ContactUs = () => {
  return (
    <>
      <ContactUsDetail />
    </>
  );
};

export default ContactUs;
