import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import firebase from "firebase/app";

export default function ProfilepictureForGroup(props) {
  const { className, groupId } = props
  const [groupProfilePicture, setGroupProfilePicture] = useState("");
  // Base path of firebase
  const database = firebase.firestore();
  let db = database.collection("teammate").doc("chatRooms")

  useEffect(() => {
    getNameFromFireBase(groupId)
  }, [groupId])

  let getNameFromFireBase = (groupId) => {
    var groupRef = db.collection("group").doc(groupId);
    groupRef.get().then((doc) => {
      if (doc.exists) {
        setGroupProfilePicture(doc.data().group_profile_pic)
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });

    // groupRef.onSnapshot((doc) => {
    //   if (doc.exists) {
    //     setGroupProfilePicture(doc.data().group_profile_pic)
    //   } else {
    //     console.log("No such document!");
    //   }
    // });
  }

  return <img src={groupProfilePicture ? groupProfilePicture : '/images/male_defualt.svg'} alt="Img" className={className} />
}
