import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.7) 100%) , url(${"../images/matchUp/bgImage.png"})`,
    width: "100%",
    height: "678px",
},
mainBanner: {
    display: "flex",
    flexDirection: 'column',
    justifyContent :"center",
    alignItems: "center",
    width: "100%",
    height: "678px",
},
headingBanner: {
    fontSize: "39px",
    marginBottom: "0px !important",
    color: "white"
},
headingText: {
    color: "white",
    letterSpacing: "1px",
    width: "800px",
    textAlign: "center",
}
}));

const BannerPart = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.root} {...rest}>
            <div className={classes.mainBanner}>
              <h1 className={classes.headingBanner}> Find a DormMates near you today! </h1>
              <p className={classes.headingText}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the.
              </p>
            </div>
      </div>
    </>
  );
};

export default BannerPart;

BannerPart.propTypes = {
  className: PropTypes.string,
};
