import { Grid, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles((theme) => ({
  cardUser: {
    background: "white",
    // padding: "25px 10px",
    // paddingBottom: "10px",
    textAlign: "center",
    margin: "7px",
    borderRadius: "10px",
    height: "auto",
  },
}));

const UserSkeletonTeamCard = () => {
  const classes = useStyles();
  return (
    <div
      className={classes.cardUser}
      style={{
        height: 330,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        boxShadow: "5px 3px 11px #00000017",
        position: "relative",
      }}
    >
      {/* <Skeleton
        animation="wave"
        variant="circle"
        width={120}
        height={120}
        style={{
          right: 10,
          top: 10,
          width: 30,
          position: "absolute",
          height: 30,
        }}
      /> */}
      <Skeleton
        animation="wave"
        width={"100%"}
        height={300}
        style={{
          position: "absolute",
          top: -71.5,
          borderRadius: "12px",
        }}
      />
      <div style={{ width: "100%", padding: "0 20px" }}>
        <div style={{ display: "flex" }}>
          <Skeleton
            animation="wave"
            height={40}
            width="60%"
            style={{ marginTop: 130 }}
          />
          <Skeleton
            animation="wave"
            variant="circle"
            height={40}
            width={40}
            style={{ marginTop: 140, marginLeft: "auto" }}
          />
        </div>
        <Skeleton animation="wave" height={20} width="70%" />
        <Skeleton animation="wave" height={20} width="80%" />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 5,
          width: "100%",
          justifyContent: "center",
          padding: "0 10px",
          position: "absolute",
          bottom: "5px",
        }}
      >
        <Skeleton
          animation="wave"
          height={55}
          width={"100%"}
          style={{
            margin: "0 10px",
            borderRadius: 5,
          }}
        />
      </div>
    </div>
  );
};

export default UserSkeletonTeamCard;
