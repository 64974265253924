import React, { useState } from 'react'
import { makeStyles } from "@material-ui/styles";
import moment from 'moment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { Grid, Popover, TextField } from '@material-ui/core';
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import firebase from "firebase/app";
import { useLocation } from 'react-router-dom';
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import { useEffect } from 'react';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  bgProfilePage: {
    // minHeight: "calc(100vh - 125px)",
    background: "#e9edf0",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
    },
  },

  myMessageRoot: {
    display: "flex",
    justifyContent: "flex-end"
  },
  myMessage: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px 0px",
    padding: "10px",
    margin: "3px",
    color: "#3F444F",
    maxWidth: "50%",
    display: "flex"
  },
  myMessageTime: {
    fontSize: "10px",
    fontWeight: "400",
    minWidth: "45px",
    marginLeft: "10px",
    alignSelf: "flex-end",
    color: "#A2A2AB",
    position: "relative"
  },
  myDoubleTick: {
    fontSize: "16px",
    bottom: "0px",
    position: "absolute",
    marginLeft: "5px"
  },

  yourMessageRoot: {
    display: "flex",
    justifyContent: "flex-start"
  },
  yourMessage: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px 0px",
    padding: "10px",
    margin: "3px",
    color: "#3F444F",
    maxWidth: "50%",
  },
  yourMessageTime: {
    fontSize: "10px",
    fontWeight: "400",
    minWidth: "25px",
    marginLeft: "10px",
    alignSelf: "flex-end",
    color: "#A2A2AB",
  },
  yourName: {
    color: "#E84A62",
    fontSize: "12px",
    textTransform: "capitalize",
    marginBottom: "3px",
    fontWeight: "500"
  },

  groupEditMsgRoot: {
    display: "flex",
    justifyContent: "center"
  },
  groupEditMsg: {
    width: "fit-content",
    fontSize: "12px",
    backgroundColor: "#FFF7F8",
    borderRadius: "5px",
    padding: "3px",
    margin: "3px",
    color: "#F24451"
  },

  mainInputMessage: {
    width: "98%",
    marginLeft: "10px",
    marginRight: "10px"
  },
  iconSendBg: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    borderRadius: "100%",
    width: "35px",
    height: "35px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "white",
    cursor: "pointer"
  },
  iconSendBgSmallDevice: {
    background: "#FF695B",
    borderRadius: "100%",
    width: "34px",
    height: "34px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    marginTop: "2px",
  },
  inputBoxWrite: {
    background: "white",
    width: "100%",
    height: "60px",
    '& .MuiOutlinedInput-root': {
      borderRadius: "50px"
    },
    display: "flex",
    alignItems: "center",
    borderRadius: "0px 0px 10px 10px",
    borderTop: "1px solid #d9d9d9"
  },
  inputBoxWrite: {
    background: "white",
    width: "100%",
    height: "60px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },
    display: "flex",
    alignItems: "center",
    borderRadius: "0px 0px 10px 10px",
    borderTop: "1px solid #d9d9d9",
  },

  inboxRoot: {
    display: "flex",
    padding: "5px",
    cursor: "pointer",
  },
  inboxImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  inboxNameRoot: {
    marginLeft: "10px",
  },
  inboxName: {
    fontSize: "14px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const Message = (props) => {
  const classes = useStyles();
  let query = useLocation();
  const { group_members, receiverId, userName, senderId, lstMessage } = props
  const [message, setMessage] = useState("");
  const [lstLocalMessage, setLstLocalMessage] = useState([]);

  // Base path of firebase
  const database = firebase.firestore();
  let db = database.collection("testRoom")
  // let dbNew = database.collection("testRoom")

  useEffect(() => {
    onload();
  }, [receiverId]);

  let onload = async () => {
    // Get message data
    let messageRef = db.doc(receiverId).collection(receiverId)
    messageRef.orderBy("timestamp", "asc").onSnapshot((querySnapshot) => {
      var prev_receiverId = Cookies.get('prev_receiverId');
      var curr_receiverId = Cookies.get('curr_receiverId');


      // console.log("prev_receiverId", prev_receiverId);
      // console.log("curr_receiverId", curr_receiverId);
      // console.log("call_receiverId", receiverId);
      let temp = [];
      querySnapshot.forEach((doc) => {
        temp.push(doc.data());
      });
      if (prev_receiverId) {
        if (prev_receiverId && curr_receiverId == receiverId) {
          setLstLocalMessage(temp)
        }
      } else {
        setLstLocalMessage(temp)
      }
    });

    // messageRef();
  }

  console.log(lstMessage, "lstMessage")


  const sendMessageGroup = async (event) => {
    event.preventDefault();
    let messageId = uuid();
    var timestamp = new Date();

    const messageBoxRef = db.doc(receiverId).collection(receiverId).doc(messageId)
    const messageData = {
      displayName: userName,
      text: message,
      timestamp: timestamp,
      uid: senderId
    }
    messageBoxRef.set(messageData)

    setMessage("")
  }

  const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const sendMessagePersonal = async (event) => {
    event.preventDefault();
  }

  function handleEnterKeyOnCards(e) {
    if (e.keyCode == 13) {
      sendMessageGroup(e);
      // if (chatType == "group") {
      //   sendMessageGroup(e);
      // } else {
      //   sendMessagePersonal(e);
      // }
    }
  }

  return (
    <div className={classes.bgProfilePage}>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "10px 10px 0px 0px",
          height: "calc(100vh - 260px)",
        }}
      >
        <div style={{ textAlign: "center", padding: "10px", background: "pink" }}>
          {receiverId}
        </div>
        {/* <ChatHeadGroup objSelectedGroup={{}} /> */}
        {/* Message list show */}
        <div style={{ overflow: "auto", height: 'calc(100vh - 330px)' }}>
          {lstLocalMessage.length && lstLocalMessage.map((objInbox, index) => {
            if (objInbox.uid === senderId) {
              return (
                <SenderMessage objInbox={objInbox} />
              )
            } else {
              return (
                <ReciverMessage objInbox={objInbox} />
              )
            }
          })}
        </div>
      </div>

      {/* Send message */}
      <SendMessageBar
        setMessage={setMessage}
        message={message}
        sendMessageGroup={sendMessageGroup}
        chatType={"group"}
        sendMessagePersonal={sendMessagePersonal}
        handleEnterKeyOnCards={handleEnterKeyOnCards}
      />

    </div>
  )
}

// message lable
const SenderMessage = (props) => {
  const classes = useStyles();
  const { objInbox } = props
  return (
    <div className={classes.myMessageRoot}>
      <div className={classes.myMessage}>
        <div>
          {objInbox.text}
        </div>
        <div className={classes.myMessageTime}>
          {moment(new Date(objInbox.timestamp * 1000)).format("HH:mm")}
          <DoneAllIcon className={classes.myDoubleTick} />
        </div>
      </div>
    </div>
  );
};

const ReciverMessage = (props) => {
  const classes = useStyles();
  const { objInbox } = props
  return (
    <div className={classes.yourMessageRoot}>
      <div className={classes.yourMessage}>
        <div className={classes.yourName}>
          {objInbox.displayName}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {objInbox.text}
          </div>
          <div className={classes.yourMessageTime}> {moment(new Date(objInbox.timestamp * 1000)).format("HH:mm")}</div>
        </div>
      </div>
    </div>
  );
};

const GroupEdit = (props) => {
  const classes = useStyles();
  const { senderId, userName, objInbox } = props
  if (objInbox.createdBy == senderId) {
    return (
      <div>
        <div className={classes.groupEditMsgRoot}>
          <div className={classes.groupEditMsg}>
            {objInbox.message.replace(userName, "you")}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className={classes.groupEditMsgRoot}>
          <div className={classes.groupEditMsg}>
            {objInbox.message.replace(userName, "you")}
          </div>
        </div>
      </div>
    )
  }
};

// Send message
const SendMessageBar = (props) => {
  const classes = useStyles();
  const { handleEnterKeyOnCards, setMessage, message, sendMessageGroup, chatType, sendMessagePersonal } = props
  return (
    <div className={classes.inputBoxWrite}>
      <Grid container style={{ display: "flex" }}>
        <Grid item xs={10} sm={10} md={10} lg={11} xl={11}>
          <TextField
            className={classes.mainInputMessage}
            fullWidth
            size="small"
            placeholder="Message..."
            variant="outlined"
            onKeyDown={(e) => handleEnterKeyOnCards(e)}
            value={message}
            onChange={(e) => { setMessage(e.target.value) }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <div className={classes.iconSendBg}>
            <SendOutlinedIcon
              type="submit"
              onClick={(e) => {
                if (chatType == "group") {
                  sendMessageGroup(e)
                } else {
                  sendMessagePersonal(e)
                }
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

// Heading
const ChatHeadGroup = (props) => {
  const classes = useStyles();
  const { objSelectedGroup } = props
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #80808061"
      }}
    >
      <div
        className={classes.inboxRoot}
        style={{ padding: "10px", width: "fit-content" }}
      // onClick={() => {
      //   router.history.push(`/groupDetail/${objSelectedGroup.slug}`);
      // }}
      >
        <div>
          <img
            src={
              objSelectedGroup.profile_picture
                ? objSelectedGroup.profile_picture
                : "/images/male_defualt.svg"
            }
            alt="Img"
            className={classes.inboxImage}
          />
        </div>
        <div className={classes.inboxNameRoot}>
          <div className={classes.inboxName}>
            {objSelectedGroup.name}
          </div>
          <div className={classes.inboxLastMsg}>
            {objSelectedGroup.no_of_members} members
          </div>
        </div>
      </div>

      <div className={classes.menuRoot}>
        <MoreVertIcon
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  )
}

export default Message