import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HowItsWork from "../HowItsWork";
import DormateBanedits from "../DormateBanedits";
import DormateBest from "../DormateBest";
import DormateLandingQuestion from "../DormateLandingQuestion";
import { Person } from "schema-dts";
import { helmetJsonLdProp } from "react-schemaorg";
import { Helmet } from "react-helmet";
import Api from '../../../../Helper/ApiHandler';
var api = new Api();

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
    // minHeight: "90vh"
  },
  propertyImage: {
    width: "100vw",
  },
  Findahome: {
    fontSize: "36px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "black",
    fontWeight: 600,
    margin: 0,
    width: "550px",
    textAlign: "center",
  },
  propertyImageLabel: {
    position: "absolute",
    top: "50px",
    [theme.breakpoints.down("sm")]: {
      top: "10px",
    },
    display: "flex",
    justifyContent: "center",
    width: "100%",
    // left: "50%"
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function getSteps() {
  return [
    {
      label: "Research Subursbs",
      subLabel:
        "Explore median unit and house prices, real estate market data, demography, and lifestyle information for suburbs.",
    },
    {
      label: "Instant Valuation",
      subLabel:
        "Our online valuation service gives you a free and instant estimate of your home's current value.",
    },
    {
      label: "Track Property",
      subLabel: "Find a suitable rental property or shared accommodation",
    },
  ];
}
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SearchIcon />,
    2: <GroupAddIcon />,
    3: <HomeIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const PrivacyPolicyDetail = (props) => {
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(3);
  const [meta, setMeta] = React.useState({});

  useEffect(() => {
    // onLoad()
  }, []);

  // const onLoad = () => {
  //   // For meta
  //   api.post('staticmetadata', {
  //     data: { type: "dormmate" }
  //   })
  //     .then((result) => {
  //       if (result.success === "true") {
  //         console.log(result.data, "result")
  //         setMeta(result.data, 'result.data')
  //       } else {
  //         console.log(result, "success === false")
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "error catch")
  //     });
  // };

  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp < Person > ({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "MatchUpMates",
            "url": "https://www.matchupmates.com/dormmate",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }),
        ]}
      >
      </Helmet>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} style={{ position: "relative" }}>
            <img
              src="/images/matchUp/dormmateLanding.webp"
              alt="dormmateLanding"
              className={classes.propertyImage}
            />
            <div className={classes.propertyImageLabel}>
              <h1 className={classes.Findahome}>
                {" "}
                Find your perfect Dormmates from MatchUpMates{" "}
              </h1>
            </div>
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <HowItsWork />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <DormateBanedits />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <DormateLandingQuestion />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <DormateBest />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PrivacyPolicyDetail;
