import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, CircularProgress, Container, Grid, Snackbar, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from '../../../../Helper/ApiHandler';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MuiAlert from '@material-ui/lab/Alert';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import validate from "validate.js";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  p_university: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    // minHeight: "100vh",
    minHeight: "calc(100vh - 125px)",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "90px"
    }
  },
  questionsText: {
    fontSize: "20px",
    padding: "20px",
    color: "white",
    fontWeight: "bold",
    margin: "0",
  },
  questionsBox: {
    background: "#0071CE",
    borderRadius: "10px 10px 0px 0px",
  },
  startQuizBox: {
    background: "#fff",
    borderRadius: "10px 10px 0px 0px",
    textAlign: "center",
    marginBottom: "50px"
  },
  startText: {
    color: "black",
    fontSize: "22px",
    // padding: "20px",
    fontWeight: "600",
    margin: "0",
  },
  selectText: {
    color: "black",
    fontSize: "18px",
    fontWeight: "400",
    marginBottom: "16px",
    textAlign: "center"
  },
  startsecondaryText: {
    color: "#3F444F",
    fontSize: "13px",
    // padding: "20px",
    fontWeight: "400",
    margin: "0",
  },
  answerBox: {
    background: "white",
    borderRadius: "0px 0px 10px 10px",
  },
  headingAns: {
    margin: "0",
    padding: "20px",
    paddingBottom: "0",
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: "10px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
  },
  chipGruop: {
    padding: "10px 20px",
  },
  lineHorizontal: {
    width: "95%",
    // borderBottom: "2px solid #000000",
    opacity: "0.1",
    margin: "0 auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 25px",
  },
  nextButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "rgba(0, 113, 206, 0.2)",
    width: "80px",
    height: "30px",
  },
  skipButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    textDecoration: "underline",
    marginTop: "5px",
    cursor: "pointer"
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
  },
  buttonStart: {
    width: "100%",
    fontSize: "14px",
    textTransform: "capitalize",
    margin: "50px 0px 0px 0px",
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)'
  },
  mainStartQuixBox: {
    textAlign: "center",
    padding: "15px 15px",
  },
  textNewStep: {
    fontSize: "16px !important",
    fontWeight: "900",
    width: "60%",
    textAlign: "center",
    margin: "19px auto",
  },
  startQuizMain: {
    marginBottom: "20px",
    padding: "50px 50px 70px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
    backgroundColor: "white",
    borderRadius: "10px"
  },
  autocompletChip: {
    background: "white",
    '& .MuiChip-root': {
      borderRadius: "5px",
      background: "#CCE3F5",
      height: "auto",
    },
    '& .MuiChip-outlined': {
      border: "none",
      color: "#22346E",
      padding: "2px"
    },
    '& .MuiFilledInput-root': {
      background: "white"
    }
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    color: "#22346E"
  },
  banaer: {
    width: "100%"
  },
  banaerRoot: {
    display: "flex",
    alignItems: "flex-end",
    paddingRight: "30px",
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
  }
}));

const QuizQuestions = (props) => {
  const classes = useStyles();
  const history = useHistory();
  

  const [p_answer, set_P_Answer] = React.useState([]);
  const [s_answer, set_S_Answer] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(props.sessionPropsMessage);
  const [lst_p_University, setLst_P_University] = React.useState([]);
  // const [lstS_University, setLst_S_University] = React.useState([]);
  const [lst_s_University, setLst_S_University] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [p_university, setP_University] = useState(undefined);
  const [s_university, setS_University] = useState(undefined);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const hasError = (field) => (formState.errors[field] ? true : false);


  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);
  useEffect(() => {
    onLoad()
  }, []);

  const change_p_OptionBaseOnValue = (value) => {
    if (value)
      get_P_University(value);
  }
  const change_s_OptionBaseOnValue = (value) => {
    if (value)
      get_S_University(value);
  }
  const handleAutocompletChange = (event, value, name) => {
    if (name === "p_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value
          }
        }));
      }
    } else if (name === "s_university") {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value
          }
        }));
      }
    } else if (name === "room_type") {
      if (value !== null) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value.value
          },
          touched: {
            ...formState.touched,
            [name]: true
          }
        }));
      }
    } else {
      if (value !== null) {
        // handleChangeAuto(event, value);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [name]: value
          }
        }));
      }
    }
  }

  const onLoad = async () => {
    // Api Call For Fetch The User Data
    await api.get(`user/viewprofile`)
      .then((result) => {
        if (result.success === "true") {
          setP_University(result.data.p_university)
          setS_University(result.data.s_university)
          change_p_OptionBaseOnValue(result.data.p_university)
          change_s_OptionBaseOnValue(result.data.s_university)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  };

  // Get Primary University
  const get_P_University = async (search) => {
    // Api Call For Fetch University List
    api.get(`universitylist?s=` + search)
      .then((result) => {
        if (result.success === "true") {
          setLst_P_University(result.data)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  }


  // Get Secon University
  const get_S_University = async (search) => {
    // Api Call For Fetch University List
    api.get(`universitylist?s=` + search)
      .then((result) => {
        if (result.success === "true") {
          setLst_S_University(result.data)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  }

  const change_P_OptionBaseOnValue = (value) => {
    if (value)
      get_P_University(value);
  }


  const change_S_OptionBaseOnValue = (value) => {
    if (value)
      get_S_University(value);
  }

  const getSelected_P_UniversityItem = () => {
    if (formState.values.p_university) {
      const item = lst_p_University.find((opt) => {
        if (opt.value == formState.values.p_university)
          return opt;
      })
      if (item)
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            p_university: item
          }
        }));
      // return item || {};
    } else {
      const item = lst_p_University.find((opt) => {
        if (opt.value == p_university)
          return opt;
      })
      if (item)
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            p_university: item
          }
        }));
      // return item || {};
    }
  }
  const getSelected_S_UniversityItem = () => {
    if (formState.values.s_university) {
      const item = lst_s_University.find((opt) => {
        if (opt.value == formState.values.s_university)
          return opt;
      })
      if (item)
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            s_university: item
          }
        }));
      // return item || {};
    } else {
      const item = lst_s_University.find((opt) => {
        if (opt.value == s_university)
          return opt;
      })
      if (item)
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            s_university: item
          }
        }));
      // return item || {};
    }
  }


  useEffect(() => {
    getSelected_P_UniversityItem()
  }, [lst_p_University]);
  useEffect(() => {
    getSelected_S_UniversityItem()
  }, [lst_s_University]);
  // Start The Quiz
  const StartTheQuizHandler = () => {
    // let lstPId = lstP_University.filter((obj) => obj.value === p_answer)
    // let lstSId = lstS_University.filter((obj) => obj.value === s_answer)
    console.log(formState.values, "formState.values")
    let data = {
      p_universityId: formState.values.p_university.id,
      s_universityId: formState.values.s_university.id
    }
    setLoading(true)

    // Api Call For Selecte university
    api.post('user/adduniversity', {
      data: data
    })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("university", formState.values.p_university.value)
          AsyncLocalStorage.setItem("puniversity_lat", formState.values.p_university.latitude)
          AsyncLocalStorage.setItem("puniversity_long", formState.values.p_university.longitude)
          // history.push("/matching-quiz")
          // history.push("/personal-details")
          history.push({
            pathname: `/personal-details`,
            search: '',
            state: {
              step4: true
            }
          })
        } else {
          console.log(result, "error")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "catch error")
        setLoading(false)
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  return (
    <>
      <div className={classes.bgProfilePage}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert
              onClose={handleErorrClose}
              severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} lg={7} xl={7} className={classes.banaerRoot}>
              <img
                className={classes.banaer}
                src={"/images/matchUp/selecteUniversity.svg"}
                alt={"Alt Image"}
              />
            </Grid>
            <Grid item xs={12} xl={5} lg={5}>
              <Box className={classes.startQuizMain}>
                <Box className={classes.startQuizBox}>
                  <p className={classes.startText}>Welcome to MatchUpMates</p>
                  <p className={classes.startsecondaryText}>it’s time to choose your best matches</p>
                </Box>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    // className={classes.imgService}
                    src="/images/matchUp/selecteUni.svg"
                    alt="step Dormates icon"
                  />
                </div>
                <div className={classes.selectText}>
                  Select University
                </div>
                <div className={classes.hrLine} />
                <Box className={classes.answerBox}>
                  <Box className={classes.mainStartQuixBox}>
                    <Autocomplete
                      options={lst_p_University}
                      onInputChange={(e, value) => {
                        change_p_OptionBaseOnValue(value);
                      }}
                      noOptionsText={"Type for search..."}
                      className={classes.autocompletChip}
                      // value={getSelectedUniversityItem()}
                      value={formState.values.p_university || ""}
                      autoHighlight
                      getOptionLabel={(lst_p_University) => lst_p_University.value}
                      fullWidth
                      name="p_university"
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {props.value}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // placeholder="Select Primary University"
                          label="Select Primary University"
                          autoFocus={false}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError('p_university')}
                          helperText={hasError('p_university') ? formState.errors.p_university[0] : null}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                          variant="outlined"
                          name="p_university"
                          fullWidth
                          className={classes.inputProfileEdit}
                        />
                      )}
                      onChange={(e, value) => {
                        handleAutocompletChange(e, value, "p_university")
                      }}
                    />
                    <Autocomplete
                      options={lst_s_University}
                      onInputChange={(e, value) => {
                        change_s_OptionBaseOnValue(value);
                      }}
                      className={classes.autocompletChip}
                      noOptionsText={"Type for search..."}
                      // value={getSelectedUniversityItem()}
                      value={formState.values.s_university || ""}
                      autoHighlight
                      getOptionLabel={(lst_s_University) => lst_s_University.value}
                      fullWidth
                      name="s_university"
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {props.value}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // placeholder="Select Secondary University"
                          label="Select Secondary University"
                          autoFocus={false}
                          required
                          error={hasError('s_university')}
                          helperText={hasError('s_university') ? formState.errors.s_university[0] : null}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                          variant="outlined"
                          name="s_university"
                          fullWidth
                          className={classes.inputProfileEdit}
                        />
                      )}
                      onChange={(e, value) => {
                        handleAutocompletChange(e, value, "s_university")
                      }}
                      style={{ marginTop: "20px" }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonStart}
                      endIcon={loading ? <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></> : <img src="/images/matchUp/startAerrow.png" alt="startAerrow" />}
                      onClick={StartTheQuizHandler}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default QuizQuestions;
