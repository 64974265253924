import React, { useContext, useEffect, useState } from "react";
import { Link, useMediaQuery, Box, Button, Checkbox, Chip, Container, FormControlLabel, Grid, IconButton, InputAdornment, Snackbar, TextField, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from '../../../../Helper/ApiHandler';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MuiAlert from '@material-ui/lab/Alert';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import validate from "validate.js";
import AppDataContext from "Helper/AppDataContext";
import firebase from 'firebase/app';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useRouter from "utils/useRouter";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import OtpInput from "react-otp-input";
import moment from "moment";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-input-2";
import WrongNavigationDialog from "components/WrongNavigation/WrongNavigation";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 },
  },
};

const schemaPhone = {
  phone: {
    presence: { allowEmpty: false, message: "is required" },
    length: { minimum: 10 },
  }
}

const useStyles = makeStyles((theme) => ({
  startedButton: {
    height: "35px",
    fontSize: "18px",
    width: "90%",
    background: 'linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)',
    textTransform: "capitalize",
    fontWeight: "400",
    marginTop: "20px"
  },
  answerBox: {
    background: "white",
    borderRadius: "0px 0px 10px 10px",
  },
  textFiledBox: {
    width: "90%",
    margin: "20px auto",
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      textTransform: "capitalize"
    }
  },
  startsecondaryText: {
    color: "#3F444F",
    fontSize: "13px",
    fontWeight: "400",
    margin: "0",
  },
  startText: {
    color: "black",
    fontSize: "22px",
    fontWeight: "600",
    margin: "0",
  },
  startQuizBox: {
    background: "#fff",
    borderRadius: "10px 10px 0px 0px",
    textAlign: "center",
    marginBottom: "50px"
  },
  startQuizMain: {
    marginBottom: "20px",
    padding: "50px 50px 70px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
    backgroundColor: "white",
    borderRadius: "10px"
  },
  banaer: {
    width: "100%"
  },
  banaerRoot: {
    display: "flex",
    alignItems: "flex-end",
    paddingRight: "30px",
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
  },
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    minHeight: "calc(100vh - 124px)",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "90px"
    }
  },
  startedButtonBox: {
    textAlign: "center",
    "& .MuiBox-root-144": {
      marginTop: "10px !important",
    },
  },
}));

const SignupDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const router = useRouter();
  const isMobile = useMediaQuery("(max-width:600px)");

  const getAuth = localStorage.getItem("authToken");
  const signupDeatils = localStorage.getItem("signupDeatils");
  const isAuth = getAuth && signupDeatils == "true" ? true : false

  const [sessionMessage, setSessionMessage] = React.useState(props.sessionPropsMessage);
  const [loading, setLoading] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const [counter, setCounter] = React.useState(null);
  const [dob, setDob] = React.useState(moment().subtract(18, "years"));
  const [email, setEmail] = React.useState("");
  const [otpPhone, setOtpPhone] = React.useState(false);
  const [verifyPhone, setVerifyPhone] = React.useState(false);
  const [otpSendPhone, setOtpSendPhone] = React.useState(false);
  const [otpLoader, setOtpLoader] = React.useState(false);
  const [signupType, setSignupType] = useState("notDefine");
  const [otpEmail, setOtpEmail] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [verifyEmail, setVerifyEmail] = React.useState(false);
  const [otpSendEmail, setOtpSendEmail] = React.useState(false);
  const [phone, setPhone] = useState("");
  const [open, setOpen] = React.useState(false);
  let query = useLocation();
  const tocken = query.pathname.split("/")[2]

  const { appContext, setAppContext } = useContext(AppDataContext);
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const onLoad = async () => {
    api.get(`user/teammate/getteamtoken?teamtoken=${tocken}`)
      .then((result) => {
        if (result.success === "true") {
          console.log(result.data, "result.data.data.type")
          setSignupType(result.data.type)
          if (result.data.type == "email") {
            setFormState({
              ...formState,
              values: {
                ...formState.values,
                email: result.data.value
              }
            })
          } else {
            setFormState({
              ...formState,
              values: {
                ...formState.values,
                phone: result.data.value
              }
            })
          }
        } else {
          router.history.push("/");
          console.log(result, "error")
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err, "catch error")
      });
  };

  useEffect(() => {
    if (isAuth) {
      setOpen(true)
    } else {
      onLoad()
    }
  }, []);

  useEffect(() => {
    // const errors = validate(formState.values, schema);
    let tmp = signupType === "phone" ? schemaPhone : schema;
    const errors = validate(formState.values, tmp);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleFieldChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  React.useEffect(() => {
    // console.log(counter, "counter--")
    if (signupType === "notADefine") {
      setCounter(null);
    } else {
      if (counter) {
        // (signupType === "email" || signupType === "phone") &&
        //   setCounter(null)

        counter &&
          counter > 0 &&
          setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        // setCounter(null)
        return;
      }
    }
  }, [counter]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleErorrClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  const verifyOtp = async (event, type, value, code) => {
    event.preventDefault();
    setLoading(true);
    setDisabledLogin(true);
    // dispatch(login());
    api
      .post("user/teammate/verifyotp", {
        data: {
          value: value,
          code: code,
          is_verify: false,
          type: type,
        },
        skipAuth: true,
      })
      .then((result) => {
        if (result.success === "true") {
          // console.log(result.data, "result.data")
          let userName = result.data.first_name + " " + result.data.last_name;
          if (result.data.is_registered) {
            AsyncLocalStorage.setItem("email", result.data.email);
            AsyncLocalStorage.setItem("phone", result.data.phone);
            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
            AsyncLocalStorage.setItem("userName", userName);
            AsyncLocalStorage.setItem(
              "userProfile",
              result.data.profile_picture
            );
            AsyncLocalStorage.setItem("from_id", result.data.user_id);
            AsyncLocalStorage.setItem("university", result.data.university);
            AsyncLocalStorage.setItem(
              "puniversity_lat",
              result.data.puniversity_lat
            );
            AsyncLocalStorage.setItem(
              "puniversity_long",
              result.data.puniversity_long
            );
            AsyncLocalStorage.setItem("from_slug", result.data.slug);
            AsyncLocalStorage.setItem("currentModule", result.data.module_name);
            AsyncLocalStorage.setItem("tabFavValue", 0);
            AsyncLocalStorage.setItem("tabValue", 0);
            AsyncLocalStorage.setItem(
              "signupDeatils",
              result.data.is_registered
            );
            AsyncLocalStorage.setItem(
              "personalDeatils",
              result.data.has_personalInfo
            );
            AsyncLocalStorage.setItem("quiz", result.data.quiz);
            setAppContext({
              ...appContext,
              currentModule: result.data.module_name,
            });
            setOtpEmail(false)
            // setOtpEmail('')
          } else {
            AsyncLocalStorage.setItem("email", result.data.email);
            AsyncLocalStorage.setItem("phone", result.data.phone);
            AsyncLocalStorage.setItem("authToken", result.data.accessToken);
            AsyncLocalStorage.setItem("senderId", result.data.firebase_uid);
            AsyncLocalStorage.setItem("userName", "");
            AsyncLocalStorage.setItem("userProfile", "");
            AsyncLocalStorage.setItem("from_id", "");
            AsyncLocalStorage.setItem("university", "");
            AsyncLocalStorage.setItem("puniversity_lat", 0);
            AsyncLocalStorage.setItem("puniversity_long", 0);
            AsyncLocalStorage.setItem("from_slug", "");
            AsyncLocalStorage.setItem("currentModule", "Teammates")
            AsyncLocalStorage.setItem("tabFavValue", 0);
            AsyncLocalStorage.setItem("tabValue", 0);
            AsyncLocalStorage.setItem(
              "signupDeatils",
              result.data.is_registered
            );
            AsyncLocalStorage.setItem(
              "personalDeatils",
              result.data.has_personalInfo
            );
            AsyncLocalStorage.setItem("quiz", result.data.quiz);
            setAppContext({ ...appContext, currentModule: "Teammates" })
          }
          if (!result.data.is_registered) {
            router.history.push({
              pathname: `/signup-details`,
              search: "",
              state: {
                step2: true,
                teamToken: tocken
              },
            });
            // router.history.push("/signup-details")
          }
          else if (!result.data.has_personalInfo) {
            router.history.push({
              pathname: `/personal-details`,
              search: "",
              state: {
                step4: true,
                teamToken: tocken
              },
            });
            // router.history.push("/personal-details")
          } else {
            router.history.push("/your-matches")
          }

          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setDisabledLogin(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setDisabledLogin(false);
        setLoading(false);
      });
  };
  const sendOtp = async (event, type, value) => {
    event.preventDefault();
    // dispatch(login());
    setDisabled(true);
    setOtpLoader(true);
    let tmpData = {};
    if (type === "email") {
      tmpData = {
        type: type,
        value: value,
      };
    } else {
      tmpData = {
        type: type,
        value: value,
        phonecode: formState.values.countryCode,
        // phonecode: 91
      };
    }
    api
      .post("user/teammate/sendotp", {
        data: tmpData,
        skipAuth: true,
      })
      .then((result) => {
        if (result.success === "true") {
          setCounter(60);
          if (type === "email") {
            setOtpSendEmail(true);
          } else {
            setOtpSendPhone(true);
          }
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          // setLoginOpen(false);
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setDisabled(false);
        setOtpLoader(false);
      })
      .catch((err) => {
        console.log(err, "error catch");
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        // setLoading(false)
        setDisabled(false);
        setOtpLoader(false);
      });
  };

  const handleSubmit = (event) => {
  }

  const handleRetackClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.bgProfilePage}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert
              onClose={handleErorrClose}
              severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} lg={7} xl={7} className={classes.banaerRoot}>
              <img
                className={classes.banaer}
                src={"/images/matchUp/signupDetail.svg"}
                alt={"Alt Image"}
              />
            </Grid>
            <Grid item xs={12} xl={5} lg={5}>
              <Box className={classes.startQuizMain}>
                <Box className={classes.startQuizBox}>
                  <p className={classes.startText}>Welcome to MatchUpMates</p>
                  <p className={classes.startsecondaryText}>it’s time to choose your best matches</p>
                </Box>

                {signupType === "email" && (
                  <form
                    onSubmit={(event) => {
                      sendOtp(event, "email", formState.values.email);
                    }}
                  >
                    {/* <form> */}
                    <div style={{ marginTop: "30px" }}>
                      <Box my={1} className={classes.textFiledBox}>
                        <TextField
                          autoFocus={true}
                          id="outlined-basic"
                          label="Emails"
                          fullWidth
                          autoComplete="off"
                          size="small"
                          disabled
                          variant="outlined"
                          value={formState.values.email || ""}
                          onChange={handleFieldChange}
                          name="email"
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          // disabled={otpSendEmail}
                          required
                          error={hasError("email")}
                          helperText={
                            hasError("email")
                              ? formState.errors.email[0]
                              : null
                          }
                        />
                      </Box>
                      <form
                        onSubmit={(event) => {
                          verifyOtp(
                            event,
                            "phone",
                            formState.values.phone,
                            otpPhone
                          );
                        }}
                      >
                        {otpSendEmail && !verifyEmail ? (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent="center"
                          >
                            <OtpInput
                              shouldAutoFocus={true}
                              value={otpEmail || ""}
                              name="otp"
                              onChange={(otp) =>
                                !verifyEmail && setOtpEmail(otp)
                              }
                              numInputs={6}
                              className={classes.otp}
                              separator={<span>&nbsp;&nbsp; </span>}
                              inputStyle={{
                                width: isMobile ? "40px" : "52px",
                                fontSize: "20px",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid rgba(0,0,0,0.15)",
                              }}
                              focusStyle={{
                                border: "2px solid #2196f3",
                                outline: "none",
                              }}
                            />
                            {/* {console.log(otpEmail, "otpEmail")} */}
                          </Box>
                        ) : (
                          ""
                        )}
                        {counter > 0 ? (
                          <Box
                            style={{
                              textAlign: "left",
                              marginLeft: "20px",
                              marginTop: "5px",
                            }}
                          >
                            <span>Resend Otp in 00:{counter}</span>
                          </Box>
                        ) : (
                          ""
                        )}

                        {counter === 0 ? (
                          <Box
                            style={{
                              textAlign: "right",
                              marginRight: "15px",
                              marginTop: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {!otpLoader && (
                              <Link
                                onClick={(event) => {
                                  sendOtp(
                                    event,
                                    "email",
                                    formState.values.email
                                  );
                                }}
                              >
                                Resend Otp
                              </Link>
                            )}
                            {otpLoader && (
                              <>
                                &nbsp;
                                <CircularProgress size={15} />
                              </>
                            )}
                          </Box>
                        ) : (
                          ""
                        )}
                        {counter !== 0 && !counter ? (
                          <Box
                            mb={3}
                            className={classes.startedButtonBox}
                          >
                            <Button
                              className={classes.startedButton}
                              variant="contained"
                              disabled={
                                formState.isValid ? disabled : true
                              }
                              size="large"
                              color="primary"
                              fullWidth
                              onClick={(event) => {
                                sendOtp(
                                  event,
                                  "email",
                                  formState.values.email
                                );
                              }}
                            >
                              Send otp
                              {otpLoader && (
                                <>
                                  &nbsp;
                                  <CircularProgress
                                    size={20}
                                    style={{ color: "white" }}
                                  />
                                </>
                              )}
                            </Button>
                          </Box>
                        ) : (
                          <Box
                            mb={3}
                            className={classes.startedButtonBox}
                          >
                            <Button
                              className={classes.startedButton}
                              variant="contained"
                              type="submit"
                              disabled={
                                disabledLogin
                                  ? disabledLogin
                                  : otpEmail.length === 6
                                    ? false
                                    : true
                              }
                              size="large"
                              color="primary"
                              onClick={(event) => {
                                verifyOtp(
                                  event,
                                  "email",
                                  formState.values.email,
                                  otpEmail
                                );
                              }}
                            >
                              Log in
                              {loading && (
                                <>
                                  &nbsp;
                                  <CircularProgress
                                    size={20}
                                    style={{ color: "white" }}
                                  />
                                </>
                              )}
                            </Button>
                          </Box>
                        )}
                      </form>
                      {/* <hr className={classes.formHr} /> */}
                    </div>
                  </form>
                )}

                {/* mobile */}
                {signupType === "phone" && (
                  <>
                    <form>
                      <Box my={1} className={classes.inputePhone}>
                        <TextField
                          id="outlined-basic"
                          label="Phone"
                          fullWidth
                          size="small"
                          variant="outlined"
                          onChange={!verifyPhone && handleFieldChange}
                          name="phone"
                          value={formState.values.phone || ""}
                          disabled
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError('phone')}
                          helperText={hasError('phone') ? formState.errors.phone[0] : null}
                        />
                      </Box>
                      <form
                        onSubmit={(event) => {
                          verifyOtp(
                            event,
                            "phone",
                            formState.values.phone,
                            otpPhone
                          );
                        }}
                      >
                        {otpSendPhone && !verifyPhone ? (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent="center"
                          >
                            <OtpInput
                              shouldAutoFocus={true}
                              value={otpPhone || ""}
                              name="otp"
                              onChange={(otp) =>
                                !verifyPhone && setOtpPhone(otp)
                              }
                              numInputs={6}
                              className={classes.otp}
                              separator={<span>&nbsp;&nbsp; </span>}
                              inputStyle={{
                                width: isMobile ? "40px" : "52px",
                                fontSize: "20px",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid rgba(0,0,0,0.15)",
                              }}
                              focusStyle={{
                                border: "2px solid #2196f3",
                                outline: "none",
                              }}
                            />
                          </Box>
                        ) : (
                          ""
                        )}
                        {counter > 0 ? (
                          <Box
                            style={{
                              textAlign: "left",
                              marginLeft: "20px",
                              marginTop: "5px",
                            }}
                          >
                            <span>Resend Otp in 00:{counter}</span>
                          </Box>
                        ) : (
                          ""
                        )}
                        {counter === 0 ? (
                          <Box
                            style={{
                              textAlign: "right",
                              marginRight: "20px",
                              marginTop: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {!otpLoader && (
                              <Link
                                onClick={(event) => {
                                  sendOtp(
                                    event,
                                    "phone",
                                    formState.values.phone
                                  );
                                }}
                              >
                                Resend Otp
                              </Link>
                            )}
                            {otpLoader && (
                              <>
                                &nbsp;
                                <CircularProgress size={15} />
                              </>
                            )}
                          </Box>
                        ) : (
                          ""
                        )}
                        {counter !== 0 && !counter ? (
                          <Box
                            mb={3}
                            className={classes.startedButtonBox}
                          >
                            <Button
                              className={classes.startedButton}
                              variant="contained"
                              fullWidth
                              disabled={
                                formState.isValid ? disabled : true
                              }
                              size="large"
                              color="primary"
                              onClick={(event) => {
                                sendOtp(
                                  event,
                                  "phone",
                                  formState.values.phone
                                );
                              }}
                            >
                              Send otp
                              {otpLoader && (
                                <>
                                  &nbsp;
                                  <CircularProgress
                                    size={20}
                                    style={{ color: "white" }}
                                  />
                                </>
                              )}
                            </Button>
                          </Box>
                        ) : (
                          <Box
                            mb={3}
                            className={classes.startedButtonBox}
                          >
                            <Button
                              className={classes.startedButton}
                              type="submit"
                              variant="contained"
                              disabled={
                                disabledLogin
                                  ? disabledLogin
                                  : otpPhone.length === 6
                                    ? false
                                    : true
                              }
                              size="large"
                              color="primary"
                              onClick={(event) => {
                                verifyOtp(
                                  event,
                                  "phone",
                                  formState.values.phone,
                                  otpPhone
                                );
                              }}
                            >
                              Log in
                              {loading && (
                                <>
                                  &nbsp;
                                  <CircularProgress
                                    size={20}
                                    style={{ color: "white" }}
                                  />
                                </>
                              )}
                            </Button>
                          </Box>
                        )}
                      </form>
                    </form>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <WrongNavigationDialog open={open} onClose={handleRetackClose} />
    </>
  );
};
export default SignupDetails;
