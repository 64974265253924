
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Api from '../../Helper/ApiHandler';
var api = new Api();

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 10px 10px 10px",
    '& .MuiButton-root': {
      textTransform: "capitalize"
    }
  },
  dialogRoot: {
    padding: "10px",
    '& .MuiDialog-paper': {
      // overflow: "visible"
      borderRadius: "10px"
    },
    '& .MuiBackdrop-root': {
      backgroundColor: "rgb(0 0 0 / 0.05)",
    },
    '& .MuiPaper-elevation24': {
      boxShadow: "none"
    }
  },
  btnSave: {
    borderRadius: "5px",
    backgroundImage: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white"
  },
  btnCancel: {
    borderRadius: "5px",
  },
  flexWithPading: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 500
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500
  }
}))
function DeleteDialog(props) {
  const classes = useStyles();
  const { userType, componentName, slug, onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleDelete = () => {
    // Api Call For Delete 
    api.delete(`${userType}/${componentName}/${slug}`)
      .then((result) => {
        if (result.success === "true") {
          onClose(selectedValue, {
            message: result.message,
            type: 'success',
            open: true
          });
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  };

  return (
    <Dialog className={classes.dialogRoot} onClose={handleClose} maxWidth="xs" open={open} >
      <Grid container justify="center" style={{ padding: "20px" }}>
        <Grid item xs={12} className={classes.flex}>
          <img
            alt="Logo"
            src="/images/delete_model.svg"
          />
        </Grid>
        <Grid item xs={12} className={classes.flexWithPading}>
          <Typography
            component="h5"
            variant="h5"
            className={classes.title}
          >
            Are you sure want to delete it?
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.flex}>
          <Typography
            component="h6"
            variant="subtitle2"
            className={classes.subTitle}
          >
            You will not able to recover this Dormmate`!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.dialogButtonHeader}>
            <Button color="primary" variant='outlined' onClick={() => { handleClose() }} className={classes.btnCancel}>No, Cancel</Button>&nbsp;&nbsp;
            <Button color="primary" variant='contained' onClick={() => { handleDelete() }} className={classes.btnSave}>Yes, Delete it</Button>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default DeleteDialog;