import React from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import {
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,

  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TumblrShareButton,
  ViberShareButton,
  VKShareButton,
  WorkplaceShareButton
} from "react-share";

import Api from '../../Helper/ApiHandler';
import { useHistory } from 'react-router-dom';
var api = new Api();

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 10px 10px 10px",
    '& .MuiButton-root': {
      textTransform: "capitalize"
    }
  },
  dialogRoot: {
    padding: "10px",
    '& .MuiDialog-paper': {
      // overflow: "visible"
      borderRadius: "10px"
    },
    '& .MuiBackdrop-root': {
      backgroundColor: "rgb(0 0 0 / 51%)",
    },
    '& .MuiPaper-elevation24': {
      boxShadow: "none"
    }
  },
  btnSave: {
    borderRadius: "5px",
    backgroundImage: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white"
  },
  btnCancel: {
    borderRadius: "5px",
  },
  flexWithPading: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 500
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500
  },
  btnicon: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    display: "flex",
    alignItem: "center",
    width: "100%",
    border: "1px solid #00000045",
    padding: "6px",
    borderRadius: "10px",
  },
}))
function ShareGroupDialog(props) {
  const classes = useStyles();
  const history = useHistory()
  const { url, onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog className={classes.dialogRoot} onClose={handleClose} maxWidth="sm" open={open} >
      <Box p={"10px"}>
        <Grid spacing={2} container justify="center" style={{ padding: "20px" }}>
          <Grid item xs={12}>
            <div style={{ position: "absolute", top: "15px", right: "25px", cursor: "pointer" }}>
              <CloseIcon
                onClick={() => {
                  handleClose()
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <TwitterShareButton url={url} style={{ alignSelf: "center", width: "100%" }}>
              <Box className={classes.btnicon}>
                <TwitterIcon size={"40px"} round={true} />
                <div style={{ fontSize: "16px", fontWeight: "500", alignSelf: "center", marginLeft: "10px" }}>
                  <Hidden xsDown>
                    Twitter
                  </Hidden>
                </div>
              </Box>
            </TwitterShareButton>
          </Grid>
          <Grid item xs={6}>
            <WhatsappShareButton url={url} style={{ alignSelf: "center", width: "100%" }}>
              <Box className={classes.btnicon}>
                <WhatsappIcon size={"40px"} round={true} />
                <div style={{ fontSize: "16px", fontWeight: "500", alignSelf: "center", marginLeft: "10px" }}>
                  <Hidden xsDown>
                    Whatsapp
                  </Hidden>
                </div>
              </Box>
            </WhatsappShareButton>
          </Grid>
          <Grid item xs={6}>
            <FacebookShareButton url={url} style={{ alignSelf: "center", width: "100%" }}>
              <Box className={classes.btnicon}>
                <FacebookIcon size={"40px"} round={true} />
                <div style={{ fontSize: "16px", fontWeight: "500", alignSelf: "center", marginLeft: "10px" }}>
                  <Hidden xsDown>
                    Facebook
                  </Hidden>
                </div>
              </Box>
            </FacebookShareButton>
          </Grid>
          <Grid item xs={6}>
            <EmailShareButton url={url} style={{ alignSelf: "center", width: "100%" }}>
              <Box className={classes.btnicon}>
                <EmailIcon size={"40px"} round={true} />
                <div style={{ fontSize: "16px", fontWeight: "500", alignSelf: "center", marginLeft: "10px" }}>
                  <Hidden xsDown>
                    Email
                  </Hidden>
                </div>
              </Box>
            </EmailShareButton>
          </Grid>
          <Grid item xs={6}>
            <TelegramShareButton url={url} style={{ alignSelf: "center", width: "100%" }}>
              <Box className={classes.btnicon}>
                <TelegramIcon size={"40px"} round={true} />
                <div style={{ fontSize: "16px", fontWeight: "500", alignSelf: "center", marginLeft: "10px" }}>
                  <Hidden xsDown>
                    Telegram
                  </Hidden>
                </div>
              </Box>
            </TelegramShareButton>
          </Grid>
          <Grid item xs={6}>
            <LinkedinShareButton url={url} style={{ alignSelf: "center", width: "100%" }}>
              <Box className={classes.btnicon}>
                <LinkedinIcon size={"40px"} round={true} />
                <div style={{ fontSize: "16px", fontWeight: "500", alignSelf: "center", marginLeft: "10px" }}>
                  <Hidden xsDown>
                    Linkedin
                  </Hidden>
                </div>
              </Box>
            </LinkedinShareButton>
          </Grid>
        </Grid>
      </Box>
    </Dialog >
  );
}

export default ShareGroupDialog;