import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import validate from "validate.js";
import MuiAlert from '@material-ui/lab/Alert';
import Api from '../../../../Helper/ApiHandler';
import { useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const schema = {
  oldPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: { minimum: 6 }
  },
  newPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: { minimum: 6 }
  },
  confirmNewPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: { minimum: 6 }
  },
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    background: "#E5E5E5",
    padding: "50px 0",
    height: "800px",
    display: "flex",
    justifyContent: "center",
  },
  whitePaperReset: {
    background: "white",
    borderRadius: "10px",
    width: "875px",
    minHeight: "330px",
    maxHeight: "420px",
    [theme.breakpoints.down('sm')]: {
      // height: "auto",
      minHeight: "650px",
      maxHeight: "700px",
      margin: "0px 30px"
    },
  },
  saveButton: {
    width: "220px",
    height: "40px",
    fontSize: "20px",
    borderRadius: "10px",
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)'
  },
  passwordFiled: {
    width: "90%",
    margin: "5px 15px",
    //fontFamily: "Gilroy !important",
  },
  headingReset: {
    padding: "15px !important",
    margin: "0",
    fontSize: "22px !important",
    fontWeight: "700 !important",
  },
  imgaeGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      borderBottom: "1px solid #b7b7b7"
    }
  },
  bottonRoot: {
    padding: "30px 0px",
    display: "flex",
    justifyContent: "center"
  },
  formRoot: {
    padding: "20px 0px 0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center"
  }
}));

const FormResetPassword = (props) => {
  const router = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [sessionMessage, setSessionMessage] = React.useState({
    message: '',
    type: '',
    open: false
  });


  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleFieldChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    setDisabled(true)
    if (formState.isValid === true) {
      api.post('user/changepwd', {
        data: formState.values
      })
        .then((result) => {
          if (result.success === "true") {
            router.push({
              pathname: `/your-profile`,
              search: '',
              state: {
                sessionPropsMessage: {
                  message: result.message,
                  type: 'success',
                  open: true
                }
              }
            })
            // router.push("/your-profile")
          } else {
            setSessionMessage({
              message: result.message,
              type: 'error',
              open: true
            });
            console.log(result, "error")
          }
          setLoading(false)
          setDisabled(false)
        })
        .catch((err) => {
          console.log(err, "error catch")
          setLoading(false)
          setDisabled(false)
        });
    }
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleClickShowPassword2 = () => {
    setShowPass2(!showPass2);
  };

  const handleClickShowPassword3 = () => {
    setShowPass3(!showPass3);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.cardRoot}>
        <Grid container className={classes.whitePaperReset}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.imgaeGrid}>
            <img src="/images/matchUp/resetPassword.png" alt="resetPassword" height="300px" width="300px" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex" }}>
            <Divider orientation="vertical" />
            <div className={classes.formRoot}>
              <form onSubmit={(event) => handleSubmit(event)}>
                <h2 className={classes.headingReset}> Change Password </h2>
                <TextField
                  className={classes.passwordFiled}
                  fullWidth={true}
                  size="small"
                  id="outlined-basic"
                  type={showPass ? "text" : "password"}
                  label="Old Password"
                  variant="outlined"
                  name="oldPassword"
                  onChange={handleFieldChange}
                  error={hasError("oldPassword")}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {!showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                  helperText={
                    hasError("oldPassword")
                      ? formState.errors.oldPassword[0]
                      : null
                  }
                />
                <TextField
                  className={classes.passwordFiled}
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label="New Password"
                  type={showPass2 ? "text" : "password"}
                  variant="outlined"
                  name="newPassword"
                  onChange={handleFieldChange}
                  error={hasError("newPassword")}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {!showPass2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                  helperText={
                    hasError("newPassword")
                      ? formState.errors.newPassword[0]
                      : null
                  }
                />
                <TextField
                  className={classes.passwordFiled}
                  size="small"
                  id="outlined-basic"
                  type={showPass3 ? "text" : "password"}
                  label="Confirm New Password"
                  variant="outlined"
                  name="confirmNewPassword"
                  onChange={handleFieldChange}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword3}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {!showPass3 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                  error={hasError("confirmNewPassword")}
                  helperText={
                    hasError("confirmNewPassword")
                      ? formState.errors.confirmNewPassword[0]
                      : null
                  }
                />
                <div className={classes.bottonRoot}>
                  <Button
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                    disabled={formState.isValid ? disabled : true}
                    type="submit"
                  >
                    SAVE
                    {loading && <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></>}
                  </Button>
                </div>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default FormResetPassword;
