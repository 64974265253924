import React from "react";
import PropertyLandingDetail from "./component/PropertyLandingDetail";

const PropertyLanding = () => {
  return (
    <>
      <PropertyLandingDetail />
    </>
  );
};

export default PropertyLanding;
