import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import Api from "../../../Helper/ApiHandler";
import { useHistory } from "react-router-dom";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // background: "#fff"
    background: "#FFF7F8",
    marginBottom: "100px",
  },
  userListHedding: {
    fontSize: "30px",
    color: "#FE4B6B",
    fontWeight: "500",
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "30px",
  },
  userList: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
    },
    // textAlign: "center"
  },
  userPicture: {
    // borderRadius: "50%",
    borderRadius: "10px",
    height: "180px",
    width: "100%",
    objectFit: "cover",
    // width: "150px",
  },
  userPicture1: {
    // borderRadius: "50%",
    borderRadius: "10px",
    height: "140px",
    width: "100%",
    objectFit: "cover",
    // width: "150px",
  },
  userName: {
    fontSize: "12px",
    // textAlign: "center",
    // marginTop: "20px",
    color: "#FE4B6B",
    fontWeight: "500",
  },
  addressText: {
    fontSize: "12px",
    // textAlign: "center",
    // marginTop: "20px",
    marginBottom: "30px",
    color: "#808080",
    fontWeight: "500",
    display: "flex",
  },
  carosalRoot: {
    "& .react-multiple-carousel__arrow--right": {
      minWidth: "25px",
      minHeight: "25px",
      top: "45%",
    },
    "& .react-multiple-carousel__arrow::before": {
      fontSize: "12px",
    },
    "& .react-multiple-carousel__arrow--left": {
      minWidth: "25px",
      minHeight: "25px",
      top: "45%",
    },
  },
  carosalImageBox: {
    height: "25vh",
    width: "100%",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px 10px 10px 10px",
    objectFit: "cover",
    // [theme.breakpoints.down("lg")]: {
    //   width: "18.8vw",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    // },
  },
  images: {
    width: "25%",
    height: "350px",
    padding: "10px",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  imagesSpace: {
    width: "25%",
    height: "350px",
    padding: "10px",
    overflow: "hidden",
    marginTop: 50,
    [theme.breakpoints.down("xs")]: {
      width: "60%",
      marginTop: 0,
    },
  },
}));
const list = [
  {
    name: "Lake Stackpoole House",
    profile_picture: "/images/matchUp/property1.png",
    address: "4140 Parker Rd.New Mexico",
  },
  {
    name: "Lake Stackpoole House",
    profile_picture: "/images/matchUp/property2.png",
    address: "4140 Parker Rd.New Mexico",
  },
  {
    name: "Lake Stackpoole House",
    profile_picture: "/images/matchUp/property1.png",
    address: "4140 Parker Rd.New Mexico",
  },
  {
    name: "Lake Stackpoole House",
    profile_picture: "/images/matchUp/property2.png",
    address: "4140 Parker Rd.New Mexico",
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const RoommateProperty = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    api
      .get(`propertyforlanding`)
      .then((result) => {
        if (result.success === "true") {
          setData(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const history = useHistory();

  return (
    <>
      <div className={classes.mainRoot}>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            lg={12}
            xl={12}
            className={classes.userListHedding}
          >
            Explore our most popular room
          </Grid>
          <Grid
            item
            xs={12}
            lg={10}
            xl={10}
            md={10}
            sm={10}
            className={classes.userList}
          >
            <Grid spacing={2} container className={classes.userList}>
              {data.map((obj, ind) => {
                // console.log(obj, "obj");
                return (
                  <>
                    <div
                      key={`obj_${ind}`}
                      className={
                        ind === 0 || ind === 3
                          ? classes.imagesSpace
                          : classes.images
                      }
                    >
                      <Carousel
                        responsive={responsive}
                        className={classes.carosalRoot}
                      >
                        {obj.property_photos &&
                          obj.property_photos.map((value) => {
                            return (
                              <a
                                target="_blank"
                                href={`property/${obj.slug}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Box
                                  className={classes.carosalImageBox}
                                  component="img"
                                  src={value}
                                />
                              </a>
                            );
                          })}
                      </Carousel>
                      <a
                        target="_blank"
                        href={`property/${obj.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography className={classes.userName}>
                          {obj.property_name}
                        </Typography>{" "}
                      </a>
                      <a
                        target="_blank"
                        href={`property/${obj.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography className={classes.addressText}>
                          <LocationOnOutlinedIcon
                            style={{ fontSize: 17, color: "#FE4B6B" }}
                          />

                          {obj.address}
                        </Typography>
                      </a>
                    </div>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RoommateProperty;
