import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Container, Grid } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CustomerSays.css";
import Api from "../../../../Helper/ApiHandler";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  root: {},
  mainBgColor: {
    [theme.breakpoints.down("xs")]: {
      "& .custom-nextArrow": {
        display: "none",
      },
      "& .custom-prevArrow": {
        display: "none",
      },
    },
    background: "#E1F2FF",
    padding: "30px 0",
    marginTop: "50px",
    paddingBottom: "80px",
    backgroundImage: `url(${"../images/matchUp/mapBg.png"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    "& .MuiGrid-grid-lg-12": {
      display: "flex",
      // flexDirection: "column",
      justifyContent: "center",
    },
    "& .slick-slide div": {
      boxShadow: "-2px 2px 6px 0px rgb(0 0 0 / 15%)",
      borderRadius: "15px",
      "& .bottomBox": {
        borderRadius: "0px 0px 15px 15px",
      },
      "& .userImageBox": {
        borderRadius: "50%",
      },
    },
  },
  headingStep: {
    textAlign: "center",
    padding: "35px 0",
    margin: "0",
    fontSize: "28px !important",
    color: "#FE4B6B",
    fontWeight: 500,
    // '& .slick-track': {
    //   padding: "100px 0px !important"
    // }
  },
  cardSlide: {
    width: "100%",
    heihgt: "500px",
    background: "white",
    padding: "20px",
    borderRadius: "10px",
  },
  slickCenter: {
    margin: "10px",
    transform: "scale(0.7) !important",
  },
}));

const CustomerSays = (props) => {
  const classes = useStyles();
  const [lstTestimonial, setLstTestimonial] = React.useState([]);

  const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }) => {
    const { onClick } = props;

    return (
      <div
        {...props}
        className="custom-prevArrow"
        onClick={onClick}
        style={{ marginRight: "10px" }}
      >
        <img src="./images/matchUp/preArrow.svg" alt="next" />
      </div>
    );
  };

  const GalleryNextArrow = ({ currentSlide, slideCount, ...props }) => {
    const { onClick } = props;

    return (
      <div
        {...props}
        className="custom-nextArrow"
        onClick={onClick}
        style={{ marginLeft: "10px" }}
      >
        <img src="./images/matchUp/nextArrow.svg" alt="next" />
      </div>
    );
  };

  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    className: "center",
    speed: 200,
    focusOnSelect: true,
    centerPadding: "100px",
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <GalleryNextArrow />,
    prevArrow: <GalleryPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getTestimonial();
  }, []);

  // Get Testimonial List
  const getTestimonial = async () => {
    // Api Call For Fetch Testimonial List
    api
      .get(`user/testimoniallist`, { skipAuth: true })
      .then((result) => {
        if (result.success === "true") {
          setLstTestimonial(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  return (
    <div style={{ marginTop: "32px" }}>
      {lstTestimonial.length ? (
        <div className={classes.mainBgColor}>
          <>
            <h1 className={classes.headingStep}>What our Customers say ?</h1>

            <Container maxWidth="lg">
              <Grid
                direction="row"
                justifyContent="center"
                alignItems="center"
                container
              >
                <Grid item container xl={12} lg={12} xs={12}>
                  <Slider style={{ width: "80%" }} {...settings}>
                    {lstTestimonial &&
                      lstTestimonial.map((obj) => {
                        return (
                          <div>
                            <div className="cardBox">
                              <p className="cardText">{obj.testimonial}</p>
                              {/* <div className="quoteImgBox">
                              <img
                                className="quoteImage"
                                src={"/images/matchUp/quote.png"}
                                alt="picture_url"
                              />
                            </div> */}
                              <div className="bottomBox">
                                <div className="userImageBox">
                                  <img
                                    src={obj.picture_url}
                                    alt="picture_url"
                                  />
                                </div>
                                <h6 className="nameOnCard"> {obj.username} </h6>
                                <p className="cityNameCard"> {obj.email} </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </Grid>
              </Grid>
            </Container>
          </>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomerSays;

CustomerSays.propTypes = {
  className: PropTypes.string,
};
