import React, { useEffect } from 'react'
import SignupDetail from './component/SignupDetails'
import useRouter from "utils/useRouter";

const SignupDetails = (props) => {
    const { teamToken, step2, sessionPropsMessage } = (props.location && props.location.state) || {};
    const router = useRouter();

    useEffect(() => {
        console.log(step2, "step2")
        if (!step2) {
            router.history.push("/")
        }
    }, [step2]);

    return (
        <>
            <SignupDetail sessionPropsMessage={sessionPropsMessage} teamToken={teamToken} />
        </>
    )
}

export default SignupDetails
