import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import HowItsWork from "../HowItsWork";
import TopMember from "../TopMember";
import SoulmateLandingTrust from "../SoulmateLandingTrust";
import SoulmateLandingQuestion from "../SoulmateLandingQuestion";
import SoulmateBest from "../SoulmateBest";
import SignupModel from "components/AuthModel/SignupModel";
import LoginModel from "components/AuthModel/LoginModel";
import Forgotpassword from "components/AuthModel/Forgotpassword";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiGrid-spacing-xs-2": {
      marginTop: 0,
    },
  },
  propertyImage: {
    width: "100vw",
    filter: "brightness(0.8)",
    height: "650px",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  Findahome: {
    fontSize: "36px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#FFFFFF",
    fontWeight: 600,
    margin: 0,
  },
  Findahome_Detail: {
    fontSize: "18px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px !important",
    },
    color: "#FFFFFF",
    fontWeight: 500,
    margin: "15px 0",
  },
  propertyImageLabel: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
    width: "100%",
  },
  cardHeading: {
    color: "#FE4B6B",
    fontSize: 32,
    fontWeight: 600,
  },
  input_label: {
    fontSize: 16,
    color: "#000000",
    fontWeight: 500,
    marginTop: 10,
  },
  learMoreButton: {
    textTransform: "capitalize",
    width: "100%",
    height: "45px",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: 400,
    marginTop: 14,
    borderRadius: 8,
  },
  learMoreDetailButton: {
    textTransform: "capitalize",
    width: "25%",
    height: "45px",
    fontWeight: 400,
    marginTop: 14,
    color: "#FFF",
    borderColor: "#FFF",
    borderRadius: 8,
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  form__input: {
    marginTop: 14,
    background: "#FFFFFF",
    borderRadius: 9,
    height: 45,
    width: "100%",
  },
  radioGroup: {
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
      width: "100%",
      height: 45,
      background: "#FFFFFF",
      borderRadius: 8,
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#fff;",
    },
  },
  gridRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 25px",
    [theme.breakpoints.down("xs")]: {
      padding: "17px 25px",
    },
  },
}));

const SoulmateLandingDetails = (props) => {
  const classes = useStyles();
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [forgotpasswordOpen, setForgotpasswordOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onLoginOpen = () => {
    setLoginOpen(true);
    setForgotpasswordOpen(false);
    setOpen(false);
  };

  const onForgotpasswordOpen = () => {
    setLoginOpen(false);
    setForgotpasswordOpen(true);
  };

  const onSignupOpen = () => {
    setLoginOpen(false);
    setOpen(true);
  };

  const handleClickOpenLogin = () => {
    setLoginOpen(true);
  };

  return (
    <>
      <div>
        <Grid container>
          <Grid item xl={12} lg={12} xs={12} style={{ position: "relative" }}>
            <img
              src="/images/matchUp/soulmateLanding.jpg"
              alt="soulmateLanding"
              className={classes.propertyImage}
            />
            <div className={classes.propertyImageLabel}>
              <Grid container justify="center">
                {/* <Grid item xs={12} sm={5} md={5} lg={4} xl={3}>
                  <Card style={{ background: "#ffffff82", padding: 24 }}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={classes.cardHeading}>
                          Find Your Soulmate
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel className={classes.input_label}>
                          I am a:
                        </InputLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={classes.mainRoot}
                      >
                        <RadioGroup
                          value={firstValues}
                          onChange={handleFirstValues}
                        >
                          <Grid
                            container
                            spacing={2}
                            className={classes.radioGroup}
                          >
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="Male"
                                control={<Radio color="primary" />}
                                label="Male"
                                className={classes.you}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <FormControlLabel
                                className={classes.you}
                                value="Female"
                                control={<Radio color="primary" />}
                                label="Female"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <InputLabel className={classes.input_label}>
                          Looking for a:
                        </InputLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={classes.mainRoot}
                      >
                        <RadioGroup
                          value={secondValues}
                          onChange={handleSecondValues}
                        >
                          <Grid
                            container
                            spacing={2}
                            className={classes.radioGroup}
                          >
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="Male"
                                control={<Radio color="primary" />}
                                label="Male"
                                className={classes.you}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <FormControlLabel
                                className={classes.you}
                                value="Female"
                                control={<Radio color="primary" />}
                                label="Female"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          fullWidth
                          name="first_name"
                          variant="outlined"
                          id="filled-password-input"
                          type="text"
                          value={formState.values.first_name || ""}
                          error={hasError("first_name")}
                          helperText={
                            hasError("first_name")
                              ? formState.errors.first_name[0]
                              : null
                          }
                          onChange={handleChange}
                          placeholder="First name"
                          InputProps={{
                            className: classes.form__input,
                            startAdornment: (
                              <InputAdornment style={{ marginRight: 10 }}>
                                <img src="/images/user.svg" alt="User" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="filled-password-input"
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          error={hasError("last_name")}
                          helperText={
                            hasError("last_name")
                              ? formState.errors.last_name[0]
                              : null
                          }
                          value={formState.values.last_name || ""}
                          onChange={handleChange}
                          InputProps={{
                            className: classes.form__input,
                            startAdornment: (
                              <InputAdornment style={{ marginRight: 10 }}>
                                <img src="/images/user.svg" alt="User" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="filled-password-input"
                          type="text"
                          name="email"
                          error={hasError("email")}
                          helperText={
                            hasError("email") ? formState.errors.email[0] : null
                          }
                          placeholder="Your Email"
                          onChange={handleChange}
                          value={formState.values.email || ""}
                          InputProps={{
                            className: classes.form__input,
                            startAdornment: (
                              <InputAdornment style={{ marginRight: 10 }}>
                                <img src="/images/mail.svg" alt="User" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                          className={classes.learMoreButton}
                          fullWidth
                          variant="contained"
                          color="primary"
                          // onClick={() => {
                          //   // siwtchModule("DormMates")
                          //   // router.history.push("/faq#dormmate")
                          //   router.history.push("/dormmate");
                          // }}
                        >
                          Get Started
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5}
                  className={classes.gridRoot}
                >
                  <h1 className={classes.Findahome}>
                    Meet new and interesting people.
                  </h1>
                  <h4 className={classes.Findahome_Detail}>
                    Join MatchUpMates, where you could meet anyone, anywhere!
                  </h4>
                  <Button
                    fullWidth
                    className={classes.learMoreDetailButton}
                    variant="outlined"
                    // style={{ color: "#FFFFFF", width: "25%", height: 45 }}
                    // color="primary"
                    onClick={handleClickOpenLogin}
                  >
                    Find Soulmate
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <HowItsWork />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <TopMember />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <SoulmateLandingTrust />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <SoulmateLandingQuestion />
          </Grid>
          <Grid item xl={12} lg={12} xs={12}>
            <SoulmateBest />
          </Grid>
        </Grid>
        <SignupModel open={open} setOpen={setOpen} setOpenLogin={onLoginOpen} />
        <LoginModel
          loginOpen={loginOpen}
          setLoginOpen={setLoginOpen}
          setOpenSignUp={onSignupOpen}
          setForgotpasswordOpen={onForgotpasswordOpen}
        />
        <Forgotpassword
          loginOpen={forgotpasswordOpen}
          setLoginOpen={onLoginOpen}
          setOpenSignUp={onSignupOpen}
        />
      </div>
    </>
  );
};

export default SoulmateLandingDetails;
