import React from "react";
import Favourite from "./component/Favourite/index";

const MyFavourite = () => {
  return (
    <>
      <Favourite />
    </>
  );
};

export default MyFavourite;
