import React, { useContext, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  Snackbar,
  Hidden,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import useRouter from "utils/useRouter";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import AppDataContext from "Helper/AppDataContext";
import MuiAlert from "@material-ui/lab/Alert";

import Api from "../../../../Helper/ApiHandler";
import OneModuleLiveOurServices from "./OneModuleLiveOurServices";
import { useEffect } from "react";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {},
  headingStep: {
    textAlign: "center",
    padding: "35px 0",
    margin: "0",
    fontSize: "28px !important",
    color: "#FE4B6B",
    backgroundColor: "#f6f6f6",
    fontWeight: 500,
  },
  bgMainService: {
    background: "#f6f6f6",
    minHeight: "550px",
    position: "relative"
  },
  cardBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    // width: "319px",
    width: "100%",
    height: "380px",
    marginBottom: "55px",
    boxShadow: "0px 15px 15px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    paddingBottom: "20px",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  imgService: {
    // width: "100px",
    minHeight: "125px",
  },
  textCard: {
    padding: "10px 20px",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  learMoreButton: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    width: "141px",
    height: "45px",
    background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    fontWeight: 400,
  },
  cardRoot: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#f6f6f6",
  },
  cardHeader: {
    fontSize: "24px",
    fontWeight: 500,
  },
  imageBack: {
    position: "absolute",
    top: 0,
    height: "100%",
    right: 0
  },
  imageModule: {
    // height: "350px",
    width: "200px",
  },
  imageModuleRootMobile: {
    top: "25%",
    height: "fit-content",
    right: "17%",
    position: "absolute",
    padding: "30px",
    background: "#FFF7F8",
    borderRadius: "10px",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 10%)",
    width: "fit-content",
    bottom: "20px",
    zIndex: 1
  },
}));

const ServicePart = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const objContain = {
    DORMMATE: {
      heading: "Find your next perfect Dormmate!",
      subheading: [
        "Tired of looking for a Dormmate? We will find you the perfect Dormmate Match that fits your Lifestyle & Preferences with a Verified ID.",
        "Make a real connection via text or video call. It's fast, safe, secure & stress-free. MatchUpMates will do it all for you!",
      ],
      image: "https://dev-matchupmates.s3.amazonaws.com/file/samples/1664261816274_Dormmates%201.png"
    },
    ROOMMATE: {
      heading: "heading heading heading heading heading heading heading heading heading heading heading ",
      subheading: [
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
      ],
      image: "https://dev-matchupmates.s3.amazonaws.com/file/samples/1664261816274_Dormmates%201.png"
    },
    SOULMATE: {
      heading: "heading heading heading heading heading heading heading heading heading heading heading ",
      subheading: [
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
      ],
      image: "https://dev-matchupmates.s3.amazonaws.com/file/samples/1664261816274_Dormmates%201.png"
    },
    TEAMMATE: {
      heading: "heading heading heading heading heading heading heading heading heading heading heading ",
      subheading: [
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
        "subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading subheading ",
      ],
      image: "https://dev-matchupmates.s3.amazonaws.com/file/samples/1664261816274_Dormmates%201.png"
    },
  }
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [loading, setLoading] = React.useState(false);
  const [lstModule, setLstModule] = React.useState([])
  const [selectData, setSelectData] = useState({})

  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  useEffect(() => {
    moduleCheck()
  }, []);


  useEffect(() => {

  }, [selectData])

  useEffect(() => {
    onLoad()
  }, [])

  const onLoad = () => {
    if (appContext.objAuth?.DORMMATE) {
      setSelectData(objContain.DORMMATE)
    } else if (appContext.objAuth?.ROOMMATE) {
      setSelectData(objContain.ROOMMATE)
    } else if (appContext.objAuth?.SOULMATE) {
      setSelectData(objContain.SOULMATE)
    } else if (appContext.objAuth?.TEAMMATE) {
      setSelectData(objContain.TEAMMATE)
    }
  }

  const moduleCheck = () => {
    // if (appContext.objAuth?.LIVE_MODULE_COUNT > 1) {
    setLoading(true)
    api.get(`ourservicedata`)
      .then((result) => {
        if (result.success === "true") {
          setLstModule(result.data)
        } else {
          console.log(result, "error")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "catch error")
        setLoading(false)
      });
    // }
  }

  const siwtchModule = (value) => {
    // handleCloseModule(e)
    // user/switchmodule
    api
      .put("user/switchmodule", {
        data: {
          module_name: value,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("currentModule", value);
          AsyncLocalStorage.setItem("tabValue", 0);
          AsyncLocalStorage.setItem("tabFavValue", 0);
          // setSelected(result?.data?.module_name)
          setAppContext({
            ...appContext,
            currentModule: result?.data?.module_name,
          });
          if (value === "DormMates") {
            if (!result.data.quiz) {
              router.history.push("/matching-quiz");
            } else {
              router.history.push("/your-profile");
            }
          } else {
            router.history.push("/comminsoon");
          }
        } else {
          console.log("call");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  console.log(lstModule, "lstModule")
  return (
    <div className={classes.bgMainService}>
      <Container>
        <Fade in={true} timeout={600}>
          <div>
            {sessionMessage && (
              <Snackbar
                open={sessionMessage.open}
                autoHideDuration={3000}
                onClose={handleClose}
                style={{ zIndex: "22222" }}
              >
                <Alert onClose={handleClose} severity={sessionMessage.type}>
                  {sessionMessage.message}
                </Alert>
              </Snackbar>
            )}
            <h2 className={classes.headingStep}> Our Services </h2>
            {console.log(appContext.objAuth?.LIVE_MODULE_COUNT, "appContext.objAuth?.LIVE_MODULE_COUNT")}
            {appContext.objAuth?.LIVE_MODULE_COUNT > 1 &&
              <Box className={classes.cardRoot} px={2}>
                <Grid container spacing={2} justify="center">
                  {lstModule.map((obj) => {
                    return (
                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Box pt={3} className={classes.cardBox}>
                          <img
                            className={classes.imgService}
                            src={obj.picture ? obj.picture : "/images/matchUp/service2.png"}
                            alt="step Dormates icon"
                          />
                          <div className={classes.cardHeader}> {obj.name} </div>
                          <p className={classes.textCard}>
                            {obj.contain}
                          </p>
                          <Button
                            className={classes.learMoreButton}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              // siwtchModule("DormMates")
                              // router.history.push("/faq#dormmate")
                              router.history.push(obj.link);
                            }}
                          >
                            Learn More
                          </Button>
                        </Box>
                      </Grid>
                    )
                  })}

                  {/*                 
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Box className={classes.cardBox}>
                    <img
                      className={classes.imgService}
                      src="/images/matchUp/service4.png"
                      alt="step Dormates icon"
                    />
                    <div className={classes.cardHeader}> TeamMates </div>
                    <p className={classes.textCard}>
                      Introduces advanced match technology allow project team to
                      complete the task on time to achieve the milestone.
                    </p>
                    <Button
                      className={classes.learMoreButton}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        // siwtchModule("DormMates")
                        router.history.push("/teammate");
                      }}
                    >
                      Learn More
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Box pt={3} className={classes.cardBox}>
                    <img
                      className={classes.imgService}
                      src="/images/matchUp/service1.png"
                      alt="step Dormates icon"
                    />
                    <div className={classes.cardHeader}> RoomMates </div>
                    <p className={classes.textCard}>
                      Providing clear decision making criteria on the roommate
                      expectations instead learn more after move in.
                    </p>
                    <Button
                      className={classes.learMoreButton}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        {
                          process.env.REACT_APP_ROOMMATES == "true"
                            ? router.history.push("/roommate")
                            : router.history.push("/comminsoon");
                        }
                      }}
                    >
                      Learn More
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Box pt={3} className={classes.cardBox}>
                    <img
                      className={classes.imgService}
                      src="/images/matchUp/service3.png"
                      alt="step Dormates icon"
                    />
                    <div className={classes.cardHeader}> SoulMates </div>
                    <p className={classes.textCard}>
                      A relationship platform connecting minds, mutual respect,
                      an unconditional love with a better understanding of each
                      other.
                    </p>
                    <Button
                      className={classes.learMoreButton}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        // siwtchModule("DormMates")
                        router.history.push("/soulmate");
                      }}
                    >
                      Learn More
                    </Button>
                  </Box>
                </Grid> */}
                </Grid>
              </Box>
            }
            {appContext.objAuth?.LIVE_MODULE_COUNT <= 1 &&
              <div>
                <OneModuleLiveOurServices />
              </div>
            }
          </div>
        </Fade>
      </Container>
      {appContext.objAuth?.LIVE_MODULE_COUNT <= 1 &&
        <Hidden smDown>
          <div className={classes.imageModuleRootMobile}>
            <img src={"https://dev-matchupmates.s3.amazonaws.com/file/samples/1664261816274_Dormmates%201.png"} alt="imageModulae1" className={classes.imageModule} />
          </div>
          <img src={"/images/matchUp/homeBack.svg"} alt="imageBacak" className={classes.imageBack} />
        </Hidden>
      }
    </div>
  );
};

export default ServicePart;

ServicePart.propTypes = {
  className: PropTypes.string,
};
