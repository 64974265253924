import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Container, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";

import Api from '../../../../Helper/ApiHandler';
const fs = require('fs')
const path = require('path');

var api = new Api();

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
    // minHeight: "90vh"
  },
  aboutStep: {
    textAlign: "center",
    margin: "0",
    paddingTop: "25px",
    color: "white"
    //fontFamily: "Gilroy !important",
  },
  contantAbout: {
    fontSize: "16px",
    color: "gray",
    textAlign: "justify",
    paddingBottom: "30px"
  },
  contantBoxAbout: {
    marginTop: "50px",
    // height:"380px",
    [theme.breakpoints.down('xs')]: {
      height: "auto",
    },
  },
  topPart: {
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    height: "400px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
}));

const AboutUsDetail = (props) => {
  const classes = useStyles();

  const [about, setAbout] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [meta, setMeta] = React.useState({});

  useEffect(() => {
    onLoad()
    // onLoadMeta()
  }, []);


  const onLoad = () => {
    setLoading(true)
    // Api Call For Fetch The About
    api.get(`user/cms/about1sp`)
      .then((result) => {
        if (result.success === "true") {
          setAbout(result.data.details)
        } else {
          console.log(result, "error")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "catch error")
        setLoading(false)
      });
  };

  // const onLoadMeta = () => {
  //   // For meta
  //   api.post('staticmetadata', {
  //     data: { type: "about" }
  //   })
  //     .then((result) => {
  //       if (result.success === "true") {
  //         console.log(result.data, "result")
  //         setMeta(result.data, 'result.data')
  //       } else {
  //         console.log(result, "success === false")
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "error catch")
  //     });
  // }
  return (
    <>
      <div className={classes.mainRoot}>
        <div className={classes.topPart}>
          {/* <img src="/images/matchUp/faq.png" alt="" className={classes.topPartImage1} />
          <img src="/images/matchUp/faq2.png" alt="" className={classes.topPartImage2} /> */}
          <h1 className={classes.aboutStep}> About Us </h1>
        </div>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xl={12} lg={12} xs={12}>
              <div className={classes.contantBoxAbout}>
                {!loading ?
                  <div className={classes.contantAbout} dangerouslySetInnerHTML={{ __html: about }}>
                  </div>
                  :
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={30} />
                  </div>
                }
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default AboutUsDetail;
