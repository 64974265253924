import React, { useState } from 'react';

function ImageWithLoading(props) {
  const { className, style, url } = props;
  const [loading, setLoading] = useState(false);
  console.log(style, url, "style, url")
  return (
    // Loading_icon11.gif
    <img
      src={!loading ? "/images/matchUp/Loading_icon2.gif" : url}
      alt={url}
      onLoad={() => {
        setLoading(true)
      }}
      style={
        loading
          ?
          style ? style : {}
          :
          {
            height: "100%",
            width: "100%",
            cursor: "pointer",
            objectFit: "contain",
            background: "white",
            borderRadius: "10px 10px 0px 0px",
          }
      }
      className={className ? className : ""}
    />
  );
}

export default ImageWithLoading;