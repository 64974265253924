import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Container, FormControlLabel, Grid, IconButton, InputAdornment, Snackbar, TextField, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from '../../Helper/ApiHandler';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MuiAlert from '@material-ui/lab/Alert';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import validate from "validate.js";
import AppDataContext from "Helper/AppDataContext";
import firebase from 'firebase/app';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useRouter from "utils/useRouter";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import clsx from "clsx";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
var api = new Api();


var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const lstGender = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
  { id: "intersex", value: "Intersex" },
  { id: "trans", value: "Trans" },
  { id: "noPreference", value: "No Preference" },
]

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character"
    }
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: { maximum: 20 },
    format: {
      pattern: "^[a-zA-Z_ ]*$",
      flags: "i",
      message: "You can enter only character"
    }
  },
  g: {
    presence: { allowEmpty: false, message: "is required" },
  },
  zodiac: {
    presence: { allowEmpty: false, message: "is required" },
  },
  location: {
    presence: { allowEmpty: false, message: "is required" },
  },
  date_of_birth: {
    presence: { allowEmpty: false, message: "is required" },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: { maximum: 254 }
  }
};

const useStyles = makeStyles(theme => ({
  bgProfilePage: {
    background: "#E5E5E5",
    padding: "35px 0",
    height: "100vh"
  },
  questionsText: {
    fontSize: "20px",
    padding: "20px",
    color: "white",
    fontWeight: "bold",
    margin: "0",
  },
  questionsBox: {
    background: "#0071CE",
    borderRadius: "10px 10px 0px 0px",
  },
  startQuizBox: {
    background: "#fff",
    borderRadius: "10px 10px 0px 0px",
  },
  startText: {
    color: "black",
    fontSize: "16px",
    padding: "10px",
    fontWeight: "500",
    margin: "0",
    textAlign: "center"
  },
  answerBox: {
    background: "white",
    borderRadius: "0px 0px 10px 10px",
    // padding: "20px"
  },
  selectOptionIn: {
    //fontFamily: "Gilroy !important",
    '& .MuiOutlinedInput-notchedOutline': {
      // borderWidth: 0
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon": {
      padding: "10.5px",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      // fontSize: "12px"
    },
    // backgroundColor: "#e9edf0"
  },
  headingAns: {
    margin: "0",
    padding: "20px",
    paddingBottom: "0",
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: "10px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
  },
  chipInputEdit: {
    // margin: "3px 20px",
    '& .MuiAutocomplete-tag': {
      borderRadius: "5px !important",
      background: "rgb(204 227 245)"
    },
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: "10px"
    }
  },
  chip: {
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
    borderRadius: "5px",
    margin: "5px 5px",
    padding: "0 0",
    height: "28px"
  },
  chipGruop: {
    padding: "10px 20px",
  },
  // autocompletChip: {
  //   background: "white",
  //   '& .MuiChip-root': {
  //     borderRadius: "5px",
  //     background: "#d5606045",
  //     height: "auto",
  //   },
  //   '& .MuiChip-outlined': {
  //     border: "none",
  //     color: "#FE4B6B",
  //     padding: "2px"
  //   },
  //   '& .MuiFilledInput-root': {
  //     background: "white"
  //   }
  // },
  lineHorizontal: {
    width: "95%",
    // borderBottom: "2px solid #000000",
    opacity: "0.1",
    margin: "0 auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 25px",
  },
  nextButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    background: "rgba(0, 113, 206, 0.2)",
    width: "80px",
    height: "30px",
  },
  skipButton: {
    textTransform: "capitalize",
    //fontFamily: "Gilroy !important",
    textDecoration: "underline",
    marginTop: "5px",
    cursor: "pointer"
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginTop: "10px"
  },
  buttonStart: {
    width: "100%",
    fontSize: "14px",
    textTransform: "capitalize",
    margin: "10px 0px",
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)'
  },
  mainStartQuixBox: {
    textAlign: "center",
    // padding: "15px 15px",
  },
  textNewStep: {
    fontSize: "16px !important",
    fontWeight: "900",
    width: "60%",
    textAlign: "center",
    margin: "19px auto",
  },
  startQuizMain: {
    marginBottom: "20px",
    width: "100%"
    // padding: "20px"
  },
  autocompletChip: {
    padding: "5px",
    background: "white",
    '& .MuiChip-root': {
      borderRadius: "5px",
      background: "#d5606045",
      height: "auto",
    },
    '& .MuiChip-outlined': {
      border: "none",
      color: "#FE4B6B",
      padding: "2px"
    },
    '& .MuiFilledInput-root': {
      background: "white"
    }
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    color: "#FE4B6B"
  },
  editIconPro: {
    position: "absolute",
    top: "80px",
    left: "53.5%",
    [theme.breakpoints.down("xs")]: {
      left: "57.5%",
    },
    cursor: "pointer"
  },
  profilePhotoEdit: {
    position: "relative",
    marginBottom: "10px",
  },
  checkboxDetail: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "35px",
    },
  },
  genderPrefranceHeading: {
    fontSize: "18px",
    fontWeight: "500",
    marginLeft: "12px",
    marginRight: "18px",
  },
  genderSelectBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  textFiledBox: {
    width: "90%",
    margin: "15px auto",
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    }
  },

  googleSelecte: {
    width: "90%",
    margin: "15px auto",
    '& .css-yk16xz-control': {
      // borderRadius: "10px",
      // padding: "8px"
    },
    '& .css-1pahdxg-control': {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    '& .css-1pahdxg-control:hover': {
      // borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      // padding: "8px"
    },
    '& .css-1uccc91-singleValue': {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 400
    },
    '& .css-1wa3eu0-placeholder': {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
    },
    '& div': {
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: "white",
      zIndex: 11
    }
  },
  startedButton: {
    // fontSize: "20px",
    // height: "50px",
    height: "35px",
    fontSize: "18px",
    width: "246px",
    // background: "#0071CE",
    background: 'linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)',
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "400"
  },
}));

const SelecteUniversityForm = props => {
  const classes = useStyles();
  const history = useHistory();

  const [p_answer, set_P_Answer] = React.useState([]);
  const [s_answer, set_S_Answer] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState(props.sessionPropsMessage);
  const [lstP_University, setLst_P_University] = React.useState([]);
  const [lstS_University, setLst_S_University] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // Get Primary University
  const get_P_University = async (search) => {
    // Api Call For Fetch University List
    api.get(`universitylist?s=` + search)
      .then((result) => {
        if (result.success === "true") {
          setLst_P_University(result.data)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  }


  // Get Secon University
  const get_S_University = async (search) => {
    // Api Call For Fetch University List
    api.get(`universitylist?s=` + search)
      .then((result) => {
        if (result.success === "true") {
          setLst_S_University(result.data)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  }

  const change_P_OptionBaseOnValue = (value) => {
    if (value)
      get_P_University(value);
  }


  const change_S_OptionBaseOnValue = (value) => {
    if (value)
      get_S_University(value);
  }

  // Start The Quiz
  const StartTheQuizHandler = () => {
    let lstPId = lstP_University.filter((obj) => obj.value === p_answer)
    let lstSId = lstS_University.filter((obj) => obj.value === s_answer)
    if (!lstPId[0]?.id || !lstSId[0]?.id) {
      return
    }
    let data = {
      p_universityId: lstPId[0]?.id,
      s_universityId: lstSId[0]?.id
    }

    setLoading(true)
    // Api Call For Selecte university
    api.post('user/adduniversity', {
      data: data
    })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("university", lstPId[0].value)
          props.setSignupType("personalDetail")
          // history.push("/matching-quiz")
          // history.push("/personal-details")
          // window.location.replace("/personal-details")
        } else {
          console.log(result, "error")
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "catch error")
        setLoading(false)
      });
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  return (
    <>
      <div className={classes.fields}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert
              onClose={handleErorrClose}
              severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
      </div>
      <Box className={classes.startQuizMain}>
        <div className={classes.hrLine} />
        <Box className={classes.startQuizBox}>
          <p className={classes.startText}>Select University</p>
        </Box>
        <Box className={classes.answerBox}>
          <Box className={classes.mainStartQuixBox}>
            <Autocomplete
              // multiple
              id="tags-filled"
              onInputChange={(e, value) => {
                change_P_OptionBaseOnValue(value);
              }}
              noOptionsText={"Type for search..."}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    color="primary"
                    label={option}
                  />
                  {option}
                </React.Fragment>
              )}
              options={lstP_University.map((option) => option.value)}
              // freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    deleteIcon={<CloseIcon className={classes.chipIcon} />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Primary university" />
              )}
              className={classes.autocompletChip}
              onChange={(e, values) => {
                set_P_Answer(values)
              }}
            />


            <Autocomplete
              // multiple
              id="tags-filled"
              onInputChange={(e, value) => {
                change_S_OptionBaseOnValue(value);
              }}
              noOptionsText={"Type for search..."}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    color="primary"
                    label={option}
                  />
                  {option}
                </React.Fragment>
              )}
              options={lstS_University.map((option) => option.value)}
              // freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    deleteIcon={<CloseIcon className={classes.chipIcon} />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Secondary university" />
              )}
              className={classes.autocompletChip}
              onChange={(e, values) => {
                set_S_Answer(values)
              }}
              style={{ marginTop: "5px" }}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStart}
              endIcon={loading ? <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></> : <img src="/images/matchUp/startAerrow.png" alt="startAerrow" />}
              onClick={StartTheQuizHandler}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// SignupDetailForm.propTypes = {
//   className: PropTypes.string
// };

export default SelecteUniversityForm;
