import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Api from '../../Helper/ApiHandler';
import DateType from 'views/QuizMatchingSoulmates/component/QuizQuestions/DateType';
import LocationType from 'views/QuizMatchingSoulmates/component/QuizQuestions/LocationType';
import RadioType from 'views/QuizMatchingSoulmates/component/QuizQuestions/RadioType';
import RadioImgType from 'views/QuizMatchingSoulmates/component/QuizQuestions/RadioImgType';
import MultiChoiceType from 'views/QuizMatchingSoulmates/component/QuizQuestions/MultiChoiceType';
import CloseIcon from "@material-ui/icons/Close";
import TextType from 'views/QuizMatchingSoulmates/component/QuizQuestions/TextType';
var api = new Api();

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 10px 10px 10px",
    '& .MuiButton-root': {
      textTransform: "capitalize"
    }
  },
  dialogRoot: {
    padding: "10px",
    '& .MuiDialog-paper': {
      // overflow: "visible"
      borderRadius: "10px"
    },
    '& .MuiBackdrop-root': {
      backgroundColor: "rgb(0 0 0 / 52%)",
    },
    '& .MuiPaper-elevation24': {
      boxShadow: "none"
    }
  },
  btnSave: {
    borderRadius: "5px",
    backgroundImage: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
    color: "white"
  },
  btnCancel: {
    borderRadius: "5px",
  },
  flexWithPading: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 500
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500
  },
  socialIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer"
  },
}))
function SolQuizeDialog(props) {
  const classes = useStyles();
  const { onLoad, objQuiz, componentName, slug, onClose, selectedValue, open } = props;
  const [lstAnswer, setLstAnswer] = React.useState([]);
  const [lstOption, setLstOption] = React.useState([]);

  const handleClose = () => {
    onClose(selectedValue);
  };


  useEffect(() => {
    if (open)
      getCriteArea()
  }, [open])

  const getCriteArea = async () => {
    api.post("user/soulmate/getcriteria", {
      data: { criteria_id: objQuiz.id }
    })
      .then((result) => {
        if (result.success === "true") {
          console.log(result, "result")
          setLstOption(result.data.quiz[0].options)
          setLstAnswer(result.data.quiz[0].answer)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    // Api Call For update 
    api.put("user/soulmate/editanswer", {
      data: {
        criteria_id: objQuiz.id,
        answer: lstAnswer
      }
    })
      .then((result) => {
        if (result.success === "true") {
          onClose(selectedValue, {
            message: result.message,
            type: 'success',
            open: true
          });
          onLoad()
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  };



  console.log(objQuiz, "objQuiz")
  return (
    <Dialog className={classes.dialogRoot} onClose={handleClose} maxWidth="sm" open={open} >
      <Grid container style={{ paddingTop: "20px", paddingLeft: "20px", positoin: "relative" }}>
        {objQuiz.question}
        <CloseIcon className={classes.socialIcon} onClick={handleClose} />
      </Grid>

      <Grid container justify="center" style={{ padding: "20px" }}>

        {objQuiz.id == 20 ? <DateType setAns={setLstAnswer} ans={[objQuiz.answer]} /> : ""}
        {objQuiz.id == 21 ? <LocationType setAns={setLstAnswer} ans={lstAnswer[0]} /> : ""}
        {objQuiz.id == 22 || objQuiz.id == 23 || objQuiz.id == 24 || objQuiz.id == 26 ?
          <RadioType
            options={lstOption}
            gridNum={6}
            setAns={setLstAnswer}
            ans={lstAnswer[0]}
          />
          :
          ""
        }
        {objQuiz.id == 25 ?
          <RadioImgType options={lstOption} setAns={setLstAnswer} ans={lstAnswer[0]} />
          :
          ""
        }
        {objQuiz.id == 27 ?
          <MultiChoiceType
            options={lstOption}
            setAns={setLstAnswer}
            ans={lstAnswer}
          />
          :
          ""
        }
        {objQuiz.id == 28 ?
          <RadioType
            setAns={setLstAnswer}
            ans={lstAnswer[0]}
            options={lstOption}
            gridNum={6}
          />
          :
          ""
        }
      </Grid>

      <Grid container justify="center" style={{ padding: "0px 20px 20px 20px" }}>
        <Button
          variant='contained'
          onClick={(e) => {
            handleUpdate(e)
          }}
          style={{ textTransform: "capitalize", color: "white", background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)" }}
        >
          Update
        </Button>
      </Grid>
    </Dialog>
  );
}

export default SolQuizeDialog;