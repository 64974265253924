import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
    position: "reletive"
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

const NotFound = () => {
  const classes = useStyles();

  const handleClick = () => {
    window.history.back();
  };

  return (
    <div className={classes.root}>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <img src="/images/matchUp/Notfound.png" alt="not found" />
          <div style={{ position: "absolute", bottom: 150, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <h4 style={{ color: "#FD3576", textAlign: "center" }}>
              Page Not Found
            </h4>
            <div style={{ textAlign: "center" }}>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod <br />
              tempor incididunt ut labore et dolore magna aliqua.
            </div>
            <Button
              size="large"
              variant="contained"
              onClick={handleClick}
              style={{
                borderRadius: "10px",
                background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
                color: "white",
                marginTop: "30px"
              }}
            >
              Go Back
            </Button>
          </div>
          {/* <SectionHeader
            label="404"
            title="Uh oh."
            subtitle={
              <span>
                There’s nothing here, but if you feel this is an error please{' '}
                <LearnMoreLink
                  title="let us know"
                  href="#"
                  typographyProps={{ variant: 'h6' }}
                />
              </span>
            }
            titleProps={{
              variant: 'h3',
            }}
            labelProps={{
              color: 'secondary',
              className: classes.label,
              variant: 'h5',
            }}
            ctaGroup={[
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                Go Back
              </Button>,
            ]}
            disableGutter
          /> */}
        </div>
      </Section>
    </div>
  );
};

export default NotFound;
