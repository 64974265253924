import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Hidden,
  IconButton,
  Container,
  Avatar,
  Box,
  Select,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { withStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import ModuleMates from "./ModuleMates";
import Divider from "@material-ui/core/Divider";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import AppDataContext from "Helper/AppDataContext";
import Api from "../../../../Helper/ApiHandler";
import useRouter from "utils/useRouter";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  root: {},
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  toolbar: {
    // maxWidth: theme.layout.contentWidth,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    // background: "white",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
    [theme.breakpoints.down("xs")]: {
      height: "70px",
    },
  },
  detailTopBar: {
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    height: "65px",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      marginRight: "10px",
    },
  },
  avtarImage: {
    marginLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      // width: "100%",
      display: "none",
    },
    "& .MuiAvatar-root": {
      width: "50px",
      height: "50px",
    },
    cursor: "pointer",
  },
  iconMessage: {
    color: "white",
    fontSize: "26px",
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
    },
    cursor: "pointer",
  },
  profileMenuImg: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
    objectFit: "cover",
    objectPosition: "top",
  },
  nameHeadingMenu: {
    margin: "0",
    fontSize: "18px",
    marginLeft: "10px",
    fontWeight: "600",
    //fontFamily: "Gilroy !important",
  },
  universitytext: {
    margin: "0",
    fontSize: "12px",
    marginLeft: "10px",
    color: "gray",
  },
  editProfileLink: {
    margin: "0",
    marginLeft: "10px",
  },
  linkMenu: {
    textDecoration: "none",
    color: "black",
  },
  nameHeadingNotification: {
    //fontFamily: "Gilroy !important",
    margin: "0",
    fontSize: "14px",
    fontWeight: "600",
  },
  imageNotification: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
  },
  imageNotificationRoot: {
    minWidth: 0,
  },
  name: {
    fontWeight: "600",
  },
  timeMessage: {
    fontWeight: "400",
    fontSize: "12px",
  },
  timeNotifiction: {
    marginLeft: "5px",
    color: "gray",
    opacity: 0.5,
    fontWeight: "400",
  },
  seeAllNotification: {
    textAlign: "center",
    width: "100%",
    //fontFamily: "Gilroy !important",
    margin: "0",
    fontSize: "14px",
    marginLeft: "10px",
    color: "#0071CE",
    textDecoration: "underline",
    fontWeight: "600",
  },
  moduleHeading: {
    color: "white",
    marginLeft: "3px",
    fontSize: "14px !important",
    fontWeight: "400",
  },
  moduleBox: {
    display: "flex",
    alignItems: "center",
    color: "white",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      // width: "100%",
      display: "none",
    },
  },
  textChange: {
    margin: "0",
    fontSize: "13px",
    fontWeight: 400,
  },
  notificationDetail: {
    margin: "0",
    fontSize: "12px",
    whiteSpace: "normal",
    width: "100%",
    fontWeight: "900",
    padding: "0px 0px 0px 10px",
  },
  moduleItem: {
    margin: "0",
    //fontFamily: "Gilroy !important",
  },
  logoMatchup: {
    [theme.breakpoints.down("xs")]: {
      width: "130%",
    },
    marginTop: "5px",
  },
  iconButton: {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      right: "0",
      // top: "18px",
      color: "white",
      marginRight: "5px",
    },
  },
  LogoGrid: {
    alignSelf: "center",
  },
  notificationCount: {
    background: "white",
    color: "red",
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    right: "-24px",
    top: "15px",
    fontSize: "11px",
  },
  selectItem: {
    fontSize: "14px",
  },
  selectRoot: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "rgb(0 0 0 / 0%)",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      color: "white",
      fontSize: "14px",
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    // border: "1px solid #d3d4d5",
    marginTop: "8px",
    borderRadius: "5px",
    boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.2)",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // "&:focus": {
    //   backgroundColor: "#e5e5e5",
    //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //     color: "black",
    //   },
    // },
    // borderBottom: `1px solid #dedede`,
  },
}))(MenuItem);

const QuestionaresTopBar = (props) => {
  const { onLoginSidebarOpen, pages, ...rest } = props;

  const router = useRouter();
  const classes = useStyles();
  const history = useHistory();
  const { appContext, setAppContext } = useContext(AppDataContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [module, setModule] = React.useState(null);
  const [userName, setUserName] = React.useState("");
  const [userNameUniversityName, setUserNameUniversityName] = React.useState(
    ""
  );
  const [profile_picture, setProfile_picture] = React.useState("");
  const [selected, setSelected] = React.useState("");

  useEffect(() => {
    // appContext?.isNewNotification && onLoad();
    appContext?.userProfile && setProfile_picture(appContext.userProfile);
  }, [appContext]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickModule = (event) => {
    setModule(event.currentTarget);
  };

  const handleCloseModule = () => {
    setModule(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOutHandler = () => {
    localStorage.clear();
    history.push("/");
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    let userProfile = await AsyncLocalStorage.getItem("userProfile");
    let Username = capitalizeFirstLetter(
      await AsyncLocalStorage.getItem("userName")
    );
    let University = await AsyncLocalStorage.getItem("university");
    let tmp = await AsyncLocalStorage.getItem("currentModule");
    setSelected(tmp);
    setProfile_picture(userProfile);
    setUserName(Username);
    setUserNameUniversityName(University);
  };

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  // const siwtchModule = (e) => {
  //   handleCloseModule(e)
  //   // user/switchmodule
  //   api.put('user/switchmodule', {
  //     data: {
  //       module_name: e.target.value
  //     }
  //   })
  //     .then((result) => {
  //       if (result.success === "true") {
  //         AsyncLocalStorage.setItem("currentModule", e.target.value)
  //         window.location.reload();
  //       } else {

  //       }
  //     })
  //     .catch((err) => {
  //     });
  // }

  const siwtchModule = (e) => {
    handleCloseModule(e);
    // user/switchmodule
    api
      .put("user/switchmodule", {
        data: {
          module_name: e.target.value,
        },
      })
      .then((result) => {
        if (result.success === "true") {
          AsyncLocalStorage.setItem("currentModule", e.target.value);
          AsyncLocalStorage.setItem("tabValue", 0);
          AsyncLocalStorage.setItem("tabFavValue", 0);
          setSelected(result?.data?.module_name);
          setAppContext({
            ...appContext,
            currentModule: result?.data?.module_name,
          });
          if (e.target.value === "Dormmates") {
            // if (process.env.REACT_APP_DORMMATES == "true") {
            if (appContext.objAuth.DORMMATE) {
              if (!result.data.hasUniversity) {
                // router.history.push("/select-university");
                router.history.push({
                  pathname: `/select-university`,
                  search: "",
                  state: {
                    step3: true,
                  },
                });
              } else if (!result.data.quiz) {
                // router.history.push("/matching-quiz");
                window.location.replace("/matching-quiz");
              } else {
                router.history.push("/your-profile");
              }
            } else {
              router.history.push("/comminsoon");
            }
          } else if (e.target.value === "Roommates") {
            if (appContext.objAuth.ROOMMATE) {
              if (!result.data.hasUniversity) {
                // router.history.push("/select-university");
                router.history.push({
                  pathname: `/select-university`,
                  search: "",
                  state: {
                    step3: true,
                  },
                });
              } else if (!result.data.quiz) {
                // router.history.push("/matching-quiz");
                window.location.replace("/matching-quiz");
              } else {
                router.history.push("/your-profile");
              }
            } else {
              router.history.push("/comminsoon");
            }
          } else if (e.target.value === "Teammates") {
            if (appContext.objAuth.TEAMMATE) {
              router.history.push("/your-profile");
              // if (!result.data.quiz) {
              //   // router.history.push("/matching-quiz");
              //   window.location.replace("/matching-quiz")
              // } else {
              //   router.history.push("/your-profile");
              // }
            } else {
              router.history.push("/comminsoon");
            }
          } else if (e.target.value === "Soulmates") {
            if (appContext.objAuth.SOULMATE) {
              if (!result.data.quiz) {
                // router.history.push("/matching-quiz");
                window.location.replace("/matching-quiz");
              } else {
                router.history.push("/your-profile");
              }
            } else {
              router.history.push("/comminsoon");
            }
          } else {
            router.history.push("/comminsoon");
          }
        } else {
        }
      })
      .catch((err) => { });
  };

  return (
    <>
      <Toolbar disableGutters className={classes.toolbar} {...rest}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xl={6} lg={6} xs={6} className={classes.LogoGrid}>
              <Link to="/">
                <img
                  className={classes.logoMatchup}
                  src={"/images/matchUp/loginBarLogo.svg"}
                  width={"35%"}
                  alt={"Alt Image"}
                />
              </Link>
            </Grid>
            <Grid item xl={6} lg={6} xs={6}>
              <div className={classes.detailTopBar}>
                {appContext.objAuth?.LIVE_MODULE_COUNT > 1 && <div className={classes.selectRoot}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected}
                    disableUnderline
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      PopoverClasses: classes.popoverClasses,
                    }}
                    onChange={(e) => siwtchModule(e)}
                  >
                    {appContext.objAuth?.DORMMATE && (
                      <MenuItem
                        value={"Dormmates"}
                        className={classes.selectItem}
                      >
                        DormMates
                      </MenuItem>
                    )}
                    {appContext.objAuth?.TEAMMATE && (
                      <MenuItem
                        value={"Teammates"}
                        className={classes.selectItem}
                      >
                        TeamMates
                      </MenuItem>
                    )}
                    {appContext.objAuth?.ROOMMATE && (
                      <MenuItem
                        value={"Roommates"}
                        className={classes.selectItem}
                      >
                        RoomMates
                      </MenuItem>
                    )}
                    {appContext.objAuth?.SOULMATE && (
                      <MenuItem
                        value={"Soulmates"}
                        className={classes.selectItem}
                      >
                        SoulMates
                      </MenuItem>
                    )}
                  </Select>
                </div>}
                <div className={classes.avtarImage}>
                  <Avatar
                    alt={userName}
                    src={profile_picture}
                    onClick={handleClick}
                  />
                </div>
                <ModuleMates
                  module={module}
                  handleCloseModule={handleCloseModule}
                />

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <StyledMenuItem style={{ backgroundColor: "white" }}>
                    <ListItemIcon>
                      <Avatar
                        className={classes.profileMenuImg}
                        src={
                          !profile_picture || profile_picture != "null"
                            ? profile_picture
                            : "/images/male_defualt.svg"
                        }
                        alt={userName}
                      />
                      {/* <img
                        className={classes.profileMenuImg}
                        src={
                          !profile_picture || profile_picture != "null"
                            ? profile_picture
                            : "/images/male_defualt.svg"
                        }
                        alt={"Alt Image"}
                      /> */}
                    </ListItemIcon>
                    <Grid>
                      <h5 className={classes.nameHeadingMenu}>{userName}</h5>
                      <p className={classes.universitytext}>
                        {userNameUniversityName !== "undefined" &&
                          userNameUniversityName}
                      </p>
                    </Grid>
                  </StyledMenuItem>
                  <Divider style={{ margin: "5px 10px" }} />
                  {/* <Link className={classes.linkMenu} to="/reset-password">
                    <StyledMenuItem>
                      <ListItemIcon>
                        <img src="/images/matchUp/changePassword.svg" />
                      </ListItemIcon>
                      <p className={classes.textChange}>Change Password</p>
                    </StyledMenuItem>
                  </Link>
                  <Divider style={{ margin: "5px 10px" }} /> */}
                  <StyledMenuItem onClick={() => logOutHandler()}>
                    <ListItemIcon>
                      <img src="/images/matchUp/logout.svg" alt={"Alt Image"} />
                    </ListItemIcon>
                    <p className={classes.textChange}>Log Out</p>
                  </StyledMenuItem>
                </StyledMenu>
                <Hidden mdUp>
                  <IconButton
                    className={classes.iconButton}
                    onClick={onLoginSidebarOpen}
                    aria-label="Menu"
                  >
                    <MenuIcon style={{ fontSize: "30px", color: "white" }} />
                  </IconButton>
                </Hidden>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </>
  );
};

QuestionaresTopBar.propTypes = {
  onLoginSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
};

export default QuestionaresTopBar;
