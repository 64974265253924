import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  TablePagination,
  Typography,
} from "@material-ui/core";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import Api from "../../../Helper/ApiHandler";
import { Skeleton } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import ShareDialog from "components/ShareModel/ShareModel";
import MuiAlert from "@material-ui/lab/Alert";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  contant: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 30px",
    [theme.breakpoints.down("xs")]: {
      padding: "15px",
    },
  },
  imgContant: {
    display: "flex",
    justifyContent: "center",
  },
  avtar: {
    display: "flex",
    alignItems: "center",
  },
  blogTitle: {
    fontSize: "12px !important",
    color: "#686EF9",
    fontWeight: 400,
    cursor: "pointer",
  },
  blog_Title: {
    fontSize: "26px !important",
    color: "#292930",
    fontWeight: 500,
    margin: "10px 0",
    cursor: "pointer",
  },
  blogDetails: {
    fontSize: "14px !important",
    color: "#292930",
    fontWeight: 300,
    cursor: "pointer",
    wordBreak: "break-all",
    overflow: "hidden",
    height: 95,
    display: "-webkit-box !important",
    "-webkitLineClamp": 4,
    "-webkitBoxOrient": "vertical",
  },
  blogsShareing: {
    alignItems: "center",
    marginTop: 40,
  },
  avtar_Title: {
    fontSize: "14px !important",
    color: "#282F36",
  },
  avtar_Detail: {
    fontSize: "13px !important",
    color: "#282F36",
    fontWeight: 400,
  },
}));

const Blogs = (props) => {
  const { onLoading, data, fetchMoreMatches, hasMore, loadingScroll } = props;
  const [shareOpen, setShareOpen] = React.useState(false);
  const [shareing, setShareing] = React.useState("");
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  const classes = useStyles();
  const history = useHistory();

  const handleNow = (object) => {
    history.push(`/blog/${object}`);
  };

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const handleClose = () => {
    setShareOpen(false);
  };

  const ShareNow = (sl_ug) => {
    setShareOpen(true);
    setShareing(sl_ug);
  };

  // console.log(data, "blogs");

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}
        >
          <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreMatches}
        hasMore={true}
        loader={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "95%",
              margin: "10px",
            }}
          >
            {loadingScroll && <CircularProgress size={20} />}
          </div>
        }
        endMessage={<p style={{ paddingBottom: "60px" }}>{/* <p> */}</p>}
        style={{ overflow: "hidden" }}
      >
        {onLoading ? (
          <SkeletonLoading />
        ) : (
          data.map((obj, ind) => {
            // console.log(obj, "obj");
            return (
              <Grid container style={{ marginTop: 40 }} key={`obj_${ind}`}>
                <Grid container item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    className={classes.imgContant}
                  >
                    <img
                      // src="/images/matchUp/BlogImages.jpg"
                      src={obj.picture_url}
                      alt="BlogImages"
                      style={{
                        cursor: "pointer",
                        width: "312px",
                        objectFit: "cover",
                        height: "350px",
                        borderRadius: 8,
                      }}
                      onClick={() => {
                        handleNow(obj.slug);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    className={classes.contant}
                  >
                    <Typography
                      variant="h5"
                      className={classes.blogTitle}
                      onClick={() => {
                        handleNow(obj.slug);
                      }}
                    >
                      {/* Dormmates */}
                      {obj.blogModule.name}
                    </Typography>
                    <Typography
                      variant="h3"
                      className={classes.blog_Title}
                      onClick={() => {
                        handleNow(obj.slug);
                      }}
                    >
                      {obj.title}
                    </Typography>
                    {/* <Typography
                      variant="h6"
                      className={classes.blogDetails}
                      onClick={() => {
                        handleNow(obj.slug);
                      }}
                    ></Typography> */}
                    <div
                      className={classes.blogDetails}
                      dangerouslySetInnerHTML={{ __html: obj.description }}
                    ></div>
                    <Grid container className={classes.blogsShareing}>
                      <Grid
                        item
                        xs={11}
                        sm={11}
                        md={11}
                        lg={11}
                        className={classes.avtar}
                      >
                        <Avatar
                          alt="BlogAvtar"
                          // src="/images/matchUp/BlogsAvtar.png"
                          src={obj.profile_picture}
                        />
                        <Box component="div" ml={1}>
                          <Typography
                            variant="h6"
                            className={classes.avtar_Title}
                          >
                            {obj.bloguser.name}
                          </Typography>
                          <Typography
                            variant="h6"
                            className={classes.avtar_Detail}
                          >
                            {obj.createdAt}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ShareOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            ShareNow(obj.slug);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
        <ShareDialog
          open={shareOpen}
          onClose={handleClose}
          shareing={shareing}
          setSessionMessage={setSessionMessage}
          // objProperty={objProperty}
        />
      </InfiniteScroll>
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <>
      <Grid container item xs={12} sm={12} md={12} lg={12} alignItems="center">
        <Grid item xs={12} sm={6} md={5} lg={5}>
          {/* img */}
          <Skeleton
            variant="rect"
            height={400}
            style={{ margin: "5px", borderRadius: 6 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7} style={{ padding: "0 35px" }}>
          <Skeleton animation="wave" width={200} />
          <Skeleton animation="wave" width={250} />
          <Skeleton animation="wave" width={250} />
          <Skeleton animation="wave" width={300} />
          <Skeleton animation="wave" width={300} />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />

          <Grid container>
            <Grid
              item
              xs={11}
              sm={11}
              md={11}
              lg={11}
              style={{ display: "flex", marginTop: 20 }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
              <div style={{ padding: "0 20px" }}>
                <Skeleton animation="wave" height={20} width={200} />
                <Skeleton animation="wave" height={20} width={150} />
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* <ShareOutlinedIcon /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} sm={12} md={12} lg={12} alignItems="center">
        <Grid item xs={12} sm={6} md={5} lg={5}>
          {/* img */}
          <Skeleton
            variant="rect"
            height={350}
            style={{ margin: "5px", borderRadius: 6 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7} style={{ padding: "0 35px" }}>
          <Skeleton animation="wave" width={200} />
          <Skeleton animation="wave" width={250} />
          <Skeleton animation="wave" width={250} />
          <Skeleton animation="wave" width={300} />
          <Skeleton animation="wave" width={300} />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />

          <Grid container>
            <Grid
              item
              xs={11}
              sm={11}
              md={11}
              lg={11}
              style={{ display: "flex", marginTop: 20 }}
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
              <div style={{ padding: "0 20px" }}>
                <Skeleton animation="wave" height={20} width={200} />
                <Skeleton animation="wave" height={20} width={150} />
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* <ShareOutlinedIcon /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Blogs;
