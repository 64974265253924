import React from 'react'
import { makeStyles } from "@material-ui/styles";
import moment from 'moment';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import useRouter from 'utils/useRouter';
import firebase from "firebase/app";
import Name from './Name';
import ProfilepictureForGroup from './ProfilepictureForGroup';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import ProfilepictureForGroupPersonal from './ProfilepictureForGroupPersonal';
import NamePersonal from './NamePersonal';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { Badge } from '@material-ui/core';
import PersonalCounter from './PersonalCounter';
import GroupCounter from './GroupCounter';

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },
  inboxHead: {
    background: "#ffff",
    borderRadius: "10px 10px 0px 0px",
    height: "66px",
    fontSize: "18px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #80808061",
    paddingLeft: "10px"
  },
  bgProfilePage: {
    background: "#ffff",
    borderRadius: "0px 0px 10px 10px",
    height: "calc(100vh - 272px)",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
      height: "calc(100vh - 206px)",
    },
    overflowY: "auto"
  },
  inboxRoot: {
    display: "flex",
    padding: "5px",
    cursor: "pointer"
  },
  inboxRootSelected: {
    display: "flex",
    padding: "5px",
    cursor: "pointer",
    backgroundColor: "#f9dade"
  },
  inboxImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover"
  },
  inboxNameRoot: {
    marginLeft: "10px"
  },
  inboxName: {
    fontSize: "14px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxLastMsg: {
    fontSize: "11px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxTyping: {
    fontSize: "11px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "green"
  },
  inboxTime: {
    fontSize: "11px",
    fontWeight: "400",
  },
  inboxCountRoot: {
    "& .MuiBadge-badge": {
      fontSize: "10px",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: "auto"
    },
    marginLeft: "auto",
    marginRight: "10px"
  }
}));

const Inbox = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const { senderId, lstMessage, setLstMessage, setExitAt, setClearAt, nameMobileChat, setNameMobileChat, receiverId, lstInbox } = props
  let query = useLocation();
  const [isSelect, setIsSelect] = React.useState(false);

  useEffect(() => {
    defualtFirst()
  }, [lstInbox]);

  let defualtFirst = async () => {
    if (router.match.path == "/messages") {
      console.log("call first")
      if (lstInbox.length) {
        if (!isSelect) {
          let item = lstInbox[0]
          var tmpId = Cookies.get('curr_receiverId');

          Cookies.set('prev_receiverId', tmpId, { expires: 365 });
          Cookies.set('curr_receiverId', item.receiverId, { expires: 365 });

          if (item.chatType == "group") {
            onClickGroupInbox(item)
          } else {
            onInboxPresonalClick(item)
          }
          setIsSelect(true)
          let scr = document.getElementById('chat')
          scr && scr.scrollTo && scr.scrollTo('0', scr.scrollHeight)
        }
      }
    }
  }

  console.log(lstInbox, "lstInbox")
  // Base path of firebase
  const database = firebase.firestore();
  let db = database.collection("teammateInbox")

  let onClickGroupInbox = async (objInbox) => {

    // AsyncLocalStorage.setItem("inboxName", objInbox.inboxName)
    // AsyncLocalStorage.setItem("inboxProfile", objInbox.group_profile_pic)
    AsyncLocalStorage.setItem("receiverId", objInbox.receiverId)
    AsyncLocalStorage.setItem("to_slug", objInbox.inboxSlug)
    // AsyncLocalStorage.setItem("no_of_members", objInbox.group_members.length)
    AsyncLocalStorage.setItem("chat_type", "group")
    // AsyncLocalStorage.setItem("group_members", JSON.stringify(objInbox.group_members))

    router.history.push(`/messages/${objInbox.receiverId}`)
  }

  const onInboxPresonalClick = async (objUser) => {
    // objUser.fullName, objUser.imageUrl, objUser.id, objUser.receiverId, objUser.slug
    AsyncLocalStorage.setItem("receiverName", objUser.fullName)
    AsyncLocalStorage.setItem("receiverProfilePicuture", objUser.imageUrl)
    AsyncLocalStorage.setItem("receiverId", objUser.receiverId)
    AsyncLocalStorage.setItem("to_slug", objUser.slug)
    AsyncLocalStorage.setItem("to_id", objUser.id)
    AsyncLocalStorage.setItem("chat_type", "personal")

    // setNameChat(name);
    router.history.push(`/messages/${objUser.receiverId}`)
    // await onload()
  };

  return (
    <div>
      <div className={classes.inboxHead}>
        Message
      </div>
      <div className={classes.bgProfilePage}>
        {lstInbox.length ? lstInbox.map((objInbox, index) => {
          if (objInbox.chatType === "group") {
            return (
              <div
                id={index}
                className={objInbox.receiverId !== receiverId ? classes.inboxRoot : classes.inboxRootSelected}
                onClick={() => {
                  setNameMobileChat(true)
                  setClearAt(null)
                  setLstMessage([])
                  setExitAt("")
                  if (receiverId) {
                    if (objInbox.receiverId !== receiverId) {
                      const prev_receiverId = query.pathname.split("/")[2];
                      Cookies.set('prev_receiverId', prev_receiverId, { expires: 365 });
                      Cookies.set('curr_receiverId', objInbox.receiverId, { expires: 365 });
                      onClickGroupInbox(objInbox)
                    }
                  } else {
                    const prev_receiverId = query.pathname.split("/")[2];
                    Cookies.set('prev_receiverId', prev_receiverId, { expires: 365 });
                    Cookies.set('curr_receiverId', objInbox.receiverId, { expires: 365 });
                    onClickGroupInbox(objInbox)
                  }
                }}
              >
                <div>
                  {/* <img src={profile ? profile : '/images/male_defualt.svg'} alt="Img" className={classes.inboxImage} /> */}
                  <ProfilepictureForGroup groupId={objInbox.receiverId} className={classes.inboxImage} />
                </div>
                <div className={classes.inboxNameRoot}>
                  {/* <div className={classes.inboxName}>{objInbox.group_name}</div> */}
                  <Name groupId={objInbox.receiverId} />
                  <div className={classes.inboxLastMsg}>
                    {/* {objInbox?.lastMessage} */}
                    <>
                      {objInbox.messageType.includes("image/") ?
                        <div style={{ display: "flex" }}>
                          <ImageOutlinedIcon style={{ fontSize: "16px", marginRight: "2px" }} />
                          <span>
                            Image
                          </span>
                        </div>
                        :
                        <>
                          {objInbox.messageType.includes("video/") ?
                            <div style={{ display: "flex" }}>
                              <VideocamOutlinedIcon style={{ fontSize: "16px", marginRight: "2px" }} />
                              <span>
                                Video
                              </span>
                            </div>
                            :
                            <>
                              {objInbox.messageType.includes("application/") ?
                                <div style={{ display: "flex" }}>
                                  <DescriptionOutlinedIcon style={{ fontSize: "16px", marginRight: "2px" }} />
                                  <span>
                                    File
                                  </span>
                                </div>
                                :
                                <>
                                  {objInbox.messageType.includes("text") &&
                                    objInbox.lastMessage
                                  }
                                </>
                              }
                            </>
                          }
                        </>
                      }
                    </>
                  </div>
                </div>

                <div className={classes.inboxCountRoot}>
                  <div className={classes.inboxTime}>
                    {/* {moment(new Date(objInbox?.messageCreatedAt * 1000)).format("HH:mm")} */}
                    {moment(new Date(objInbox?.createdAt * 1000)).format("HH:mm")}
                  </div>
                  {receiverId !== objInbox.receiverId && 
                    <GroupCounter receiverId={objInbox.receiverId} senderId={localStorage.getItem("senderId")} />
                  }
                  {/* {receiverId !== objInbox.receiverId &&
                    <GroupCounter receiverId={objInbox.receiverId} />
                  } */}
                  {/* <Badge badgeContent={10} color="primary"></Badge> */}
                </div>
              </div>
            )
          } else {
            console.log(objInbox.receiverId, "objInbox.receiverId")
            return (
              <div
                id={index}
                className={objInbox.receiverId !== receiverId ? classes.inboxRoot : classes.inboxRootSelected}
                onClick={() => {
                  setNameMobileChat(true)
                  setClearAt(null)
                  setLstMessage([])
                  setExitAt("")
                  if (receiverId) {
                    if (objInbox.receiverId !== receiverId) {
                      const prev_receiverId = query.pathname.split("/")[2];
                      Cookies.set('prev_receiverId', prev_receiverId, { expires: 365 });
                      Cookies.set('curr_receiverId', objInbox.receiverId, { expires: 365 });
                      onInboxPresonalClick(objInbox)
                    }
                  } else {
                    const prev_receiverId = query.pathname.split("/")[2];
                    Cookies.set('prev_receiverId', prev_receiverId, { expires: 365 });
                    Cookies.set('curr_receiverId', objInbox.receiverId, { expires: 365 });
                    onInboxPresonalClick(objInbox)
                  }
                }}
              >
                <div>
                  <ProfilepictureForGroupPersonal groupId={objInbox.receiverId} className={classes.inboxImage} />
                </div>
                <div className={classes.inboxNameRoot}>
                  <NamePersonal groupId={objInbox.receiverId} />
                  <div className={objInbox?.typing ? classes.inboxTyping : classes.inboxLastMsg}>
                    {objInbox?.typing ? "typing..." :
                      <>
                        {objInbox.messageType.includes("image/") ?
                          <div style={{ display: "flex" }}>
                            <ImageOutlinedIcon style={{ fontSize: "16px", marginRight: "2px" }} />
                            <span>
                              Image
                            </span>
                          </div>
                          :
                          <>
                            {objInbox.messageType.includes("video/") ?
                              <div style={{ display: "flex" }}>
                                <VideocamOutlinedIcon style={{ fontSize: "16px", marginRight: "2px" }} />
                                <span>
                                  Video
                                </span>
                              </div>
                              :
                              <>
                                {objInbox.messageType.includes("application/") ?
                                  <div style={{ display: "flex" }}>
                                    <DescriptionOutlinedIcon style={{ fontSize: "16px", marginRight: "2px" }} />
                                    <span>
                                      File
                                    </span>
                                  </div>
                                  :
                                  <>
                                    {objInbox.messageType.includes("text") &&
                                      objInbox.lastMessage
                                    }
                                  </>
                                }
                              </>
                            }
                          </>
                        }
                      </>
                    }
                  </div>
                </div>

                <div className={classes.inboxCountRoot}>
                  <div className={classes.inboxTime}>
                    {moment(new Date(objInbox?.createdAt * 1000)).format("HH:mm")}
                  </div>
                  {receiverId !== objInbox.receiverId &&
                    <PersonalCounter receiverId={objInbox.receiverId} />
                  }
                </div>
              </div>
            )
          }
        }) : ""}
      </div>
    </div>
  )
}

export default Inbox