import React from "react";
import PrivacyPolicyDetail from "./component/PrivacyPolicyDetail";

const PrivacyPolicy = () => {
  return (
    <>
      <PrivacyPolicyDetail />
    </>
  );
};

export default PrivacyPolicy;
