import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  Snackbar,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import TelegramIcon from "@material-ui/icons/Telegram";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { Link as browserLink, useLocation } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Checkbox from "@material-ui/core/Checkbox";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import ReactFancyBox from "react-fancybox";
import useRouter from "utils/useRouter";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BlockIcon from "@material-ui/icons/Block";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../Helper/ApiHandler";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import GroupDetailDisplay from "views/GroupDetailDisplay/GroupDetailDisplay";
import GroupDetailDisplayNormal from "views/GroupDetailDisplayNormal/GroupDetailDisplayNormal";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #c4c4c4",
  },
  indicator: {
    backgroundColor: "#FE4B6B",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: "500px",
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#FE4B6B",
      opacity: 1,
    },
    "&$selected": {
      color: "#FE4B6B",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#FE4B6B",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    // padding: "35px 0",
    minHeight: "90vh",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    margin: "10px 10px 0px 10px",
    // marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex",
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "10px 10px 10px 10px",
  },
  nextButton: {
    borderRadius: "5px",
    textTransform: "capitalize",
    minWidth: "0px",
    padding: "5px 10px",
  },
  addUserButton: {
    borderRadius: "5px",
    textTransform: "capitalize",
    minWidth: "0px",
    padding: "5px 10px",
    background: "linear-gradient(347.29deg, #E84A62 1.03%, #F4A360 113.2%)",
    color: "white",
    marginRight: "10px",
  },

  groupNameRoot: {
    display: "flex",
    alignItems: "center",
    "&:hover ": {
      "& .hide": {
        display: "block",
      },
    },
  },
  groupName: {
    fontSize: "24px",
    fontWeight: "600",
    textTransform: "capitalize",
  },
  groupNameEditIcon: {
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
  },
  groupNameEditIconRoot: {
    display: "none",
  },
  groupDescription: {
    fontSize: "14px",
    fontWeight: "400",
  },
  formHr: {
    width: "100%",
    background: "#FFFFFF",
    opacity: "0.1",
    border: "1px solid #000000",
    margin: "20px 0px",
  },
  memberRoot: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  acceptButton: {
    padding: "0px 15px",
    borderRadius: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
    color: "white",
    background: "#F24451",
    "&:hover": {
      background: "#F24451",
    },
    borderColor: "#F24451",
  },
  deleteButton: {
    padding: "0px 15px",
    borderRadius: "30px",
    fontSize: "12px",
    textTransform: "capitalize",
    color: "#F24451",
    backgroundColor: "#F9EBEB",
    borderColor: "#F24451",
  },
  menu: {
    cursor: "pointer",
    "& .MuiPaper-rounded": {
      marginTop: "10px",
      borderRadius: "10px",
    },
  },
  typographyMenu: {
    fontSize: "14px",
    padding: "7px",
  },
  adminChip: {
    position: "absolute",
    fontSize: "10px",
    background:
      "linear-gradient(168.55deg, #E84A62 0%, #ED6E61 44.79%, #F4A360 100%)",
    color: "white",
    padding: "3px 10px",
    borderRadius: "5px",
    bottom: "-4px",
    margin: "4px",
  },
}));

const GroupInfo = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();
  const [groupName, setGroupName] = React.useState("");
  const [groupDescription, setGroupDescription] = React.useState("");
  const [groupData, setGroupData] = React.useState({});
  const [userName, setUserName] = React.useState("");
  const [firebase_uid, setFirebase_uid] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFavourite, setIsFavourite] = React.useState(false);
  const [isBlock, setIsBlock] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [isNameEdit, setIsNameEdit] = React.useState("");
  const [isDescriptionEdit, setIsDescriptionEdit] = React.useState("");
  const [lstInterests, setLstInterests] = React.useState([]);
  const [userSlug, setUserSlug] = React.useState("");
  const [userList, setUserList] = React.useState([]);

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const idMenu = openMenu ? "simple-popover" : undefined;

  let query = useLocation();
  const slug = query.pathname.split("/")[2];

  // Get Interests
  const getInterests = async () => {
    // Api Call For Fetch Interests List
    api
      .get(`interestlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstInterests(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  useEffect(() => {
    getInterests();
  }, []);

  useEffect(() => {
    // onLoad()
    (async () => {
      setIsLoading(true);

      const userSlug = await AsyncLocalStorage.getItem("from_slug");
      setUserSlug(userSlug);
      console.log("call get by id");
      // Api Call For Fetch The Our Matches
      api
        .get(`user/teammate/myteam/${slug}`)
        .then((result) => {
          if (result.success === "true") {
            setGroupData(result.data);
            setUserName(result.data.first_name + " " + result.data.last_name);
            setFirebase_uid(result.data.firebase_uid);
            setGroupName(result.data.group_subject)
            setGroupDescription(result.data.group_description)
            setIsLoading(false);
            setIsFavourite(
              result.data.userfavour ? result.data.userfavour : false
            );
            setIsBlock(result.data.is_blocked);

            let lstTmp = result.data.teammateGroupdata;
            let lstAdmin = lstTmp.filter((obj) => obj.is_group_admin);
            let lstUser = lstTmp.filter((obj) => obj.slug == userSlug);
            let lstOtherUser = lstTmp.filter(
              (obj) => obj.slug != userSlug && obj.is_group_admin !== true
            );

            let lstFirst = [];
            if (lstAdmin[0].slug == lstUser[0].slug) {
              lstFirst = lstAdmin;
            } else {
              lstFirst = [...lstAdmin, ...lstUser];
            }
            let lstFinal = [...lstFirst, ...lstOtherUser];
            setUserList(lstFinal);
            console.log(lstFinal, "lstFinal");
          } else {
            console.log(result, "error");
          }
        })
        .catch((err) => {
          console.log(err, "catch error");
        });
    })();
  }, [slug]);

  const onLoad = async () => {
    setIsLoading(true);
    console.log("call get by id");
    const userSlug = await AsyncLocalStorage.getItem("from_slug");

    // Api Call For Fetch The Our Matches
    api
      .get(`user/teammate/myteam/${slug}`)
      .then((result) => {
        if (result.success === "true") {
          setGroupData(result.data);
          setUserName(result.data.first_name + " " + result.data.last_name);
          setFirebase_uid(result.data.firebase_uid);
          setIsLoading(false);
          setIsFavourite(
            result.data.userfavour ? result.data.userfavour : false
          );
          setIsBlock(result.data.is_blocked);

          let lstTmp = result.data.teammateGroupdata;
          let lstAdmin = lstTmp.filter((obj) => obj.is_group_admin);
          let lstUser = lstTmp.filter((obj) => obj.slug == userSlug);
          let lstOtherUser = lstTmp.filter(
            (obj) => obj.slug != userSlug && obj.is_group_admin !== true
          );

          let lstFirst = [];
          if (lstAdmin[0].slug == lstUser[0].slug) {
            lstFirst = lstAdmin;
          } else {
            lstFirst = [...lstAdmin, ...lstUser];
          }
          let lstFinal = [...lstFirst, ...lstOtherUser];
          setUserList(lstFinal);
          console.log(lstFinal, "lstFinal");
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const onClickUpdate = async () => {
    console.log("call get by id");
    const userSlug = await AsyncLocalStorage.getItem("from_slug");

    // Api Call For Fetch The Our Matches
    api
      .get(`user/teammate/myteam/${slug}`)
      .then((result) => {
        if (result.success === "true") {
          setGroupData(result.data);
          setUserName(result.data.first_name + " " + result.data.last_name);
          setFirebase_uid(result.data.firebase_uid);
          setIsFavourite(
            result.data.userfavour ? result.data.userfavour : false
          );
          setIsBlock(result.data.is_blocked);

          let lstTmp = result.data.teammateGroupdata;
          let lstAdmin = lstTmp.filter((obj) => obj.is_group_admin);
          let lstUser = lstTmp.filter((obj) => obj.slug == userSlug);
          let lstOtherUser = lstTmp.filter(
            (obj) => obj.slug != userSlug && obj.is_group_admin !== true
          );

          let lstFirst = [];
          if (lstAdmin[0].slug == lstUser[0].slug) {
            lstFirst = lstAdmin;
          } else {
            lstFirst = [...lstAdmin, ...lstUser];
          }
          let lstFinal = [...lstFirst, ...lstOtherUser];
          setUserList(lstFinal);
          console.log(lstFinal, "lstFinal");
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        {groupData.isGroupAdmin ? (
          <GroupDetailDisplay
            groupDescription={groupDescription}
            setGroupDescription={setGroupDescription}
            groupName={groupName}
            setGroupName={setGroupName}
            onLoad={onClickUpdate}
            groupData={groupData}
            setGroupData={setGroupData}
            userName={userName}
            setUserName={setUserName}
            firebase_uid={firebase_uid}
            setFirebase_uid={setFirebase_uid}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isFavourite={isFavourite}
            setIsFavourite={setIsFavourite}
            isBlock={isBlock}
            setIsBlock={setIsBlock}
            userList={userList}
            setUserList={setUserList}
          />
        ) : (
          <GroupDetailDisplayNormal
            onLoad={onLoad}
            groupData={groupData}
            setGroupData={setGroupData}
            userName={userName}
            setUserName={setUserName}
            firebase_uid={firebase_uid}
            setFirebase_uid={setFirebase_uid}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isFavourite={isFavourite}
            setIsFavourite={setIsFavourite}
            isBlock={isBlock}
            setIsBlock={setIsBlock}
            userList={userList}
            setUserList={setUserList}
          />
        )}
      </div>
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" height={500} style={{ margin: "5px" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" height={250} style={{ margin: "5px" }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupInfo;
