import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRouter from "utils/useRouter";
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  }
}));

const age = [
  {
    value: 0,
    label: 'Age',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 35,
    label: '35',
  },
  {
    value: 45,
    label: '45',
  },
  {
    value: 65,
    label: '65',
  },
]
function valuetext(value) {
  return `${value}°C`;
}
const RangeType = (props) => {
  const { ans, setAns, match } = props;
  const classes = useStyles();
  const router = useRouter();
  const [rangeValue, setRangeValue] = React.useState([20, 30]);

  const handleRangeChange = (event, newValue) => {
    setRangeValue(newValue);
    setAns(newValue)
  };

  useEffect(() => {
    if (ans.length)
      setRangeValue(ans)
  }, [])

  return (
    <div className={classes.root}>
      <div style={{ color: "#808080", fontSize: "14px", textAlign: "center", paddingTop: "10px" }}>
        Age Range
      </div>
      <Slider
        value={rangeValue}
        classes={{ valueLabel: classes.sliderPointer }}
        onChange={handleRangeChange}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        size="small"
        marks={age}
      />
    </div>
  );
};

export default RangeType;
