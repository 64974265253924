import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRouter from "utils/useRouter";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from "react-google-places-autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {

  },
  googleSelecte: {
    width: "300px",
    '& .css-yk16xz-control': {
      borderRadius: "10px",
      padding: "8px"
    },
    '& .css-1pahdxg-control': {
      borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      padding: "8px"
    },
    '& .css-1pahdxg-control:hover': {
      borderRadius: "10px",
      border: "1px solid #FE4B6B !important",
      boxShadow: "0 0 0 1px #FE4B6B !important",
      padding: "8px"
    },
    '& .css-1uccc91-singleValue': {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 400
    },
    '& .css-1wa3eu0-placeholder': {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
    },
    '& div': {
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: "white",
      zIndex: 11
    }
  },
}));

const LocationType = (props) => {
  const { ans, setAns, match } = props;
  const classes = useStyles();
  const router = useRouter();
  const [location, setLocation] = React.useState(ans ? ans[0] : "");
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const onCHnageLocation = (value) => {
    setAns([value.label])
    if (value) {
      geocodeByAddress(value.value.description)
        .then(results => {
          console.error("valuevaluevalue", results)
          console.error("valuevaluevalue", results[0].geometry.location.lat())
          setLocation(value)
          setLatitude(results[0].geometry.location.lat())
          setLongitude(results[0].geometry.location.lng())
        })
        .catch(error => console.error("valuevaluevalue", error));
    } else {
      setLocation("")
      setLatitude("")
      setLongitude("")
    }

  }
  // useEffect(() => {
  //   setAns(["Surat"])
  // }, []);

  return (
    <div className={classes.root}>
      <div className={classes.googleSelecte}>
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            types: ['(cities)']
          }}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          label="City / state"
          selectProps={{
            placeholder: <><span>Location</span><span style={{ color: "red" }}>*</span> </>,
            isClearable: true,
            value: location || '',
            onChange: (val) => {
              onCHnageLocation(val);
              console.log("close", val)
            }
          }}
        />
      </div>
    </div>
  );
};

export default LocationType;
