import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import Api from '../../../../Helper/ApiHandler';
var api = new Api();

const useStyles = makeStyles((theme) => ({
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
  },
}));

const QuizQuestions = (props) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ position: "relative", background: "black", overflowX: "hidden" }}>
        <img src="/images/matchUp/solQueBack.jpg" alt="startQuiz" style={{ width: "100%", height: "90vh" }} />
        <div style={{ width: "100vw", position: "absolute", top: 0, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <div style={{ color: "white", fontSize: "35px", marginTop: "125px" }}>
            A great place to connect with like-minded people
          </div>
          <p style={{ color: "white", fontSize: "20px", textAlign: "center" }}>
            Friendship is never left behind! Make friends and connect with compatible<br />
            people who have similar interests and likes as you!
          </p>
          <div style={{ color: "white", fontSize: "16px", marginTop: "60px" }}>
            Our website
          </div>
          <div style={{ color: "white", fontSize: "110px", fontWeight: "300" }}>
            COMING SOON
          </div>
          {/* <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "#FD3576", textTransform: "capitalize", borderRadius: "10px", marginTop: "60px" }}
          >
            Notify me about that !
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default QuizQuestions;