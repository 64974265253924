import React, { useState, useEffect, useContext } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from './firebase';
import { makeStyles } from "@material-ui/styles";
import AppDataContext from 'Helper/AppDataContext';

const useStyles = makeStyles((theme) => ({
    notification: {
        display: "flex",
        height: "50px"
    },
    image: {
        width: "45px",
        height: "45px",
        borderRadius: "50%",
        objectFit: "cover",
        marginTop: 1
    },
    title: {
        padding: 0,
        margin: 0,
        marginBottom: 1
    },
    body: {
        padding: 0,
        margin: 0
    },
    textRoot: {
        display: "flex",
        justifyContent: "center",
        marginLeft: "10px",
        flexDirection: "column"
    }
}));

const Notification = () => {
    const classes = useStyles();
    const [notification, setNotification] = useState({ title: '', body: '', image: '' });
    const { appContext, setAppContext } = useContext(AppDataContext);
    const notify = () => toast(<ToastDisplay />);
    function ToastDisplay() {
        return (
            <div className={classes.notification}>
                <div>
                    <img
                        className={classes.image}
                        src={notification?.image}
                        alt="userPhoto Load"
                    />
                </div>
                <div className={classes.textRoot}>
                    <p className={classes.title}><b>{notification?.title}</b></p>
                    <p className={classes.body}>{notification?.body}</p>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (notification?.title) {
            notify()
        }
    }, [notification])

    requestForToken();

    onMessageListener()
        .then((payload) => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body, image: payload?.notification?.image });
            setAppContext({ ...appContext, isNewNotification: true })

        })
        .catch((err) => console.log('failed: ', err));

    return (
        <Toaster position="top-right" reverseOrder={false} />
    )
}

export default Notification