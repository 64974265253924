import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Container, Grid } from "@material-ui/core";
import CustomerSays from "./CustomerSays";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    background: "#fff",
  },
  userListHedding: {
    fontSize: "30px",
    color: "#FE4B6B",
    fontWeight: "500",
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "30px",
  },
  userList: {
    textAlign: "center",
  },
  userPicture: {
    borderRadius: "50%",
    height: "150px",
    width: "150px",
  },
  userName: {
    fontSize: "16px",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px",
  },
}));
const list = [
  {
    name: "Anderson",
    profile_picture: "/images/matchUp/dormmates1.png",
  },
  {
    name: "Ashley",
    profile_picture: "/images/matchUp/dormmates2.png",
  },
  {
    name: "Inge",
    profile_picture: "/images/matchUp/dormmates3.png",
  },
];
const RoommateBanedits = (props) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            lg={12}
            xl={12}
            className={classes.userListHedding}
          >
            Meet Your Next Roommate
          </Grid>
          <Grid item xs={12} lg={12} xl={12} className={classes.userList}>
            <Grid container justify="center">
              <CustomerSays />
              {/* {list.map((obj) => {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.userList}>
                    <img src={obj.profile_picture} alt="propertyLanding1" className={classes.userPicture} />
                    <div className={classes.userName}>
                      {obj.name}
                    </div>
                  </Grid>
                )
              })} */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RoommateBanedits;
