import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRouter from "utils/useRouter";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {

  }
}));

const DateType = (props) => {
  const { ans, setAns, match } = props;
  const classes = useStyles();
  const router = useRouter();
  const [dob, setDob] = React.useState();

  const dob_Change = (date) => {
    let changeDate = convertDate(date)
    if (moment().subtract(18, "years") >= moment(date)) {
      setDob(date);
      setAns([changeDate])
    } else {
      setDob("");
      setAns([""])
    }
  };
  useEffect(() => {
    console.log(ans, "ans")
    if (ans.length)
      setDob(ans[0])
    else {
      setDob(moment().subtract(18, "years"))
      setAns([convertDate(moment().subtract(18, "years"))])
    }
  }, [])

  function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('/')
  }


  return (
    <div className={classes.root}>
      <KeyboardDatePicker
        placeholder="DD/MM/YYYY"
        // maxDate={access ? new Date("01/01/2011") : undefined}
        maxDate={moment().subtract(18, "years")}
        format="DD/MM/YYYY"
        margin="normal"
        InputLabelProps={{
          classes: {
            asterisk: classes.asterisk
          }, shrink: true
        }}
        required
        name="dob"
        label="DOB"
        value={dob}
        defaultValue={dob}
        onChange={dob_Change}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        inputVariant="outlined"
        fullWidth
        autoOk
        className={classes.datePicker}
        size="small"

      />
    </div>
  );
};

export default DateType;
