import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colors, Divider, Snackbar } from "@material-ui/core";
import { Section, SectionAlternate } from "components/organisms";
import { Customization, Hero, Hub, Partners, Pricings } from "./components";

import BannerPart from "./components/BannerPart/index";
import StepHome from "./components/StepHome/index";
import ServicePart from "./components/ServicePart/index";
import WhyUseParts from "./components/WhyUseParts/index";
import CustomerSays from "./components/CustomerSays/index";
import DownloadApp from "./components/DownloadApp/index";
import { Helmet } from "react-helmet";

import { integrations } from "./data";
import Footer from "./components/Footer/index";
import PropertyPart from "./components/PropertyPart/index";
import MuiAlert from '@material-ui/lab/Alert';
import Api from '../../Helper/ApiHandler';
// import index from '/index.html';
// var perf ='index.html');
var api = new Api();
// var markup = document.documentElement.innerHTML;
var markup = document.documentElement.outerHTML;
// alert(markup);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    background: theme.palette.alternate.main,
    borderBottomRightRadius: "50%",
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}));

const Home = (props) => {
  const [meta, setMeta] = React.useState({});
  const { sessionPropsMessage } = (props.location && props.location.state) || {};
  console.log(sessionPropsMessage, "props.sessionPropsMessage")
  const classes = useStyles();
  const [sessionMessage, setSessionMessage] = React.useState(sessionPropsMessage ? sessionPropsMessage : {
    message: '',
    type: '',
    open: false
  });

  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  useEffect(() => {
    onLoadMeta()
    // console.log(markup, "markup")
  }, []);

  console.log(getMeta('description'), "meta");
  function getMeta(metaName) {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === metaName) {
        return metas[i].getAttribute('content');
      }
    }

    return '';
  }


  const onLoadMeta = () => {
    // For meta
    api.post('staticmetadata', {
      data: { type: "home" }
    })
      .then((result) => {
        if (result.success === "true") {
          // console.log(result.data, "result")
          setMeta(result.data, 'result.data')
        } else {
          console.log(result, "success === false")
        }
      })
      .catch((err) => {
        console.log(err, "error catch")
      });
  }
  return (
    <div className={classes.root}>
      <Helmet>
        <title>MatchUpMates | Find RoomMates & DormMates</title>
        {/* <meta name="description" content={meta.meta_description} />
        <meta name="keywords" content={meta.meta_keyword} />

        
        <meta itemprop="name" content={meta.page_name} />
        <meta itemprop="description" content={meta.meta_description} />
        <meta itemprop="image" content={meta.meta_image} />

        
        <meta property="og:url" content="https://matchupmates.com" />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={meta.meta_title} />
        <meta property="og:description" content={meta.meta_description} />
        <meta property="og:image" content={meta.meta_image} />

        
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={meta.meta_title} />
        <meta name="twitter:description" content={meta.meta_description} />
        <meta name="twitter:image" content={meta.meta_image} /> */}

      </Helmet>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <StepHome />
      <ServicePart />
      {/* <PropertyPart /> */}
      <WhyUseParts />
      <CustomerSays />
      {/* <DownloadApp /> */}
    </div>
  );
};

export default Home;
