import React from 'react'
import MyProfileDetailSol from './component/MyProfileDetail'

const ViewYourProfileSol = (props) => {
    const { sessionPropsMessage } = (props.location && props.location.state) || {};    
    return (
        <div>
            <MyProfileDetailSol sessionPropsMessage={sessionPropsMessage}/>
        </div>
    )
}

export default ViewYourProfileSol
