import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Api from "../../../Helper/ApiHandler";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
var api = new Api();

const useStyles = makeStyles((theme) => ({
  form__input: {
    marginTop: 14,
    background: "#FFFFFF",
    borderRadius: 9,
    height: 45,
    width: "100%",
  },
  Title: {
    display: "flex",
    marginTop: 30,
  },
  TitleText: {
    fontSize: "18px !important",
    fontWeight: 400,
  },
  Details: {
    display: "flex",
    margin: "15px 25px",
  },
  images: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 5,
  },
  contant: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  image_Details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 10px",
  },
  imageTitle: {
    fontSize: "14px !important",
    fontWeight: 500,
  },
  imageDetail: {
    fontSize: "12px !important",
    fontWeight: 500,
    color: "#93969A",
  },
  imageDetailMessage: {
    fontSize: "12px !important",
    fontWeight: 500,
    color: "#292930",
  },
}));

const BlogsDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [onLoading, setOnLoading] = useState([]);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    setOnLoading(true);
    api
      .get(`viewrecentbloglist`)
      .then((result) => {
        if (result.success === "true") {
          setData(result.data);
        } else {
          console.log(result, "error");
        }
        setOnLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setOnLoading(false);
      });
  };
  const handleNow = (object) => {
    history.push(`/blog/${object}`);
  };
  // console.log(data, "viewrecentbloglist");
  // searchvalue
  return (
    <Grid container>
      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          fullWidth
          type="text"
          placeholder="Search"
          variant="outlined"
          onChange={(e) => {
            handleChange(e);
          }}
          InputProps={{
            className: classes.form__input,
          }}
        />
      </Grid> */}
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.Title}>
        <Box
          component="img"
          mr={1}
          src="/images/matchUp/database.svg"
          alt="database"
        />
        <Typography variant="h5" className={classes.TitleText}>
          Recent Posts
        </Typography>
      </Grid>
      <div style={{ marginTop: 30 }}>
        {data.map((obj, ind) => {
          // console.log(obj, "obj");
          return (
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.Details}
              key={`obj_${ind}`}
            >
              <Grid
                item
                xs={3}
                sm={2}
                md={2}
                lg={2}
                xl={3}
                style={{ height: 80 }}
              >
                {onLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={100}
                    style={{ borderRadius: 6 }}
                  />
                ) : (
                  <img
                    src={obj.picture_url}
                    alt="BlogImages"
                    className={classes.images}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={9}
                sm={10}
                md={10}
                lg={9}
                className={classes.image_Details}
              >
                {onLoading ? (
                  <Skeleton animation="wave" width={150} />
                ) : (
                  <Typography
                    variant="h5"
                    className={classes.imageTitle}
                    onClick={() => {
                      handleNow(obj.slug);
                    }}
                  >
                    {obj.title}
                  </Typography>
                )}

                <Box component="div" display="flex" mt={1}>
                  {onLoading ? (
                    <Skeleton animation="wave" width={100} />
                  ) : (
                    <Typography variant="h5" className={classes.imageDetail}>
                      {obj.createdAt}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </div>
    </Grid>
  );
};

export default BlogsDetails;
