import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRouter from "utils/useRouter";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Checkbox, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {

  },
  chipUserDetail: {
    margin: "6px 5px",
    fontSize: "14px",
    padding: "0 10px",
    //fontFamily: "Gilroy !important",
    height: "auto",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0 7px",
      fontSize: "14px",
      margin: "4px 3px",
    },
    "& .MuiChip-label": {
      whiteSpace: "break-spaces",
      padding: "5px"
    }
  },
}));

const MultiChoiceType = (props) => {
  const { ans, setAns, options } = props;
  const classes = useStyles();
  const router = useRouter();
  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    if (ans)
      setSelected(ans)
  }, [ans]);

  const handleSelectAnswer = (item) => () => {
    let Ans = [...selected]
    const index = Ans.indexOf(item)
    if (Ans.includes(item)) {
      Ans.splice(index, 1)
    } else {
      Ans.push(item)
    }
    setSelected(Ans)
    setAns(Ans)
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {options.map((value) => {
          return (
            <Chip
              label={value}
              onClick={handleSelectAnswer(value)}
              style={{
                background: selected.includes(value) ? "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)" : "#DFDFDF",
                color: selected.includes(value) ? "white" : "#535353"
              }}
              className={classes.chipUserDetail}
            />
          )
        })}
      </div>
    </div>
  );
};

export default MultiChoiceType;
