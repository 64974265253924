import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Radio,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import validate from "validate.js";
import TelegramIcon from "@material-ui/icons/Telegram";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import { Link as browserLink, useLocation } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Checkbox from "@material-ui/core/Checkbox";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import ReactFancyBox from "react-fancybox";
import useRouter from "utils/useRouter";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BlockIcon from "@material-ui/icons/Block";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../Helper/ApiHandler";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Compressor from "compressorjs";
import crypto from "crypto";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import firebase from "firebase/app";
import moment from "moment";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const schema = {
  group_subject: {
    presence: { allowEmpty: false, message: "Group Subject is required" },
    length: { maximum: 50 },
    // format: {
    //   pattern: "^[a-zA-Z_ ]*$",
    //   flags: "i",
    //   message: "You can enter only character",
    // },
  },
  group_description: {
    presence: { allowEmpty: false, message: "Group Description is required" },
    length: { maximum: 500 },
  },
  group_type: {
    presence: { allowEmpty: false, message: "is required" },
  },
  no_of_members: {
    presence: { allowEmpty: false, message: "Participants limit is required" },
    numericality: {
      greaterThan: 0,
      message: "Participants must be positive",
    },
  },
  group_profile_pic: {
    presence: { allowEmpty: false, message: "is required" },
  },
  group_members: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  bgProfilePage: {
    background: "#e9edf0",
    padding: "35px 0",
    minHeight: "90vh",
    // marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  detailBox: {
    background: "white",
    borderRadius: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    position: "relative",
    paddingBottom: "10px",
  },
  textUserDetail: {
    // margin: "0",
    color: "gray",
    margin: "5px",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkitLineClamp": 3,
    "-webkitBoxOrient": "vertical",
    [theme.breakpoints.down("sm")]: {
      "-webkitLineClamp": 2,
    },
    overflow: "hidden",
  },
  directMessageBtn: {
    bottom: "10px",
    position: "absolute",
    // marginTop: "30px",
    textTransform: "capitalize",
    background: "#d5606045",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      // width: "100px",
    },
  },
  varticalLine: {
    height: "180px",
    width: "1px",
    background: "gray",
    borderBottom: "1px solid #000000",
    opacity: "0.3",
    marginBottom: "15px",
  },
  detailGridBox: {
    position: "relative",
  },
  educationDetail: {
    display: "flex",
    fontSize: "14px !important",
  },
  textEducation: {
    margin: "0",
    marginLeft: "10px",
    fontSize: "16px",
  },
  imgBoxView: {
    marginTop: "10px",
  },
  picsGrid: {
    background: "white",
    borderRadius: "10px",
    padding: "10px",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  lifeStyleHeading: {
    margin: "5px 10px",
    color: "gray",
    fontSize: "15px !important",
    fontWeight: "400",
  },
  imageLifStyle: {
    "& .react-fancybox .thumbnail img": {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
      margin: "5px",
      objectFit: "cover",
      cursor: "pointer",
    },
  },
  imageBoxStyle: {
    border: "2px solid #FE4B6B",
    borderRadius: "100%",
    textAlign: "center",
    padding: "3px",
    width: "90px",
    height: "90px",
    [theme.breakpoints.down("xs")]: {
      width: "70px",
      height: "70px",
    },
  },
  aboutHeading: {
    color: "grey",
    margin: "8px 0",
    marginLeft: "10px",
    fontSize: "15px !important",
    fontWeight: "400",
  },
  textParagraph: {
    marginLeft: "10px",
    fontSize: "16px",
    fontWeight: "600",
  },
  imageQuote: {
    textAlign: "right",
  },
  quotedImage: {
    width: "80px",
    height: "50px",
  },
  chipUserDetail: {
    margin: "6px 5px",
    color: "#FE4B6B",
    background: "#d5606045",
    //fontFamily: "Gilroy !important",
  },
  chipGruop: {
    marginBottom: "20px",
  },
  gridProfile: {
    marginTop: "10px",
  },
  userDetailMain: {
    position: "relative",
    padding: "0px 10px 0px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  favouriteIcon: {
    position: "absolute",
    top: "130px",
    right: "30px",
    color: "	#DC143C",
    border: "1px solid gray",
    padding: "2px",
    borderRadius: "6px",
    height: "30px",
    width: "30px",
    [theme.breakpoints.down("xs")]: {
      top: "120px",
      right: "10px",
    },
  },
  headingPage: {
    fontWeight: "600 !important",
    fontSize: "18px",
    margin: "10px 10px 10px 10px",
  },
  nameUserView: {
    fontWeight: "600",
    fontSize: "22px",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
    },
  },
  hrLine: {
    borderBottom: "2px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  yourImageFix: {
    width: "150px",
    height: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "120px",
      height: "120px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  smallHeightChange: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  sidelistAns: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  sidelistlabel: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    width: "80px",
  },
  heading: {
    fontSize: "18px !important",
    fontWeight: "600",
    // textAlign: "center"
  },
  headingAddress: {
    fontSize: "12px !important",
    fontWeight: "400",
  },
  chip: {
    border: "1px solid #E84A62",
    borderRadius: "25px",
    color: "#E84A62",
    width: "fit-content",
    fontSize: "12px",
    padding: "0px 5px",
    alignSelf: "center",
  },
  headindRoomate: {
    fontSize: "20px !important",
    fontWeight: "400",
    margin: 0,
    // textAlign: "center"
  },
  headindgender: {
    fontSize: "16px !important",
    fontWeight: "400",
    color: "#7E7E7E",
    margin: 0,
    // textAlign: "center"
  },
  backRoot: {
    maxWidth: "fit-content",
    cursor: "pointer",
    marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex",
  },
  university: {
    margin: "0px",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    width: "150px",
  },
  imgViewDetail: {
    width: "20px",
  },
  chipIcon: {
    width: "13px",
    height: "13px",
    // color: "#FE4B6B"
  },
  autocompletChip: {
    background: "white",
    "& .MuiChip-root": {
      borderRadius: "5px",
      // background: "#d5606045",
      height: "auto",
    },
    "& .MuiChip-outlined": {
      // border: "none",
      // color: "#FE4B6B",
      padding: "2px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
  nextButton: {
    width: "320px",
    borderRadius: "20px",
  },
  groupImageHover: {
    display: "none",
    position: "absolute",
    top: "0",
    opacity: "0.3",
  },
  groupImageRoot: {
    position: "relative",
    "&:hover ": {
      "& .hide": {
        display: "block",
      },
    },
  },
}));

const AddNewGroupInfoNew = (props) => {
  const { lst_group_members } =
    (props.location && props.location.state) || {};
  const classes = useStyles();
  const router = useRouter();
  const [userData, setUserData] = React.useState({});
  const [userName, setUserName] = React.useState("");
  const [firebase_uid, setFirebase_uid] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [lstInterests, setLstInterests] = React.useState([]);
  const [isFavourite, setIsFavourite] = React.useState(false);
  const [isBlock, setIsBlock] = React.useState(false);
  const [uploadImagePath, setUploadImagePath] = React.useState("");
  const [userList, setUserList] = React.useState([
    {
      photo: "/images/matchUp/lifestyle.png",
      name: "Cody Fisher",
    },
    {
      photo: "/images/matchUp/lifestyle.png",
      name: "Cody Fisher",
    },
    {
      photo: "/images/matchUp/lifestyle.png",
      name: "Cody Fisher",
    },
    {
      photo: "/images/matchUp/lifestyle.png",
      name: "Cody Fisher",
    },
    {
      photo: "/images/matchUp/lifestyle.png",
      name: "Cody Fisher",
    },
    {
      photo: "/images/matchUp/lifestyle.png",
      name: "Cody Fisher",
    },
    {
      photo: "/images/matchUp/lifestyle.png",
      name: "Cody Fisher",
    },
  ]);
  const db = firebase.firestore();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  let query = useLocation();
  const slug = query.pathname.split("/")[3];
  // const matchPercent = query.pathname.split("/")[4]
  console.log(lst_group_members, lst_group_members, "lst_group_members, lst_group_members")
  // Get Interests
  const getInterests = async () => {
    // Api Call For Fetch Interests List
    api
      .get(`interestlist`)
      .then((result) => {
        if (result.success === "true") {
          setLstInterests(result.data);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };
  useEffect(() => {
    getInterests();
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        group_type: "public",
        group_members: lst_group_members,
      },
    });
  }, []);

  const handleFieldChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleRadioChange = (value) => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        group_type: value,
      },
    });
  };

  const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  useEffect(() => {
    // onLoad()
    (() => {
      // setIsLoading(true)
      // Api Call For Fetch The Our Matches
      api
        .get(`user/yourroommatematch/${slug}`)
        .then((result) => {
          if (result.success === "true") {
            setUserData(result.data);
            setUserName(result.data.first_name + " " + result.data.last_name);
            setFirebase_uid(result.data.firebase_uid);
            // setIsLoading(false)
            setIsFavourite(
              result.data.userfavour ? result.data.userfavour : false
            );
            setIsBlock(result.data.is_blocked);
          } else {
            console.log(result, "error");
          }
        })
        .catch((err) => {
          console.log(err, "catch error");
        });
    })();
  }, [slug]);

  const handleClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };
  // console.log(lst_group_members, "lst_group_members")

  // const handleNext = (event) => {
  //   if (!formState.isValid) {
  //     // console.log("not valid")
  //     return;
  //   }
  //   // setLoading(true)
  //   // setDisabled(true)
  //   var timestamp = new Date();
  //   var timestampMid = new Date(new Date().valueOf() - 10000);
  //   var timestampOld = new Date(new Date().valueOf() - 30000);

  //   var groupKey = crypto.randomBytes(14).toString("hex");
  //   let userUid = localStorage.getItem("senderId");
  //   let userEmail = localStorage.getItem("email");
  //   let userName = localStorage.getItem("userName");
  //   let userProfile = localStorage.getItem("userProfile");

  //   let lstTmp = lst_group_members.map((obj) => {
  //     return obj.firebase_uid;
  //   });
  //   lstTmp.push(userUid);
  //   let lstUser = lstTmp
  //   console.log(lstTmp, "lstTmp")
  //   // let lstUser = [...lstTmp, userUid];
  //   // console.log(groupKey, "resetPasswordToken")
  //   setIsDisabled(true);
  //   setIsLoading(true);
  //   // groupKey add group_firebase_uid
  //   api
  //     .post("user/teammate/creategroup", {
  //       data: { ...formState.values, group_firebaseUid: groupKey },
  //     })
  //     .then(async (result) => {
  //       if (result.success === "true") {
  //         let objGroup = result.data;
  //         // console.log(lstUser, "lstUser")
  //         lstUser.map((f_uid) => {
  //           var docRef = db.collection("user").doc(f_uid);
  //           docRef
  //             .get()
  //             .then((doc) => {
  //               if (doc.exists) {
  //                 // console.log("Document data:", doc.data());
  //                 // console.log([...doc.data().groups, groupKey])
  //                 db.collection("user")
  //                   .doc(f_uid)
  //                   .set({
  //                     ...doc.data(),
  //                     groups: [...doc.data().groups, groupKey],
  //                   });
  //               } else {
  //                 // console.log("No such document!");
  //                 db.collection("user")
  //                   .doc(f_uid)
  //                   .set({
  //                     displayName: userName,
  //                     email: userEmail,
  //                     groups: [groupKey],
  //                     photoURL: userProfile,
  //                     uid: userUid,
  //                     slug: doc.slug,
  //                   });
  //               }
  //             })
  //             .catch((error) => {
  //               console.log("Error getting document:", error);
  //             });
  //         });
  //         // console.log("call 1")
  //         //  admin group create kare eno message add
  //         let messageId = uuid();
  //         await db
  //           .collection("message")
  //           .doc(groupKey)
  //           .collection("messages")
  //           .doc(messageId)
  //           .set({
  //             messageId: messageId,
  //             readBy: [userUid],
  //             textMessage: `Group created by ${userName}`,
  //             createdAt: timestampMid,
  //             createdBy: userUid,
  //             groupEdit: true,
  //             name: userName,
  //             type: "group",
  //             receiverId: groupKey,
  //           });
  //         // console.log("call 2")

  //         // await Promise.all(khamo)
  //         //  admin members add kare eno message add
  //         let lastMessage = "";
  //         lst_group_members.map((obj) => {
  //           let messageIdInMap = uuid();
  //           // Group message add
  //           db.collection("message")
  //             .doc(groupKey)
  //             .collection("messages")
  //             .doc(messageIdInMap)
  //             .set({
  //               messageId: messageIdInMap,
  //               readBy: [userUid],
  //               textMessage: `${obj.full_name} added by ${userName}`,
  //               createdAt: timestamp,
  //               createdBy: userUid,
  //               groupEdit: true,
  //               name: userName,
  //               type: "group",
  //               receiverId: groupKey,
  //             });
  //           lastMessage = `${obj.full_name} added by ${userName}`;
  //         });

  //         // new group create
  //         db.collection("group")
  //           .doc(groupKey)
  //           .set({
  //             createdAt: timestamp,
  //             createdBy: userUid,
  //             id: groupKey,
  //             // members: [...lstUser, userUid],
  //             members: lstUser,
  //             modifyAt: timestamp,
  //             name: objGroup.group_subject,
  //             group_description: objGroup.group_description,
  //             group_profile_pic: objGroup.group_profile_pic,
  //             slug: objGroup.slug,
  //             group_admin_id: objGroup.group_admin_id,
  //             group_type: objGroup.group_type,
  //             messageText: lastMessage,
  //             recentMessage: {
  //               messageText: lastMessage,
  //               readBy: [],
  //               sentAt: timestamp,
  //               sentBy: userUid,
  //             },
  //             type: 2,
  //             users: null,
  //           });

  //         // User inbox add
  //         lstUser.map((f_uid) => {
  //           db.collection("message")
  //             .doc(f_uid)
  //             .collection("inbox")
  //             .doc(groupKey)
  //             .set({
  //               type: "group",
  //               createdAt: timestamp,
  //               createdBy: userUid,
  //               groupId: groupKey,
  //               messageText: lastMessage,
  //             });

  //           db.collection("message")
  //             .doc(f_uid)
  //             .collection("activity")
  //             .doc(groupKey)
  //             .set({
  //               type: "group",
  //               clearAt: timestampOld,
  //             });
  //         });

  //         router.history.push({
  //           pathname: "/your-matches",
  //           search: "",
  //           state: {
  //             sessionPropsMessage: {
  //               message: result.message,
  //               type: "success",
  //               open: true,
  //             },
  //           },
  //         });
  //       } else {
  //         setSessionMessage({
  //           message: result.message,
  //           type: "error",
  //           open: true,
  //         });
  //       }
  //       setIsLoading(false);
  //       setIsDisabled(false);
  //     })
  //     .catch((err) => {
  //       setSessionMessage({
  //         message: err.message,
  //         type: "error",
  //         open: true,
  //       });
  //       setIsLoading(false);
  //       setIsDisabled(true);
  //     });

  //   // router.history.push("/addNewGroupInfo")
  // };
  const handleNext = async (event) => {
    if (!formState.isValid) {
      return;
    }

    let from_slug = await AsyncLocalStorage.getItem("from_slug")

    setIsDisabled(true);
    setIsLoading(true);
    // let lstTmp = lst_group_members.filter((obj) => obj.slug != from_slug)
    // console.log(lst_group_members, "formState.values.lst_group_members")
    // console.log(lstTmp, "lstTmp")
    // groupKey add group_firebase_uid
    api
      .post("user/teammate/addnewgroup", {
        data: { ...formState.values, group_firebaseUid: "PURVIK" },
      })
      .then(async (result) => {
        if (result.success === "true") {
          router.history.push({
            pathname: "/your-matches",
            search: "",
            state: {
              sessionPropsMessage: {
                message: result.message,
                type: "success",
                open: true,
              },
            },
          });
        } else {
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
        setIsLoading(false);
        setIsDisabled(false);
      })
      .catch((err) => {
        setSessionMessage({
          message: err.message,
          type: "error",
          open: true,
        });
        setIsLoading(false);
        setIsDisabled(true);
      });
  };
  // const hasError = (field) => (formState.errors[field] ? true : false);
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const comppressImageProfile = (e) => {
    const file = e?.target?.files[0];
    if (!file) {
      return;
    }
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const formData = new FormData();
        formData.append("file", result, result.name);
        // console.log(formData, "formData")
        onUploadimageComprace(e, formData, file);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const onUploadimageComprace = async (e, data, file) => {
    // Base64
    var reader = new FileReader();
    reader.onloadend = function () {
      setUploadImagePath(reader.result);
    };
    reader.readAsDataURL(file);

    // Upload image
    api
      .post("user/teammate/uploadprofilepic", {
        data: data,
      })
      .then((result) => {
        if (result.success === "true") {
          // console.log(result.data, "result---")
          setFormState({
            ...formState,
            values: {
              ...formState.values,
              group_profile_pic: result.data,
            },
          });
        } else {
          console.log(result, "success === false");
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  // console.log(formState, "formState.values")
  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.bgProfilePage}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              <Grid className={classes.detailBox} container>
                <Grid item xs={12} lg={12} xl={12}>
                  <Box
                    onClick={() => router.history.goBack()}
                    className={classes.backRoot}
                  >
                    <IconButton
                      aria-label="delete"
                      style={{ color: "black", marginRight: "5px" }}
                      size="small"
                    >
                      <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                    <h5 className={classes.headingPage}> New Group </h5>
                  </Box>
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className={classes.groupImageRoot}>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          onChange={(e) => {
                            comppressImageProfile(e);
                          }}
                        />
                        <label htmlFor="icon-button-file">
                          <img
                            src={
                              uploadImagePath
                                ? uploadImagePath
                                : "/images/matchUp/imageGroupAddIcon.svg"
                            }
                            alt="image"
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              width: "115px",
                              height: "115px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                          <img
                            src={"/images/matchUp/editIcon.svg"}
                            alt="image"
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              width: "25px",
                              height: "25px",
                              right: 0,
                              bottom: 25,
                              borderRadius: "50%",
                              objectFit: "cover",
                              zIndex: 100,
                            }}
                          />
                          <div className={`${classes.groupImageHover} hide`}>
                            <img
                              src={"/images/matchUp/imageGroupAddIcon.svg"}
                              alt="image"
                              style={{
                                cursor: "pointer",
                                width: "115px",
                                height: "115px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </label>
                      </div>
                      <div className={classes.hrLine} />
                    </div>
                  </div>
                  <div>
                    <Grid container style={{ padding: "30px" }} spacing={3}>
                      <Grid
                        item
                        lg={12}
                        xl={12}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <TextField
                          id="outlined-basic"
                          size="small"
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          // required
                          error={hasError("group_subject")}
                          helperText={
                            hasError("group_subject")
                              ? formState.errors.group_subject[0]
                              : null
                          }
                          className={classes.inputProfileEdit}
                          label="Group Subject"
                          name="group_subject"
                          value={formState.values.group_subject || ""}
                          onChange={handleFieldChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid
                        item
                        lg={6}
                        xl={6}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <TextField
                          id="outlined-basic"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError("no_of_members")}
                          helperText={
                            hasError("no_of_members")
                              ? formState.errors.no_of_members[0]
                              : null
                          }
                          className={classes.inputProfileEdit}
                          label="Set Participants limit"
                          name="no_of_members"
                          value={formState.values.no_of_members || ""}
                          onChange={handleFieldChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        xl={3}
                        xs={12}
                        className={classes.textRoot}
                        onClick={() => {
                          handleRadioChange("public");
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid rgb(196 196 196)",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <Radio
                            checked={formState.values.group_type === "public"}
                            color={"primary"}
                            value="public"
                            checkedIcon={<CheckCircleIcon />}
                            name="radio-public"
                          //inputProps={{ 'aria-label': 'A' }}
                          />
                          <div>Public</div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        lg={3}
                        xl={3}
                        xs={12}
                        className={classes.textRoot}
                        onClick={() => {
                          handleRadioChange("private");
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid rgb(196 196 196)",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                          onChange={() => {
                            handleRadioChange("private");
                          }}
                        >
                          <Radio
                            checked={formState.values.group_type === "private"}
                            value="private"
                            name="radio-public"
                            checkedIcon={<CheckCircleIcon />}
                            color={"primary"}
                          //inputProps={{ 'aria-label': 'A' }}
                          />
                          <div>Private</div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        xl={12}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <TextField
                          id="outlined-basic"
                          size="small"
                          className={classes.shareAboutInput}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.asterisk,
                            },
                            shrink: true,
                          }}
                          required
                          error={hasError("group_description")}
                          helperText={
                            hasError("group_description")
                              ? formState.errors.group_description[0]
                              : null
                          }
                          label="Group Description"
                          multiline={true}
                          rows={3}
                          name="group_description"
                          onChange={handleFieldChange}
                          value={formState.values.group_description || ""}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        xl={12}
                        xs={12}
                        className={classes.textRoot}
                      >
                        <h5 className={classes.headingPage}>
                          {" "}
                          Participants : {lst_group_members.length}{" "}
                        </h5>
                        <Grid container spacing={2}>
                          {lst_group_members.map((obj) => {
                            return (
                              <Grid
                                item
                                xs={3}
                                sm={2}
                                md={2}
                                lg={1}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <img
                                    src={
                                      obj.profile_picture
                                        ? obj.profile_picture
                                        : "/images/male_defualt.svg"
                                    }
                                    alt="obj.photo"
                                    style={{
                                      borderRadius: "50%",
                                      height: "60px",
                                      width: "60px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                                <div style={{
                                  fontSize: "10px",
                                  textAlign: "center",
                                  overflow: "hidden",
                                  width: "100%",
                                  height: "25px"
                                }}>
                                  {obj.value}
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className={classes.nextButton}
                      variant="contained"
                      color="primary"
                      disabled={formState.isValid ? isDisabled : true}
                      style={{
                        background:
                          "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
                      }}
                      onClick={(e) => {
                        handleNext(e);
                      }}
                    >
                      Create
                      {isLoading && (
                        <>
                          &nbsp;
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        </>
                      )}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default AddNewGroupInfoNew;
