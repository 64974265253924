import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Link as RouterLink } from 'react-router-dom';
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(11, 0),
    },
    background: "#0071CE",
  },
  lastFooter: {
    background: "black",
    width: "100%",
    // height: "60px",
    color: "white",
    textAlign: "center",
    lineHeight: "60px",
    // zIndex: "111"
  },
  itemListLink: {
    color: '#E9EDF0',
    // textDecoration:'none'
  },
  fixedHeader: {
    // position: "fixed",
    bottom: "0px",
    width: "100vw",
    zIndex:"12"
  }
}));

const Footer = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();
  const [fixed, setFixed] = React.useState(true);

  const controlSidebar = async () => {
    let footer = document.getElementById('footer')
    if (footer.offsetTop < window.innerHeight) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', controlSidebar);
    return () => {
      window.removeEventListener('scroll', controlSidebar);
    };
    //eslint-disable-next-line
  }, [window.screenY]);

  return (
    <Box>
      <Box className={fixed && classes.fixedHeader} id="footer">
        <div className={classes.lastFooter}>
          <Link to="/terms-of-use" className={classes.itemListLink}>Terms Of Service</Link> &nbsp;&nbsp; <Link to="/privacy-policy" className={classes.itemListLink}> Privacy Policy</Link> &nbsp; &copy; Copyright 2022
        </div>
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
