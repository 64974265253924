import React from "react";
import DormmateLandingDetail from "./component/DormmateLandingDetail";

const PropertyLanding = () => {
  return (
    <>
      <DormmateLandingDetail />
    </>
  );
};

export default PropertyLanding;
