import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { IconButton, List, ListItem } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Grid, Box } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Link, Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(11, 0),
    },
    // background: "#0071CE",
    background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)',
  },
  textFooter: {
    color: "#FFFFFF;",
    // marginLeft: "10px",
    //fontFamily: "Gilroy !important",
    fontSize: "16px",
    fontWeight: 300

  },
  socialIcon: {
    color: "white",
    // '& .MuiIconButton-root':{
    padding: "5px !important"
    // }
  },
  icon: {
    fontSize: "30px"
  },
  linksHeading: {
    color: "white",
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: "18px",
    },
  },
  itemList: {
    fontSize: "14px",
    color: "#FFFFFF",
    padding: "8px 0",
    margin: "2px !important",
    padding: "0px !important",
    // fontWeight: "lighter"

    fontWeight: 300,

  },
  BoxListLink: {
    hieght: "100%",
    width: "100%",
    marginTop: "5px !important",
    // textAlign: "start",
  },
  subLinkGrid: {
    marginTop: "5px !important",
    height: "180px",
  },
  lastFooter: {
    background: "black",
    width: "100%",
    height: "60px",
    color: "white",
    textAlign: "center",
    lineHeight: "60px",
    borderTop: "1px solid",
  },
  footerLogo: {
    width: "238px",
    height: "55px",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  itemListLink: {
    textDecoration: 'none'
  }
}));

const Footer = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <>
      <div {...rest} className={clsx(classes.root, className)}>
        <Container>
          <Grid
            direction="row"
            justifyContent="center"
            alignItems="center"
            container
            spacing={6}
          >
            <Grid item xl={5} lg={3} md={5} xs={12}>
              <img className={classes.footerLogo} src="/images/matchUp/loginBarLogo.svg" alt={"Alt Image"} />
              <div>
                <p className={classes.textFooter}>
                  Matchupmates is a social networking resource that helps high school and college students identify and connect with compatible matches.
                </p>
              </div>
              <div>
                <ListItem disableGutters>
                  <IconButton className={classes.socialIcon}>
                    <FacebookIcon className={classes.icon} />
                  </IconButton>
                  <IconButton className={classes.socialIcon}>
                    <InstagramIcon className={classes.icon} />
                  </IconButton>
                  <IconButton className={classes.socialIcon}>
                    <TwitterIcon className={classes.icon} />
                  </IconButton>
                  <IconButton className={classes.socialIcon}>
                    <LinkedInIcon className={classes.icon} />
                  </IconButton>
                </ListItem>
              </div>
            </Grid>
            <Grid item xl={2} lg={3} md={2} xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <h4 className={classes.linksHeading}> Links </h4>
                  <Grid className={classes.subLinkGrid}>
                    <a href="/" className={classes.itemListLink}><p className={classes.itemList}> Home </p></a>
                    <a href="/about-us" className={classes.itemListLink}><p className={classes.itemList}> About us </p></a>
                    <a href="/how-it-work" className={classes.itemListLink}><p className={classes.itemList}> How It Works </p></a>
                    <a href="/" className={classes.itemListLink}><p className={classes.itemList}> FAQ </p></a>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={2} lg={3} md={2} xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <h4 className={classes.linksHeading}> Links </h4>
                  <Grid className={classes.subLinkGrid}>
                    <a href="/privacy-policy" className={classes.itemListLink}><p className={classes.itemList}> Privacy policy</p></a>
                    <a href="/terms-of-use" className={classes.itemListLink}><p className={classes.itemList}> Terms of service</p></a>
                    <a href="/contact-us" className={classes.itemListLink}><p className={classes.itemList}> Contact us</p></a>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={3} xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <h4 className={classes.linksHeading}> Most Visited Links </h4>
                  <Grid className={classes.subLinkGrid}>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
