import React, { useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Container, Grid, Box, Button, Typography, Snackbar, Link } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import useRouter from 'utils/useRouter';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import AppDataContext from "Helper/AppDataContext";
import MuiAlert from '@material-ui/lab/Alert';

import Api from '../../../../Helper/ApiHandler';
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {},
  mainRoot: {
    background: "#FFF7F8",
    // height: "600px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // position: "relative"
    padding: "0px 35px"
  },
  primaryHadding: {
    color: "#FE4B6B",
    fontSize: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "29px",
    },
    fontWeight: "500",
    marginTop: "35px",
    textAlign: "center"
  },
  subHadding: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    marginTop: "20px",
    textAlign: "center"
  },
  imagePeople: {
    marginTop: "35px",
    width: "90vw",
    maxWidth: "857px"
    // position: "absolute",
    // bottom: 0
  },
  downloadIcon: {
    marginTop: "35px",
    display: "flex",
    justifyContain: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  appStore: {
    [theme.breakpoints.up("sm")]: {
      marginRight: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
    cursor: "pointer",
    width: "200px"
  },
  playStore: {
    cursor: "pointer",
    width: "200px"
  }
}));

const DownloadApp = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [sessionMessage, setSessionMessage] = React.useState({
    message: '',
    type: '',
    open: false
  });

  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }
  return (
    <div className={classes.mainRoot}>
      <div className={classes.primaryHadding}>
        Download MatchUpMates Now !
      </div>
      <p className={classes.subHadding}>
        Friendship is never left behind! Make friends and connect with compatible<br />
        people who have similar interests and likes as you!
      </p>
      <div className={classes.downloadIcon}>
        <Link
          href={"https://apps.apple.com/us/app/matchupmates-like-minded-pair/id1625071306"}
        >
          <img src={"/images/matchUp/AppStore.png"} className={classes.appStore} alt={"Alt Image"} />
        </Link>
        <Link
          href={"https://play.google.com/store/apps/details?id=com.matchupmates"}
        >
          <img src={"/images/matchUp/PlayStore.png"} className={classes.playStore} alt={"Alt Image"} />
        </Link>
      </div>
      <img src={"/images/matchUp/DownloadSection.png"} className={classes.imagePeople} alt={"Alt Image"} />
    </div>
  );
};

export default DownloadApp;

DownloadApp.propTypes = {
  className: PropTypes.string,
};
