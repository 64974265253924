import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRouter from "utils/useRouter";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {

  }
}));

const RadioType = (props) => {
  const { ans, setAns, gridNum, options } = props;
  const classes = useStyles();
  const router = useRouter();
  const [radioSelectedValue, setRadioSelectedValue] = React.useState("");

  useEffect(() => {
    if (ans)
      setRadioSelectedValue(ans)
  }, [ans]);

  const handleRadioChange = (e) => {
    setRadioSelectedValue(e.target.value);
    setAns([e.target.value])
  };
  console.log(radioSelectedValue, "radioSelectedValue")
  return (
    <div className={classes.root}>
      <RadioGroup label="gender" name="gender" value={radioSelectedValue || ""} onChange={handleRadioChange} >
        <Grid container>
          {options.map((value) => {
            return (
              <Grid item xs={12} sm={12} md={gridNum}>
                <FormControlLabel value={value} control={<Radio color="primary" />} label={value} />
              </Grid>
            )
          })}
        </Grid>
      </RadioGroup>
    </div>
  );
};

export default RadioType;
