import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import DeleteDialog from "components/DeleteModel/DeleteModel";
import MuiAlert from "@material-ui/lab/Alert";
import Api from "../../../../Helper/ApiHandler";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";
import Fancybox from "components/fancybox";

var api = new Api();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bgGroupPage: {
    minHeight: "90vh",
    background: "#e9edf0",
    padding: "35px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      paddingBottom: "30px",
    },
  },
  dorRoot: {
    padding: "6px"
  },
  groupHead: {
    background: "white",
    padding: "3px",
    // borderRadius: "10px",
    marginLeft: "10px",
    borderRadius: 10,
  },
  headingText: {
    fontWeight: "600 !important",
    fontSize: "18px",
    // marginLeft: "10px",
    margin: "30px 10px 18px 15px"
  },
  imgeUser: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "65px",
      height: "65px",
    },
    objectFit: "cover",
    objectPosition: "top",
    margin: "0px 10px"
  },
  textpeople: {
    margin: "0",
    marginBottom: "5px",
    color: "gray",
  },
  nameGroupPeople: {
    marginBottom: "5px",
    margin: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
    fontWeight: "500",
    width: "fit-content",
    cursor: "pointer",
  },
  removeButton: {
    textTransform: "capitalize",
    position: "relative",
    top: "50px",
    width: "100px",
    height: "25px",
    background: "#FFEDF0",
    [theme.breakpoints.down("xs")]: {
      top: "0px",
      height: "30px",
      right: "10px",
      display: "none",
    },
  },
  HrLine: {
    borderTop: "1px solid lightgray",
  },
  memberDiv: {
    padding: "10px 0",
  },
  boxListDetail: {
    marginTop: "10px",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  removeButtonSm: {
    position: "absolute",
    right: "30px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    cursor: "pointer",
  },
}));

const GroupList = (props) => {
  const router = useHistory();
  const classes = useStyles();
  const [lstDormmate, setLstDormMates] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [objDeleteProps, setObjDeleteProps] = React.useState({
    slug: "",
    open: false,
    componentName: "",
    userType: "",
  });
  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });
  useEffect(() => {
    onLoad();
  }, []);

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  const onLoad = () => {
    setLoading(true);
    // Api Call For Fetch The DormMates
    api
      .get(`user/yourdormmates`)
      .then((result) => {
        if (result.success === "true") {
          setLstDormMates(result.data);
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoading(false);
      });
  };

  const handleClickOpen = (slug) => {
    setObjDeleteProps({
      slug: slug,
      open: true,
      componentName: "deletefromgroup",
      userType: "user",
    });
  };

  const handleClose = (value, objMessage) => {
    setObjDeleteProps({
      slug: "",
      open: false,
      componentName: "",
    });
    onLoad();
    setSessionMessage(objMessage);
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onProfile = (slug) => {
    router.push(`/your-matches/view/${slug}`);
  };

  return (
    <>
      <div className={classes.bgGroupPage}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleErorrClose}
          >
            <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid className={classes.container} item>
              <Grid container>
                <Grid item xs={12}>
                  <div>
                    <h5 className={classes.headingText}> My DormMates </h5>
                  </div>

                  {!loading ? (
                    <Grid container spacing={1} className={classes.dorRoot}>
                      {lstDormmate.length ? (
                        lstDormmate.map((dormmates, index) => (
                          <Grid item xl={12} lg={12} xs={12}>
                            <Box className={classes.groupHead}>
                              <div className={classes.memberDiv}>
                                <Grid container>
                                  <Grid item>
                                    <Fancybox>
                                      <a
                                        data-fancybox="Images"
                                        href={
                                          dormmates.profile_picture
                                            ? dormmates.profile_picture
                                            : "/images/male_defualt.svg"
                                        }
                                      >
                                        <div style={{ textAlign: "center" }}>
                                          <img
                                            className={classes.imgeUser}
                                            src={
                                              dormmates.profile_picture
                                                ? dormmates.profile_picture
                                                : "/images/male_defualt.svg"
                                            }
                                            alt="profile_picture"
                                          />
                                        </div>
                                      </a>
                                    </Fancybox>
                                  </Grid>
                                  <Grid item xl={4} lg={4} xs={6} onClick={() => onProfile(dormmates.slug)} style={{ display: "flex", alignItems: "center" }}>
                                    <div>
                                      <h4
                                        className={classes.nameGroupPeople}
                                        onClick={() => onProfile(dormmates.slug)}
                                      >
                                        {dormmates.first_name}{" "}
                                        {dormmates.last_name}
                                      </h4>
                                      <p className={classes.textpeople}>
                                        {capitalizeFirstLetter(dormmates.gender)}
                                      </p>
                                      <p className={classes.textpeople}>
                                        {dormmates.email}
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xl={4} lg={4} xs={1}></Grid>
                                  <div className={classes.removeButtonSm}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#FFEDF0",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <ClearIcon
                                        style={{
                                          fontSize: "14px",
                                          color: "#FD3576",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <Grid
                                    item
                                    xl={2}
                                    lg={2}
                                    xs={2}
                                    style={{ position: "relative" }}
                                  >
                                    <Button
                                      className={classes.removeButton}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        handleClickOpen(dormmates.groupslug);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                    {/* <div className={classes.removeButtonSm}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          backgroundColor: "#FFEDF0",
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <ClearIcon
                                          style={{
                                            fontSize: "14px",
                                            color: "#FD3576",
                                          }}
                                        />
                                      </div>
                                    </div> */}
                                  </Grid>
                                </Grid>
                              </div>
                              {/* {lstDormmate.length - 1 !== index && (
                                // <hr className={classes.HrLine} />
                                <Divider />
                              )} */}
                            </Box>
                          </Grid>
                        ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            marginTop: "50px"
                          }}
                        >
                          <img
                            src="/images/matchUp/notFoundMatches.svg"
                            alt="favAlt"
                          />
                          <Typography
                            variant="caption"
                            component="primary"
                            color="primary"
                            style={{
                              fontSize: "20px",
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            No DormMates Yet!
                          </Typography>
                          <Typography
                            variant="caption"
                            component="primary"
                            color="primary"
                            style={{
                              fontSize: "14px",
                              color: "gray",
                              fontWeight: "400",
                            }}
                          >
                            Mark your favorite profile and always have them
                            here.
                          </Typography>
                        </div>
                      )}
                    </Grid>
                  ) : (
                    <SkeletonLoading />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <DeleteDialog
          // selectedValue={selectedValue}
          slug={objDeleteProps.slug}
          componentName={objDeleteProps.componentName}
          open={objDeleteProps.open}
          onClose={handleClose}
          userType={objDeleteProps.userType}
        />
      </div>
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} xl={12} lg={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={"120px"}
            style={{ borderRadius: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={12} lg={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={"120px"}
            style={{ borderRadius: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={12} lg={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={"120px"}
            style={{ borderRadius: "10px" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default GroupList;
