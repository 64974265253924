import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import { Skeleton } from "@material-ui/lab";
import MuiAlert from '@material-ui/lab/Alert';
import Api from '../../Helper/ApiHandler';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import MapTC from 'components/MapTC/MapTC';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useRouter from "utils/useRouter";

var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // backgroundColor: "E9EDF0 !important",
    background: "#E9EDF0",
    padding: "35px 0",
    [theme.breakpoints.down('xs')]: {
      padding: "0",
    },
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  searchInput: {
    width: "290px",
    marginRight: "10px",
    background: "#E9EDF0",
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    textAlign: "center",
    marginTop: "35px",
    textTransform: "capitalize",
  },
  filterIconBtn: {
    marginLeft: "10px",
  },
  listBg: {
    background: "#E9EDF0",
  },
  matchesHead: {
    margin: "0",
    // padding: "20px 15px",
    paddingBottom: "10px",
    fontWeight: "600 !important",
    fontSize: "18px !important",
    textTransform: "capitalize"
  },
  cardUser: {
    background: "white",
    padding: "25px 10px",
    textAlign: "center",
    paddingBottom: "10px",
    margin: "7px",
    borderRadius: "15px",
    height: "260px"
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400"
  },
  chipUserDetail: {
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0"
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGrid: {
    paddingBottom: "60px",
    padding: "10px"
  },
  imageMatches: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down('xs')]: {
      width: "80px",
      height: "80px",
    },
    borderRadius: "50%",
    objectFit: "cover"
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  responsiveHide: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    [theme.breakpoints.only('md')]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only('lg')]: {
      width: "28%",
    },
    [theme.breakpoints.down('xl')]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.only('md')]: {
      width: "66.67%",
    },
    [theme.breakpoints.only('lg')]: {
      width: "72%",
    },
    [theme.breakpoints.only('xl')]: {
      width: "72%",
    },
  },
  typography: {
    padding: "15px"
  },
  hadding: {
    marginTop: "20px",
    padding: "30px 0px 5px 0px"
  },
  chip: {
    backgroundColor: '#FFDBE1',
    color: "#FE4B6B",
    margin: "3px"
  },
  divider: {
    backgroundColor: "#d9d4d4"
  },
  card: {
    // backgroundColor: theme.palette.background.paper, 
    borderRadius: "10px"
  },
  roomCaradantiol: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px"
  },
  roomCaradantiolCount: {

  },
  roomCaradantiolCountText: {
    fontWeight: "400",
    fontSize: "14px !important",
    margin: "5px 0px !important"
  },
  roomPhotoLocation: {
    display: "flex",
    alignItems: "center"
  },
  roomImage: {
    // marginTop: "20px",
    padding: "5px 25px 15px 25px",
    backgroundColor: "white",
    borderRadius: "10px"
  },
  image: {
    "& .react-fancybox .thumbnail img": {
      height: "290px",
      width: "100%",
      objectFit: "cover"
    }
  },
  imageSub: {
    "& .react-fancybox .thumbnail img": {
      height: "140px",
      width: "100%",
      objectFit: "cover",
    }

  },
  roomLocation: {
    color: "#FE4B6B",
    textTransform: "capitalize"
  },
  rentalUnit: {
    marginTop: "15px",
    padding: "25px 25px 25px 25px",
    backgroundColor: "white",
    borderRadius: "10px"
  },
  rentalUnitSubHeading1: {
    fontWeight: "400",
    fontSize: "14px !important",
    padding: "5px 0px 5px 0px"
  },
  rentalUnitSubHeading2: {
    fontWeight: "400"
  },
  offer: {
    marginTop: "30px",
    padding: "25px 25px 25px 25px",
    backgroundColor: "white",
    borderRadius: "10px"
  },
  offerHeading: {
    fontWeight: "500",
    fontSize: "16px !important",
  },
  offerChipRoot: {
    padding: "10px 0px"
  },
  offerChip: {
    padding: "10px !important",
    borderRadius: "20px !important",
    fontSize: "14px",
    color: "#FE4B6B",
    backgroundColor: "#FFDBE1",
    margin: "5px"
  },
  offerChipIcon: {
    color: "#FE4B6B",
  },
  headindRoomate: {
    fontSize: "20px !important",
    fontWeight: "400",
    margin: 0
    // textAlign: "center"
  },
  headindgender: {
    fontSize: "16px !important",
    fontWeight: "400",
    color: "#7E7E7E",
    margin: 0
    // textAlign: "center"
  },
  joinChipRoot: {
    marginRight: "10px",
    '& .MuiChip-outlined': {
      borderColor: "#FE4B6B"
    },
    '& .MuiChip-label': {
      fontSize: "18px",
      fontWeight: "500",
      margin: "10px 15px"
    }
  },
  joinChip: {
    color: "#FE4B6B",
    backgroundColor: "#FFDBE1",
    cursor: "pointer"
  },
  backRoot: {
    // maxWidth: "fit-content",
    cursor: "pointer",
    marginLeft: "10px",
    // marginBottom: "20px",
    // marginTop: "45px",
    alignItems: "center",
    display: "flex"
  },
}));

const Favourite = (props) => {
  const { match } = props;

  const router = useRouter();
  const { id } = match.params
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [objProperty, setObjProperty] = React.useState([]);
  const [sessionMessage, setSessionMessage] = React.useState({
    message: '',
    type: '',
    open: false
  });

  useEffect(() => {
    (() => {
      setLoading(true)
      // Api Call For Fetch The User Data
      api.get(`user/viewproperty/${id}`)
        .then((result) => {
          if (result.success === "true") {
            setObjProperty(result.data)
          } else {
            console.log(result, "error")
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log(err, "catch error")
          setLoading(false)
        });
    })()

  }, [id]);


  // const onLoad = () => {
  //   setLoading(true)
  //   // Api Call For Fetch The User Data
  //   api.get(`user/viewproperty/${id}`)
  //     .then((result) => {
  //       if (result.success === "true") {
  //         console.log(result, "suc")
  //         setObjProperty(result.data)
  //       } else {
  //         console.log(result, "error")
  //       }
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       console.log(err, "catch error")
  //       setLoading(false)
  //     });
  // };


  const onJoin = async () => {
    // let from_id = await AsyncLocalStorage.getItem("from_id")

    api.post('user/property/sendrequesttoowner', {
      data: {
        to_id: objProperty.property_owner_id,
        property_id: objProperty.id
      }
    })
      .then((result) => {
        if (result.success === "true") {
          // onLoad()
          setSessionMessage({
            message: result.message,
            type: 'success',
            open: true
          });
          setObjProperty({ ...objProperty, request_status: true })
        } else {
          console.log(result, "error")
          setSessionMessage({
            message: result.message,
            type: 'error',
            open: true
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch")
        setSessionMessage({
          message: err.message,
          type: 'error',
          open: true
        });
      });
  }
  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 1
  // };
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.mainRoot}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid item className={classes.container} spaccing={2}>
              <div className={classes.listBg}>
                {/* <Container maxWidth="lg"> */}
                <Grid
                  justifyContent="center"
                  alignItems="center"
                  className={classes.cardBoxGrid}
                  container
                >
                  {!loading ?
                    <>
                      <Grid item xs={12} className={classes.card}>
                        <div className={classes.roomCaradantiol}>
                          <div className={classes.roomCaradantiolCount}>
                            <Box onClick={() => router.history.goBack()} className={classes.backRoot}>
                              <IconButton aria-label="delete" style={{ color: "black", marginRight: "5px", marginBottom: "10px" }} size="small">
                                <ArrowBackIcon fontSize="inherit" />
                              </IconButton>
                              <h5 className={classes.matchesHead}> {objProperty.property_name} </h5>
                            </Box>
                          </div>

                        </div>
                        {/* Flat */}
                        <div className={classes.roomImage}>
                          <Grid container justify="center" spacing={1}>
                            <div className="container">
                              <Slider {...settings}>
                                {objProperty?.property_photos?.map((value) => {
                                  return (
                                    // <ReactFancyBox
                                    //   thumbnail={value && value ? value : "/images/placeholder.png"}
                                    //   image={value && value ? value : "/images/placeholder.png"}
                                    //   defaultThumbnailWidth={"100%"}
                                    //   defaultThumbnailHeight={"290px"}
                                    //   className={classes.image}
                                    // />
                                    <div>
                                      <img src={value} alt="Credit to Joshua Earle on Unsplash" />
                                    </div>
                                  )
                                })}
                              </Slider>
                            </div>
                            {/* <Grid item xs={12} sm={4} className={classes.image}>
                              <ReactFancyBox
                                thumbnail={objProperty.property_photos && objProperty.property_photos[0] ? objProperty.property_photos[0] : "/images/placeholder.png"}
                                image={objProperty.property_photos && objProperty.property_photos[0] ? objProperty.property_photos[0] : "/images/placeholder.png"}
                                defaultThumbnailWidth={"100%"}
                                defaultThumbnailHeight={"290px"}
                              // className={classes.image}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Grid container justify="center">
                                <Grid item xs={12} className={classes.imageSub}>
                                  <ReactFancyBox
                                    thumbnail={objProperty.property_photos && objProperty.property_photos[1] ? objProperty.property_photos[1] : "/images/placeholder.png"}
                                    image={objProperty.property_photos && objProperty.property_photos[1] ? objProperty.property_photos[1] : "/images/placeholder.png"}
                                    defaultThumbnailWidth={"100%"}
                                    defaultThumbnailHeight={"140px"}
                                  />
                                </Grid>
                                <Grid item xs={12} className={classes.imageSub} style={{ paddingTop: "4px" }}>
                                  <ReactFancyBox
                                    thumbnail={objProperty.property_photos && objProperty.property_photos[2] ? objProperty.property_photos[2] : "/images/placeholder.png"}
                                    image={objProperty.property_photos && objProperty.property_photos[2] ? objProperty.property_photos[2] : "/images/placeholder.png"}
                                    defaultThumbnailWidth={"100%"}
                                    defaultThumbnailHeight={"140px"}
                                  // className={classes.image}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.image}>
                              <ReactFancyBox
                                thumbnail={objProperty.property_photos && objProperty.property_photos[3] ? objProperty.property_photos[3] : "/images/placeholder.png"}
                                image={objProperty.property_photos && objProperty.property_photos[3] ? objProperty.property_photos[3] : "/images/placeholder.png"}
                                defaultThumbnailWidth={"100%"}
                                defaultThumbnailHeight={"290px"}
                              // className={classes.image}
                              />
                            </Grid> */}
                          </Grid>

                          <div style={{ display: "flex", marginTop: "10px", marginLeft: "15px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                              {/* <img src={'/images/locationOutLine.svg'} alt='' /> */}
                              <Typography
                                variant="subtitle1"
                                component="subtitle1"
                                className={classes.roomLocation}
                              >
                                Property Owner - {objProperty.property_owner_name}
                              </Typography>

                              {!objProperty.request_status &&
                                <div className={classes.joinChipRoot}>
                                  <Chip label="Join" variant="outlined" className={classes.joinChip} onClick={() => { onJoin() }} />
                                </div>
                              }
                            </div>
                            {/* <div style={{ marginLeft: "15px", display: "flex", alignItems: "center" }}>
                              <img src={'/images/duck.svg'} alt='' />&nbsp;
                              <Typography
                                variant="subtitle1"
                                component="subtitle1"
                                className={classes.roomLocation}
                              >
                                Family Friendly
                              </Typography>
                            </div> */}
                          </div>
                        </div>

                        {/* Rental Unit */}
                        <div className={classes.rentalUnit}>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                              <Typography
                                variant="h4"
                                component="h4"
                                className={classes.offerHeading}
                              >
                                {objProperty.property_type}
                              </Typography>
                              <Typography
                                variant="h6"
                                component="h6"
                                className={classes.roomCaradantiolCountText}
                              >
                                {`(${objProperty.no_of_bathrooms} bathrooms , ${objProperty.no_of_beds} bed)`}
                              </Typography>
                            </div>
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                              <img src={'/images/location.svg'} alt='Img' />&nbsp;
                              <Tooltip title={objProperty.address} placement="top">
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    marginTop: "2px",
                                    maxWidth: "270px",
                                    // maxWidth: "130px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",

                                  }}
                                >
                                  {objProperty.address}
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <Divider style={{ margin: "20px 0px" }} />
                          <Typography
                            variant="h4"
                            component="h4"
                            className={classes.offerHeading}
                          >
                            {objProperty.place_type}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.rentalUnitSubHeading1}
                          >
                            {objProperty.description}
                          </Typography>

                          <Divider style={{ margin: "20px 0px" }} />
                          {/* offers */}
                          <Typography
                            variant="h5"
                            component="h5"
                            className={classes.offerHeading}
                          >
                            Aminities
                          </Typography>
                          <div className={classes.offerChipRoot}>
                            {objProperty.amenities && objProperty.amenities.map((value) => {
                              return (
                                <Chip
                                  // icon={obj.icon}
                                  label={value}
                                  className={classes.offerChip}
                                />
                              )
                            })}
                            {objProperty.property_highlights && objProperty.property_highlights.map((value) => {
                              return (
                                <Chip
                                  // icon={obj.icon}
                                  label={value}
                                  className={classes.offerChip}
                                />
                              )
                            })}
                          </div>
                        </div>
                        <Grid className={classes.rentalUnit} container style={{ marginBottom: "15px" }}>
                          <Grid item xs={12} xl={12} lg={12}>
                            <h5 className={classes.offerHeading} style={{ margin: 0 }}> RoomMates</h5>
                            <Box
                              display="flex"
                              alignItems="flex-start"
                              flexDirection="column"
                            >
                              {objProperty.roommates &&
                                objProperty.roommates.length ? objProperty.roommates.map((obj, index) => {
                                  return (
                                    <>
                                      <div style={{ display: "flex", marginLeft: "10px", padding: "10px" }}>
                                        <div>
                                          <img
                                            src={obj.profile_picture ? obj.profile_picture : "/images/male_defualt.svg"}
                                            style={{ width: "80px", height: "80px", borderRadius: "50%", objectFit: "cover" }}
                                            alt="profile_picture"
                                          />
                                        </div>
                                        <div style={{ marginLeft: "20px" }}>
                                          <h6 className={classes.headindRoomate}>
                                            {obj.first_name} {obj.last_name}
                                          </h6>
                                          <p className={classes.headindgender}>
                                            {obj.gender}
                                          </p>
                                          <p className={classes.headindgender}>
                                            {obj.email}
                                          </p>
                                        </div>
                                      </div>
                                      {/* {index !== objProperty.roommates.length - 1 && */}
                                      {/* <Divider style={{ color: "red" }} /> */}
                                      {/* } */}
                                    </>
                                  )
                                }) :
                                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                  <Typography
                                    variant="caption"
                                    component="primary"
                                    color="primary"
                                    style={{ fontSize: "14px", color: "gray", fontWeight: "400" }}
                                  >
                                    No Member found
                                  </Typography>
                                </div>
                              }
                            </Box>
                          </Grid>
                        </Grid>
                        {objProperty && objProperty.latitude && <LoadScript
                          id="script-loader"
                          // googleMapsApiKey={"AIzaSyBXLz2f0FmKJj-98ncABlISrs0WWxrymGo"}
                          // googleMapsApiKey={"AIzaSyCDoPmvnBPd0XTaGSftzJC25Hva6BYxYz0"}
                          // googleMapsApiKey={"AIzaSyCs8J8gkndmunp7FLbMCMvr7bqONTxl1Lk"}
                          googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          libraries={["places"]}

                        >
                          <MapTC
                            latitude={objProperty.latitude ? objProperty.latitude : 45}
                            longitude={objProperty.longitude ? objProperty.longitude : 45}
                            // latitude={45}
                            // longitude={45}
                            width="100%"
                          >
                          </MapTC>
                        </LoadScript>}
                      </Grid>
                    </>
                    :
                    <SkeletonLoading />
                  }
                </Grid>
                {/* </Container> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div >
    </>
  );
};
const SkeletonLoading = () => {
  return (
    <Grid item xs={12} md={12} style={{ marginLeft: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rect" width={"100%"} height={"260px"} style={{ borderRadius: "10px" }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" width={"100%"} height={"260px"} style={{ borderRadius: "10px" }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" width={"100%"} height={"260px"} style={{ borderRadius: "10px" }} />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Favourite;
