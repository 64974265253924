import React from "react";
import FaqDetail from "./component/PrivacyPolicyDetail";

const Faq = () => {
  return (
    <>
      <FaqDetail />
    </>
  );
};

export default Faq;
