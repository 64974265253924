import React, { useContext, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Container, Grid, Box, Button, Typography, Snackbar, CircularProgress } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import useRouter from 'utils/useRouter';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import AppDataContext from "Helper/AppDataContext";
import MuiAlert from '@material-ui/lab/Alert';

import Api from '../../Helper/ApiHandler';
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "90vh"
  },
  headingStep: {
    textAlign: "center",
    padding: "35px 0",
    margin: "0",
    fontSize: "40px !important",
    color: "#FE4B6B",
    backgroundColor: '#f6f6f6',
    fontWeight: 500
  },
  bgMainService: {
    background: "#f6f6f6",
    minHeight: "90vh"
  },
  cardBox: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // // justifyContent: "space-between",
    // background: "#FFF7F8",
    // width: "319px",
    // // height: "300px",
    // height: "350px",
    // marginBottom: "55px",
    // boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.1)',
    // textAlign: "center",
    // paddingBottom: "20px",
    // transition: "transform .2s",
    // border: "3px solid #FE4B6B",
    // "&:hover": {
    //   transform: "scale(1.05)"
    // },
    // cursor: "pointer"

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "space-between",
    background: "white",
    width: "319px",
    // height: "300px",
    height: "350px",
    marginBottom: "55px",
    boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.1)',
    textAlign: "center",
    paddingBottom: "20px",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.05)"
    },
    cursor: "pointer"
  },
  selectedcardBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "space-between",
    background: "#FFF7F8",
    width: "319px",
    // height: "300px",
    height: "350px",
    marginBottom: "55px",
    boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.1)',
    textAlign: "center",
    paddingBottom: "20px",
    transition: "transform .2s",
    border: "3px solid #FE4B6B",
    "&:hover": {
      transform: "scale(1.05)"
    },
    cursor: "pointer"
  },
  imgServiceRoot: {
    minHeight: "135px",
  },
  imgService: {
    // width: "100px",
    height: "100%",
  },
  textCard: {
    padding: "10px 10px",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: 300
  },
  learMoreButton: {
    //fontFamily: "Gilroy !important",
    textTransform: "capitalize",
    width: "141px",
    height: "45px",
    background: 'linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)',
    fontWeight: 400
  },
  cardRoot: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: '#f6f6f6'
  },
  cardHeader: {
    fontSize: "24px",
    fontWeight: 500
  },
  heading: {
    fontSize: "20px",
    color: "#FE4B6B",
    textAlign: "center",
    padding: "50px"
    // padding: "50px 150px"
  }
}));

const SelecteModule = (props) => {
  const { className, ...rest } = props;
  const { step1 } = (props.location && props.location.state) || {};
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const { appContext, setAppContext } = useContext(AppDataContext);
  const [selectedModule, setSelectedModule] = React.useState("Dormmates")
  const [loading, setLoding] = React.useState(false)
  const [sessionMessage, setSessionMessage] = React.useState({
    message: '',
    type: '',
    open: false
  });

  useEffect(() => {
    if (!step1) {
      router.history.push("/")
    }
  }, [step1]);


  const siwtchModule = (valueSelecte) => {
    if (valueSelecte === "Roommates") {
      if (appContext.objAuth.ROOMMATE) {
        // router.history.push("/selecte-roommate-module")
        router.history.push({
          pathname: `/selecte-roommate-module`,
          search: '',
          state: {
            step2: true
          }
        })
      } else {
        router.history.push("/comminsoon");
      }
    } else {
      if (valueSelecte === "Dormmates") {
        // if (process.env.REACT_APP_DORMMATES == "true") {
        if (appContext.objAuth.DORMMATE) {
          setAppContext({ ...appContext, currentModule: valueSelecte })
          AsyncLocalStorage.setItem("currentModule", valueSelecte)
          // window.location.replace("/signup-details")
          router.history.push({
            pathname: `/signup-details`,
            search: '',
            state: {
              step2: true
            }
          })
          // router.history.push("/signup-details")
        } else {
          router.history.push("/comminsoon");
        }
      } else if (valueSelecte === "Teammates") {
        if (appContext.objAuth.TEAMMATE) {
          setAppContext({ ...appContext, currentModule: valueSelecte })
          AsyncLocalStorage.setItem("quiz", true)
          AsyncLocalStorage.setItem("currentModule", valueSelecte)
          // window.location.replace("/signup-details")
          router.history.push({
            pathname: `/signup-details`,
            search: '',
            state: {
              step2: true
            }
          })
          // router.history.push("/signup-details")
        } else {
          router.history.push("/comminsoon");
        }
      } else if (valueSelecte === "Soulmates") {
        if (appContext.objAuth.SOULMATE) {
          setAppContext({ ...appContext, currentModule: valueSelecte })
          AsyncLocalStorage.setItem("currentModule", valueSelecte)
          // window.location.replace("/signup-details")
          router.history.push({
            pathname: `/signup-details`,
            search: '',
            state: {
              step2: true
            }
          })
          // router.history.push("/signup-details")
        } else {
          router.history.push("/comminsoon");
        }
      }
    }
  }

  const handleClose = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: false
    });
  }
  return (
    <Fade in={true} timeout={600}>
      <div className={classes.bgMainService}>
        {sessionMessage && (
          <Snackbar
            open={sessionMessage.open}
            autoHideDuration={3000}
            onClose={handleClose}
            style={{ zIndex: "22222" }}
          >
            <Alert
              onClose={handleClose}
              severity={sessionMessage.type}>
              {sessionMessage.message}
            </Alert>
          </Snackbar>
        )}
        {/* <h1 className={classes.headingStep}> Select Your Module </h1> */}
        <div className={classes.heading}>
          Choose your preference
        </div>
        <Box className={classes.cardRoot} px={2}>
          <Grid container spacing={2} justify="center" >
            {/* <Grid item> */}
            {/* </Grid> */}
            {appContext.objAuth?.DORMMATE ?
              <Grid item>
                <Box pt={3} className={selectedModule === "Dormmates" ? classes.selectedcardBox : classes.cardBox} onClick={() => { setSelectedModule("Dormmates") }}>
                  <div className={classes.imgServiceRoot}>
                    <img
                      className={classes.imgService}
                      src="/images/matchUp/service2.png"
                      alt="step Dormates icon"
                    />
                  </div>
                  <div className={classes.cardHeader}> DormMates </div>
                  <p className={classes.textCard}>
                    Matching with like minded people helps student improve the on-campus living experience for university students.
                  </p>
                </Box>
              </Grid> : ""}

            {appContext.objAuth?.TEAMMATE ?
              <Grid item>
                <Box className={selectedModule === "Teammates" ? classes.selectedcardBox : classes.cardBox} onClick={() => { setSelectedModule("Teammates") }}>
                  <div className={classes.imgServiceRoot}>
                    <img
                      className={classes.imgService}
                      src="/images/matchUp/team1.png"
                      alt="step Dormates icon"
                    />
                  </div>
                  <div className={classes.cardHeader}> TeamMates </div>
                  <p className={classes.textCard}>
                    Introduces advanced match technology allow project team to complete the task  on time to achieve the milestone.
                  </p>
                </Box>
              </Grid>
              : ""}
            {appContext.objAuth?.ROOMMATE ?
              <Grid item>
                <Box pt={3} className={selectedModule === "Roommates" ? classes.selectedcardBox : classes.cardBox} onClick={() => { setSelectedModule("Roommates") }}>
                  <div className={classes.imgServiceRoot}>
                    <img
                      className={classes.imgService}
                      src="/images/matchUp/service1.png"
                      alt="step Dormates icon"
                    />
                  </div>
                  <div className={classes.cardHeader}> RoomMates </div>
                  <p className={classes.textCard}>
                    Providing clear decision making criteria on the roommate expectations instead learn more after move in.
                  </p>
                </Box>
              </Grid>
              : ""}
            {appContext.objAuth?.SOULMATE ?
              <Grid item>
                <Box pt={3} className={selectedModule === "Soulmates" ? classes.selectedcardBox : classes.cardBox} onClick={() => { setSelectedModule("Soulmates") }}>
                  <div className={classes.imgServiceRoot}>
                    <img
                      className={classes.imgService}
                      src="/images/matchUp/service3.png"
                      alt="step Dormates icon"
                    />
                  </div>
                  <div className={classes.cardHeader}> SoulMates </div>
                  <p className={classes.textCard}>
                    A relationship platform connecting minds, mutual respect, an unconditional love with a better understanding of each other.
                  </p>
                </Box>
              </Grid>
              : ""}
          </Grid>
        </Box>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            style={{
              background: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
              color: "white",
              borderRadius: "20px",
              width: "200px"
            }}
            onClick={() => {
              siwtchModule(selectedModule)
            }}
          >
            Next
            {loading && <>&nbsp;<CircularProgress size={20} style={{ color: "white" }} /></>}
          </Button>
        </div>
      </div>
    </Fade>
  );
};

export default SelecteModule;

SelecteModule.propTypes = {
  className: PropTypes.string,
};
