import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ListMenuSideBar from "layouts/Main/components/ListMenuSideBar";
import ClearIcon from "@material-ui/icons/Clear";
import "react-fancybox/lib/fancybox.css";
import { Skeleton } from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert from "@material-ui/lab/Alert";

import Api from "../../../../Helper/ApiHandler";
import UserSkeletonCard from "components/Skeleton/UserSkeletonCard";
var api = new Api();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // backgroundColor: "E9EDF0 !important",
    minHeight: "calc(100vh - 125px)",
    // minHeight: "90vh",
    background: "#E9EDF0",
    padding: "35px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  root: {
    width: "100%",
    height: "110px",
    background: "white",
    paddingBottom: "10px !important",
  },
  labelSearch: {
    margin: "0",
    marginTop: "10px",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  searchInput: {
    width: "290px",
    marginRight: "10px",
    background: "#E9EDF0",
  },
  formControl: {
    width: "100%",
    background: "#E9EDF0",
  },
  dateInput: {
    width: "200px",
    background: "#E9EDF0",
  },
  buttonFilter: {
    textAlign: "center",
    marginTop: "35px",
    textTransform: "capitalize",
  },
  filterIconBtn: {
    marginLeft: "10px",
  },
  listBg: {
    background: "#E9EDF0",
  },
  matchesHead: {
    margin: "0",
    padding: "30px 15px",
    paddingBottom: "0px",
    fontWeight: "600 !important",
    fontSize: "18px",
  },
  cardUser: {
    background: "white",
    padding: "25px 10px",
    textAlign: "center",
    paddingBottom: "10px",
    margin: "7px",
    borderRadius: "15px",
    height: "auto",
  },
  nameTextCard: {
    color: "black",
    marginBottom: "0",
    marginTop: "0",
    fontSize: "18px",
    fontWeight: "400",
  },
  textCityName: {
    marginTop: "5px",
    color: "#6C6C6C",
    fontSize: "14px",
    maxHeight: "40px",
    minHeight: "22px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chipUserDetail: {
    whiteSpace: "nowrap",
    width: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "2px 5px",
    color: "#FE4B6B",
    fontWeight: "500",
    height: "20px",
    fontSize: "12px",
    background: "#FFEDF0",
    //fontFamily: "Gilroy !important",
  },
  cardMatched: {
    position: "relative",
  },
  cardBoxGrid: {
    paddingBottom: "60px",
    padding: "10px",
  },
  imageMatches: {
    width: "120px",
    height: "120px",
    // [theme.breakpoints.down('xs')]: {
    //   width: "80px",
    //   height: "80px",
    // },
    borderRadius: "50%",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
      height: "80px",
    },
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    // color: '#1a90ff',
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  hrLine: {
    borderBottom: "1px solid #000000",
    opacity: "0.1",
    marginBottom: "8px",
  },
  responsiveHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      width: "33.33% !important",
    },
    [theme.breakpoints.only("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "28%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "66.67%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "72%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "72%",
    },
  },
  carosalImageBox: {
    height: "120px",
    width: "120px",
    margin: "auto",
    backgroundColor: "gray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      width: 80,
      height: 80,
    },
  },
}));

const Favourite = (props) => {
  const classes = useStyles();
  const [favourite, setFavourite] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [sessionMessage, setSessionMessage] = React.useState({
    message: "",
    type: "",
    open: false,
  });

  const handleErorrClose = () => {
    setSessionMessage({
      message: "",
      type: "",
      open: false,
    });
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    setLoading(true);
    // Api Call For Fetch The Our Matches
    api
      .get("user/yourfavourite")
      .then((result) => {
        if (result.success === "true") {
          setFavourite(result.data.user);
        } else {
          console.log(result, "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "catch error");
        setLoading(false);
      });
  };

  const onClickLoad = () => {
    // Api Call For Fetch The Our Matches
    api
      .get("user/yourfavourite")
      .then((result) => {
        if (result.success === "true") {
          setFavourite(result.data.user);
        } else {
          console.log(result, "error");
        }
      })
      .catch((err) => {
        console.log(err, "catch error");
      });
  };

  const removeFav = (slug) => {
    api
      .post("user/addfavourite", {
        data: { slug: slug, isFavourite: true },
      })
      .then((result) => {
        if (result.success === "true") {
          onClickLoad();
          setSessionMessage({
            message: result.message,
            type: "success",
            open: true,
          });
        } else {
          console.log(result, "error");
          setSessionMessage({
            message: result.message,
            type: "error",
            open: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error catch");
      });
  };

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      {sessionMessage && (
        <Snackbar
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleErorrClose}
        >
          <Alert onClose={handleErorrClose} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.mainRoot}>
        <Container maxWidth="lg">
          <Grid spacing={3} container>
            <Grid className={classes.responsiveHide} item>
              <ListMenuSideBar />
            </Grid>
            <Grid item className={classes.container} spaccing={2}>
              <div className={classes.listBg}>
                {/* <Container maxWidth="lg"> */}
                <h5 className={classes.matchesHead}> My Favourite </h5>
                <Grid
                  justifyContent="center"
                  alignItems="center"
                  className={classes.cardBoxGrid}
                  container
                >
                  {!loading ? (
                    <>
                      {favourite.length ? (
                        favourite.map((item, index) => (
                          <Grid
                            key={index}
                            item
                            xs={6}
                            sm={6}
                            xl={3}
                            lg={3}
                            className={classes.cardMatched}
                          >
                            {/* <Link
                          style={{ textDecoration: "none" }}
                          to={`/your-matches/view/${item.users.slug}/${item.matchPercent}`}
                        > */}
                            <div
                              style={{ display: "flex", position: "relative" }}
                            >
                              <Box
                                top={30}
                                bottom={8}
                                left={20}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <ClearIcon
                                  style={{
                                    color: "#80808059",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    removeFav(item.slug);
                                  }}
                                />
                              </Box>
                              <Tooltip
                                title={`${capitalizeFirstLetter(
                                  item.first_name
                                )} and you  match ${item.matchPercent !== "NaN"
                                    ? item.matchPercent
                                    : 0
                                  }%`}
                                placement="top"
                              >
                                <Box position="absolute" top={15} right={15}>
                                  <CircularProgress
                                    variant="static"
                                    value={100}
                                    size={35}
                                    thickness={4}
                                    className={classes.bottom}
                                  />
                                  <CircularProgress
                                    variant="static"
                                    value={item.matchPercent}
                                    // value={25}
                                    size={35}
                                    thickness={4}
                                    className={classes.top}
                                    style={{ transform: "rotate(270deg)" }}
                                  />
                                  <Box
                                    top={0}
                                    left={0}
                                    bottom={8}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Typography
                                      variant="caption"
                                      component="div"
                                      color="textSecondary"
                                      style={{
                                        fontSize: "10px",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      {item.matchPercent !== "NaN"
                                        ? item.matchPercent
                                        : 0}
                                      %
                                    </Typography>
                                  </Box>
                                </Box>
                              </Tooltip>
                            </div>

                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/favourit/view/${item.slug}`}
                            >
                              <div className={classes.cardUser}>
                                <div className={classes.carosalImageBox}>
                                  <img
                                    src={
                                      item.profile_picture
                                        ? item.profile_picture
                                        : "/images/male_defualt.svg"
                                    }
                                    alt="profile_picture"
                                    className={classes.imageMatches}
                                  />
                                </div>
                                {/* <img
                                  src={
                                    item.profile_picture
                                      ? item.profile_picture
                                      : "/images/male_defualt.svg"
                                  }
                                  alt="UserImage"
                                  className={classes.imageMatches}
                                /> */}
                                <Tooltip
                                  title={
                                    capitalizeFirstLetter(item.first_name) +
                                    "  " +
                                    capitalizeFirstLetter(item.last_name)
                                  }
                                  placement="top"
                                >
                                  <h4 className={classes.nameTextCard}>
                                    {capitalizeFirstLetter(item.first_name)}
                                  </h4>
                                </Tooltip>
                                <Tooltip title={"Location"} placement="top">
                                  <p className={classes.textCityName}>
                                    {item.location}
                                  </p>
                                </Tooltip>
                                {item.gender && item.zodiac && (
                                  <div className={classes.hrLine} />
                                )}
                                {item.gender && (
                                  <>
                                    {!item.zodiac && (
                                      <div className={classes.hrLine} />
                                    )}
                                  </>
                                )}
                                {item.zodiac && (
                                  <>
                                    {!item.gender && (
                                      <div className={classes.hrLine} />
                                    )}
                                  </>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {item.gender && (
                                    <Tooltip
                                      title={capitalizeFirstLetter(item.gender)}
                                      placement="top"
                                    >
                                      <Chip
                                        className={classes.chipUserDetail}
                                        label={capitalizeFirstLetter(
                                          item.gender
                                        )}
                                      />
                                    </Tooltip>
                                  )}
                                  {item.zodiac && (
                                    <Tooltip
                                      title={item.zodiac}
                                      placement="top"
                                    >
                                      <Chip
                                        className={classes.chipUserDetail}
                                        label={capitalizeFirstLetter(
                                          item.zodiac
                                        )}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            </Link>
                            {/* </Link> */}
                          </Grid>
                        ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            marginTop: "50px"
                          }}
                        >
                          <img
                            src="/images/matchUp/notFoundMatches.svg"
                            alt="favAlt"
                          />
                          <Typography
                            variant="caption"
                            component="primary"
                            color="primary"
                            style={{
                              fontSize: "20px",
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            No Favourites Yet!
                          </Typography>
                          <Typography
                            variant="caption"
                            component="primary"
                            color="primary"
                            style={{
                              fontSize: "14px",
                              color: "gray",
                              fontWeight: "400",
                            }}
                          >
                            Mark your favorite profile and always have them
                            here.
                          </Typography>
                        </div>
                      )}
                    </>
                  ) : (
                    <SkeletonLoading />
                  )}
                </Grid>
                {/* </Container> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      // className={classes.cardBoxGrid}
      container
    >
      {[0, 1, 2, 3, 4, 5, 6, 7].map((obj) => {
        return (
          <Grid item xs={6} sm={6} xl={3} lg={3}>
            <UserSkeletonCard />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default Favourite;
