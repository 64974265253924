import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import firebase from "firebase/app";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { Badge } from '@material-ui/core';

export default function GroupCounter(props) {
  const { senderId, receiverId } = props
  const [unReadCounter, setUnReadCounter] = useState(0);
  const [clearAt, setClearAt] = useState(null);
  const [exitAt, setExitAt] = useState(null);

  // Base path of firebase
  const database = firebase.firestore();
  let db = database.collection("teammate").doc("chatRooms")

  useEffect(() => {
    getCounterFromFireBase(receiverId)
  }, [])

  //   .collection("CxmFUYvReaP2OSd9n05DTzoDfbq1")
  // .where("seen", "==", false)

  let getClearAt = async () => {
    let senderId = await AsyncLocalStorage.getItem("senderId");
    if (!clearAt) {
      const clearAtRef = db.collection("messageBox").doc(senderId).collection("inbox").doc(receiverId)
      clearAtRef.get().then((doc) => {
        if (doc.exists) {
          console.log("doc--==", doc.data().clearAt)
          setClearAt(doc.data().clearAt)
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    }
  }

  let getExitAt = async () => {
    if (!exitAt) {
      const exitAtRef = db.collection("messageBox").doc(senderId).collection("inbox").doc(receiverId)
      exitAtRef.onSnapshot((doc) => {
        if (doc.exists) {
          if (doc.exists) {
            console.log("doc--==", doc.data().exitAt)
            if (doc.data().exitAt) {
              if (exitAt) {
                if (exitAt?.seconds != doc.data().exitAt?.seconds) {
                  setExitAt(doc.data().exitAt)
                }
              } else {
                if (doc.data().exitAt) {
                  setExitAt(doc.data().exitAt)
                }
              }
            } else {
              setExitAt(undefined)
            }
          } else {
            if (doc.data().exitAt) {
              if (exitAt) {
                if (exitAt?.seconds != doc.data().exitAt?.seconds) {
                  setExitAt(doc.data().exitAt)
                }
              } else {
                if (doc.data().exitAt) {
                  setExitAt(doc.data().exitAt)
                }
              }
            } else {
              setExitAt(undefined)
            }
          }
          if (doc.data().exitAt) {
            setExitAt(doc.data().exitAt)
          }
        } else {
          console.log("No such document!");
        }
      });
    }
  }


  let getCounterFromFireBase = async (receiverId) => {
    var senderId = await AsyncLocalStorage.getItem("senderId")

    if (!clearAt) {
      getClearAt()
      return
    }
    // if (exitAt == null) {
    console.log("call--")

    getExitAt()
    if (exitAt == undefined || exitAt) {
      console.log("call++")
      const messageRef2 = exitAt ?
        db.collection("messageBox").doc(receiverId).collection("messages").where("createdAt", ">", clearAt).where("createdAt", "<", exitAt)
        :
        db.collection("messageBox").doc(receiverId).collection("messages").where("createdAt", ">", clearAt)

      // var messageRef = db.collection("messageBox").doc(receiverId).collection("messages").where('seen','array-contains', senderId)
      var messageRef = db.collection("messageBox").doc(receiverId).collection("messages")
      messageRef2.onSnapshot((querySnapshot) => {
        let temp = [];
        querySnapshot.forEach((mainDoc) => {
          if (!mainDoc.data().seen.includes(senderId)) {
            temp.push(mainDoc.data())
          }
          console.log(mainDoc.data(), "mainDoc.data()")
        });

        // let lstNew = temp.filter((obj) => !obj.seen.includes(senderId))
        console.log(temp, "temp.length")
        setUnReadCounter(temp.length)
      });
    } else {
      getExitAt()
    }
  }
  console.log(unReadCounter, "unReadCounter")
  return <div>
    <Badge badgeContent={unReadCounter} color="primary"></Badge>
  </div>

}
