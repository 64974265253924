import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
  Container,
  Avatar,
  Box,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import Api from '../../../../Helper/ApiHandler';
var api = new Api();

const useStyles = makeStyles((theme) => ({
  moduleItem: {
    margin: "0",
    padding: "8px",
  },
  moduleItemSelected: {
    margin: "0",
    padding: "8px",
    background: "linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)",
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    // marginTop: "8px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    // borderBottom: `1px solid #dedede`,
  },
}))(MenuItem);


const ModuleMates = (props) => {

  const classes = useStyles();
  const { handleCloseModule, module, ...rest } = props;
  const [selected, setSelected] = React.useState("");


  useEffect(() => {
    onLoad()
  }, []);


  const onLoad = async () => {
    let tmp = await AsyncLocalStorage.getItem("currentModule")
    setSelected(tmp)
  };

  const siwtchModule = (e, moduleName) => {
    handleCloseModule(e)
    // user/switchmodule
    api.put('user/switchmodule', {
      data: {
        module_name: moduleName
      }
    })
      .then((result) => {
        if (result.success === "true") {

          if(moduleName==="DormMates"){
            AsyncLocalStorage.setItem("currentModule", moduleName)
            window.location.reload();
          }else{
            window.location.replace("/comminsoon");
          }
        } else {

        }
      })
      .catch((err) => {
      });
  }
  return (
    <>
      <StyledMenu
        id="customized-menu"
        anchorEl={module}
        keepMounted
        value={selected}
        open={Boolean(module)}
        onClose={handleCloseModule}
      >
        <StyledMenuItem
          onClick={(e) => {
            siwtchModule(e, "DormMates")
          }}
        >
          <p className={classes.moduleItem}> DormMates </p>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <p className={classes.moduleItem}> TeamMates </p>
        </StyledMenuItem >
        <Divider />
        <StyledMenuItem onClick={(e) => {
          siwtchModule(e, "RoomMates")
          // handleCloseModule(e)
          // AsyncLocalStorage.setItem("currentModule", "roommates")
          // window.location.reload();
        }}>
          <p className={classes.moduleItem}> RoomMates </p>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <p className={classes.moduleItem}> SoulMates </p>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default ModuleMates;
