import React from "react";
import TeammateLandingDetails from "./component/TeammateLandingDetails";

const TeammateLanding = () => {
  return (
    <>
      <TeammateLandingDetails />
    </>
  );
};

export default TeammateLanding;
